import { TChartSpec } from 'components/general/types';

const statsOpModesChartSpec: TChartSpec = [
  {
    name: 'Operational Modes Overview',
    subtitle: 'Proportion of time each operational mode was active',
    plots: [
      {
        type: 'pie',
        unit: 'days',
        variables: [
          {
            each: 'OperationalMode',
            legend: ['{name}'],
            keys: ['isActive'],
            ops: ['integral'],
          },
        ],
      },
    ],
  },
];

export default statsOpModesChartSpec;
