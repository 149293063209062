import { IColumn, IGuidanceCard } from 'components/general/types';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityDialogControl } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import * as React from 'react';
import { ReactNode } from 'react';
import EntitySegment from './EntitySegment';

interface IProps<EntityType> {
  index: string;
  guidance: IGuidanceCard;
  entityColumns: IColumn[];
  entityData: EntityType[] | undefined;
  title: string;
  titleButton?: ReactNode;
  widgetTitle?: string;
  // In order to be passed down as a component to EntityTableSegment the react element cannot be typed as a generic React FC type.
  // It must be a specific type of React element that allows the constructor signature to be passed down with it.
  // React.ElementType allows this so any comoponent that needs to be passed down should be typed as such.
  DialogComponent?: React.ElementType<{
    control: TEntityDialogControl<EntityType>;
  }>;
  modelName: string;
  // children are currently prepended before table - change if different functionality is needed
  children?: ReactNode;
  customColumns?: boolean;
  cloningAllowed?: boolean;
}

// Component can be used for any segment including a widget table and guidance card. Typically used in edit boards to display multiple entities for the user to interact with (add/edit/delete)
// Generics used in functional component to match the Entity Type that comes in with the Config state.
const EntityTableSegment = <EntityType extends { id: number | string }>(
  props: IProps<EntityType>
) => {
  const classes = useStyles();

  const {
    index,
    guidance,
    entityColumns,
    modelName,
    entityData,
    title,
    titleButton,
    widgetTitle,
    DialogComponent,
    customColumns,
    cloningAllowed,
  } = props;
  const dialogControl = useEntityDialogControl<EntityType>();
  const { openDialogForNew, openDialogForExisting } = dialogControl;

  return (
    <>
      <EntitySegment title={title} index={index} guidance={guidance}>
        <div className={classes.preTableRow}>
          {widgetTitle && <h5>{widgetTitle}</h5>}
          {titleButton}
        </div>
        {props.children}
        <WidgetTable
          className={classes.table}
          columns={entityColumns}
          data={entityData}
          onActionClick={openDialogForExisting}
          onFabClick={openDialogForNew}
          modelName={modelName}
          customColumns={customColumns}
          cloningAllowed={cloningAllowed}
        />
      </EntitySegment>
      {dialogControl && dialogControl.dialogConfig.open && DialogComponent && (
        <DialogComponent control={dialogControl} />
      )}
    </>
  );
};

export default EntityTableSegment;
