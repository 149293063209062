import { Collapse, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { TStatsTableDatum } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import { ReactNode, useState } from 'react';
import StatsTableRow from './StatsTableRow';
import useStyles from './styles';

interface IStatsTableSectionProps {
  headerDatum: TStatsTableDatum;
  collapsibleContent: ReactNode;
  unit?: string;
  defaultExpanded?: boolean;
}

const StatsTableSection = (props: IStatsTableSectionProps) => {
  const { headerDatum, collapsibleContent, unit, defaultExpanded = true } = props;
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };
  const classes = useStyles();
  return (
    <>
      <StatsTableRow
        datum={headerDatum}
        unit={headerDatum?.unit || unit}
        expanded={expanded}
        onExpandClick={toggleExpanded}
        className={classes.sectionHeader}
      />
      <TableRow>
        <TableCell className={classes.statsTableNestedCell} colSpan={3}>
          <Collapse in={expanded} unmountOnExit>
            <Table>
              <TableBody>{collapsibleContent}</TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default StatsTableSection;
