import WGroup from 'components/general/WGroup';
import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesScenario } from 'components/ScenarioView/menu';
import AgentSegment from './AgentSegment';
import AgentGroupsSegment from './AgentGroupsSegment';
import TimeSegment from './TimeSegment';

const EditBoard = () => {
  return (
    <WGroup index={wGroupIndicesScenario.EDIT}>
      <Wizard>
        <TimeSegment index={wGroupIndicesScenario.EDIT_TIME} />
        <AgentSegment index={wGroupIndicesScenario.EDIT_AGENTS} />
        <AgentGroupsSegment index={wGroupIndicesScenario.EDIT_AGENTGROUPS} />
      </Wizard>
    </WGroup>
  );
};

export default EditBoard;
