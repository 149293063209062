import { faCheck, faDatabase, faProjectDiagram, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import clsx from 'clsx';
import A from 'components/general/A';
import StyledLabel from 'components/general/StyledLabel';
import SupportDialog from 'components/general/dialogs/SupportDialog';
import { LICENSE_AGREEMENT_URL, PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'config';
import { useUser } from 'hooks';
import { logo } from 'multimedia/brand';
import WelcomeView_1 from 'multimedia/examples/WelcomeView_1-min.png';
import WelcomeView_2A from 'multimedia/examples/WelcomeView_2A-min.png';
import WelcomeView_2B from 'multimedia/examples/WelcomeView_2B-min.png';
import WelcomeView_3 from 'multimedia/examples/WelcomeView_3-min.png';
import WelcomeView_4 from 'multimedia/examples/WelcomeView_4-min.png';
import cdhLogo from 'multimedia/icons/cdh.png';
import ebLogo from 'multimedia/icons/eb.png';
import gncLogo from 'multimedia/icons/gnc.png';
import linkLogo from 'multimedia/icons/link.png';
import mdLogo from 'multimedia/icons/md_welcome.png';
import thermLogo from 'multimedia/icons/therm.png';
import { useCallback, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { NavLink, useHistory } from 'react-router-dom';
import Routes from 'routes';
import theme from 'theme';
import useStyles from './styles';

const TimelineLeader = () => {
  const height = 70;
  return (
    <>
      <TimelineItem style={{ minHeight: height / 2 }}>
        <TimelineOppositeContent />
        <TimelineSeparator>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent />
      </TimelineItem>
      <TimelineItem style={{ minHeight: height / 2 }}>
        <TimelineOppositeContent />
        <TimelineSeparator>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent />
      </TimelineItem>
    </>
  );
};

const TimelineEntry = (props) => {
  const { children, header, outlinedDot, releaseDate, right } = props;
  const inline = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ inline });
  const entry = (
    <div
      className={clsx(
        classes.tlContent,
        right || inline ? classes.tlContentRight : classes.tlContentLeft,
        classes.tlContentWithHeader
      )}
    >
      <h3 className={clsx('accent', classes.tlItemHeader)}>{header}</h3>
      {inline && <h4>{releaseDate}</h4>}
      {children}
    </div>
  );
  return (
    <TimelineItem className={classes.tlItem}>
      <TimelineOppositeContent
        className={clsx(
          classes.tlOppositeContent,
          classes.tlContent,
          classes.tlContentHideOnMobile
        )}
      >
        <div
          className={clsx(
            classes.tlContent,
            !right ? classes.tlContentRight : classes.tlContentLeft
          )}
        >
          {!inline && <h4 className={clsx(classes.tlReleaseDate)}>{releaseDate}</h4>}
        </div>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector style={inline ? { height: 50 } : {}} />
        {inline ? (
          entry
        ) : (
          <TimelineDot
            variant={outlinedDot && 'outlined'}
            className={clsx(
              classes.tlDot,
              outlinedDot ? classes.tlDotOutlined : classes.tlDotFilled
            )}
          />
        )}
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent className={classes.tlContentHideOnMobile}>
        {!inline && entry}
      </TimelineContent>
    </TimelineItem>
  );
};

const SignUpOrContactButton = (props) => {
  const { user, openSupportDialog } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    if (!user) {
      history.push(Routes.SIGNUP());
    } else {
      openSupportDialog(e, true);
    }
  };
  return (
    <div className={classes.contactUsContainer}>
      <div
        className={clsx(
          classes.authLinkFramed,
          classes.authLink,
          classes.getStartedLink,
          classes.contactUsButton
        )}
        onClick={handleClick}
      >
        {!user ? `Sign up now for a 7-day evaluation` : 'Contact us'}
      </div>
    </div>
  );
};

const WelcomeView = (props) => {
  const user = useUser();
  const classes = useStyles();
  const [supportDialogConfig, setSupportDialogConfig] = useState({ open: false, sales: false });
  const openSupportDialog = useCallback(
    (e, sales) => {
      e.preventDefault();
      setSupportDialogConfig({ open: true, sales });
    },
    [setSupportDialogConfig]
  );

  return (
    <div className={classes.root}>
      <div className={classes.offerContainer}>
        <Container>
          <div className={classes.topBar}>
            <div className={classes.brandNavLink}>
              <img src={logo} alt="Rapid, efficient, digital satellite engineering with Sedaro" />
              {user && (
                <NavLink to={Routes.ACCOUNT()} className={classes.backToAccount}>
                  <ArrowBackIcon />
                  <h5>Back to my account</h5>
                </NavLink>
              )}
            </div>
            {!user && (
              <div className={classes.authLinksContainer}>
                <NavLink
                  to={Routes.LOGIN()}
                  className={clsx(classes.authLink, classes.authLinkFramed)}
                  type="button"
                >
                  Login
                </NavLink>
                <NavLink
                  to={Routes.SIGNUP()}
                  className={clsx(classes.authLink, classes.authLinkFilled)}
                  type="button"
                >
                  Sign up
                </NavLink>
              </div>
            )}
          </div>
          <div className={classes.centerHeading}>
            {/* {!user ? ( */}
            <>
              <h1>
                <strong>True</strong> spacecraft digital twins
              </h1>
              {!user && <SignUpOrContactButton openSupportDialog={openSupportDialog} user={user} />}
              <div className={classes.infoHook}>
                <h2>
                  Create, simulate, and analyze a <strong>virtual representation</strong> of your
                  spacecraft
                </h2>
              </div>
            </>
          </div>
        </Container>
      </div>
      <Container>
        <div className={classes.section}>
          <div className={classes.productViewWrapper}>
            <Fade duration={1500} right>
              <h2>
                Meet the world's first ever <strong>unified</strong> modeling platform for
                spacecraft
              </h2>
            </Fade>
            <Fade duration={1500} left>
              <div className={classes.browserView}>
                <img
                  className={classes.productView}
                  src={WelcomeView_1}
                  alt="Sedaro's intuitive, efficient user interfaces and cloud-based architecture make satellite modeling and simulation fast and easy"
                />
                <div className={clsx('control', 'control-red')}></div>
                <div className={clsx('control', 'control-yellow')}></div>
                <div className={clsx('control', 'control-green')}></div>
              </div>
            </Fade>
          </div>
          <div className={classes.productViewWrapper}>
            <Fade duration={1500} right>
              <h2>
                Quickly build and simulate a complex model of your satellite for use during{' '}
                <strong>design, integration, test, and operations</strong>
              </h2>
            </Fade>
            <Grid container spacing={6}>
              <Grid item lg={5} xs={12}>
                <Fade duration={1500} left>
                  <div className={classes.browserView}>
                    <img
                      className={clsx(classes.productView, classes.productViewCutoff)}
                      src={WelcomeView_2A}
                      alt="Design capture is completed in modern, streamlined interfaces which have been optimized for the task at hand and context-driven guidance leads the way"
                    />
                    <div className={clsx('control', 'control-red')}></div>
                    <div className={clsx('control', 'control-yellow')}></div>
                    <div className={clsx('control', 'control-green')}></div>
                  </div>
                </Fade>
              </Grid>
              <Grid item lg={7} xs={12}>
                <Fade duration={1500} right>
                  <div className={classes.browserView}>
                    <img
                      className={classes.productView}
                      src={WelcomeView_2B}
                      alt="Custom, specialized visualizations make analysis quick and easy, without the need for post-processing or third-party integrations"
                    />
                    <div className={clsx('control', 'control-red')}></div>
                    <div className={clsx('control', 'control-yellow')}></div>
                    <div className={clsx('control', 'control-green')}></div>
                  </div>
                </Fade>
              </Grid>
            </Grid>
          </div>
          <div className={classes.productViewWrapper}>
            <Fade duration={1500} right>
              <h2>
                <strong>Not just a flight dynamics tool,</strong> Sedaro models all engineering
                disciplines, including Power, Thermal, GNC, Comms, and C&DH
              </h2>
            </Fade>
            <Fade duration={1500} left>
              <div className={classes.browserView}>
                <img
                  className={classes.productView}
                  src={WelcomeView_3}
                  alt="Focus on the data that you need to ensure mission success and upload your own CAD model to make Sedaro your own"
                />
                <div className={clsx('control', 'control-red')}></div>
                <div className={clsx('control', 'control-yellow')}></div>
                <div className={clsx('control', 'control-green')}></div>
              </div>
            </Fade>
          </div>
          <div className={classes.productViewWrapper}>
            <Fade duration={1500} right>
              <h2>
                Rapidly generate key inputs for deeper spacecraft analysis and{' '}
                <strong>direct measures of mission performance</strong>
              </h2>
            </Fade>
            <Fade duration={1500} left>
              <div className={classes.browserView}>
                <img
                  className={classes.productView}
                  src={WelcomeView_4}
                  alt="Know at a glance whether your mission satisfies high-level performance metrics and key requirements"
                />
                <div className={clsx('control', 'control-red')}></div>
                <div className={clsx('control', 'control-yellow')}></div>
                <div className={clsx('control', 'control-green')}></div>
              </div>
            </Fade>
          </div>
        </div>
        <div className={classes.section}>
          <Grid container spacing={10} className={classes.sellingPointsContainer}>
            <Grid item xs={12}>
              <div className={classes.sellingPoint}>
                <FontAwesomeIcon icon={faServer} className={classes.sellingPointIcon} />
                <h3 className="accent">Regardless of what's under your hood</h3>
                <h2>Optimized, high-performance simulation in the cloud</h2>
                <div className={classes.sellingPointDescription}>
                  Gone are the days of expensive, in-house machines for modeling and simulation.
                  Leverage our <strong>managed, distributed </strong>
                  compute clusters to complete complex simulations
                  <strong> within minutes</strong>. Kick off your simulations from any device -
                  desktop to mobile.
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.sellingPoint}>
                <FontAwesomeIcon icon={faDatabase} className={classes.sellingPointIcon} />
                <h3 className="accent">Your data, anytime & anywhere</h3>
                <h2 className="wrap-5">Robust, accessible, and secure data storage</h2>
                <div className={classes.sellingPointDescription}>
                  Leave your data versioning, security, and back-ups to us so that you can{' '}
                  <strong>focus on the exciting challenges </strong>
                  unique to your mission.
                  <div className={classes.dataStorageBox}>
                    <div>
                      <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
                      <h3>Nightly backups</h3>
                    </div>
                    <div>
                      <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
                      <h3>State-of-the-art encryption</h3>
                    </div>
                    <div>
                      <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
                      <h3>Regulatory compliance</h3>
                    </div>
                    <div>
                      <FontAwesomeIcon className={classes.checkMark} icon={faCheck} />
                      <h3>Physical access controls</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.sellingPoint}>
                <FontAwesomeIcon icon={faProjectDiagram} className={classes.sellingPointIcon} />
                <h3 className="accent">Single source of truth</h3>
                <h2 className="wrap-4">Everything in one place</h2>
                <div className={classes.sellingPointDescription}>
                  Sedaro is structured to automate the exchange of engineering data between modules.
                  As additional modules are added to the platform, your team will be able to unleash
                  the full power of <strong>model-based engineering</strong> out of the box.
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          <div className={classes.centerHeading}>
            <h2>Modular structure for detailed, interdisciplinary simulation</h2>
            <h4>Sedaro modules automatically integrate with existing engineering data</h4>
          </div>
        </div>
        <Timeline align="left">
          <TimelineLeader />
          <TimelineEntry right>
            <div className={clsx(classes.moduleWrapper)}>
              <img
                className={classes.logoImage}
                src={mdLogo}
                alt="Design your satellite mission architecture, orbit, pointing, and operations in a modern, easy to use web-interface"
              />
              <div className={classes.moduleDetailsWrapper}>
                <div className={classes.moduleDetails}>
                  <h2>Scenarios</h2>
                  <h4>Multi-Agent - Orbits - Targets</h4>
                </div>
              </div>
            </div>
          </TimelineEntry>
          <TimelineEntry right>
            <div className={clsx(classes.moduleWrapper)}>
              <img
                className={classes.logoImage}
                src={ebLogo}
                alt="Size your spacecraft solar arrays, EPS, and batteries and launch with confidence that your power system is sufficient across all corner cases"
              />
              <div className={classes.moduleDetailsWrapper}>
                <div className={classes.moduleDetails}>
                  <h2>Power</h2>
                  <h4>Solar Arrays - PMAD - Batteries</h4>
                </div>
              </div>
            </div>
          </TimelineEntry>
          <TimelineEntry right>
            <div className={clsx(classes.moduleWrapper)}>
              <img
                className={classes.logoImage}
                src={thermLogo}
                alt="Model and simulate spacecraft sub-system-level thermal interfaces and thermal control devices on orbit"
              />
              <div className={classes.moduleDetailsWrapper}>
                <div className={classes.moduleDetails}>
                  <h2>Thermal</h2>
                  <h4>Interfaces - Materials - Control</h4>
                </div>
              </div>
            </div>
          </TimelineEntry>
          <TimelineEntry right>
            <div className={clsx(classes.moduleWrapper)}>
              <img
                className={classes.logoImage}
                src={gncLogo}
                alt="Ensure mission success, from launch to re-entry, by sizing your spacecrafts thrusters, prop tanks, ADACS, and deorbit devices"
              />
              <div className={classes.moduleDetailsWrapper}>
                <div className={classes.moduleDetails}>
                  <h2>GNC</h2>
                  <h4>Sensors - Actuators - Controllers</h4>
                </div>
              </div>
            </div>
          </TimelineEntry>
          <TimelineEntry right>
            <div className={clsx(classes.moduleWrapper)}>
              <img
                className={classes.logoImage}
                src={linkLogo}
                alt="Analyze realistic link and data margins using Mission Module ConOps data"
              />
              <div className={classes.moduleDetailsWrapper}>
                <div className={classes.moduleDetails}>
                  <h2>Data Handling</h2>
                  <h4>TX/RX - Networks - Data Flow</h4>
                </div>
              </div>
            </div>
          </TimelineEntry>
          <TimelineEntry right>
            <div className={clsx(classes.moduleWrapper)}>
              <img
                className={classes.logoImage}
                src={cdhLogo}
                alt="A testbed for spacecraft autonomy and commanded operations"
              />
              <div className={classes.moduleDetailsWrapper}>
                <div className={classes.moduleDetails}>
                  <h2>Command & Control</h2>
                  <h4>C2 - Processing - Autonomy</h4>
                </div>
              </div>
            </div>
          </TimelineEntry>
          <TimelineLeader />
        </Timeline>
        {/* <div className={classes.section}>
          <div className={clsx(classes.centerHeading)}>
            <h2>Not convinced?</h2>
            <h4>
              Watch our introductory demo where we design a complex mission in under 30 minutes
            </h4>
          </div>
          <div className={clsx(classes.videoContainer)}>
            <iframe
              className={classes.introVideo}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/O8OeATLvcy0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div> */}
        <div className={clsx(classes.section, classes.closer)}>
          <div className={classes.centerHeading}>
            <h2>Let's do this.</h2>
            <h4>Better satellite engineering is right around the corner</h4>
          </div>
          <SignUpOrContactButton user={user} openSupportDialog={openSupportDialog} />
        </div>
        <div className={classes.legal}>
          <StyledLabel monotone>
            <A href={LICENSE_AGREEMENT_URL} target="_blank">
              Licensing
            </A>
            {' | '}
            <A href={TERMS_OF_USE_URL} target="_blank">
              Terms
            </A>
            {' | '}
            <A href={PRIVACY_POLICY_URL} target="_blank">
              Privacy
            </A>
            {' | '}
            <A href="#" onClick={openSupportDialog}>
              Support
            </A>
          </StyledLabel>
        </div>
        <SupportDialog
          config={supportDialogConfig}
          onClose={() =>
            setSupportDialogConfig({
              ...supportDialogConfig,
              open: false,
            })
          }
        />
      </Container>
    </div>
  );
};

export default WelcomeView;
