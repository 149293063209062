import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  branchExplorerRoot: {
    margin: 30,
    maxWidth: 1200,
    minWidth: 400,
  },
  title: {
    borderBottom: '1px solid #dddddd',
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    marginBottom: '1rem',
    maxWidth: '60%',
    '& > h1': {
      display: 'flex',
      alignItems: 'center',
      height: '1.75rem',
    },
    '& > p': {
      margin: 0,
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  primaryAndSecondaryButtonContainer: {
    display: 'flex',
    columnGap: '0.65rem',
  },
}));

export default useStyles;
