import _ from 'lodash';
import { useCallback, useState } from 'react';

const resolveAlias = (guidance, g) => {
  if (_.has(g, 'alias')) {
    g = _.get(guidance, _.get(g, 'alias'));
    return resolveAlias(guidance, g);
  }
  return g;
};

const makeGuidance = (guidance) => {
  return (values) => {
    const [guidanceText, setGuidanceText] = useState();
    const guide = useCallback(
      (e, name, heading, newValue) => {
        const gValues = { ...values };
        if (newValue) gValues[name] = newValue;
        // If the name passed to guidance ends in a number then check if it's in an array
        // Typically we want the arrays to have the same guidance for all entries
        // So set the name to the parent key for guidance to display correctly
        if (name?.match(/[0-9]$/)) {
          let splitName = name?.split('.');
          if (!isNaN(splitName[splitName.length - 1])) {
            splitName.pop();
            name = splitName.join('.');
          }
        }
        let g = _.get(guidance, name, guidance['_default']);
        let props = [];
        if (typeof g === 'function') {
          g = g(gValues);
        }
        if (_.has(g, 'alias')) {
          if (_.has(g, 'props')) {
            props = _.get(g, 'props');
          }
          g = resolveAlias(guidance, g);
        }
        if (typeof g === 'function') {
          g = g(gValues, ...props);
        }
        let body = g;
        if (g.heading) {
          heading = g.heading;
        }
        if (g.body) {
          body = g.body;
        }
        setGuidanceText({
          heading: heading,
          body: body,
        });
      },
      [setGuidanceText, values]
    );
    if (!guidanceText) {
      guide();
    }

    return { guidance: guidanceText, guide };
  };
};

export default makeGuidance;
