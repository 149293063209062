import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import StyledDivider from 'components/general/StyledDivider';
import Dialog from 'components/general/dialogs/Dialog';
import { hotkeys } from 'config';
import { useActiveEntities } from 'hooks';
import { SpacecraftContext } from 'providers';
import { useContext } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { SubsystemVables } from 'utils/vable';
import CADModelPanel from './CADModelPanel';
import CreateSubsystemForm from './CreateSubsystemForm';
import GeometryPanel from './GeometryPanel';
import ReferenceVectorsPanel from './ReferenceVectorsPanel';
import SubsystemPanel from './SubsystemPanel';
import TabPanel from './TabPanel';
import useStyles from './styles';

const CUSTOM = SubsystemVables.Categories.CUSTOM.value;
export const ssTabsBeginIdx = 4;

const SpacecraftDialog = (props) => {
  const { ...remainingProps } = props;
  const { spacecraftDialogConfig, closeSpacecraftDialog, setSpacecraftDialogConfig } =
    useContext(SpacecraftContext);
  const { subsystems } = useActiveEntities();
  const { open, tabNumber } = spacecraftDialogConfig;
  const classes = useStyles();

  const lastSS = subsystems.length + ssTabsBeginIdx;

  useHotkeys(
    hotkeys.SC_DIALOG.keys,
    () =>
      spacecraftDialogConfig.open
        ? closeSpacecraftDialog()
        : setSpacecraftDialogConfig((prev) => ({ ...prev, open: true })),
    [spacecraftDialogConfig, closeSpacecraftDialog, setSpacecraftDialogConfig]
  );

  return (
    <Dialog title="Spacecraft" open={open} noButtons onClose={closeSpacecraftDialog} xxlarge>
      <StyledDivider className={classes.hr} />
      <div className={classes.tabsWrapper}>
        <Tabs
          orientation="vertical"
          value={tabNumber}
          onChange={(_event, newValue) => {
            setSpacecraftDialogConfig((prev) => ({ ...prev, tabNumber: newValue }));
          }}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="CAD Model" />
          <Tab label="Reference Vectors" />
          <Tab label="Geometry" />
          <h5 style={{ margin: '1rem 0' }}>Subsystems</h5>
          {subsystems.map((ss) => (
            <Tab
              key={ss.id}
              label={<p style={ss.category === CUSTOM ? { fontStyle: 'italic' } : {}}>{ss.name}</p>}
            />
          ))}
          <CreateSubsystemForm
            setTabToLastSS={() =>
              setSpacecraftDialogConfig((prev) => ({ ...prev, tabNumber: lastSS }))
            }
          />
        </Tabs>

        <TabPanel value={tabNumber} index={0}>
          <CADModelPanel {...remainingProps} />
        </TabPanel>
        <TabPanel value={tabNumber} index={1}>
          <ReferenceVectorsPanel {...remainingProps} />
        </TabPanel>
        <TabPanel value={tabNumber} index={2}>
          <GeometryPanel {...remainingProps} />
        </TabPanel>
        {subsystems.map((ss, i) => (
          <TabPanel value={tabNumber} index={ssTabsBeginIdx + i} key={ss.id}>
            <SubsystemPanel
              subsystem={ss}
              // makes sure the selected tab (blue vertical highlight line) doesn't end up on the "+ Subsystem" button/form (which is also in a tab slot)
              preventTabOnAddCustomButton={() =>
                setSpacecraftDialogConfig(({ open, tabNumber }) => ({
                  open,
                  tabNumber: tabNumber === lastSS - 1 ? tabNumber - 1 : tabNumber,
                }))
              }
            />
          </TabPanel>
        ))}
      </div>
    </Dialog>
  );
};

export default SpacecraftDialog;
