import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { ISolarArray, ISolarPanel } from 'components/general/types/power';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import * as Yup from 'yup';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import useGuidance from './guidance';
import { useActiveEntities } from 'hooks';
import { PowerAccent } from 'components/general/Accent/variants';
import { translateOut } from 'utils/forms';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { TBlockId } from 'components/general/types';

interface IForm {
  name: string;
  panels?: ISolarPanel[];
}

interface IProps {
  control: TEntityDialogControl<ISolarArray>;
}

const defaultValues = {
  name: '',
};

const validation = Yup.object().shape({
  name: Yup.string()
    .required('A solar array name is required.')
    .max(32, 'Solar array name must be no more than 32 characters.'),
});

const solarPanelTableColumns = [{ title: 'Name', field: 'name' }];

interface IParsedSolarPanel {
  id: TBlockId;
  name: string;
  tableData: {
    checked: boolean;
  };
}

const Dialog = (props: IProps) => {
  // Handle props
  const { control } = props;
  const { dialogConfig } = control;
  const { entity: solarArray } = dialogConfig;

  // Grab entities and actions
  const { solarArrays, solarPanels, powerProcessor } = useActiveEntities();

  // Set up table data
  const tableRef = useRef(null);
  const [parsedSolarPanels, setParsedSolarPanels] = useState<IParsedSolarPanel[]>([]);

  // Finds panels not already connected to other arrays so that the UI doesn't give the choice to select already-connected panels
  // When a panel is disconnected from a solar array, the new panel is updated in the store, but the old one does not have the array removed
  // So we filter the panels available by filtering out all panels not associated to an array (while keeping panels attached to the current array)
  const attachableSolarPanels = useMemo(() => {
    const attachedPanels: TBlockId[] = [];
    solarArrays.forEach((array) => {
      if (array.id === solarArray?.id) return;
      attachedPanels.push(...array.panels.map(({ id }) => id));
    });
    return solarPanels.filter((panel) => !attachedPanels.includes(panel.id));
  }, [solarPanels, solarArray, solarArrays]);

  const initSolarPanels = useCallback(() => {
    let _parsedSolarPanels: IParsedSolarPanel[] = [];
    if (attachableSolarPanels) {
      _parsedSolarPanels = attachableSolarPanels.map((panel: ISolarPanel) => ({
        id: panel.id,
        name: panel.name,
        tableData: { checked: !!solarArray?.panels?.map(({ id }) => id).includes(panel.id) },
      }));
    }
    setParsedSolarPanels(_parsedSolarPanels);
  }, [solarArray, attachableSolarPanels, setParsedSolarPanels]);

  useEffect(() => {
    initSolarPanels();
  }, [initSolarPanels]);

  const customTranslateOut = useCallback(
    (values) => {
      values.panels = parsedSolarPanels
        .filter((panel: IParsedSolarPanel) => panel.tableData?.checked)
        .map((panel) => panel.id);
      return translateOut(values);
    },
    [parsedSolarPanels]
  );

  const validateForm = useCallback((values: IForm, action?: string): string | boolean => {
    if (values.panels && values.panels.length < 1) {
      return 'A solar array requires at least one solar panel.';
    }
    return false;
  }, []);

  // Set up styles
  const classes = useStyles();

  // Hook up form
  const entityForm = useEntityForm<ISolarArray, IForm>({
    entityTypeText: 'Solar Array',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    validateForm,
    extendReset: initSolarPanels,
    additionalCreateValues: { powerProcessor: powerProcessor?.id, type: 'SolarArray' },
    formikOptionalParams: { useGuidance, translateOut: customTranslateOut },
  });

  const { formik } = entityForm;
  const { getFieldProps, setFieldValue } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Solar Array Name"
            type="text"
            placeholder="Solar Array Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <PowerAccent header="Solar Panels">
            <WidgetTable
              tableRef={tableRef}
              className={classes.table}
              columns={solarPanelTableColumns}
              data={parsedSolarPanels}
              setData={setParsedSolarPanels}
              // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
              // It is used through a level of indirection here to set the value on the form data and therefore mark
              // the form as dirty. This will enable the save button for the form.
              onSelectionChange={(newTableData) => setFieldValue('panels', newTableData)}
              selection={true}
              title="Panels"
              search={true}
              emptyMessage="No unallocated solar panels found"
            />
          </PowerAccent>
        </div>
      </div>
    </EntityDialog>
  );
};

export default Dialog;
