export const BLOCK_TYPE_NAMES = [
  'Cooler',
  'SatelliteToScalarCondition',
  'LoadState',
  'Heater',
  'SolarArray',
  'RectangularFieldOfView',
  'SphericalFuelTank',
  'FullyRegDetPowerProcessor',
  'TransmitDataInterface',
  'StaticThrustControlAlgorithm',
  'SpherocylinderFuelTank',
  'DataStorage',
  'PowerProcessor',
  'Satellite',
  'VectorInFovCondition',
  'GroundTarget',
  'MEKFAlgorithm',
  'OpticalAttitudeSensor',
  'PositionSensor',
  'Antenna',
  'BatteryCell',
  'SolarPanel',
  'LaserCommModule',
  'TargetGroupToSatelliteCondition',
  'PassiveTransmitInterface',
  'BodyInFovCondition',
  'MaxAlignPointingMode',
  'SingleConvMpptPowerProcessor',
  'PassivePointingMode',
  'CelestialVector',
  'SpaceTarget',
  'EKFAlgorithm',
  'TargetGroupToTargetCondition',
  'FuelReservoir',
  'ReactionWheel',
  'PIDAlgorithm',
  'ExternalDataInterface',
  'VectorSensor',
  'TimeCondition',
  'DataBus',
  'TargetGroup',
  'Thruster',
  'TargetGroupVector',
  'Magnetorquer',
  'Component',
  'Battery',
  'ThermalInterfaceMaterial',
  'TempControllerState',
  'SolarCell',
  'BodyFrameVector',
  'SatelliteToSatelliteCondition',
  'AngularVelocitySensor',
  'LocalVector',
  'ReceiveInterface',
  'SurfaceMaterial',
  'AveragingAlgorithm',
  'TargetGroupInFovCondition',
  'CelestialTarget',
  'TriadAlgorithm',
  'BatteryPack',
  'TargetGroupToScalarCondition',
  'SunTrackingSurface',
  'Modem',
  'InternalDataInterface',
  'LockPointingMode',
  'SatelliteToTargetCondition',
  'DirectionSensor',
  'ResistanceLoad',
  'Subsystem',
  'OperationalMode',
  'DataType',
  'GPSAlgorithm',
  'TargetInFovCondition',
  'PointingMode',
  'ActivePointingMode',
  'ThermalInterface',
  'CircularFieldOfView',
  'SingleConvHybridPowerProcessor',
  'Orbit',
  'CompoundCondition',
  'DataMode',
  'TargetVector',
  'TargetToTargetCondition',
  'FixedSurface',
  'SimulatableSatellite',
  'VectorTrackingSurface',
  'CooperativeTransmitInterface',
  'ReferenceVector',
  'TargetToScalarCondition',
  'QuasiRegDetPowerProcessor',
  'DataInterface',
  'SameTargetMultiCondition',
  'SlidingModeAlgorithm',
  'BusRegulator',
  'TwoConvMpptPowerProcessor',
  'PowerLoad',
  'Agent',
  'ClockConfig',
  'AgentGroup',
  'DesignLayout',
];
