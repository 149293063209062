import { createSlice } from '@reduxjs/toolkit';

interface IMissionExplorerState {
  userId: number | undefined;
  activeWorkspaceId: number | undefined;
  previousWorkspaceId: number | undefined;
  activeMissionId: number | undefined;
  activeMissionVersionId: number | undefined;
  latestJobId: number | undefined;
}

const initialState: IMissionExplorerState = {
  userId: undefined,
  activeWorkspaceId: undefined,
  previousWorkspaceId: undefined,
  activeMissionId: undefined,
  activeMissionVersionId: undefined,
  latestJobId: undefined,
};

const missionExplorerSlice = createSlice({
  name: 'missionExplorer',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setActiveWorkspaceId: (state, action) => {
      state.activeWorkspaceId = action.payload;
    },
    setPreviousWorkspaceId: (state, action) => {
      state.previousWorkspaceId = action.payload;
    },
    setActiveMissionId: (state, action) => {
      state.activeMissionId = action.payload;
    },
    setActiveMissionVersionId: (state, action) => {
      state.activeMissionVersionId = action.payload;
    },
    setLatestJobId: (state, action) => {
      state.latestJobId = action.payload;
    },
  },
});

export default missionExplorerSlice.reducer;
export const {
  setUserId,
  setActiveWorkspaceId,
  setPreviousWorkspaceId,
  setActiveMissionId,
  setActiveMissionVersionId,
  setLatestJobId,
} = missionExplorerSlice.actions;
