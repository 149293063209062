import { TChartSpec } from 'components/general/types';

const tsAttitudeControlDPSchema: TChartSpec = [
  {
    name: 'Attitude Error',
    subtitle: 'Commanded minus truth attitude and angular velocity',
    plots: [
      {
        type: 'line',
        label: 'ECI Error Angles',
        unit: 'rad',
        variables: [
          {
            keys: ['attitudeError.0', 'attitudeError.1', 'attitudeError.2'],
            legend: ['Error: Yaw', 'Error: Pitch', 'Error: Roll'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Body Frame Angular Velocity Error',
        unit: 'rpm',
        variables: [
          {
            keys: [
              'angularVelocityError.rpm.0',
              'angularVelocityError.rpm.1',
              'angularVelocityError.rpm.2',
            ],
            legend: ['Error: x', 'Error: y', 'Error: z'],
          },
        ],
      },
    ],
  },
  {
    name: 'Reaction Wheels',
    plots: [
      {
        type: 'line',
        label: 'Commanded Torque',
        unit: 'N-m',
        variables: [
          {
            keys: ['ReactionWheel.$each.commandedTorqueMagnitude'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Speed',
        unit: 'rad/s',
        variables: [
          {
            keys: ['ReactionWheel.$each.speed'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Angular Momentum',
        unit: 'kg m^2 / s',
        variables: [
          {
            keys: ['ReactionWheel.$each.momentum'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Torque Margin',
        variables: [
          {
            keys: ['ReactionWheel.$each.torqueMargin'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Momentum Margin',
        variables: [
          {
            keys: ['ReactionWheel.$each.momentumMargin'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Magnetorquers',
    plots: [
      {
        type: 'line',
        label: 'Commanded Torque',
        unit: 'N-m',
        variables: [
          {
            keys: ['Magnetorquer.$each.commandedTorqueMagnitude'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Magnetic Moment',
        unit: 'N-m/T',
        variables: [
          {
            keys: ['Magnetorquer.$each.magneticMoment'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Magnetic Moment Margin',
        variables: [
          {
            keys: ['Magnetorquer.$each.magneticMomentMargin'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Duty Cycle',
        variables: [
          {
            keys: ['Magnetorquer.$each.pwmDutyCycle'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
];

export default tsAttitudeControlDPSchema;
