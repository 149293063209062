import { TChartSpec } from 'components/general/types';

const tsSubsysLoadingChartSpec: TChartSpec = [
  {
    name: '"{name}" Subsystem Electronic Loading',
    subtitle: 'Loading by component',
    each: 'Subsystem',
    plots: [
      {
        type: 'line',
        label: 'Power Consumed',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: ['components.$each.powerConsumed'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
];

export default tsSubsysLoadingChartSpec;
