import { IMenu } from 'components/general/types';
import { ITEMS, TITLE } from 'config';
import Routes, { routePathsCommon } from 'routes';

const { REPOSITORIES } = routePathsCommon;
const MEMBERS = 'members';
const ROLES = 'roles';

const WorkspaceMenu: IMenu = {
  routeFn: Routes.WORKSPACE,
  dynamicItems: [],
  [ITEMS]: {
    [REPOSITORIES]: {
      [TITLE]: 'Repositories',
    },
    [MEMBERS]: {
      [TITLE]: 'Members',
    },
    [ROLES]: {
      [TITLE]: 'Roles',
    },
  },
};

export const wGroupIndicesWorkspace = {
  REPOSITORIES: `${REPOSITORIES}*`,
  MEMBERS: `${MEMBERS}*`,
  ROLES: `${ROLES}*`,
};

export default WorkspaceMenu;
