import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { TClickEvent } from 'components/general/types';
import { CSSProperties, useState } from 'react';
import useStyles from './styles';

interface IProps {
  text: string;
  style?: CSSProperties;
  displayLabel?: boolean;
}

const ClipboardCopy = ({ text, style = {}, displayLabel = true }: IProps) => {
  const [clicked, setClicked] = useState(false);
  const styles = useStyles();

  const saveToClipboard = (e: TClickEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    setClicked(true);
  };

  const resetClicked = () => setClicked(false);
  const cursor = clicked ? { cursor: 'default' } : { cursor: 'copy' };

  return (
    <span className={styles.outerSpan} style={style}>
      {displayLabel && text}
      <Tooltip title={clicked ? 'Copied' : 'Click to Copy ID'}>
        <span
          className={styles.innerSpan}
          style={cursor}
          onClick={saveToClipboard}
          onMouseEnter={resetClicked}
        >
          <FontAwesomeIcon icon={faClipboard} />
        </span>
      </Tooltip>
    </span>
  );
};

export default ClipboardCopy;
