import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { IComponent } from 'components/general/types/power';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import getThermalProps from 'hooks/getThermalProps';
import useGuidance from './guidance';
import validation from './validation';
import { TBlockId } from 'components/general/types';

const defaultValues = {
  name: '',
  manufacturer: '',
  partNumber: '',
};

const ComponentDialog = (props: {
  control: TEntityDialogControl<IComponent>;
  subsystemId: TBlockId;
}) => {
  const { control, subsystemId } = props;

  const classes = useStyles();

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const entityForm = useEntityForm<IComponent, typeof defaultValues & typeof thermalDefaultValues>({
    entityTypeText: 'Component',
    entityDialogControl: control,
    additionalCreateValues: {
      subsystem: subsystemId,
      type: 'Component',
    },
    defaultValues: {
      ...defaultValues,
      ...thermalDefaultValues,
    },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      allowedEmptyFields: ['manufacturer', 'partNumber'],
    },
  });

  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            label="Component Name"
            placeholder="Component Name"
            {...getFieldProps('name')}
            autoFocus
          />
          <LabeledInput
            label="Part Number"
            placeholder="Part Number"
            {...getFieldProps('partNumber')}
            optional
          />
          <LabeledInput
            label="Manufacturer"
            placeholder="Manufacturer"
            {...getFieldProps('manufacturer')}
            optional
          />
          {thermalPropsInput(getFieldProps)}
        </div>
      </div>
    </EntityDialog>
  );
};

export default ComponentDialog;
