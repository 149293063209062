import { getSeriesForKeys } from './series';

const initCommon = (givenCollection) => ({
  checkpoint: givenCollection?.checkpoint ?? -1,
  duration: givenCollection?.duration ?? 0,
});

const initStat = (givenCollection) => ({
  ...initCommon(givenCollection),
  //count: 0, // unused
  integral: null, // NOTE: This will disregard the last value until a new series data is appended, at which point its last value will be disregarded
  max: -Infinity, // initialize to -Infinity so that any number will replace max when stat.max is updated
  min: Infinity, // initialize to Infinity so that any number will replace min when stat.min is updated
  // ^^^ NaN and null no not exhibit intended behavior for initial max & min values, so test before changing
  //sum: null, // unused
});

const initStatCollection = () => ({
  ...initCommon(),
  collection: {},
});

export const initStatsData = () => ({
  stats: {},
});

const _updateStat = (stat, _series, time) => {
  let series = _series;
  let nullTime = 0;

  if (stat.collection) {
    for (const key in stat.collection) {
      _updateStat(stat.collection[key], _series[key], time);
      series = _series[key]; // For use below
    }
  } else {
    if (stat.checkpoint >= 0 && stat.min === Infinity) {
      // if the checkpoint is greater than -1 (its starting point) and min
      // is Infinity (no values in series) then set null time equal to duration
      nullTime = stat.duration;
    }
    for (let i = stat.checkpoint + 1; i < series.length; i++) {
      if (i > 0) {
        if (series[i - 1] != null && series[i] != null)
          stat.integral += ((series[i - 1] + series[i]) * (time[i] - time[i - 1])) / 2;
        else {
          // add to null time if the current or previous index is null so that it can be accounted for in the duration
          nullTime += time[i] - time[i - 1];
        }
      }
      const value = series[i];
      if (value === null) continue;
      stat.max = Math.max(stat.max, value);
      stat.min = Math.min(stat.min, value);
      //stat.sum += value; // unused
      //stat.count++; // unused
    }
  }
  stat.duration += time[series.length - 1] - time[Math.max(0, stat.checkpoint)] - nullTime; // time between valid series entries NOT COUNTING INVALID (null) ENTRIES
  stat.checkpoint = series.length - 1;
};

export const defineStat = (statsData, seriesData, seriesKey) => {
  const [, ...rest] = seriesKey.split('.');
  const [time, series] = getSeriesForKeys(seriesData, [rest.join('.')]);

  if (!statsData.stats[seriesKey]) {
    // Currently only supports collection depth of 1
    if (!Array.isArray(series)) {
      statsData.stats[seriesKey] = initStatCollection();
    } else {
      statsData.stats[seriesKey] = initStat();
    }
  }

  const stat = statsData.stats[seriesKey];

  // Add any values not currently in the collection
  if (stat.collection) {
    for (const key in series) {
      const absoluteKey = `${seriesKey}.${key}`;
      if (!stat.collection[key]) {
        if (!statsData.stats[absoluteKey]) statsData.stats[absoluteKey] = initStat(stat);
        stat.collection[key] = statsData.stats[absoluteKey];
      }
    }
  }

  // Update stat
  _updateStat(stat, series, time);
};

export const updateStatsData = (statsData, seriesData) => {
  for (const seriesKey in statsData.stats) {
    defineStat(statsData, seriesData, seriesKey);
  }
};

export const finalizeStat = (statsData, seriesKey) => {
  let result = {};

  const stat = statsData.stats[seriesKey];
  if (!stat) throw new Error(`No stat found for key ${seriesKey}`);

  if (stat.collection) {
    for (const key in stat.collection) {
      result = { ...result, ...finalizeStat(statsData, `${seriesKey}.${key}`) };
    }
  } else {
    result[seriesKey] = {
      integral: stat.integral,
      max: stat.max,
      min: stat.min,
      negativeMax: stat.min <= 0 ? -stat.min : null, // returns positive value of minimum value if it is not positive, returns null otherwise
      positiveMax: stat.max >= 0 ? stat.max : null, // returns maximum value if it is not negative, returns null otherwise
      avg: stat.integral / stat.duration, // get average by dividing integral by duration so that unequal time steps don't skew results
      absAvg: Math.abs(stat.integral / stat.duration), // the absolute value of the integral/duration average
    };
  }

  // throw new Error('Not implemented - average not weighted');

  return result;
};
