import { TChartSpec } from 'components/general/types';

const tsTempControllerChartSpec: TChartSpec = [
  {
    name: '"{name}" Temperature Controller - COOLER',
    subtitle: '',
    each: 'Cooler',
    plots: [
      {
        type: 'line',
        label: 'Heat Flow Rate',
        unit: 'W',
        variables: [
          {
            keys: ['regHeatFlowRate'],
            legend: ['"{name}" Heat Flow Rate'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Regulated Component Temperature',
        unit: 'C',
        variables: [
          {
            keys: ['commandedTemperature.degC', 'temperature.degC'],
            legend: ['Target Temperature', '"{name}" Actual Temperature'],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Temperature Controller - HEATER',
    subtitle: '',
    each: 'Heater',
    plots: [
      {
        type: 'line',
        label: 'Heat Flow Rate',
        unit: 'W',
        variables: [
          {
            keys: ['regHeatFlowRate'],
            legend: ['"{name}" Heat Flow Rate'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Regulated Component Temperature',
        unit: 'C',
        variables: [
          {
            keys: ['commandedTemperature.degC', 'temperature.degC'],
            legend: ['Commanded Temperature', '"{name}" Actual Temperature'],
          },
        ],
      },
    ],
  },
];

export default tsTempControllerChartSpec;
