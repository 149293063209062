import getThermalProps from 'hooks/getThermalProps';
import * as Yup from 'yup';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  name: Yup.string().required('A sensor name is required.'),
  manufacturer: Yup.string(),
  partNumber: Yup.string(),
  type: Yup.object().required('Select a sensor type.'),
  /* Handled by Tier 2 validation. Leaving in place in case user experience would benefit from earlier notice. */
  // referenceVector: Yup.object().when('type', {
  //   is: (type: ISelectOption) =>
  //     type?.value === SensorVables.Type.DirectionSensor.value ||
  //     type?.value === SensorVables.Type.VectorSensor.value,
  //   then: Yup.object().required('Reference vector is required.'),
  // }),
  // fieldOfView: Yup.object()
  //   .nullable()
  //   .when('type', {
  //     is: (type: ISelectOption) => type?.value === SensorVables.Type.OpticalAttitudeSensor.value,
  //     then: Yup.object().required('A field of view is required for optical attitude sensors.'),
  //   }),
  ...thermalValidation,
});

export default validation;
