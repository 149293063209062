import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import StyledButton from 'components/general/StyledButton';
import { TClickEvent } from 'components/general/types';
import { ReactElement } from 'react';
import { IButtonProps, IIconProps } from '../index';
import useStyles from './styles';

interface IProps {
  name: string;
  description: string | ReactElement;
  onClick?: () => void;
  editIconProps?: IIconProps;
  trashIconProps?: IIconProps;
  buttonProps?: IButtonProps;
  noHover?: boolean;
}

const Row = (props: IProps) => {
  const { name, description, onClick, buttonProps, editIconProps, trashIconProps } = props;

  const {
    onClick: btnOnClick,
    text: btnText,
    tooltip: btnTooltip,
    disabled: btnDisabled,
  } = buttonProps || {};
  const {
    onClick: editIconOnClick,
    tooltip: editIconTooltip,
    disabled: editIconDisabled,
  } = editIconProps || {};
  const {
    onClick: trashIconOnClick,
    tooltip: trashIconTooltip,
    disabled: trashIconDisabled,
  } = trashIconProps || {};

  const classes = useStyles({ noHover: !onClick });

  return (
    <div className={classes.row} onClick={onClick}>
      <div className={classes.infoContainer}>
        <h3>{name}</h3>
        <div className={classes.description}>{description}</div>
      </div>
      <div className={classes.buttonContainer}>
        {btnOnClick && (
          <StyledButton
            framed
            onClick={(e: TClickEvent) => {
              e.stopPropagation();
              btnOnClick();
            }}
            tooltip={btnTooltip}
            disabled={btnDisabled}
          >
            {btnText}
          </StyledButton>
        )}
        {editIconOnClick && (
          <StyledButton
            framed
            className={classes.iconButton}
            onClick={(e: TClickEvent) => {
              e.stopPropagation();
              editIconOnClick();
            }}
            tooltip={editIconTooltip}
            disabled={editIconDisabled}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </StyledButton>
        )}
        {trashIconOnClick && (
          <StyledButton
            className={clsx(classes.iconButton, classes.deleteButton)}
            onClick={(e: TClickEvent) => {
              e.stopPropagation();
              trashIconOnClick();
            }}
            tooltip={trashIconTooltip}
            disabled={trashIconDisabled}
          >
            <FontAwesomeIcon icon={faTrash} />
          </StyledButton>
        )}
      </div>
    </div>
  );
};

export default Row;
