import { TChartSpec } from 'components/general/types';

const tsOrbitChartSpec: TChartSpec = [
  {
    name: 'Geodetic Position',
    subtitle: 'True position, WGS84 ellipsoid',
    plots: [
      {
        type: 'line',
        label: 'Angle',
        unit: 'deg',
        labelRight: 'Altitude',
        unitRight: 'km',
        variables: [
          {
            keys: ['position.llaDeg.0', 'position.llaDeg.1'],
            legend: ['Latitude', 'Longitude'],
          },
          {
            keys: ['position.llaDeg.2'],
            legend: ['Altitude'],
            right: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Semi-Major Axis and Apsides',
    subtitle: 'Semi-major axis, apogee, and perigee',
    plots: [
      {
        type: 'line',
        label: 'Value',
        unit: 'km',
        variables: [
          {
            keys: ['orbitalElements.a', 'missionOrbit.radiusApogee', 'missionOrbit.radiusPerigee'],
            legend: ['Semi-Major Axis', 'Apogee', 'Perigee'],
          },
        ],
      },
    ],
  },
  {
    name: 'Orbital Elements',
    plots: [
      {
        type: 'line',
        unit: 'rad',
        variables: [
          {
            keys: ['orbitalElements.inc'],
            legend: ['Inclination'],
          },
        ],
      },
      {
        type: 'line',
        variables: [
          {
            keys: ['orbitalElements.e'],
            legend: ['Eccentricity'],
          },
        ],
      },
      {
        type: 'line',
        unit: 'rad',
        variables: [
          {
            keys: ['orbitalElements.eAnom', 'orbitalElements.mAnom', 'orbitalElements.nu'],
            legend: ['Eccentric Anomaly', 'Mean Anomaly', 'True Anomaly'],
          },
        ],
      },
      {
        type: 'line',
        unit: 'rad',
        variables: [
          {
            keys: ['orbitalElements.om'],
            legend: ['Argument of Periapsis'],
          },
        ],
      },
      {
        type: 'line',
        unit: 'rad',
        variables: [
          {
            keys: ['orbitalElements.raan'],
            legend: ['RAAN'],
          },
        ],
      },
    ],
  },
];

export default tsOrbitChartSpec;
