import { makeGuidance } from 'hooks';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Operational Mode Inputs',
    body: [
      {
        chunk:
          'Input a name and assign one of your pointing modes. You will be able to assign conditions once the operational mode has been saved. If this is not your default mode, apply conditions to the mode by checking them in the Conditions Table.',
      },
      {
        subHeading: 'Time Constraints',
        chunk:
          'An operational mode "occurrence" is a continuous period of time spent as the active operational mode. Use timing constraints to set a minimum duration, maximum duration, and/or minimum time between occurrences of this mode.',
      },
    ],
  },

  name: {
    alias: '_default',
  },

  pointingMode: {
    alias: '_default',
  },

  maxOccurrenceDuration: {
    min: {
      heading: 'Occurrence Duration and Frequency Limits',
      body: [
        {
          chunk:
            'Input either (1) minimum time between occurrences or (2) both maximum occurrence duration and minimum time between occurrences.',
        },
        {
          subHeading: 'Avoiding Back-to-Back Occurrences',
          chunk:
            'It is important to set an appropriate minimum time between occurrences when a maximum occurrence duration is applied. Otherwise, the spacecraft will switch back to this mode after a single simulation time-step each time the maximum duration is met.',
        },
        {
          subHeading: 'Comms Pass Application',
          chunk:
            'Communications passes over ground stations are essential for most satellite missions. However, in many cases, contact should not be made on every ground station pass to conserve power and prioritize other operational modes. A maximum duration can limit each pass to what is necessary for a complete link. A minimum time between occurrences can prevent the execution of more links than are necessary to meet data latency requirements and to prevent on-board memory from filling up.',
        },
      ],
    },
  },

  minTimeBetweenOccurrences: {
    min: {
      alias: 'maxOccurrenceDuration.min',
    },
  },

  minOccurrenceDuration: {
    min: {
      heading: 'Minimum Occurrence Duration',
      body: [
        {
          chunk:
            'Input a minimum occurrence duration. This will prevent entry into this operational mode if its time as the active mode will be insufficient.',
        },
        {
          subHeading: 'Comms Pass Application',
          chunk:
            'Communications passes over ground stations are essential for most satellite missions. Each pass must be long enough to include the time needed to establish contact and then complete a useful link. A minimum occurrence duration can ensure that passes that will not meet this requirement are not entered.',
        },
      ],
    },
  }
});
export default useGuidance;
