import { IMenu } from 'components/general/types';
import { ITEMS } from 'config';
import Routes from 'routes';
// import agentTargetsMenu from './targets';
import agentCdhMenu from './cdh';
import agentCustomMenu from './custom';
import agentDataHandlingMenu from './dataHandling';
import agentGncMenu from './gnc';
import agentPowerMenu from './power';
import agentSimulationMenu from './simulation';
import agentThermalMenu from './thermal';

const AgentAnalyzeMenu: IMenu = {
  routeFn: Routes.AGENT_ANALYZE,
  dynamicItems: [],
  [ITEMS]: {
    // ...agentTargetsMenu,
    ...agentGncMenu,
    ...agentCdhMenu,
    ...agentDataHandlingMenu,
    ...agentPowerMenu,
    ...agentThermalMenu,
    ...agentCustomMenu,
    ...agentSimulationMenu,
  },
};

export const wGroupIndicesAgentAnalyze = {};

export default AgentAnalyzeMenu;
