import { InputAdornment } from '@material-ui/core';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { IDataStorage } from 'components/general/types/dataHandling';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import _ from 'lodash';
import { useMemo } from 'react';
import { translateIn, translateOut } from 'utils/forms';
import { useGuidance } from './guidance';
import validation from './validation';

interface IForm {
  name: string;
  capacity: number | '';
  maxBitRateRead: number | '';
  maxBitRateWrite: number | '';
  component: ISelectOption | '';
}

interface IProps {
  control: TEntityDialogControl<IDataStorage>;
}

export interface IOptionsByCategory {
  [keys: string]: ISelectOption[];
}

const defaultValues: IForm = {
  name: '',
  capacity: '',
  maxBitRateRead: '',
  maxBitRateWrite: '',
  component: '',
};

const DataStorageDialog = ({ control }: IProps) => {
  const { components } = useActiveEntities();

  const initial: IOptionsByCategory = {};
  const componentOptionsBySubsystem = components.reduce((cummulative, component) => {
    const subsystemName: string = component.subsystem.name;
    if (_.has(cummulative, subsystemName)) {
      cummulative[subsystemName].push({ value: component.id, label: component.name });
    } else {
      cummulative[subsystemName] = [{ value: component.id, label: component.name }];
    }
    return cummulative;
  }, initial);

  const subLabeledComponents = Object.keys(componentOptionsBySubsystem).map((key) => ({
    label: key,
    options: componentOptionsBySubsystem[key],
  }));

  const options = useMemo(() => {
    return {
      component: subLabeledComponents,
    };
  }, [subLabeledComponents]);

  const classes = useStyles();
  const entityForm = useEntityForm<IDataStorage, IForm>({
    entityTypeText: 'Data Storage',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    additionalCreateValues: { type: 'DataStorage' },
    formikOptionalParams: {
      options,
      useGuidance,
      translateIn,
      translateOut,
    },
  });
  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <LabeledInput
          {...getFieldProps('name')}
          label="Storage Name"
          type="text"
          placeholder="Name"
          autoFocus
        />
        <LabeledInput
          {...getFieldProps('capacity')}
          label="Storage Capacity"
          type="number"
          endAdornment={<InputAdornment position="end">bits</InputAdornment>}
        />
        <LabeledInput
          {...getFieldProps('maxBitRateWrite')}
          label="Max Write Bitrate"
          type="number"
          endAdornment={<InputAdornment position="end">bit/s</InputAdornment>}
        />
        <LabeledInput
          {...getFieldProps('maxBitRateRead')}
          label="Max Read Bitrate"
          type="number"
          endAdornment={<InputAdornment position="end">bit/s</InputAdornment>}
        />
        <LabeledSelect
          {...getFieldProps('component')}
          label="Component"
          options={options.component}
        />
      </div>
    </EntityDialog>
  );
};

export default DataStorageDialog;
