import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { IReferenceVector } from 'components/general/types/spacecraft';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { Fragment, useCallback, useMemo } from 'react';
import { translateOut } from 'utils/forms';
import { ReferenceVectorVables, TLocalReferenceFrames } from 'utils/vable';
import useGuidance from './guidance';
import validation from './validation';

const FRAMES = new Set(ReferenceVectorVables.LocalReferenceFrames._keys);

interface IProps {
  control: TEntityDialogControl<IReferenceVector>;
}

interface IForm {
  representation: ISelectOption | '';
  celestialPointingDirection: ISelectOption | '';
  localPointingDirection: ISelectOption | '';
  referenceTarget: ISelectOption | '';
  targetGroup: ISelectOption | '';
  type: ISelectOption | '';
  name: ISelectOption | '';
  vectorCoordinates: ISelectOption | '';
}

const defaultValues: IForm = {
  representation: ReferenceVectorVables.Representation.options[0],
  celestialPointingDirection: '',
  localPointingDirection: '',
  referenceTarget: '',
  targetGroup: '',
  type: '',
  name: '',
  vectorCoordinates: '',
};

const ReferenceVectorDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action },
  } = control;
  const classes = useStyles();
  const { targets, targetGroups } = useActiveEntities();

  const options = useMemo(
    () => ({
      representation: ReferenceVectorVables.Representation.options,
      celestialPointingDirection: ReferenceVectorVables.CelestialPointingDirections.options,
      localPointingDirection: [
        {
          label: 'Reference Frames',
          options: ReferenceVectorVables.LocalReferenceFrames.options,
          value: '',
          backendField: 'localPointingDirection',
        },
        {
          label: 'Pointing Directions',
          options: ReferenceVectorVables.LocalPointingDirections.options,
          value: '',
          backendField: 'localPointingDirection',
        },
      ],
      referenceTarget: targets.map((t) => ({ value: t.id, label: t.name })),
      targetGroup: targetGroups.map((tg) => ({ value: tg.id, label: tg.name })),
      type: ReferenceVectorVables.Type.options,
    }),
    [targets, targetGroups]
  );

  // Auto-generate a name for reference vector
  const customTranslateOut = useCallback((refVector, allowedEmptyFields, options) => {
    // Note: newId is just a way to make sure every ref vec has a unique name
    // It might repeat sometimes, in which case it fails and you can just try again
    // You'd need to make 66.5k of the exact same type of ref vec through this UI to get a 1/1000 chance of repeating
    const newId = 'x'
      .repeat(7)
      .replace(
        /./g,
        (c) =>
          'abcdefghjklmnopqrstvwxyzABCDEFGHJKLMNOPQRSTVWXYZ0123456789'[
            Math.floor(Math.random() * 58)
          ]
      );
    switch (refVector.type.value) {
      case ReferenceVectorVables.Type.CelestialVector.value:
        refVector.name = `${refVector.celestialPointingDirection.label} - ${refVector.representation.label} (${newId})`;
        break;
      case ReferenceVectorVables.Type.LocalVector.value:
        if (!FRAMES.has(refVector.localPointingDirection.value)) {
          refVector.name = `${refVector.localPointingDirection.label} - ${refVector.representation.label} (${newId})`;
        }
        break;
      case ReferenceVectorVables.Type.TargetVector.value:
        refVector.name = `${refVector.referenceTarget.label} - Target ${refVector.representation.label} (${newId})`;
        break;
      case ReferenceVectorVables.Type.TargetGroupVector.value:
        refVector.name = `${refVector.targetGroup.label}  - Group ${refVector.representation.label} (${newId})`;
        break;
      default:
        break;
    }
    return translateOut(refVector, allowedEmptyFields, options);
  }, []);

  const entityForm = useEntityForm<IReferenceVector, IForm>({
    entityTypeText: 'Reference Vector',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('type')}
            label="Reference Vector Type"
            options={options.type}
            isDisabled={action !== 'create'}
          />
          <LabeledSelect
            {...getFieldProps('representation')}
            label="Representation used by algorithms"
            options={options.representation}
          />
          <div className={classes.indent}>
            {values.type === ReferenceVectorVables.Type.CelestialVector && (
              <LabeledSelect
                {...getFieldProps('celestialPointingDirection')}
                label="Celestial Pointing Direction"
                options={options.celestialPointingDirection}
              />
            )}
            {values.type === ReferenceVectorVables.Type.LocalVector && (
              <>
                <LabeledSelect
                  {...getFieldProps('localPointingDirection')}
                  label="Local Vector Definition"
                  options={options.localPointingDirection}
                />
                {values?.localPointingDirection &&
                  FRAMES.has(
                    values.localPointingDirection.value.toString() as TLocalReferenceFrames
                  ) && (
                    <Fragment>
                      <LabeledInput
                        {...getFieldProps('name')}
                        type="string"
                        label="Name"
                        autoFocus
                      />
                      <LabeledInput
                        {...getFieldProps('vectorCoordinates.0')}
                        type="number"
                        label="Vector x-component"
                      />
                      <LabeledInput
                        {...getFieldProps('vectorCoordinates.1')}
                        type="number"
                        label="Vector y-component"
                      />
                      <LabeledInput
                        {...getFieldProps('vectorCoordinates.2')}
                        type="number"
                        label="Vector z-component"
                      />
                    </Fragment>
                  )}
              </>
            )}
            {values.type === ReferenceVectorVables.Type.TargetVector && (
              <LabeledSelect
                {...getFieldProps('referenceTarget')}
                label="Target"
                options={options.referenceTarget}
              />
            )}
            {values.type === ReferenceVectorVables.Type.TargetGroupVector && (
              <LabeledSelect
                {...getFieldProps('targetGroup')}
                label="Target Group"
                options={options.targetGroup}
              />
            )}
          </div>
        </div>
      </div>
    </EntityDialog>
  );
};

export default ReferenceVectorDialog;
