import { ReactElement, useContext } from 'react';
import { IGenericObject, IMenu } from '../types';
import DataPlottingWidgetGroup from './DataPlottingWidgetGroup';
import _ from 'lodash';
import { SUB_ITEMS, TITLE, ITEMS, CHART_SPEC } from 'config';
import { RangeContext } from 'providers';
import { getSearchParams } from 'routes';
import { wGroupIndicesAgentSimulation } from 'components/AgentAnalyzeView/menu/simulation';
// import TimeSeriesPlaybackControls from 'components/general/PlaybackWGroups/TimeSeriesPlaybackControls';

interface IProps {
  menu: IMenu;
}

const genTimestepSpec = (agentId: string, engine: string, i: number) => ({
  name: engine,
  subtitle: `Step taken at each iteration of the ${engine} engine in seconds`,
  plots: [
    {
      type: 'line',
      label: 'Time Step',
      unit: 's',
      variables: [
        {
          keys: [`${agentId}/${i}.timeStep.s`],
          legend: ['Time Step'],
        },
      ],
    },
  ],
});

const createPlottingWGroups = (menu: IMenu, data: IGenericObject, agentId: string) => {
  const plottingWGroups: ReactElement[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _recurIterKeyVals = (obj: { [key: string]: any }, keys = '') => {
    _.forOwn(obj, (v, k) => {
      if (k === CHART_SPEC) {
        let _v = v;
        if (keys === wGroupIndicesAgentSimulation.TS_TS) {
          _v = [
            ...v, 
            ...['GNC', 'CC&DH', 'Power', 'Thermal'].map((engine, i) => genTimestepSpec(agentId, engine, i))
          ]
        }
        plottingWGroups.push(
          <DataPlottingWidgetGroup
            wGroupIndex={keys}
            data={data}
            chartSpec={_v}
            key={JSON.stringify(_v)}
          />
        );
        return;
      }

      let newKeys = keys;
      if (![ITEMS, SUB_ITEMS, TITLE].includes(k)) {
        if (newKeys.length) newKeys += '.';
        newKeys += k;
      }

      if (typeof v === 'object' && v !== null) {
        _recurIterKeyVals(v, newKeys);
      }
    });
  };

  _recurIterKeyVals(menu);
  return plottingWGroups;
};

const DataPlotting = ({ menu }: IProps) => {
  const { echartsData } = useContext(RangeContext);
  const { agentId } = getSearchParams();

  const plottingWGroups = createPlottingWGroups(menu, echartsData, agentId);

  return (
    <>
      {/* TODO: commented out for small sat, coment back in later */}
      {/* <TimeSeriesPlaybackControls /> */}
      {plottingWGroups}
    </>
  );
};

export default DataPlotting;
