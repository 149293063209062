import { IUser } from 'components/general/types/general';
import { useSelector } from 'react-redux';
import { useSelectById } from './stateHooks';

const useUser = () => {
  // TS ignore because we added ui to state
  //@ts-ignore: next-line
  const userId = useSelector((state) => state.ui.missionExplorer.userId);
  const user = useSelectById('User', userId) as IUser;
  return user;
};

export default useUser;
