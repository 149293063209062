import { IColumn } from 'components/general/types';
import { IDataBus } from 'components/general/types/dataHandling';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import DataBusDialog from './DataBusDialog';
import guidance from './guidance';

interface IProps {
  index: string;
}

const dataBusesColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
];

const DataBusSegment = ({ index }: IProps) => {
  const { dataBuses } = useActiveEntities();

  return (
    <EntityTableSegment<IDataBus>
      title="Data Buses"
      index={index}
      guidance={guidance}
      entityColumns={dataBusesColumns}
      entityData={dataBuses}
      DialogComponent={DataBusDialog}
      modelName="data bus"
    ></EntityTableSegment>
  );
};

export default DataBusSegment;
