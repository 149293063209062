import { TChartSpec } from 'components/general/types';

const tsConditionChartSpec: TChartSpec = [
  {
    name: 'Conditions',
    plots: [
      {
        name: 'Condition Status',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['Condition.$each.compliance'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Compound Condition: "{name}"',
    each: 'CompoundCondition',
    plots: [
      {
        name: 'Condition Status',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['compliance', 'conditions.$each.compliance'],
            legend: ['Overall Status', '{name}'],
          },
        ],
      },
    ],
  },
];

export default tsConditionChartSpec;
