import Tooltip from '@material-ui/core/Tooltip';
import AttitudeDisplay from 'components/general/SpacecraftDialog/general/AttitudeDisplay';
import StyledButton from 'components/general/StyledButton';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { SUPPORT_EMAIL } from 'config';
import { useActiveEntities, useInReadOnlyBranch, useSelectAll, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { SpacecraftContext } from 'providers';
import { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import Panel from './general/Panel';

// Required import (as of September '22, on v4.2) – gives babylon the ability to load glb and glTF files
import '@babylonjs/loaders/glTF';
import { getSearchParams } from 'routes';

const guidance = {
  heading: 'Define your Spacecraft CAD Model',
  body: [
    {
      chunk: 'Select a default CAD model or upload your own CAD model as a GLB file.',
    },
    {
      subHeading: 'Principal Axes',
      chunk:
        'It is important to ensure that the the principal axes in your CAD model are consistent with your spacecraft body-frame. This will ensure that spacecraft geometry parameters and attitude simulation results are consistent with your definition of the body-frame.',
    },
    {
      subHeading: 'Applications',
      chunk:
        'The spacecraft CAD model is a useful tool for visualizing attitude dynamics throughout Sedaro. This CAD model allows you to verify the orientation of spacecraft body frame vectors in the Geometry Panel of the Spacecraft Dialog. This CAD model will also appear in 3D playback animations - allowing you to easily verify that simulated attitude dynamics are consistent with the intended behavior.',
    },
    {
      subHeading: 'Model Size',
      chunk:
        'The size of the model is only relevant if agents are viewed using the "Scale Models to Real Size" setting when visualizing simulation results. Otherwise, all models are normalized to roughly the same size.',
    },
  ],
};
// bytes (7mb)
const maxFileSize = (5242880 * 20) / 5;

const CADModelPanel = (props) => {
  const { setOnSubmit, ...remainingProps } = props;

  const dispatch = useDispatch();
  const inReadOnlyBranch = useInReadOnlyBranch();
  const defaultCADModels = useSelectAll('DefaultCADModels');

  const {
    MissionVersion: {
      actions: { uploadCadFile, toggleDefaultModel },
    },
    DefaultCADModels: {
      actions: { getDefaultCADModels },
    },
  } = SatelliteApi;
  const { model, branch, bodyFrameVectors } = useActiveEntities();

  const { closeSpacecraftDialog } = useContext(SpacecraftContext);

  const [cadModelPreview, setCadModelPreview] = useState({
    fileUrl: model.cadSignedUrl,
    fileName: model.cadFileName,
  });

  const [fileToUpload, setFileToUpload] = useState(null);
  const [loading, setLoading] = useState(false);

  // allows for save button to be enabled when selecting default models
  const [defaultModelSelected, setDefaultModelSelected] = useState(false);

  // calculated and set in the CADModel component
  const [cadScaleFactor, setCadScaleFactor] = useState(1);
  const [modelAxisSizes, setModelAxisSizes] = useState(false);

  // set in CADModel component and used to reset model information if there is an error displaying user's file
  const [modelLoadErrorUrl, setModelLoadErrorUrl] = useState('');

  const [disableSubmit, setDisableSubmit] = useState(true);

  const [defaultModel, setDefaultModel] = useState(
    defaultCADModels.find((option) => model.cadSignedUrl === option.cadSignedUrl)
  );

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { share } = getSearchParams();

  // Reset state back to default if there is an error loading the model
  useEffect(() => {
    if (!modelLoadErrorUrl) return;

    let fileUrl, fileName;
    if (modelLoadErrorUrl === model.cadSignedUrl) {
      // this case is triggered if a url gets persisted onto the model but is no longer good, so reset to one of our defaults
      fileUrl = defaultCADModels[4].cadSignedUrl; // cube-sat
      fileName = `${model.cadFileName} (failed to load)`;
    } else {
      // this case is triggered if an uploaded but not yet saved file fails to load, so reset to what is on the model
      fileUrl = model.cadSignedUrl;
      fileName = model.cadFileName;
    }

    setCadModelPreview({ fileUrl, fileName });
    setModelLoadErrorUrl('');
    setFileToUpload(null);
    setDisableSubmit(true);
  }, [modelLoadErrorUrl]); // eslint-disable-line

  // Get list of default CAD models
  useEffect(() => {
    if (defaultCADModels.length) return;
    dispatch(
      getDefaultCADModels({
        queryParams: share ? { share } : undefined,
        successCallback: (response) => {
          setDefaultModel(
            response.find((response_i) => model.cadSignedUrl === response_i.cadSignedUrl)
          );
        },
        failureCallback: (response) => {
          if (response.error?.code === 'RESOURCE_NOT_FOUND') {
            enqueueSnackbar(
              `Failed to retrieve default CAD models. Please refresh the page and reach out to ${SUPPORT_EMAIL} if error persists.`
            );
          }
        },
      })
    );
  }, [dispatch, getDefaultCADModels, enqueueSnackbar, defaultCADModels]); // eslint-disable-line

  const changeDefaultModel = async (option) => {
    if (option.cadSignedUrl === cadModelPreview.fileUrl) return;
    setLoading(true);
    setCadModelPreview({
      fileUrl: option.cadSignedUrl,
      fileName: '',
    });
    if (option.cadSignedUrl === model.cadSignedUrl) {
      setDisableSubmit(true);
      setDefaultModelSelected(false);
    } else {
      setDisableSubmit(false);
      setDefaultModelSelected(true);
    }
    setDefaultModel(option);
  };

  const cancelChangeModel = async () => {
    setCadModelPreview({
      fileUrl: model.cadSignedUrl,
      fileName: model.cadFileName,
    });
    setFileToUpload(null);
    setDisableSubmit(true);
    setDefaultModel(defaultCADModels.find((option) => model.cadSignedUrl === option.cadSignedUrl));
    setDefaultModelSelected(false);
  };

  // Necessary to bypass bug in Babylon for files under 20 characters
  const prevalidateFileForBabylon = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          try {
            let arrayBuffer = evt.target.result;
            let array = new Uint8Array(arrayBuffer, 0, 20);
            resolve(array);
          } catch (e) {
            enqueueSnackbar(
              'There was an error loading your file. Please check that your file is not corrupted.'
            );
            setLoading(false);
            reject(e);
          }
        }
      };
    });
  };

  const handleFileAccept = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    // prevent uploading the exact same file twice
    if (
      file.lastModified === cadModelPreview.lastModified &&
      file.name === cadModelPreview.fileName
    )
      return;

    setLoading(true);

    // glTF Validation
    try {
      await prevalidateFileForBabylon(file);
    } catch (e) {
      return;
    }

    setFileToUpload(file);
    // Convert file to blob first for babylon preview
    const blob = new Blob([file]);
    setCadModelPreview({ fileUrl: blob, fileName: file.name, lastModified: file.lastModified });
    setDisableSubmit(false);

    // change defaultModelSelected back to false when user uploads to trigger correct onSubmit logic
    setDefaultModelSelected(false);
  };

  const handleFileReject = (rejectedFiles) => {
    if (rejectedFiles[0].file.size > maxFileSize) enqueueSnackbar('File must be less than 7MB.');
    else enqueueSnackbar('File must be a valid .glb or .glTF');
  };

  const onSubmit = async () => {
    setDisableSubmit(true);
    setLoading(true);
    // onSubmit for handling changing default model
    if (defaultModelSelected) {
      dispatch(
        toggleDefaultModel({
          id: branch.id,
          defaultCadModelId: String(defaultModel.id),
          successCallback: (response) => {
            setLoading(false);
            setFileToUpload(null);
            setDefaultModelSelected(false);
            enqueueSnackbar('Successfully updated CAD model.', { variant: 'success' });
          },
          failureCallback: (response) => {
            setLoading(false);
            setDisableSubmit(false);
            enqueueSnackbar(response.error.message);
          },
        })
      );
    }

    // onSubmit for cad file upload
    else {
      const formData = new FormData();

      formData.append('cadFile', fileToUpload);
      formData.append('cadScaleFactor', cadScaleFactor);

      setLoading(true);
      dispatch(
        uploadCadFile({
          id: branch.id,
          formData,
          successCallback: (response) => {
            // TODO: this is a temporary fix, the arg passed to successCallback should be just the single branch object,
            // however, when there are multiple branches in a repo, it is an array of all of the branches.
            const b = Array.isArray(response) ? response.find((b) => b.id === branch.id) : response;
            enqueueSnackbar('Model uploaded successfully!', { variant: 'success' });
            setCadModelPreview({
              fileUrl: b.model._root.cadSignedUrl,
              fileName: b.model._root.cadFileName,
            });
            setDefaultModel(null);
            setFileToUpload(null);
            setLoading(false);
            setDefaultModelSelected(false);
          },
          failureCallback: (response) => {
            setLoading(false);
            setDisableSubmit(false);
            enqueueSnackbar(response.error.message);
          },
        })
      );
    }
  };

  return (
    <Panel
      title="CAD Model"
      guidance={guidance}
      disableSubmit={disableSubmit}
      onClose={closeSpacecraftDialog}
      onSubmit={onSubmit}
      loading={loading}
      setLoading={setLoading}
      submitActionText="Save"
      secondaryActionText="Close"
      {...remainingProps}
    >
      <div
        className={
          cadModelPreview.fileName
            ? classes.cadFileUploaderContainer
            : classes.cadFileUploaderButton
        }
      >
        {cadModelPreview.fileName && (
          <Tooltip title={cadModelPreview.fileName.length > 60 ? cadModelPreview.fileName : ''}>
            <h4
              className={`sub ${classes.cadFileName}`}
            >{`Current Filename: ${cadModelPreview.fileName}`}</h4>
          </Tooltip>
        )}
        {(fileToUpload || defaultModelSelected) && (
          <StyledButton
            onClick={cancelChangeModel}
            disabled={!defaultModelSelected && !cadModelPreview.fileName}
            framed
            type="button"
            error
            loading={loading}
          >
            {'Cancel'}
          </StyledButton>
        )}
      </div>
      <AttitudeDisplay
        setCadScaleFactor={setCadScaleFactor}
        setModelAxisSizes={setModelAxisSizes}
        file={cadModelPreview}
        modelLoadErrorUrl={modelLoadErrorUrl}
        setModelLoadErrorUrl={setModelLoadErrorUrl}
        loading={loading}
        setLoading={setLoading}
        bodyFrameVectors={bodyFrameVectors}
      />
      <h3 className="sub">Model Size</h3>
      <table className={classes.modelSizeTable}>
        <tr>
          <th>X</th>
          <td>{Math.round(modelAxisSizes.x * 10000) / 10000} m</td>
          <th>Y</th>
          <td>{Math.round(modelAxisSizes.y * 10000) / 10000} m</td>
          <th>Z</th>
          <td>{Math.round(modelAxisSizes.z * 10000) / 10000} m</td>
        </tr>
      </table>
      <h3 className="sub">Upload a CAD model</h3>
      <Dropzone
        maxSize={maxFileSize}
        multiple={false}
        onDropRejected={handleFileReject}
        accept={{ 'model/gltf-binary': ['.glb'] }} // TODO: How can get .gtlf files to upload properly?
        onDropAccepted={handleFileAccept}
        disabled={inReadOnlyBranch}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div
              {...getRootProps({ className: classes.cadFileUploader })}
              style={{ cursor: inReadOnlyBranch ? 'no-drop' : 'auto' }}
            >
              <input type="file" {...getInputProps()} />
              <h4 className="sub">
                + Drag and drop file here or{' '}
                <StyledButton type="button" framed onClick={(e) => e.preventDefault()}>
                  Browse
                </StyledButton>
              </h4>
            </div>
          );
        }}
      </Dropzone>
      <h3 className={`sub ${classes.cadFileUploaderSubText}`}>or select a preloaded model</h3>
      <LabeledSelect
        label="Default Models"
        options={defaultCADModels}
        onChange={(value) => changeDefaultModel(value)}
        value={defaultModel}
      />
    </Panel>
  );
};

export default CADModelPanel;
