import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

const useStyles = makeStyles({});

export const barTheme = {
  axis: {
    ticks: {
      text: {
        fill: theme.palette.text.primary,
        ...theme.typography.h4,
        fontWeight: 500,
      },
      line: { stroke: theme.palette.background.contrastText },
    },
  },
  bars: {
    labels: {
      text: {
        fill: theme.palette.text.primary,
        ...theme.typography.subh4,
        fontWeight: 300,
      },
    },
    palette: {
      booleanColors: {
        positive: theme.palette.charts.booleanBars.primary.green.light,
        negative: theme.palette.charts.booleanBars.primary.red.light,
      },
      barColors: [
        theme.palette.charts.primary.blue,
        theme.palette.charts.primary.purple,
        theme.palette.charts.primary.yellow,
      ],
    },
  },
};

export default useStyles;
