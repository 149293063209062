import { makeGuidance } from 'hooks';

const dataTypeDescription =
  'Data Types are used to tag and track a particular kind of data. On a given Data Storage block, each Data Type will be stored and flow separately. For example, a camera may produce the Data Type "Imaging Data", which is stored and later downlinked to a ground station.';

export const dataTypeGuidance = {
  _default: {
    heading: 'Data Types',
    body: [{ chunk: dataTypeDescription }],
  },
};

export const useGuidance = makeGuidance(dataTypeGuidance);

const dataTypeSegmentGuidance = {
  heading: 'Data Types',
  body: [{ chunk: dataTypeDescription }],
};

export default dataTypeSegmentGuidance;
