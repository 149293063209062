import { IColumn } from 'components/general/types';
import { IFuelTank } from 'components/general/types/gnc';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import { FuelTankVables, TFuelTankTypes } from 'utils/vable';
import FuelTankDialog from './FuelTankDialog';
import guidance from './guidance';

interface IProps {
  index: string;
}

const fuelTanksColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (row) => FuelTankVables.Type[row.type as TFuelTankTypes].label,
  },
];

const FuelTanksSegment = ({ index }: IProps) => {
  const { fuelTanks } = useActiveEntities();
  return (
    <EntityTableSegment<IFuelTank>
      title="Fuel Tanks"
      index={index}
      guidance={guidance}
      entityColumns={fuelTanksColumns}
      entityData={fuelTanks}
      DialogComponent={FuelTankDialog}
      modelName="fuelTank"
    ></EntityTableSegment>
  );
};

export default FuelTanksSegment;
