import { faCodeBranch, faDownload, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import SpacecraftDialogButton from 'components/general/SpacecraftDialogButton';
import Xray from 'components/general/Xray';
import { IS_CES, hotkeys } from 'config';
import { useActiveEntities, useInReadOnlyBranch, usePermissionCheck } from 'hooks';
import useWorkspace from 'hooks/useWorkspace';
import mdLogo from 'multimedia/icons/md.png';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router-dom';
import Routes, { getSearchParams, routePathsCommon } from 'routes';
import { RepoVables, WorkspaceVables } from 'utils/vable';
import ShareableLinkDialog from './ShareableLinkDialog';
import useStyles from './styles';

import Tier2ValidationIcon from './Tier2ValidationIcon';
const { BRANCH, SCENARIO } = Routes;

const BranchIcons = () => {
  const history = useHistory();
  const classes = useStyles();
  const { repo, branch } = useActiveEntities();
  const { agentId, share } = getSearchParams();

  const inReadOnlyBranch = useInReadOnlyBranch();
  const canCreateShareableLink = usePermissionCheck(
    WorkspaceVables.Permission.CREATE_SHAREABLE_LINK
  );
  const inAnAgentTemplate = repo?.repoType === RepoVables.RepoTypes.AGENT_TEMPLATE.value;
  const inAgentAnalyze = !!agentId;

  const [shareLinkDialogOpen, setShareLinkDialogOpen] = useState(false);
  const [openXray, setOpenXray] = useState(false);

  const goBranches = () => history.push(BRANCH(repo?.id));
  const goScenario = () => {
    let scenarioPath = SCENARIO(branch.id, routePathsCommon.EDIT);
    if (share) scenarioPath += '?share=' + share;
    history.push(scenarioPath);
  };

  useHotkeys(hotkeys.BRANCHES.keys, goBranches, [goBranches]);

  const workspace = useWorkspace();
  const goToWorkspace = () => history.push(Routes.WORKSPACE(workspace.id, 'repositories'));
  useHotkeys(hotkeys.WORKSPACE.keys, goToWorkspace, [goToWorkspace]);

  return (
    <>
      <div>
        {inAnAgentTemplate && (
          <SpacecraftDialogButton iconButton className={classes.branchNavIcon} />
        )}
        {inAgentAnalyze && (
          <Tooltip arrow title={`Edit Scenario`}>
            <IconButton onClick={goScenario} className={classes.branchNavIcon}>
              <img src={mdLogo} className={classes.branchNavImg} alt="scenario-logo" />
            </IconButton>
          </Tooltip>
        )}
        {canCreateShareableLink && !IS_CES && (
          <Tooltip arrow title="Shareable Link">
            <IconButton
              className={classes.branchNavIcon}
              disabled={inReadOnlyBranch}
              onClick={() => setShareLinkDialogOpen(true)}
            >
              <FontAwesomeIcon icon={faLink} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip arrow title={`Repository's Branches [${hotkeys.BRANCHES.keys}]`}>
          <IconButton
            disabled={inReadOnlyBranch}
            onClick={goBranches}
            className={classes.branchNavIcon}
          >
            <FontAwesomeIcon icon={faCodeBranch} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={`View as SedaroML`}>
          <IconButton className={classes.branchNavIcon} onClick={() => setOpenXray(true)}>
            <Visibility className={classes.branchXRayIcon} />
          </IconButton>
        </Tooltip>
        <Tier2ValidationIcon />
        <Tooltip arrow title="Save As">
          {/* TODO: Fill out onClick, remove disabled prop, and remove react fragment wraping IconButton*/}
          <>
            <IconButton className={classes.branchNavIcon} disabled onClick={() => undefined}>
              <FontAwesomeIcon icon={faDownload} />
            </IconButton>
          </>
        </Tooltip>
        {branch.repoType === 'SCENARIO' ? (
          // TODO: Currently hidden to avoid overflowing the nav width, bring back once enabled
          // <Tooltip arrow title="Open">
          //   {/* TODO: Fill out onClick, remove disabled prop, and remove react fragment wraping IconButton*/}
          //   <>
          //     <IconButton className={classes.branchNavIcon} disabled onClick={() => undefined}>
          //       <FontAwesomeIcon icon={faFolderOpen} />
          //     </IconButton>
          //   </>
          // </Tooltip>
          <></>
        ) : (
            <></>
        )}
      </div>
      <ShareableLinkDialog
        open={shareLinkDialogOpen}
        closeDialog={() => setShareLinkDialogOpen(false)}
      />
      {branch && (
        <Xray
          title={branch.name || ''}
          data={branch.data}
          open={openXray}
          onClose={() => setOpenXray(false)}
        />
      )}
    </>
  );
};

export default BranchIcons;
