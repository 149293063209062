import { TChartSpec } from 'components/general/types';

const tsComponentDissipationChartSpec: TChartSpec = [
  {
    name: 'Dissipation Overview',
    subtitle: 'Dissipation by Subsystem',
    plots: [
      {
        type: 'line',
        label: 'Thermal Dissipation',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: ['Subsystem.$each.dissipations.total'],
            legend: ['"{name}" Total Dissipation'],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Subsystem Dissipation',
    subtitle: 'Dissipation by Component',
    each: 'Subsystem',
    plots: [
      {
        type: 'line',
        label: 'Dissipation',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: ['dissipations.total', 'components.$each.dissipations.total'],
            legend: ['Total Dissipation', '"{name}" Dissipation'],
          },
        ],
      },
    ],
  },
];

export default tsComponentDissipationChartSpec;
