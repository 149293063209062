export type TUnit = 'a' | 'c' | 'days' | 'deg' | 'km' | 'ms' | 'pct' | 'w' | 'wday' | 'whr';

type TAlias = {
  alias: TUnit;
  conversion: number;
};

type TScale = {
  unit?: string;
  units?: string[];
  unitsSingular?: string[];
  conversion?: number;
  conversions?: number[];
};

type TScales = {
  [unit in TUnit]: TAlias | TScale;
};

type TRepresentConfig = {
  plural?: boolean;
};

type TPrecisionConfig = {
  digits?: number; // significant figures override
  trailingZeros?: boolean; // include trailing zeros?
};

type TConfig = TRepresentConfig & TPrecisionConfig;

const scales: TScales = {
  // amps
  a: {
    units: ['A', 'kA', 'MA', 'GA', 'TA'],
    conversions: [1000, 1000, 1000, 1000],
  },
  // degrees Celsius
  c: {
    unit: '\u2103',
    conversion: 1,
  },
  // days
  days: {
    units: ['days', 'hrs', 'mins', 's'],
    conversions: [1 / 24, 1 / 60, 1 / 60],
  },
  // degrees
  deg: {
    unit: '\u00B0',
    conversion: 1,
  },
  // kilometer
  km: {
    unit: 'km',
    conversion: 1,
  },
  // milliseconds
  ms: {
    units: ['ms', 's', 'mins', 'hrs', 'days', 'yrs'],
    unitsSingular: ['ms', 'sec', 'min', 'hr', 'day', 'yr'],
    conversions: [1000, 60, 60, 24, 365.2422],
  },
  // percent
  pct: {
    unit: '%',
    conversion: 1 / 100,
  },
  // watts
  w: {
    units: ['W', 'kW', 'MW', 'GW', 'TW', 'PW'],
    conversions: [1000, 1000, 1000, 1000, 1000],
  },
  // watt-days
  wday: {
    alias: 'whr',
    conversion: 1 / 24,
  },
  // watt-hours
  whr: {
    units: ['W-hrs', 'kW-hrs', 'MW-hrs', 'GW-hrs', 'TW-hrs', 'PW-hrs'],
    unitsSingular: ['W-hr', 'kW-hr', 'MW-hr', 'GW-hr', 'TW-hr', 'PW-hr'],
    conversions: [1000, 1000, 1000, 1000, 1000],
  },
};

const SIG_FIGS = 4; // significant figures, default digits of precision for displayed values

export const represent = (value: number, baseUnit: TUnit, config: TConfig = {}) => {
  if (!value) value = 0; // Handle null case
  const { plural = true } = config;
  if (!scales[baseUnit]) throw new Error('Invalid baseUnit:' + baseUnit);

  while ('alias' in scales[baseUnit]) {
    const { alias, conversion } = scales[baseUnit] as TAlias;
    value /= conversion;
    baseUnit = alias;
  }

  const { units, unit, conversions, conversion } = scales[baseUnit] as TScale;

  if (unit && conversion) return `${precision(value / conversion, config)}${['%', '\u00B0', '\u2103'].includes(unit) ? '' : ' '}${unit}`;
  if (!units || !conversions) throw new Error('Invalid baseUnit:' + baseUnit);

  let { unitsSingular } = scales[baseUnit] as TScale;
  if (!unitsSingular) unitsSingular = units;

  let i = 0;
  while (
    ((value > conversions[i] && conversions[i] > 1) || (value < 1 && conversions[i] < 1)) &&
    i < conversions.length - 1
  ) {
    value /= conversions[i];
    i++;
  }

  if (!plural) return `${precision(value, config)} ${unitsSingular[i]}`;
  return `${precision(value, config)} ${units[i]}`;
};

export function precision(value: number, config: TPrecisionConfig = {}): string {
  // Limit precision, convert to exponential form for longer values, and remove trailing zeros
  
  if (typeof value !== 'number' || isNaN(value)) return ''; // handle non-number values (TS should enforce this but just in case)
  let { digits = SIG_FIGS } = config; // default digits
  const { trailingZeros = false } = config; // default trailing zero
  if (digits < 1 || !Number.isInteger(digits)) digits = SIG_FIGS; // handle invalid digits arguement

  const exponent = Math.log10(Math.abs(value)); // floating point order of magnitude
  const numStr =
    (exponent < digits && exponent >= -3) || value === 0 // is value within digits range?
      ? value.toPrecision(digits) // if yes, limit to precision
      : value.toExponential(digits - 1); // if no, convert to exponential form
  if (!trailingZeros) return numStr.replace(/(\.[0-9]*[1-9])0*|(\.0*)/, '$1'); // if trailingZeros is false, remove trailing zeros with regular expression
  return numStr; // if trailingZeros is true, leave as is
}
