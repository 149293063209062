import PersonIcon from '@material-ui/icons/Person';
import Nav from 'components/general/Nav';
import StyledButton from 'components/general/StyledButton';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import WGroup from 'components/general/WGroup';
import { useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import ApiKeysWidget from './ApiKeysWidget';
import PasswordWidget from './PasswordWidget';
import UserInfoWidget from './UserInfoWidget';

const ManagementConsoleView = () => {
  const {
    User: {
      actions: { logout },
    },
  } = SatelliteApi;

  const dispatch = useDispatch();

  const user = useUser();

  const { closeSnackbar } = useSnackbar();

  return (
    <ViewContainer>
      <Nav
        contextIcon={<PersonIcon />}
        contextName={'Management Console'}
        contextHeader={user.firstName + ' ' + user.lastName}
        contextSubHeader={'User since ' + moment(user.dateCreated).format('MMMM YYYY')}
      >
        <StyledButton
          type="button"
          onClick={() => {
            closeSnackbar();
            dispatch(logout());
          }}
          framed
          tallMargin
          fullWidth
        >
          Logout
        </StyledButton>
      </Nav>
      <ViewPort>
        <WGroup>
          <ApiKeysWidget apiKeys={user.apiKeys} />
        </WGroup>
        <WGroup maxWidth={800} masonryConfig={{ 750: [50, 50] }}>
          <UserInfoWidget />
          <PasswordWidget />
        </WGroup>
      </ViewPort>
    </ViewContainer>
  );
};

export default ManagementConsoleView;
