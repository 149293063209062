import { TChartSpec } from 'components/general/types';

const statsPowGenerationChartSpec: TChartSpec = [
  {
    name: 'Power Generation Overview',
    subtitle:
      'Average power generation and percent utilization of available power for solar arrays and constituent panels',
    plots: [
      {
        type: 'horizontal-bar',
        name: 'Average Power Generation',
        unit: 'w',
        variables: [
          {
            each: 'SolarArray',
            legend: ['{name}'],
            keys: ['power'],
            ops: ['avg'],
            variables: [
              {
                each: 'panels',
                legend: ['{name}'],
                keys: ['power'],
                ops: ['avg'],
              },
            ],
          },
        ],
      },
      {
        type: 'horizontal-bar',
        name: 'Average Utilization',
        unit: 'pct',
        variables: [
          {
            each: 'SolarArray',
            legend: ['{name}'],
            keys: ['utilization'],
            ops: ['avg'],
            variables: [
              {
                each: 'panels',
                legend: ['{name}'],
                keys: ['utilization'],
                ops: ['avg'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Power Generation',
    each: 'SolarArray',
    plots: [
      {
        name: 'Energy Generation by Panel',
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            keys: ['panels.$each.power'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
];

export default statsPowGenerationChartSpec;
