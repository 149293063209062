import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  TCOTSComponent,
  TCOTSComponentReconciliation,
} from 'components/general/COTSDialogButton/general/types';
import { useActiveEntities, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import * as React from 'react';
import { useDispatch } from 'react-redux';

const importFail =
  'Failed to load importable COTS components. Please try refreshing the page, and reach out to Sedaro if error persists.';

interface IDialogOptions {
  BROWSER: 'browser';
  RECON: 'reconciliation';
  CLOSED: '';
}

export const dialogOptions: IDialogOptions = {
  BROWSER: 'browser',
  RECON: 'reconciliation',
  CLOSED: '',
};

export type TCOTSDialogState = 'browser' | 'reconciliation' | '';
interface ICOTSContext {
  allCOTS: TCOTSComponent[];
  selectedCOTS: TCOTSComponent;
  setSelectedCOTS: React.Dispatch<React.SetStateAction<TCOTSComponent>>;
  // Current reconciliation object, updated by reconciliation dialog as user drops options into relation slots
  selectedReconciliation: TCOTSComponentReconciliation;
  setSelectedReconciliation: React.Dispatch<React.SetStateAction<TCOTSComponentReconciliation>>;
  isCOTSSelected: boolean;
  resetSelCOTS: () => void;
  dialogState: TCOTSDialogState;
  setDialogState: React.Dispatch<React.SetStateAction<TCOTSDialogState>>;
}

interface IProps {
  children: ReactNode;
}

export const defaultSelectedCots: TCOTSComponent = {
  cotsTemplate: '',
  entity: 'default', // placeholder that will be overwritten
  manufacturer: '',
  metadata: '',
  name: '',
  partNumber: '',
  // @ts-ignore
  subsystemCategory: 'default', // placeholder that will be overwritten
  reconciliation: {},
};

const defaultState: ICOTSContext = {
  allCOTS: [],
  selectedCOTS: defaultSelectedCots,
  setSelectedCOTS: () => undefined,
  selectedReconciliation: {},
  setSelectedReconciliation: () => undefined,
  isCOTSSelected: false,
  resetSelCOTS: () => undefined,
  dialogState: dialogOptions.CLOSED,
  setDialogState: () => undefined,
};

const COTSContext = createContext<ICOTSContext>(defaultState);
export const useCOTSContext = () => useContext(COTSContext);

const COTSProvider = ({ children }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    Cots: {
      actions: { getCots },
    },
  } = SatelliteApi;
  const { branch } = useActiveEntities();

  const [allCOTS, setAllCOTS] = useState<TCOTSComponent[]>(defaultState.allCOTS);
  const [selectedCOTS, setSelectedCOTS] = useState<TCOTSComponent>(defaultState.selectedCOTS);
  const [dialogState, setDialogState] = useState<TCOTSDialogState>('');
  const [selectedReconciliation, setSelectedReconciliation] =
    useState<TCOTSComponentReconciliation>(defaultState.selectedReconciliation);

  const isCOTSSelected = useMemo(() => selectedCOTS.entity !== 'default', [selectedCOTS.entity]);
  const resetSelCOTS = useCallback(() => setSelectedCOTS(defaultSelectedCots), [setSelectedCOTS]);

  useEffect(() => {
    if (dialogState === dialogOptions.CLOSED || allCOTS.length) return;
    dispatch(
      getCots({
        branchId: branch.id,
        successCallback: (data: TCOTSComponent[]) => setAllCOTS(data),
        failureCallback: () => {
          enqueueSnackbar(importFail);
          setDialogState(dialogOptions.CLOSED);
        },
      })
    );
  }, [dialogState, allCOTS.length, dispatch, getCots, enqueueSnackbar, branch]);

  const value = useMemo(
    () => ({
      allCOTS,
      selectedCOTS,
      setSelectedCOTS,
      selectedReconciliation,
      setSelectedReconciliation,
      isCOTSSelected,
      resetSelCOTS,
      dialogState,
      setDialogState,
    }),
    [
      allCOTS,
      selectedCOTS,
      setSelectedCOTS,
      selectedReconciliation,
      setSelectedReconciliation,
      isCOTSSelected,
      resetSelCOTS,
      dialogState,
      setDialogState,
    ]
  );

  return <COTSContext.Provider value={value}>{children}</COTSContext.Provider>;
};

export default COTSProvider;
