import { Handle, Position } from 'react-flow-renderer';

interface IProps {
  type: 'target' | 'source';
  position: Position;
}

const style = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  height: 0,
  width: 0,
};

const GNCNodeHandle = ({ type, position }: IProps) => {
  return <Handle type={type} position={position} style={style} isConnectable={false} />;
};

export { Position };
export default GNCNodeHandle;
