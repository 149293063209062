import {
  setActiveMissionVersionId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';
import Nav from 'components/general/Nav';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import { hotkeys } from 'config';
import useWorkspace from 'hooks/useWorkspace';
import { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import BranchExplorer from './BranchExplorer';

const BranchesView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveMissionVersionId(undefined));
    dispatch(setLatestJobId(undefined));
  }, [dispatch]);

  const workspace = useWorkspace();
  const history = useHistory();
  const goToWorkspace = useCallback(() => history.push(Routes.WORKSPACE(workspace.id, 'repositories')), [history, workspace]);

  useHotkeys(hotkeys.WORKSPACE.keys, goToWorkspace, [goToWorkspace]);

  useEffect(() => {
    // If workspace has inactive, no branches will be visible
    if (workspace && !workspace.isActive) {
      goToWorkspace();
    }
  }, [workspace, goToWorkspace])

  return (
    <ViewContainer>
      <Nav>
        <p>Select a branch to open its modules or interact with it via the options on the right.</p>
      </Nav>
      <ViewPort>
        <BranchExplorer />
      </ViewPort>
    </ViewContainer>
  );
};

export default BranchesView;
