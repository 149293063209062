import { useMemo } from 'react';
import { IBatteryCell, IBatteryPack, ISubsystem } from 'components/general/types/power';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import useGuidance from './guidance';
import { useActiveEntities } from 'hooks';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import validation from './validation';
import { PowerAccent } from 'components/general/Accent/variants';
import { ISelectOption } from 'components/general/types';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { SubsystemVables, ComponentVables } from 'utils/vable';
import getThermalProps from 'hooks/getThermalProps';

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  numSeries: string;
  numParallel: string;
  cell: ISelectOption | '';
}

interface IProps {
  control: TEntityDialogControl<IBatteryPack>;
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  numSeries: '',
  numParallel: '',
  cell: '',
};

const Dialog = (props: IProps) => {
  // Handle props
  const { control } = props;

  // Grab entities and actions
  const { battery, batteryCells, subsystems } = useActiveEntities();

  // Set up styles
  const classes = useStyles();

  // Set up options
  const batteryCellList = useMemo(
    () =>
      batteryCells.map((batteryCell: IBatteryCell) => {
        return { value: batteryCell.id, label: batteryCell.partNumber };
      }),
    [batteryCells]
  );

  const options = useMemo(() => {
    return {
      cell: batteryCellList,
    };
  }, [batteryCellList]);

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  // Hook up form
  const entityForm = useEntityForm<IBatteryPack, IForm & typeof thermalDefaultValues>({
    entityTypeText: 'Battery Pack',
    overrideEntityText: 'Component',
    entityType: { type: ComponentVables.Type.BatteryPack.value },
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: {
      subsystem: subsystems.find(
        (system: ISubsystem) => system.category === SubsystemVables.Categories.POWER.value
      )?.id,
      battery: battery?.id, 
      type: 'BatteryPack'
    },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
      allowedEmptyFields: ['manufacturer', 'partNumber'],
    },
  });

  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Battery Pack Name"
            placeholder="Battery Pack Name"
            type="text"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <PowerAccent header="Battery Cell">
            <LabeledSelect
              {...getFieldProps('cell')}
              options={options.cell}
              noOptionsMessage={() => 'Create a Battery Cell'}
            />
          </PowerAccent>
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('numParallel')}
            label="Number of Cells In Parallel"
            type="number"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('numSeries')}
            label="Number of Cells in Series"
            type="number"
          />
        </div>
        <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
      </div>
    </EntityDialog>
  );
};

export default Dialog;
