import Grid from '@material-ui/core/Grid';
import A from 'components/general/A';
import GuidanceCard from 'components/general/GuidanceCard';
import Dialog from 'components/general/dialogs/Dialog';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { useActiveEntities, useEditBoardRoute, useSelectByIds, useSelectEntityIdMap } from 'hooks';
import { MouseEvent, useEffect, useState } from 'react';
import { IMission, IMissionVersion } from '../types';

interface IProps {
  badBranchIds: string[];
}

interface IRowData {
  repo: IMission;
  branch: IMissionVersion;
}

const emptyMessage = 'No branches with issues';

const NotSimulateableDialog = ({ badBranchIds }: IProps) => {
  const [open, setOpen] = useState(false);

  const badBranches: IMissionVersion[] = useSelectByIds('MissionVersion', badBranchIds);
  const repos = useSelectEntityIdMap('Mission');

  const data = badBranches.map((branch) => ({ repo: repos[branch.repository], branch }));
  const columns = useMakeColumns(setOpen);

  useEffect(() => {
    setOpen(!!badBranchIds.length);
  }, [badBranchIds]);

  return (
    <Dialog
      title="Simulation Issues"
      open={open}
      large
      onClose={() => setOpen(false)}
      secondaryActionText="Close"
    >
      <Grid container spacing={1}>
        <Grid item md={8}>
          <WidgetTable data={data} columns={columns} emptyMessage={emptyMessage} noXray />
        </Grid>
        <Grid item md={4}>
          <GuidanceCard
            guidance={{
              heading: 'Branches with Isssues',
              body: `All branches shown in this dialog have issues that need to be resolved before a simulation can be run.
              Please visit the respective branch edit boards to resolve them.`,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

// ============================================= Helpers =============================================
type TMouse = MouseEvent<HTMLElement>;

function useMakeColumns(setOpen: (v: boolean) => void) {
  const { branch: activeBranch } = useActiveEntities();
  const editBoardRoute = useEditBoardRoute();

  const onClick = (e: TMouse, branchId: string) => {
    // close dialog if clicked link to the scenario branch we're currently in without opening it in a new tab/window
    if (activeBranch.id !== branchId) return;
    if (e.ctrlKey || e.shiftKey || e.metaKey || e?.button === 1) return;
    // e.metakey is "apple command key"; e.button === 1 is "middle click"
    setOpen(false);
  };

  return [
    {
      title: 'Repo > Branch:',
      render: ({ repo, branch: b }: IRowData) => {
        return (
          <>
            {repo.name} {' > '}{' '}
            <A onClick={(e: TMouse) => onClick(e, b.id)} href={'#' + editBoardRoute(b.id)}>
              {b.name}
            </A>
          </>
        );
      },
    },
  ];
}

export default NotSimulateableDialog;
