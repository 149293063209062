import { makeGuidance } from 'hooks';
import maxSecAlignGraphic from 'multimedia/guidance/pointing_mode_max_secondary_alignment_graphic.png';
import spinGraphic from 'multimedia/guidance/pointing_mode_spin_graphic.png';

const directionLockDescription =
  'Direction Lock pointing mode locks one satellite-fixed (i.e. body frame) vector to a specified lock direction.  Optionally, this pointing mode can be configured to rotate about the lock direction at a specified spin rate.';
const maxSecAlignDescription =
  'Max Secondary Alignment pointing mode locks one satellite-fixed (i.e. body frame) vector to a specified lock direction and simultaneously maximizes the alignment of a secondary satellite body frame vector with a secondary pointing direction.';
const pointDirDescription =
  'Pointing direction options include directions linked to your satellite\'s orbital motion and all of your mission\'s targets. If you select the "Target" option, an additional menu will appear to select a target. \n Nadir points to the geocenter and Zenith points away from the geocenter. \n Ram is coincident with the satellite velocity vector. \n Positive Cross Track is coincident with the angular velocity vector of the orbit. \n Positive Along Track is perpendicular to the Zenith and Cross Track directions and has a positive projection onto Ram. ';
const pointBfDescription =
  'Click on the "+" to open the Spacecraft Dialog > Geometry Tab, where you can add spacecraft body frame vectors or edit existing ones.';
const targetGroupsAndPointing =
  'When pointing is driven by a target group direction, the Mission Module will select a single "active target" from the group at each time step. The direction to this "active target" will be used to compute spacecraft attitude. \nIf target group parameters are associated with any of the active operational mode\'s conditions, then only the targets that meet those conditions will be "active target" candidates. \nThe Mission Module will select the highest-priorty target from this set of candidates as the "active target".';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Pointing Mode Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the pointing mode and select the appropriate pointing mode type from the options below.',
      },
      {
        subHeading: 'Max Secondary Alignment Pointing Mode',
        chunk: maxSecAlignDescription,
        graphic: maxSecAlignGraphic,
      },
      {
        subHeading: 'Direction Lock Mode',
        chunk: directionLockDescription,
        graphic: spinGraphic,
      },
    ],
  },

  name: {
    alias: '_default',
  },

  type: {
    alias: '_default',
  },

  pointingDirectionA: (values) => {
    let pointingType = values.type.value;
    if (pointingType === 'MaxAlignPointingMode') {
      return {
        heading: 'Max Secondary Alignment Pointing Mode',
        body: [
          {
            chunk: maxSecAlignDescription,
            graphic: maxSecAlignGraphic,
          },
          {
            subHeading: 'Body Frame Vectors',
            chunk:
              'Select the lock and max aligned body frame vector from those defined for your spacecraft. ' +
              pointBfDescription,
          },
          {
            subHeading: 'Pointing Directions',
            chunk: pointDirDescription,
          },
        ],
      };
    } else if (pointingType === 'LockPointingMode') {
      return {
        heading: 'Direction Lock Pointing Mode',
        body: [
          {
            chunk: directionLockDescription,
            graphic: spinGraphic,
          },
          {
            subHeading: 'Body Frame Vector',
            chunk:
              'Select the lock body frame vector from those defined for your spacecraft. ' +
              pointBfDescription,
          },
          {
            subHeading: 'Pointing Direction',
            chunk: pointDirDescription,
          },
          {
            subHeading: 'Spin Rate',
            chunk:
              'Spin rate about the lock direction is positive clockwise and can be negative or zero.',
          },
        ],
      };
    }
  },

  pointingDirectionB: {
    alias: 'pointingDirectionA',
  },

  lockBodyFrameVector: {
    alias: 'pointingDirectionA',
  },

  maxAlignBodyFrameVector: {
    alias: 'pointingDirectionA',
  },

  targetA: {
    alias: 'pointingDirectionA',
  },

  targetB: {
    alias: 'pointingDirectionA',
  },

  targetGroupA: {
    heading: 'Target Group Directions and Pointing',
    body: targetGroupsAndPointing,
  },

  targetGroupB: {
    alias: 'targetGroupA',
  },

  pointingModeParams: {
    spinRate: {
      rpm: {
        alias: 'pointingDirectionA',
      }
    },
  },
});
export default useGuidance;
