import ViewPortInlay from 'components/general/ViewPortInlay';
import Dialog from 'components/general/dialogs/Dialog';
import { IMissionVersion } from 'components/general/types';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import _ from 'lodash';
import { useMemo } from 'react';
import StyledReactDiffViewer from '../general/StyledReactDiffViewer';
import { IEditedAndCommittedData } from '../useGitChanges';
import useStyles from './styles';

interface IProps {
  dialogControl: TEntityDialogControl<IMissionVersion>;
  editedAndCommittedData: IEditedAndCommittedData;
}

const ChangesDialog = (props: IProps) => {
  const { dialogControl, editedAndCommittedData } = props;
  const { edited, committed } = editedAndCommittedData;
  const { dialogConfig, closeDialog } = dialogControl;

  const classes = useStyles();

  const noDifferences = useMemo(() => {
    return _.isEqual(edited, committed);
  }, [edited, committed]);

  return (
    <Dialog
      open={dialogConfig.open}
      onClose={closeDialog}
      title={`Changes for "${dialogConfig.entity?.name}"`}
      secondaryActionText="Close"
      xxlarge
    >
      <div className={classes.changesContainer}>
        {noDifferences ? (
          <ViewPortInlay text="No uncommitted changes" />
        ) : (
          <>
            <div className={classes.titleContainer}>
              <h3 className={classes.title}>Committed</h3>
              <h3 className={classes.title}>Uncommitted Edits</h3>
            </div>
            <StyledReactDiffViewer
              oldValue={JSON.stringify(committed, undefined, 4)}
              newValue={JSON.stringify(edited, undefined, 4)}
            />
          </>
        )}
      </div>
    </Dialog>
  );
};

export default ChangesDialog;
