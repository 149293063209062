import Dialog from 'components/general/dialogs/Dialog';
import { IMissionVersion } from 'components/general/types';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import moment from 'moment-timezone';
import { useRef } from 'react';
import { ICommit } from '../useGitHistory';
import useStyles from './styles';

interface IProps {
  dialogControl: TEntityDialogControl<IMissionVersion>;
  commitHist: ICommit[];
}

const HistoryDialog = (props: IProps) => {
  const { dialogControl, commitHist } = props;
  const { dialogConfig, closeDialog } = dialogControl;

  const ref = useRef<HTMLDivElement>(null);

  const passedMaxHeight = !!ref.current && ref.current.scrollHeight > ref.current.clientHeight;
  const classes = useStyles({ passedMaxHeight });

  return (
    <Dialog
      open={dialogConfig.open}
      onClose={closeDialog}
      title={`Commit History for ${dialogConfig.entity?.name}`}
      secondaryActionText="Close"
    >
      <div className={classes.fadeAndBorder}>
        <div className={classes.historyContainer} ref={ref}>
          {commitHist.map((commit) => {
            return (
              <div className={classes.commitContainer} key={JSON.stringify(commit)}>
                <div>
                  <strong>{commit.message}</strong>
                </div>
                <div>
                  {commit.author} - {moment(commit.time).fromNow()}
                </div>
              </div>
            );
          })}
          {passedMaxHeight && <div style={{ minHeight: 20 }} />}
        </div>
      </div>
    </Dialog>
  );
};

export default HistoryDialog;
