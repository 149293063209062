import { TChartSpec } from 'components/general/types';

const statsPowerProcessingChartSpec: TChartSpec = [
  {
    name: 'Output Power Overview',
    subtitle: 'Maximum and average output power of power controller and bus regulators',
    plots: [
      {
        type: 'table',
        variables: [
          {
            legend: ['Power Processor'],
            variables: [
              {
                unit: 'w',
                keys: [
                  'powerProcessor.outputPowers.total',
                  'powerProcessor.outputPowers.total',
                ],
                ops: ['avg', 'max'],
                legend: ['Average Output Power', 'Max Output Power'],
              },
            ],
          },
          {
            each: 'BusRegulator',
            legend: ['{name}'],
            variables: [
              {
                unit: 'w',
                keys: ['outputPowers.total', 'outputPowers.total'],
                ops: ['avg', 'max'],
                legend: ['Average Output Power', 'Max Output Power'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Minimum Margins',
    subtitle: 'Minimum power margin of power controller and bus regulators',
    plots: [
      {
        type: 'margin',
        unit: 'pct',
        variables: [
          {
            legend: ['Power Processor', '{name}'],
            keys: [
              'powerProcessor.outputPowerMargin',
              'powerProcessor.busRegulators.$each.outputPowerMargin',
            ],
            ops: ['min', 'min'],
          },
        ],
      },
    ],
  },
  {
    name: 'Power Processing',
    plots: [
      {
        name: 'Energy Output of Power Controller and Bus Regulators',
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            keys: [
              'powerProcessor.outputPowers.loads',
              'powerProcessor.busRegulators.$each.outputPowers.total',
            ],
            legend: ['Power Controller', '{name}'],
          },
        ],
      },
    ],
  },
];

export default statsPowerProcessingChartSpec;
