import { useMemo } from 'react';
import { IBusRegulator } from 'components/general/types/power';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import * as Yup from 'yup';
import { BusRegulatorVables } from 'utils/vable';
import { useGuidance } from './guidance';
import { useActiveEntities } from 'hooks';
import InputAdornment from '@material-ui/core/InputAdornment';
import PowerAccent from 'components/general/Accent/variants/PowerAccent';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { exclude } from 'utils/forms';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { ISelectOption } from 'components/general/types';

const busRegulatorSchema = Yup.object().shape({
  name: Yup.string().required('A bus regulator name is required.'),
  inputType: Yup.object().required('Select an input source.'),
  inRegulator: Yup.object().when('inputType', {
    is: (inputType: ISelectOption) =>
      inputType?.value === BusRegulatorVables.InputTypes.BUS_REGULATOR.value,
    then: Yup.object().required('An input regulator is required.'),
  }),
  voltage: Yup.number()
    .required('Output voltage is required.')
    .notOneOf([0], 'Output voltage cannot be 0.'),
  efficiency: Yup.number()
    .required('Efficiency is required.')
    .moreThan(0, 'Efficiency must be greater than 0%.')
    .max(100, 'Efficiency cannot be above 100%.'),
  maxOutputPower: Yup.number()
    .required('Output power rating is required.')
    .moreThan(0, 'Max power must be a positive value.'),
});

interface IForm {
  name: string;
  inputType: ISelectOption | '';
  inRegulator: ISelectOption | '';
  voltage: string;
  efficiency: string;
  maxOutputPower: string;
}

const defaultValues: IForm = {
  name: '',
  inputType: '',
  inRegulator: '',
  voltage: '',
  efficiency: '',
  maxOutputPower: '',
};

interface IProps {
  control: TEntityDialogControl<IBusRegulator>;
}
const BusRegulatorDialog = (props: IProps) => {
  // Handle props
  const { control } = props;
  const {
    dialogConfig: { entity: busRegulator },
  } = control;

  // Grab entities and actions
  const { busRegulators, powerProcessor } = useActiveEntities();

  // Set up styles
  const classes = useStyles();

  const busRegulatorsList = useMemo(
    () =>
      busRegulators.map((regulator: IBusRegulator) => {
        return { value: regulator.id, label: regulator.name };
      }),
    [busRegulators]
  );

  const options = useMemo(() => {
    return {
      inputType: BusRegulatorVables.InputTypes.options,
      inRegulator: busRegulatorsList,
    };
  }, [busRegulatorsList]);

  const entityForm = useEntityForm<IBusRegulator, IForm>({
    entityTypeText: 'Bus Regulator',
    entityDialogControl: control,
    defaultValues,
    validationSchema: busRegulatorSchema,
    additionalCreateValues: { powerProcessor: powerProcessor?.id, type: 'BusRegulator' },
    formikOptionalParams: { useGuidance, options, percentages: ['efficiency'] },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setValues } = formik;
  const { inputType } = values;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Bus Regulator Name"
            type="text"
            placeholder="Bus Regulator Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('inputType')}
            label="Input Source"
            formikOnChange={(val: ISelectOption) => {
              if (val === BusRegulatorVables.InputTypes.EPS_ROOT_NODE) {
                setValues((values) => ({ ...values, inRegulator: defaultValues.inRegulator }));
              }
            }}
            options={options.inputType}
          />
        </div>
        <div className={classes.inputGroup}>
          {typeof inputType === 'object' &&
            inputType.value === BusRegulatorVables.InputTypes.BUS_REGULATOR.value && (
              <PowerAccent header="In Regulator">
                <LabeledSelect
                  {...getFieldProps('inRegulator')}
                  options={exclude(options.inRegulator, busRegulator)}
                  noOptionsMessage={() => 'Create a Bus Regulator'}
                />
              </PowerAccent>
            )}
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('voltage')}
            type="number"
            endAdornment={<InputAdornment position="end">V</InputAdornment>}
            label="Voltage"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('efficiency')}
            type="number"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            label="Efficiency"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('maxOutputPower')}
            type="number"
            endAdornment={<InputAdornment position="end">W</InputAdornment>}
            label="Output Power Rating"
          />
        </div>
      </div>
    </EntityDialog>
  );
};

export default BusRegulatorDialog;
