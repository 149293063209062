import { COMING_SOON } from 'components/AgentTemplateEditView/menu/comingSoon';
import { TARGETS } from 'components/AgentTemplateEditView/menu/targets';
import { useSelectEntityIdMap } from 'hooks';
import Routes, { routePathsCommon } from 'routes';
import { TMetamodelTypes } from 'utils/repoAndMmType';
import { RepoVables } from 'utils/vable';

const { AGENT_TEMPLATE, SCENARIO } = RepoVables.RepoTypes;
const { EDIT } = routePathsCommon;

const mmTypeToRoute: { [key in TMetamodelTypes]: string } = {
  // TODO: Having to edit this sucks, need more automatic way to do this
  Spacecraft: TARGETS,
  TerrestrialVehicle: COMING_SOON,
  Scenario: EDIT,
};

const repoTypeToRoute = {
  [AGENT_TEMPLATE.value]: (branchId: string, metamodelType: TMetamodelTypes) =>
    Routes.AGENT_TEMPLATE_EDIT(branchId, mmTypeToRoute[metamodelType]),
  [SCENARIO.value]: (branchId: string) => Routes.SCENARIO(branchId, EDIT),
};

/**
 * Hook to help with creating a path to a scenario/agent template edit board
 *
 * @returns a function that takes in a `branchId` and creates a path to the corresponding edit board.
 */
const useEditBoardRoute = (): ((branchId: string) => string) => {
  const branchMap = useSelectEntityIdMap('MissionVersion');
  const repoMap = useSelectEntityIdMap('Mission');

  const res = (branchId: string) => {
    const branch = branchMap[branchId];
    const repo = repoMap[branch.repository];
    return repoTypeToRoute[repo.repoType](branch.id, branch.metamodelType);
  };

  return res;
};

export default useEditBoardRoute;
