import { timing } from 'config';
import type { EChartOption } from 'echarts';
import { graphic } from 'echarts';
import { useCallback, useMemo, useState } from 'react';
import theme from 'theme';
import { dateFormatLong, mjd2Moment } from 'utils/time';
import { precision } from 'utils/units';
import { IGenericObject, TPlotVariable } from '../types';
import Chart from './Chart';

interface IProps {
  title?: string;
  data: IGenericObject;
  withZoom?: boolean;
  variables: TPlotVariable[];
  rightLabel?: string;
  rightUnits?: string;
}

const rightColor = Object.values(theme.palette.charts.secondary)[0];

const BooleanSeriesChart = ({
  title,
  data,
  withZoom,
  variables,
  rightLabel,
  rightUnits,
}: IProps) => {
  // Keys that this chart will plot (used for float series)
  const [dimensions, source] = useMemo(() => {
    const dims = Array.from(new Set(variables.flatMap(({ xKey, yKey }) => [xKey, yKey])));
    if (Array.isArray(data)) return [dims, data];
    return [dims, dims.reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})];
  }, [variables, data]);

  // Define colors for this plot
  const colors = useMemo(() => {
    const colors = Object.values(theme.palette.charts.primary);
    if (variables.length && variables[variables.length - 1].right) {
      colors.splice(variables.length - 1, 0, rightColor);
    }
    return colors;
  }, [variables]);

  // Format data for boolean rectangles
  const series = variables.map((v, row) => {
    const seriesData: number[][] = [];
    let start = data[v.xKey][0];
    let end = data[v.xKey][0];
    let state = data[v.yKey][0];
    data[v.xKey].forEach((t: number, i: number) => {
      const currentState = data[v.yKey][i];
      if (currentState !== state) {
        seriesData.push([start, (end + t) / 2, row, state]);
        start = (end + t) / 2;
        state = currentState;
      }
      if (i === data[v.xKey].length - 1) {
        seriesData.push([start, t, row, currentState]);
      }
      end = t;
    });
    return { ...v, data: seriesData };
  });

  // Blank series for tooltip purposes
  series.push({
    name: '', // must have name '' to hide from legend
    xKey: variables[0].xKey,
    yKey: rightLabel ? series[series.length - 1].yKey : '',
    right: true,
    data: rightLabel
      ? series[series.length - 1].data
      : data[variables[0].xKey].map((x: number) => [x, 0]),
  });

  const [useRenderBool, setRenderBool] = useState(!rightLabel);

  // Render boolean rectangles
  const renderBool = useCallback(
    (params: IGenericObject, api: IGenericObject) => {
      const start = api.coord([api.value(0), api.value(2)]);
      const end = api.coord([api.value(1), api.value(2)]);
      const height = api.size([0, 1])[1] * (!api.value(3) ? 0.25 : 0.5);
      const style = api.value(3)
        ? {
            fill: colors[api.value(2) % colors.length],
            opacity: 0.8,
          }
        : { fill: '#FFFFFF', opacity: 0.1 };
      const rectShape = graphic.clipRectByRect(
        {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height: height,
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height,
        }
      );
      return (
        rectShape && {
          type: 'rect',
          shape: rectShape,
          focus: 'none',
          style: style,
        }
      );
    },
    [colors]
  );

  // Render boolean rectangles
  const renderFloat = useCallback(
    (params: IGenericObject, api: IGenericObject) => {
      if (api.value(3)) {
        const start = api.coord([api.value(0), api.value(2)]);
        const end = api.coord([api.value(1), api.value(2)]);
        const height = params.coordSys.height;
        const style = {
          fill: colors[api.value(2) % colors.length],
          opacity: 0.3,
        };
        const rectShape = graphic.clipRectByRect(
          {
            x: start[0],
            y: params.coordSys.y,
            width: end[0] - start[0],
            height: height,
          },
          {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height,
          }
        );
        return (
          rectShape && {
            type: 'rect',
            shape: rectShape,
            focus: 'none',
            style: style,
          }
        );
      }
    },
    [colors]
  );

  // Define yAxes for this plot, including second axis for floats
  const yAxes = useMemo(() => {
    const yAxes: EChartOption.YAxis[] = [
      {
        name: '',
        nameLocation: 'center',
        nameGap: 60,
        nameRotate: 90,
        axisLine: {
          lineStyle: { color: theme.palette.background.contrastText },
        },
        inverse: true,
        axisLabel: {
          show: !rightLabel,
          fontSize: 12,
          width: 110,
          overflow: 'break',
        },
        axisTick: {
          show: !rightLabel,
          alignWithLabel: true,
        },
        data: variables.filter((x) => !x.right).map((x) => x.name),
      } as EChartOption.YAxis,
    ];
    // Second Axis for floats
    yAxes.push({
      name: rightUnits ? rightLabel + ` (${rightUnits})` : rightLabel,
      show: Boolean(rightLabel),
      nameLocation: 'center',
      nameGap: 60,
      nameRotate: -90,
      type: 'value',
      axisLine: {
        lineStyle: { color: rightColor },
      },
      splitLine: {
        show: true,
        lineStyle: { color: theme.palette.action.disabled },
      },
      splitNumber: 3,
      nameTextStyle: {
        color: rightColor,
      },
      min: (value) => value.min - (value.max - value.min) * 0.05,
      max: (value) => value.max + (value.max - value.min) * 0.05,
      //@ts-ignore:next-line // Types haven't been updated in a while
      animation: false,
      axisLabel: {
        showMinLabel: false,
        color: rightColor,
        formatter: (value: number) => precision(value),
      },
    } as EChartOption.YAxis);
    return yAxes;
  }, [variables, rightLabel, rightUnits]);

  const option = useMemo(
    () =>
      ({
        color: colors,
        textStyle: {
          color: theme.palette.background.contrastText,
          ...theme.typography.body,
        },
        animationDuration: timing.lineAnimationDuration,
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
              filterMode: 'none',
              // Don't actually draw the toolbox on the widget, but retain its functionality
              icon: null,
            },
            saveAsImage: {
              show: true,
              backgroundColor: theme.palette.background.main,
              name: title || 'Sedaro Boolean Series Chart',
              pixelRatio: 2, // saved image to displayed container resolution ratio
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            axis: 'x',
            snap: false,
            lineStyle: {
              type: 'solid',
            },
          },
          backgroundColor: theme.palette.background.light,
          borderWidth: 0,
          extraCssText: `box-shadow: ${theme.shadows[5]};`,
          // Define custom format for tooltip contents (not container)
          formatter: (params: IGenericObject[]) => {
            const date = mjd2Moment(params[0].axisValue).format(dateFormatLong);
            const style = Object.entries(theme.typography.body).reduce(
              (finalString, entry) => finalString + `${entry[0]}:${entry[1]};`,
              ''
            );
            let res = `<span style="color:${theme.palette.background.contrastText};${style}">${date}</span>`;
            // Because rectangles are merged in custom series, we use binary search to get correct boolean interval
            // Binary search is also used for float series so that data shows in tooltip when cursor is on rectangle edge
            series.forEach((plot, i) => {
              if (plot.name !== '') {
                let start = 0;
                let end = plot.data.length - 1;
                let interval = end;
                while (start <= end) {
                  const mid = Math.floor((start + end) / 2);
                  if (
                    plot.data[mid][0] <= params[0].axisValue &&
                    plot.data[mid][1] > params[0].axisValue
                  ) {
                    interval = mid;
                    break;
                  }
                  if (params[0].axisValue < plot.data[mid][0]) {
                    end = mid - 1;
                  } else {
                    start = mid + 1;
                  }
                }
                res += `<br><span style="color:${colors[i % colors.length]};${style}">${
                  plot.name
                } : ${
                  plot.right
                    ? `${precision(Number(plot.data[interval][3]))} ${rightUnits}`
                    : plot.data[interval][3]
                    ? 'True'
                    : 'False'
                }</span>`;
              }
            });
            return res;
          },
        },
        title: {
          text: title,
          top: 10,
          left: 'center',
          textStyle: {
            color: theme.palette.background.contrastText,
            ...theme.typography.h3,
          },
        },
        legend: {
          top: title && 40,
          width: '60%',
          textStyle: {
            ...theme.typography.body,
            color: theme.palette.background.contrastText,
            fontSize: 12,
          },
          selected: {
            [series[series.length - 2].name]: !rightLabel || !useRenderBool,
          },
          inactiveColor: theme.palette.action.disabled,
          itemHeight: 3,
          itemWidth: 20,
          // Manually set the icon through the `data` property, instead of using the default `icon` prop
          // Allows us to change icon per line, specifically for dashed lines here
        },
        grid: {
          // Params for the whole chart
          // NOTE: If you're looking for gridlines in particular, they're defined by "splitLine" on x/y-Axis
          // NOTE: If you're looking for the top, left, right margins, they're delegated down to the `Chart`
          //       component – hacked to be more dynamic.
        },
        xAxis: {
          name: 'Time (UTC)',
          nameLocation: 'center',
          nameGap: 50,
          animation: false,
          min: 'dataMin',
          max: 'dataMax',
          axisTick: {
            show: true,
          },
          axisLabel: {
            formatter: (value: number) => mjd2Moment(value).format('MM-DD-YYYY[\n]HH:mm:ss'),
          },
          axisLine: {
            show: true,
            lineStyle: { color: theme.palette.background.contrastText },
          },
          splitLine: {
            lineStyle: { color: theme.palette.action.disabled, opacity: 0.25 },
          },
        },
        yAxis: yAxes,
        dataset: {
          id: 'main',
          dimensions,
          source,
        },
        series: series.map((seriesParams) => {
          return !seriesParams.right
            ? ({
                name: seriesParams.name,
                type: 'custom',
                renderItem: useRenderBool ? renderBool : renderFloat,
                data: seriesParams.data,
                encode: { x: seriesParams.xKey, y: seriesParams.yKey, seriesLayoutBy: 'row' },
              } as EChartOption.SeriesCustom)
            : ({
                name: seriesParams.name,
                type: 'line',
                animation: true,
                smooth: 0.3,
                symbol: 'none',
                large: true,
                largeThreshold: 5000,
                connectNulls: true,
                lineStyle: {
                  width: 2,
                  color: rightLabel && seriesParams.name !== '' ? rightColor : 'transparent', //Make extra series invisible
                  type: 'solid',
                  shadowColor: '#000000',
                  shadowBlur: 2,
                },
                encode: { x: seriesParams.xKey, y: seriesParams.yKey, seriesLayoutBy: 'row' },
                yAxisIndex: 1,
                sampling: 'average',
              } as EChartOption.SeriesLine);
        }),
      } as EChartOption),
    [
      dimensions,
      useRenderBool,
      renderBool,
      renderFloat,
      rightLabel,
      rightUnits,
      series,
      source,
      yAxes,
      colors,
      title,
    ]
  );
  console.log(option);
  return (
    <Chart
      option={option}
      style={{ height: 400, marginTop: 15, marginBottom: 15, width: 'auto!important' }}
      titled={Boolean(title)}
      withRightAxis={Boolean(rightLabel)}
      withZoom={withZoom}
      eventListener={{
        type: 'legendselectchanged',
        action: function (params: IGenericObject) {
          console.log(params);
          if (rightLabel && params.name === series[series.length - 2].name)
            setRenderBool(!useRenderBool);
        },
      }}
    />
  );
};

export default BooleanSeriesChart;
