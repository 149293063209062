import { PowerBarGraph } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general';
import StatsTable from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/StatsTable';
import WGroup from 'components/general/WGroup';
import BooleanSeriesChart from 'components/general/chartComponents/BooleanSeriesChart';
import PieChart from 'components/general/chartComponents/PieChart';
import TimeSeriesChart from 'components/general/chartComponents/TimeSeriesChart';
import {
  IGenericObject,
  TChartSpec,
  TPlotDef,
  TPlotVariable,
  TWidgetDef,
} from 'components/general/types';
import Widget from 'components/general/widgets/Widget';
import { useModel } from 'middleware/SatelliteApi/template';
import { DataContext } from 'providers/DataProvider';
import { defineStat, finalizeStat, initStatsData } from 'providers/DataProvider/stats';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getSearchParams } from 'routes';
import { represent } from 'utils/units';
import { interpret } from './specInterpreter';

interface IProps {
  wGroupIndex: string;
  data: IGenericObject;
  chartSpec: TChartSpec;
}

interface IWidget {
  def: TWidgetDef;
  plots: {
    def: TPlotDef;
    vars: {
      xKey: string;
      yKey: string;
      name: string;
      right?: boolean;
    }[];
  }[];
}

const DataPlottingWidgetGroup = ({ wGroupIndex, data, chartSpec }: IProps) => {
  const { staticModels, resolveSeriesDataKeyPair, seriesData } = useContext(DataContext);
  const { agentId } = getSearchParams();
  const model = useModel(staticModels.agents[agentId]);
  const [state, setState] = useState<{ widgets: IWidget[]; statsData: IGenericObject }>({
    widgets: [],
    statsData: initStatsData(),
  });

  // Reinterpret spec and update stats whenever series data changes
  useEffect(() => {
    const statsData = initStatsData();
    setState({
      widgets: interpret(chartSpec, model).map((widgetDef) => {
        return {
          def: widgetDef,
          plots: widgetDef.plots.flatMap((plotDef) => {
            const vars = plotDef.variables
              .filter((v) => v.key)
              .map(
                ({ name, right, key }): TPlotVariable => ({
                  name,
                  right,
                  ...resolveSeriesDataKeyPair(agentId, key),
                })
              )
              .filter((v) => v.yKey);
            if (['table', 'pie', 'horizontal-bar', 'margin'].includes(plotDef.type)) {
              try {
                vars.forEach((v) => defineStat(statsData, seriesData, v.yKey));
              } catch (e) {
                return []; // skip missing stats
              }
            }
            if (vars.length === 0) return [];
            return {
              def: plotDef,
              vars,
            };
          }),
        };
      }),
      statsData,
    });
  }, [model, seriesData, chartSpec, agentId, resolveSeriesDataKeyPair]); //eslint-disable-line

  const widgets = useMemo(
    () =>
      state.widgets.map(({ def: widgetDef, plots }) => {
        return (
          <Widget
            title={widgetDef.title}
            collapsibleConfig
            subtitle={widgetDef.subtitle}
            key={widgetDef.title}
          >
            {plots.map(({ def: plotDef, vars }, i) => {
              if (plotDef.type === 'line' || plotDef.type === 'scatter') {
                return (
                  <TimeSeriesChart
                    title={plotDef.title}
                    key={i}
                    data={data}
                    withZoom={true}
                    lines={vars}
                    type={plotDef.type}
                    step={plotDef.step}
                    leftLabel={plotDef.label || ''}
                    leftUnits={plotDef.unit || ''}
                    rightLabel={plotDef.labelRight}
                    rightUnits={plotDef.unitRight}
                  />
                );
              } else if (plotDef.type === 'bool') {
                return (
                  <BooleanSeriesChart
                    title={plotDef.title}
                    key={i}
                    data={data}
                    withZoom={true}
                    variables={vars}
                    rightLabel={plotDef.labelRight}
                    rightUnits={plotDef.unitRight}
                  />
                );
              } else if (plotDef.type === 'pie') {
                return (
                  <PieChart
                    title={plotDef.title}
                    key={i}
                    statsData={state.statsData}
                    variables={vars}
                    baseUnit={plotDef.unit}
                  />
                );
              } else if (plotDef.type === 'table') {
                return (
                  <StatsTable
                    data={plotDef.variables.flatMap((v) => {
                      let value;
                      if (v.key) {
                        const key = resolveSeriesDataKeyPair(agentId, v.key)?.yKey;
                        if (!key) return [];
                        value = (finalizeStat(state.statsData, key) as IGenericObject)[key][
                          v.op || 'avg'
                        ];
                        const unit = v.unit || plotDef.unit;
                        value = unit ? represent(value, unit) : value;
                      }
                      return {
                        name: v.name,
                        value,
                        hierarchy: (v.level || 0) + 1,
                      };
                    })}
                    key={i}
                  />
                );
              } else if (['horizontal-bar', 'margin'].includes(plotDef.type)) {
                return (
                  <PowerBarGraph
                    title={plotDef.title}
                    data={plotDef.variables.flatMap((v) => {
                      let value;
                      if (v.key) {
                        const key = resolveSeriesDataKeyPair(agentId, v.key)?.yKey;
                        if (!key) return [];
                        value = (finalizeStat(state.statsData, key) as IGenericObject)[key][
                          v.op || 'avg'
                        ];
                      }
                      if (!value || isNaN(value) || value === Infinity || value === -Infinity)
                        return []; // if the value is null, NaN, Infinity, or negative Infinity don't return it and instead return an empty list
                      return {
                        barName: v.name,
                        value,
                        hierarchy: (v.level || 0) + (plotDef.type === 'margin' ? 1 : 2), // This is junk.  Need to fix the underlying issue in the component
                      };
                    })}
                    baseUnit={plotDef.unit}
                    colorBySign={plotDef.type === 'margin'}
                    key={i}
                  />
                );
              } else {
                console.warn('Unknown plot type', plotDef.type);
                return null;
              }
            })}
          </Widget>
        );
      }),
    [agentId, data, state, resolveSeriesDataKeyPair]
  );

  return <WGroup index={wGroupIndex}>{widgets}</WGroup>;
};

export default DataPlottingWidgetGroup;
