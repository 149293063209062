import { IMenu } from 'components/general/types';
import { SUB_ITEMS, TITLE } from 'config';

export const DATA_HANDLING = 'data-handling';
const DATA_TYPES = 'data-types';
const DATA_STORAGE = 'data-storage';
const DEVICE = 'device';
const DATA_INTERFACE = 'data-interface';
const DATA_MODE = 'data-mode';
const DATA_BUS = 'data-bus';

const aTDataHandlingMenu: IMenu['items'] = {
  [DATA_HANDLING]: {
    [TITLE]: 'Data Handling',
    [SUB_ITEMS]: {
      [DATA_TYPES]: {
        [TITLE]: 'Data Types',
      },
      [DATA_STORAGE]: {
        [TITLE]: 'Data Storage',
      },
      [DEVICE]: {
        [TITLE]: 'Devices',
      },
      [DATA_INTERFACE]: {
        [TITLE]: 'Data Interfaces',
      },
      [DATA_MODE]: {
        [TITLE]: 'Data Modes',
      },
      [DATA_BUS]: {
        [TITLE]: 'Data Buses',
      },
    },
  },
};
export const wGroupIndicesATDataHandling = {
  DATA_HANDLING: `${DATA_HANDLING}*`,
  DATA_TYPES: `${DATA_HANDLING}.${DATA_TYPES}`,
  DATA_STORAGE: `${DATA_HANDLING}.${DATA_STORAGE}`,
  DEVICE: `${DATA_HANDLING}.${DEVICE}`,
  DATA_INTERFACE: `${DATA_HANDLING}.${DATA_INTERFACE}`,
  DATA_MODE: `${DATA_HANDLING}.${DATA_MODE}`,
  DATA_BUS: `${DATA_HANDLING}.${DATA_BUS}`,
};

export default aTDataHandlingMenu;
