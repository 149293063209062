import BaseNode from '../general/BaseNode';
import GNCNodeHandle, { Position } from '../general/GNCNodeHandle';
import { IActuatorNodeData } from '../general/types';
import ActuatorBar from './ActuatorBar';

const ActuatorNode = ({ data }: IActuatorNodeData) => {
  const { title, subtitle, percentages } = data;
  return (
    <BaseNode title={title} subtitle={subtitle}>
      {Object.entries(percentages).map(([key, val]) => (
        <ActuatorBar title={key} percentage={val} key={`actuatorNode_${title}_${key}`} />
      ))}
      <GNCNodeHandle type="target" position={Position.Left} />
    </BaseNode>
  );
};

export default ActuatorNode;
