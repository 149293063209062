import { TChartSpec } from 'components/general/types';

const tsPropulsionChartSpec: TChartSpec = [
  {
    name: 'Thrust',
    plots: [
      {
        type: 'line',
        label: 'Thrust Magnitude',
        unit: 'N',
        variables: [
          {
            keys: ['Thruster.$each.thrust'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Fuel',
    plots: [
      {
        type: 'line',
        label: 'Fuel Mass',
        unit: 'kg',
        variables: [
          {
            keys: ['FuelTank.$each.wetMass'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
];

export default tsPropulsionChartSpec;
