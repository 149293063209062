import BaseNode from './general/BaseNode';
import GNCNodeHandle, { Position } from './general/GNCNodeHandle';
import { IAlgoNodeData } from './general/types';

const customContainerStyle = {
  height: '3rem',
};

const AlgoNode = ({ data }: IAlgoNodeData) => {
  return (
    <BaseNode title={data.title} subtitle={data.subtitle} containerStyle={customContainerStyle}>
      <GNCNodeHandle type="target" position={Position.Left} />
      <GNCNodeHandle type="source" position={Position.Right} />
    </BaseNode>
  );
};

export default AlgoNode;
