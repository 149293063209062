import { useCallback } from 'react';
import { animated, useSpring } from 'react-spring';
import useStyles from '../general/styles';
import { barTheme } from '../styles';
import { useMotionConfig } from '@nivo/core';
import { Scale } from '@nivo/scales';

interface IProps {
  value: number | string;
  // ts-ignore is here because of unintuitive nivo typing where xScale is type Scale within BarCustomLayerProps even though it is a function here
  // @ts-ignore
  xScale: Scale | ((val: number) => number);
  labelMargin: number;
  translateY: number;
  labelText: string;
  height: number;
  handleMaxLabelWidth: (width: number, sign: number) => void;
}

// Creating this functional component allows for use of useSpring so that label animations match the bar animations upon resize
const InfoLabel = (props: IProps) => {
  const {
    value: _value,
    xScale,
    labelMargin,
    translateY,
    labelText,
    height,
    handleMaxLabelWidth,
  } = props;

  // convert value back to Number since calling .toFixed(2) converts value to a string
  const value = Number(_value);

  const classes = useStyles();
  const translateSpring = useSpring({
    // add margin if the value of bar is 0
    transform: `translate(${
      xScale(value) + Math.sign(value) * labelMargin || labelMargin * 2
    }, ${translateY})`,
    config: useMotionConfig().config,
  });

  const labelRefCallback = useCallback(
    (labelRef) => {
      const labelWidth = labelRef?.getBBox()?.width || null;
      if (labelWidth) handleMaxLabelWidth(labelWidth, value);
    },
    [handleMaxLabelWidth, value]
  );

  return (
    <animated.g {...translateSpring} className={classes.nonInteractable} ref={labelRefCallback}>
      <text
        textAnchor={Math.sign(value) >= 0 ? 'beginning' : 'end'}
        alignmentBaseline="central"
        dominantBaseline="central"
        y={height / 2}
        style={{ ...barTheme.bars.labels.text }}
      >
        {labelText}
      </text>
    </animated.g>
  );
};

export default InfoLabel;
