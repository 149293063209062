import {
  TBarData,
  TExtendedPowerBarDatum,
} from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import { useCallback } from 'react';
import HoverHighlight from './HoverHighlight';

interface IProps {
  layers: { bars: TBarData[] };
  barData: TExtendedPowerBarDatum[];
  leftMargin: number;
  setBarData: (barData: TExtendedPowerBarDatum[]) => void;
}

const HoverHighlightLayer = (props: IProps) => {
  const {
    layers: { bars },
    barData,
    leftMargin,
    setBarData,
  } = props;
  // Expands or collapses bar and its children based on the bar's ind. visible=true expands, visible=false collapses.
  const toggleExpansion = useCallback(
    (ind: number, visible: boolean) => {
      const currHierarchy = barData[ind].hierarchy;
      let index = ind + 1;
      const toggledBarData = barData.slice();
      while (index < barData.length && barData[index].hierarchy > currHierarchy) {
        toggledBarData[index].visible = !visible ? 1 : 0;
        index++;
      }
      setBarData(toggledBarData);
    },
    [barData, setBarData]
  );
  return (
    <>
      {bars.map((bar: TBarData, i: number) => {
        const innerBarData: TExtendedPowerBarDatum = bar.data.data;
        // expanded is true/false based on if bar below is visible, or undefined if bar isn't expandable
        const expanded =
          innerBarData.hierarchy === 2
            ? barData[innerBarData.indexInBars]?.visible === 1 || false
            : undefined;
        return (
          <HoverHighlight
            key={i}
            bar={bar}
            leftMargin={leftMargin}
            expanded={expanded}
            onClick={() => {
              if (expanded !== undefined) toggleExpansion(innerBarData.indexInBars - 1, expanded);
            }}
          />
        );
      })}
    </>
  );
};

export default HoverHighlightLayer;
