import { TChartSpec } from 'components/general/types';

const tsDataTransferDPSchema: TChartSpec = [
  {
    name: 'Internal Data Interfaces',
    plots: [
      {
        name: 'Active Interfaces',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['InternalDataInterface.$each.isActive'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Transfer Rate',
        unit: 'bit/s',
        variables: [
          {
            keys: ['InternalDataInterface.$each.bitRate'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'External Data Interfaces',
    plots: [
      {
        name: 'Active Interfaces',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['ExternalDataInterface.$each.isActive'],
            legend: ['{name}'],
          },
        ],
      },
      {
        name: 'Link Possible',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['ExternalDataInterface.$each.canLink'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Transfer Rate',
        unit: 'bit/s',
        variables: [
          {
            keys: ['ExternalDataInterface.$each.bitRate'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Data Buses',
    plots: [
      {
        type: 'line',
        label: 'Transfer Rate',
        unit: 'bit/s',
        variables: [
          {
            keys: ['DataBus.$each.bitRate'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },

];

export default tsDataTransferDPSchema;
