import { useMemo } from 'react';
//@ts-ignore
import { ILink, INode } from '@nivo/sankey';
import { IComponent, ISubsystem } from 'components/general/types/power';
import { ISurface } from 'components/general/types/spacecraft';
import { TCompiledModel } from 'middleware/SatelliteApi/template';
import { precision } from 'utils/units';

export interface ISankeyData {
  sankeySeriesData: {
    nodes: INode;
    links: ILink;
  };
  totalHeatFlowRate: number;
}

const netHeatFlowStr = 'Net Heat Flow Rate';
const linkColor = 'black';

enum EntityTypes {
  COMPONENT = 'component',
  SUBSYSTEM = 'subsystem',
  SURFACE_IN = 'surfaceIn',
  SURFACE_OUT = 'surfaceOut',
  VEHICLE = 'vehicle',
}

export const colorMap = {
  surfaceIn: 'rgb(255, 0, 0)',
  component: 'rgb(91, 192, 235)',
  subsystem: 'rgb(253, 231, 76)',
  vehicle: 'rgb(84, 143, 89)',
  surfaceOut: 'rgb(68, 75, 177)',
};

export const createNodesAndLinks = (model: TCompiledModel): ISankeyData => {
  // Keep track of total heat flow rate
  let totalHeatFlowRate = 0;

  // nodes represent all the entities
  // links represent the power used between them
  const nodes: INode[] = [];
  const links: ILink[] = [];

  // Keep track of surface heat flow rates
  let totalSurfaceHeatFlowRateIn = 0;
  let totalSurfaceHeatFlowRateOut = 0;
  // Loop through surfaces to place positive on the left side, negative on the right
  model.Surface.all().forEach((surface: ISurface) => {
    // Key into the heatFlowRate of each surface object
    const surfaceHeatFlowRate = surface.heatFlowRate;
    if (surfaceHeatFlowRate) {
      // type panel is used for the color map
      nodes.push({
        id: surface.id,
        label: surface.name,
        type: surfaceHeatFlowRate > 0 ? EntityTypes.SURFACE_IN : EntityTypes.SURFACE_OUT,
      });
      if (surfaceHeatFlowRate > 0) {
        links.push({
          source: surface.id,
          target: netHeatFlowStr,
          startColor: linkColor,
          endColor: linkColor,
          value: precision(surfaceHeatFlowRate),
        });
        totalSurfaceHeatFlowRateIn += surfaceHeatFlowRate;
      } else if (surfaceHeatFlowRate < 0) {
        // type panel is used for the color map
        links.push({
          source: netHeatFlowStr,
          target: surface.id,
          startColor: linkColor,
          endColor: linkColor,
          value: precision(-surfaceHeatFlowRate),
        });
        totalSurfaceHeatFlowRateOut += -surfaceHeatFlowRate;
      }
    }
  });

  // Each component has loads that make up their power consumption
  // So each subsystem adds the power of all components, which add the power of all loads for that component
  // Total Dissipation
  let totalDissipation = 0;
  // Loop through subsystems to get their related components
  model.Subsystem.all().forEach((subsystem: ISubsystem) => {
    // Check if the subsystem has been simulated and has series data
    subsystem.components.forEach((component: IComponent) => {
      const componentDissipation = component.dissipations?.total ?? 0;
      // If the component powerConsumed is greater than 0, create it's node and link
      if (componentDissipation > 0) {
        nodes.push({
          id: EntityTypes.COMPONENT + '_' + component.id,
          label: component.name,
          type: EntityTypes.COMPONENT,
        });
        links.push({
          source: EntityTypes.COMPONENT + '_' + component.id,
          target: EntityTypes.SUBSYSTEM + '_' + subsystem.id,
          startColor: linkColor,
          endColor: linkColor,
          value: precision(componentDissipation),
        });
      }
    });
    // If the subsytem power is greater than 0 create it's node and link
    const subPowerConsumed = subsystem.dissipations?.total;
    if (subPowerConsumed && subPowerConsumed > 0) {
      totalDissipation += subPowerConsumed;
      nodes.push({
        id: EntityTypes.SUBSYSTEM + '_' + subsystem.id,
        label: subsystem.name,
        type: EntityTypes.SUBSYSTEM,
      });
      links.push({
        source: EntityTypes.SUBSYSTEM + '_' + subsystem.id,
        target: netHeatFlowStr,
        startColor: linkColor,
        endColor: linkColor,
        value: precision(subPowerConsumed),
      });
    }
  });

  totalHeatFlowRate = totalSurfaceHeatFlowRateIn + totalDissipation + totalSurfaceHeatFlowRateOut;
  const netHeatFlowRate =
    totalSurfaceHeatFlowRateIn + totalDissipation - totalSurfaceHeatFlowRateOut;
  nodes.push({
    id: netHeatFlowStr,
    label: `Vehicle\nNet: ${precision(netHeatFlowRate)} W`,
    type: EntityTypes.VEHICLE,
    tooltipLabel: 'Vehicle',
  });

  return { sankeySeriesData: { nodes, links }, totalHeatFlowRate };
};

export const useSankeySeriesData = (model: TCompiledModel) => {
  return useMemo(() => createNodesAndLinks(model), [model]);
};
