import { TChartSpec } from 'components/general/types';

const statsSubsystemLoadingChartSpec: TChartSpec = [
  {
    name: 'Power Consumption by Subsystem',
    plots: [
      {
        name: 'Energy Consumption by Subsystem',
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            keys: ['Subsystem.$each.powerConsumed'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Power Consumption',
    each: 'Subsystem',
    plots: [
      {
        name: 'Energy Consumption by Component',
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            keys: ['components.$each.powerConsumed'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
];

export default statsSubsystemLoadingChartSpec;
