import { IErrorResponse, IMissionVersion } from 'components/general/types';
import { useEntityDialogControl, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

export interface IEditedAndCommittedData {
  committed: string;
  edited: string;
}
/**
 * Designed for use in the BranchExplorer, to extract out logic connected only to the changes dialog
 *
 * @returns object to be desctructured:
 * ```
 * {
 *   changesDialogControl: dialogControl,
 *   fetchGitChanges,
 *   editedAndCommittedData,
 *   branchLoadingGitChanges,
 * }
 * ```
 */
const useGitChanges = () => {
  const [editedAndCommittedData, setEditedAndCommittedData] = useState<IEditedAndCommittedData>({
    committed: '',
    edited: '',
  });
  // this is set to branch.id when changes for that branch are being loaded so the proper button will show the loading spinner. Defaults to '' (to not match up with any branch id)
  const [branchLoadingGitChanges, setBranchLoadingGitChanges] = useState('');

  const dialogControl = useEntityDialogControl<IMissionVersion>();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    MissionVersion: {
      actions: { getGitChanges },
    },
  } = SatelliteApi;

  const fetchGitChanges = useCallback(
    (branch: IMissionVersion) => {
      setBranchLoadingGitChanges(branch.id);
      dispatch(
        getGitChanges({
          id: branch.id,
          successCallback: (response: IEditedAndCommittedData) => {
            const { committed, edited } = response;
            dialogControl.openDialogForExisting(branch, 'create');
            setEditedAndCommittedData({ committed, edited });
            setBranchLoadingGitChanges('');
          },
          failureCallback: (response: IErrorResponse) => {
            enqueueSnackbar(response.error.message);
            dialogControl.closeDialog();
            setBranchLoadingGitChanges('');
          },
        })
      );
    },
    [
      dispatch,
      enqueueSnackbar,
      getGitChanges,
      setEditedAndCommittedData,
      setBranchLoadingGitChanges,
      dialogControl,
    ]
  );

  return {
    changesDialogControl: dialogControl,
    fetchGitChanges,
    editedAndCommittedData,
    branchLoadingGitChanges,
  };
};

export default useGitChanges;
