import Grid from '@material-ui/core/Grid';
import Dialog from 'components/general/dialogs/Dialog';
import GuidanceCard from 'components/general/GuidanceCard';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IErrorResponse, IWorkspace, TDialogConfig } from 'components/general/types';
import { IUser } from 'components/general/types/general';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useSelectByIds, useSnackbar, useUser } from 'hooks';
import useFormikForm from 'hooks/FormikHook';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translateIn } from 'utils/forms';
import { object } from 'yup';

interface IProps {
  config: TDialogConfig<IUser>;
  workspace: IWorkspace;
  onClose: () => void;
}

const defaultValues = {
  name: '',
  email: '',
  role: '',
};

const MemberEditDialog = (props: IProps) => {
  const { config, workspace, onClose } = props;
  const { open, entity: member } = config;
  const user = useUser();

  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const roles = useSelectByIds('Role', workspace.roles);
  const options = useMemo(
    () => ({
      role: roles
        .sort((r1, r2) => r2.permissions - r1.permissions)
        .map((r) => ({ value: r.id, label: r.name })),
    }),
    [roles]
  );

  const {
    Workspace: {
      actions: { editMemberRole },
    },
    User: {
      actions: { getUser },
    },
  } = SatelliteApi;

  const editRole = useCallback(
    (values) => {
      if (!member) return;
      setLoading(true);

      dispatch(
        editMemberRole({
          id: workspace.id,
          roleAssignments: {
            [member.id]: values.role,
          },
          successCallback: () => {
            // Self user needs to always have a list of *all* roles in redux, not just those for this repo
            // Fetch user separately
            const successCallback = () => {
              onClose();
              enqueueSnackbar('Member role updated successfully', {
                variant: 'success',
              });
              setLoading(false);
            };
            if (member.id === user.id) {
              dispatch(
                getUser({
                  successCallback,
                })
              );
            } else {
              successCallback();
            }
          },
          failureCallback: (response: IErrorResponse) => {
            enqueueSnackbar(response?.error?.message);
            setLoading(false);
          },
        })
      );
    },
    [member, dispatch, editMemberRole, getUser, enqueueSnackbar, workspace, onClose, user.id]
  );

  const customTranslateIn = useCallback(
    (member, defaultValues, options) => {
      member.name = `${member.firstName} ${member.lastName}`;
      member.role = roles.find((r) => member.roles.includes(r.id));
      return translateIn(member, defaultValues, options);
    },
    [roles]
  );

  const { formik } = useFormikForm(defaultValues, editRole, object().shape({}), member, {
    options,
    translateIn: customTranslateIn,
  });
  const { getFieldProps, resetForm, dirty, handleSubmit } = formik;

  return (
    <Dialog
      prompt={'Edit member role'}
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      submitActionText="Save"
      secondaryActionText="Reset"
      onSecondaryAction={resetForm}
      disableSubmit={!dirty}
      large
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <div className={classes.inputGroup}>
              <LabeledInput {...getFieldProps('name')} label="Name" disabled />
              <LabeledInput {...getFieldProps('email')} label="Email" disabled />
            </div>
            <div className={classes.inputGroup}>
              <LabeledSelect
                label="Role"
                options={options.role}
                autoFocus
                {...getFieldProps('role')}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard
            guidance={{
              heading: 'Members',
              body: `Select a role for this member. Roles define the permissions a user has across a workspace. For example, \
              one role might allow a member to launch simulations, while another role only allows viewing simulation results.
              
              You can view your workspace's roles and their associated permissions in the "Roles" segment in the left nav.`,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default MemberEditDialog;
