import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faBookOpen, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import A from 'components/general/A';
import StyledButton from 'components/general/StyledButton';
import StyledDivider from 'components/general/StyledDivider';
import WaveDecoration from 'components/general/WaveDecoration';
import SupportDialog from 'components/general/dialogs/SupportDialog';
import {
  DOCUMENTATION_URL,
  LICENSE_AGREEMENT_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_USE_URL,
  VERSION_NUMBER,
  hotkeys,
} from 'config';
import { useActiveEntities, useUser } from 'hooks';
import { logo, logoStacked } from 'multimedia/brand';
import { ContextNavContext } from 'providers';
import { useContext, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { NavLink, useHistory } from 'react-router-dom';
import Routes from 'routes';
import ClipboardCopy from '../ClipboardCopy';
import InfoBadge from '../InfoBadge';
import BranchIcons from './BranchIcons';
import ContextNavList from './ContextNavList';
import useStyles from './styles';

/* Navigation Sidebar
 * Renders left-justified sidebar for global and contextual navigation
 */
const Nav = (props) => {
  let { children, contextName, contextHeader, contextSubHeader, control, contextIcon, contextImg } =
    props;

  const user = useUser();
  const { repo, branch } = useActiveEntities();
  const history = useHistory();

  if (!contextHeader) {
    contextHeader = repo != null ? repo.name : undefined;
  }
  if (!contextSubHeader) {
    contextSubHeader = branch?.id ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {branch.name} <InfoBadge content={`Branch ID: ${branch.id}`} style={{ marginLeft: 5 }} />
        <ClipboardCopy text={branch.id} style={{ paddingLeft: 5 }} displayLabel={false} />
      </div>
    ) : undefined;
  }

  const [supportDialogConfig, setSupportDialogConfig] = useState({
    open: false,
  });

  const classes = useStyles({ user });

  const navContext = useContext(ContextNavContext);

  const goHome = () => history.push(Routes.ROOT());

  useHotkeys(hotkeys.HOME.keys, goHome, [goHome]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.padder}>
            {contextImg || contextIcon || contextName ? (
              <>
                <NavLink
                  exact
                  to={!user ? Routes.WELCOME() : Routes.ROOT()}
                  className={classes.brandNavLink}
                >
                  <img src={logo} alt="Unified spacecraft modeling and simulation with Sedaro" />
                </NavLink>
                <StyledDivider />
                <div className={classes.iconWrapper}>
                  {contextIcon && contextIcon}
                  {contextImg && (
                    <img src={contextImg} className={classes.contextImg} alt="context-icon" />
                  )}
                  <h1 className={classes.contextName}>{contextName}</h1>
                </div>
              </>
            ) : (
              <div className={classes.stackedLogoWrapper}>
                <NavLink exact to={Routes.ROOT()}>
                  <img
                    src={logoStacked}
                    alt="Unified spacecraft modeling and simulation with Sedaro"
                  />
                </NavLink>
              </div>
            )}
          </div>
          <WaveDecoration />
        </div>
        {contextHeader && (
          <div className={classes.padder}>
            {contextHeader != null && <h4>{contextHeader}</h4>}
            {contextSubHeader != null && <h4 className="sub">{contextSubHeader}</h4>}
            {branch.id && navContext && <BranchIcons />}
            <StyledDivider $margin="top" />
          </div>
        )}
        <div className={classes.contextNavMenu}>
          {control && (
            <div className={classes.padder}>
              {control}
              <StyledDivider />
            </div>
          )}
          {navContext && <ContextNavList />}
          <div className={classes.padder}>{children}</div>
        </div>
        <div className={classes.globalNavRoot}>
          <div className={classes.padder}>
            <StyledButton
              type="button"
              fullWidth
              style={{ marginTop: 0, marginBottom: 10 }}
              onClick={() =>
                window.open(DOCUMENTATION_URL.concat('IDFTrainingModules/Introduction'))
              }
            >
              IDF Training Modules
            </StyledButton>
            <StyledDivider />
            <Tooltip
              className={classes.globalNavLink}
              arrow
              title={user ? 'Account' : 'Anonymous User'}
            >
              <IconButton
                disableFocusRipple
                disableRipple
                onClick={() => (user ? history.push(Routes.ACCOUNT()) : undefined)}
              >
                <span className={classes.accountAvatar}>
                  {user && <h4>{user.firstName.charAt(0) + user.lastName.charAt(0)}</h4>}
                  {!user && <AccountCircleIcon className={classes.anonymousUserIcon} />}
                </span>
              </IconButton>
            </Tooltip>
            <span className={classes.globalNavControls}>
              <Tooltip arrow title={`Workspaces [${hotkeys.HOME.keys}]`}>
                <IconButton className={classes.globalNavLink} disabled={!user} onClick={goHome}>
                  <FontAwesomeIcon icon={faDatabase} />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Documentation">
                <a
                  href={DOCUMENTATION_URL}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.globalNavLink}
                >
                  <FontAwesomeIcon icon={faBookOpen} />
                </a>
              </Tooltip>
              <Tooltip arrow title="Support">
                <IconButton
                  onClick={() => setSupportDialogConfig({ open: true })}
                  className={classes.globalNavLink}
                  disableFocusRipple={true}
                  disableRipple={true}
                  color="inherit"
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </IconButton>
              </Tooltip>
            </span>
          </div>
          <div className={classes.footer}>
            <div className={classes.padder}>
              <div className={classes.footerContent}>
                <div className={classes.versionNumber}>v {VERSION_NUMBER}</div>
                <div className={classes.legal}>
                  <A href={LICENSE_AGREEMENT_URL} target="_blank">
                    Licensing
                  </A>
                  {' | '}
                  <A href={TERMS_OF_USE_URL} target="_blank">
                    Terms
                  </A>
                  {' | '}
                  <A href={PRIVACY_POLICY_URL} target="_blank">
                    Privacy
                  </A>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupportDialog
        config={supportDialogConfig}
        onClose={() =>
          setSupportDialogConfig({
            ...supportDialogConfig,
            open: false,
          })
        }
      />
    </>
  );
};

export default Nav;
