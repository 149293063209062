import { InputAdornment } from '@material-ui/core';
import { MdAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import {
  IErrorResponse,
  IGenericObject,
  IMission,
  IMissionVersion,
  ISelectOption,
} from 'components/general/types';
import { IAgent } from 'components/general/types/agent';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectAll, useSnackbar } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import useWorkspace from 'hooks/useWorkspace';
import _ from 'lodash';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translateIn, translateOut } from 'utils/forms';
import { AgentVables, OrbitVables, RepoVables, TargetVables } from 'utils/vable';
import OrbitForm from '../OrbitForm';
import { useGuidance } from './guidance';
import validation from './validation';

export type IAgentDialogBranchOption = ISelectOption & {
  repository: string;
  // WARN: salt. String is actually undefined? No error. String could be undefined by definition? Typescript error -_-
  targetList: [string, string, string | undefined][];
  targetGroupList: [string, string, string][];
};

export type IAgentDialogAgentOptions =
  | ISelectOption
  | IAgentDialogBranchOption
  | IGenericObject
  | (IGenericObject & { initialRefOrbit: '' | ISelectOption })
  | unknown[];

interface IForm {
  // Agent
  name: string;
  agentType: ISelectOption | '';
  differentiatingState: IGenericObject;
  targetMapping: unknown[];
  // Peripheral
  targetType?: ISelectOption | '';
  // Ground targets
  lat?: number | '';
  lon?: number | '';
  alt?: number | '';
  // Space targets and templated agents
  orbit?: ISelectOption | '';
  // Templated agents
  template?: ISelectOption | '';
  branch?: IAgentDialogBranchOption | '';
  [key: number]: ISelectOption | '';
  polynomialEphemerisBody?: ISelectOption | '';

  // Orbit
  initialStateDefType: ISelectOption | '';
  initialStateDefParams: IGenericObject & { initialRefOrbit: ISelectOption | '' };
}

const defaultValues: IForm = {
  // Agent
  name: '',
  agentType: '',
  differentiatingState: {},
  targetMapping: [],
  targetType: '',
  lat: '',
  lon: '',
  alt: '',
  orbit: '',
  template: '',
  branch: '',
  polynomialEphemerisBody: '',

  // Orbit
  initialStateDefType: '',
  initialStateDefParams: {
    a: '',
    e: '',
    inc: '',
    nu: '',
    om: '',
    raan: '',
    alt: '',
    lon: '',
    altPerigee: '',
    mltAscNode: null,
    initialRefOrbit: '',
    stateEci: ['', '', '', '', '', ''],
  },
};

const baseValues = [
  'name',
  'peripheral',
  'templateRef',
  'targetMapping',
  'targetGroupMapping',
  'differentiatingState',
  'orbit',
];
const orbitValues = ['initialStateDefType', 'initialStateDefParams'];

interface IProps {
  control: TEntityDialogControl<IAgent>;
}
const AgentDialog = ({ control }: IProps) => {
  // Set up styles
  const classes = useStyles();
  const dispatch = useDispatch();
  const workspace = useWorkspace();
  const { enqueueSnackbar } = useSnackbar();
  const {
    Orbit: {
      actions: { createOrbit, updateOrbit, deleteOrbit },
    },
  } = SatelliteApi;
  const { orbits, agents, agentGroups, branch } = useActiveEntities();
  const {
    dialogConfig: { entity: agent, action },
  } = control;

  const agentTemplateRepos = (useSelectAll('Mission') as IMission[]).filter(
    (repo) => repo.repoType === RepoVables.RepoTypes.AGENT_TEMPLATE.value
  );
  const templateBranches = useSelectAll('MissionVersion') as IMissionVersion[];

  const templateBranchesOptions = useMemo(
    () =>
      templateBranches
        .filter((b) => b.workspace === workspace?.id)
        .map((b) => {
          return {
            value: b.id,
            label: b.name,
            repository: b.repository,
            // Include a list of targets, each target is a 3-tuple: (id, name, targetType)
            // allows for easier creation of targetMapping form
            targetList: (b.targets || []).map((t) => [
              // simplify targets down to id, name, and target type
              `${t.id}`,
              t.name,
              // @ts-ignore NOTE: targetType is indeed not on the model, but it has been manually added to the response in the API
              t.targetType,
            ]),
            targetGroupList: (b.targetGroups || [])
              .filter(
                // remove celestial target groups
                (t) => t.targetType !== 'CelestialTarget'
              )
              .map((t) => [
                // simplify target groups down to id, name, and target type
                `${t.id}`,
                t.name,
                t.targetType,
              ]),
          } as IAgentDialogBranchOption;
        }),
    [templateBranches, workspace]
  );

  const allOptions = useMemo(
    () => ({
      agentType: AgentVables.AgentType.options,
      targetType: TargetVables.Type.options,
      polynomialEphemerisBody: TargetVables.PolynomialEphemerisBody.options,
      orbit: orbits.map((orbit) => {
        return { value: orbit.id, label: orbit.name };
      }),
      template: agentTemplateRepos
        .filter((b) => b.workspace === workspace?.id)
        .map((repo) => ({
          value: repo.id,
          label: repo.name,
        })),
      branch: templateBranchesOptions,
      agent: agents
        .filter((a) => agent?.id !== a.id)
        .map((a) => {
          return {
            value: a.id,
            label: a.name,
            type: a.peripheral
              ? a.differentiatingState.targetType
              : TargetVables.Type.SpaceTarget.value,
            _isGroup: false,
          };
        }),
      // _isGroup differentiates agents from agentGroups in the values. Otherwise, they hold similar structures and can be confused in pre/post-processing
      agentGroup: agentGroups.map((a) => {
        return { value: a.id, label: a.name, type: a.agentType, _isGroup: true };
      }),
      initialStateDefType: OrbitVables.InitialStateDefType.options,
      'initialStateDefParams.initialRefOrbit': OrbitVables.InitialRefOrbit.options,
    }),
    [orbits, agentTemplateRepos, agents, agentGroups, agent, templateBranchesOptions, workspace]
  );

  const customTranslateIn = useCallback(
    (agent, defaultValues, options, datetimes) => {
      // agentType dropdown is a replacement for the boolean checkbox 'peripheral',
      // feels more natural
      agent.agentType = agent.peripheral
        ? AgentVables.AgentType.PERIPHERAL.value
        : AgentVables.AgentType.TEMPLATED.value;
      for (const key in agent.differentiatingState) {
        agent[key] = agent.differentiatingState[key];
      }

      // Convert templateRef into template and branch dropdowns
      const branch = templateBranches.find((b) => b.id === agent.templateRef);
      agent.branch = branch?.id || '';
      agent.template = branch?.mission || '';

      // Convert targetMapping into dropdowns for each target
      if (agent?.targetMapping) {
        for (const mappedAgent of agent.targetMapping) {
          for (const mappedTarget of mappedAgent.manySideData.associatedTargets) {
            const targetId = mappedTarget;
            agent[targetId] = options.agent.find(
              (option: ISelectOption) => option.value === mappedAgent.id
            );
            defaultValues[targetId] = '';
          }
        }
      }

      // Convert targetGroupMapping into dropdowns for each agent
      if (agent?.targetGroupMapping) {
        for (const mappedAgentGroup of agent.targetGroupMapping) {
          for (const mappedTargetGroup of mappedAgentGroup.manySideData.associatedTargetGroups) {
            const targetGroupId = mappedTargetGroup;
            agent[targetGroupId] = options.agentGroup.find(
              (option: ISelectOption) => option.value === mappedAgentGroup.id
            );
            defaultValues[targetGroupId] = '';
          }
        }
      }

      // Transfer orbit values in
      if (agent.orbit)
        for (const orbitValue of orbitValues) agent[orbitValue] = agent.orbit[orbitValue];

      return translateIn(agent, defaultValues, options, datetimes);
    },
    [templateBranches]
  );

  const customTranslateOut = useCallback((agent, allowedEmptyFields, options, datetimes) => {
    // Convert agentType dropdown back to peripheral boolean
    agent.peripheral = agent.agentType.value === AgentVables.AgentType.PERIPHERAL.value;
    delete agent.agentType;

    // Convert template and branch dropdown into template ref (id of agent template branch)
    agent.templateRef = agent.branch.value;
    delete agent.branch;
    delete agent.template;

    // Remove empty orbit state value if necessary
    if (
      agent.initialStateDefParams &&
      _.isEqual(agent.initialStateDefParams.stateEci, defaultValues.initialStateDefParams.stateEci)
    )
      delete agent.initialStateDefParams.stateEci;

    const result = translateOut(agent, allowedEmptyFields, options, datetimes);

    result.targetMapping = {};
    result.targetGroupMapping = {};
    result.differentiatingState = result.differentiatingState || {};
    for (const key in result) {
      // Collapse agent ids into single targetMapping field
      if (_.has(result[key], 'type')) {
        if (result[key]._isGroup === false) {
          const agentId = result[key].value;
          if (result.targetMapping[agentId]?.associatedTargets?.constructor === Array) {
            if (!result.targetMapping[agentId].associatedTargets.includes(key)) {
              result.targetMapping[agentId].associatedTargets.push(key);
            }
          } else {
            result.targetMapping[agentId] = { associatedTargets: [key] };
          }
          delete result[key];
        } else {
          const agentGroupId = result[key].value;
          if (
            result.targetGroupMapping[agentGroupId]?.associatedTargetGroups?.constructor === Array
          ) {
            if (!result.targetGroupMapping[agentGroupId].associatedTargetGroups.includes(key)) {
              result.targetGroupMapping[agentGroupId].associatedTargetGroups.push(key);
            }
          } else {
            result.targetGroupMapping[agentGroupId] = { associatedTargetGroups: [key] };
          }
          delete result[key];
        }
      }
      // Move everything else into differentiating state
      // Orbit values will be stripped off later, using valuesToRemove
      else if (!baseValues.includes(key) && !orbitValues.includes(key)) {
        result.differentiatingState[key] = result[key];
        delete result[key];
      }
    }
    return result;
  }, []);

  // Create the orbit before creating the agent
  const [loading, setLoading] = useState(false);

  const predispatchCallback = async (values: IGenericObject) => {
    // Only run this callback if an orbit needs to be created
    if (
      values.peripheral &&
      values.differentiatingState.targetType !== TargetVables.Type.SpaceTarget.value
    )
      return true;

    let result;
    const DEFAULT_ERROR_MESSAGE =
      'Something went wrong. Please try again. If this problem persists, please contact our support team.';
    setLoading(true);
    const successCallback = () => {
      result = true;
      setLoading(false);
    };
    const failureCallback = (errorResponse: IErrorResponse) => {
      result = false;
      setLoading(false);
      enqueueSnackbar(errorResponse?.error?.message || DEFAULT_ERROR_MESSAGE);
    };
    const orbit: IGenericObject = {};
    for (const orbitValue of orbitValues) orbit[orbitValue] = values[orbitValue];

    switch (action) {
      case 'clone':
      case 'create':
        dispatch(
          createOrbit({
            branchId: branch.id,
            type: 'Orbit',
            ...orbit,
            successCallback: (response: { id: unknown }) => {
              values.orbit = response.id;
              successCallback();
            },
            failureCallback,
          })
        );
        break;
      case 'edit':
        dispatch(
          updateOrbit({
            id: agent?.orbit?.id,
            branchId: branch.id,
            type: 'Orbit',
            ...orbit,
            successCallback,
            failureCallback,
          })
        );
        break;
      case 'delete':
        if (agent?.orbit?.id) {
          dispatch(
            deleteOrbit({
              id: agent.orbit.id,
              branchId: branch.id,
              successCallback,
              failureCallback,
            })
          );
        } else {
          successCallback();
        }
        break;
    }

    // await response for orbit before setting agent
    // dispatch itself can't be awaited unfortunately, so this is a sleep hack
    // check for result to be defined every 1/3 second
    while (result === undefined) {
      await new Promise((r) => setTimeout(r, 333));
    }
    return result;
  };

  const entityForm = useEntityForm<IAgent, IForm>({
    entityTypeText: 'Agent',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    predispatchCallback,
    valuesToRemove: orbitValues,
    formikOptionalParams: {
      useGuidance,
      options: allOptions,
      datetimes: ['initialStateDefParams.mltAscNode'],
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
    additionalCreateValues: { type: 'Agent' },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setFieldValue } = formik;

  return (
    <EntityDialog entityForm={entityForm} additionalLoading={loading}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Agent Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          <LabeledSelect
            {...getFieldProps('agentType')}
            label="Agent Type"
            options={allOptions.agentType}
            isDisabled={action !== 'create'}
            formikOnChange={(val: IForm['agentType']) => {
              if (val !== values.agentType) {
                setFieldValue('template', '');
                setFieldValue('targetType', '');
                setFieldValue('orbit', '');
                setFieldValue('initialStateDefType', '');
              }
            }}
          />
          {values.agentType === AgentVables.AgentType.PERIPHERAL && (
            <>
              <LabeledSelect
                {...getFieldProps('targetType')}
                label="Peripheral Agent Subtype"
                options={allOptions.targetType}
                isDisabled={action !== 'create'}
              />
              {values.targetType === TargetVables.Type.GroundTarget && (
                <>
                  <LabeledInput
                    {...getFieldProps('lat')}
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                    label="Latitude (North)"
                  />
                  <LabeledInput
                    {...getFieldProps('lon')}
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                    label="Longitude (East)"
                  />
                  <LabeledInput
                    {...getFieldProps('alt')}
                    type="number"
                    endAdornment={<InputAdornment position="end">km</InputAdornment>}
                    label="Altitude"
                  />
                </>
              )}
              {values.targetType === TargetVables.Type.SpaceTarget && (
                <OrbitForm entityForm={entityForm} options={allOptions} />
              )}
              {values.targetType === TargetVables.Type.CelestialTarget && (
                <LabeledSelect
                  label="Celestial Bodies"
                  options={allOptions.polynomialEphemerisBody}
                  {...getFieldProps('polynomialEphemerisBody')}
                />
              )}
            </>
          )}
          {values.agentType === AgentVables.AgentType.TEMPLATED && (
            <>
              <LabeledSelect
                {...getFieldProps('template')}
                label="Template Repository"
                options={allOptions.template}
                formikOnChange={(val: IForm['template']) => {
                  if (val !== values.template) setFieldValue('branch', '');
                }}
              />
              {values.template && (
                <div className={classes.indent}>
                  <LabeledSelect
                    {...getFieldProps('branch')}
                    label="Branch"
                    options={allOptions.branch.filter(
                      (tb) => values.template && tb.repository === values.template.value
                    )}
                  />
                  {values.branch && values.branch.targetGroupList.length > 0 && (
                    // Create a dropdown for each target in this branch,
                    // letting the user map each to an actual agent in the scenario
                    <MdAccent header="Target Group Mapping">
                      {values.branch.targetGroupList.map(([id, name, targetType]) => (
                        <LabeledSelect
                          {...getFieldProps(id)}
                          key={id}
                          label={name}
                          isClearable
                          options={allOptions.agentGroup.filter(
                            (a) =>
                              // @ts-ignore: next-line
                              // (values[id]?.value === a.value || !agentGroupsMapped.has(a.value)) &&
                              // Double mapping allowed
                              a.type === targetType
                          )}
                        />
                      ))}
                    </MdAccent>
                  )}
                  {values.branch && values.branch.targetList.length > 0 && (
                    // Create a dropdown for each target in this branch,
                    // letting the user map each to an actual agent in the scenario
                    <MdAccent header="Target Mapping">
                      {values.branch.targetList.map(([id, name, targetType]) => (
                        <LabeledSelect
                          {...getFieldProps(id)}
                          key={id}
                          label={name}
                          isClearable
                          options={allOptions.agent.filter(
                            (a) =>
                              // @ts-ignore: next-line
                              // (values[id]?.value === a.value || !agentsMapped.has(a.value)) &&
                              // Double mapping allowed
                              a.type === targetType
                          )}
                        />
                      ))}
                    </MdAccent>
                  )}
                </div>
              )}
              <div className={classes.inputGroup}>
                <OrbitForm entityForm={entityForm} options={allOptions} />
              </div>
            </>
          )}
        </div>
      </div>
    </EntityDialog>
  );
};

export default AgentDialog;
