import Nav from 'components/general/Nav';
import SpacecraftDialog from 'components/general/SpacecraftDialog';
import { IUrlParams } from 'components/general/types';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import LoadingView from 'components/LoadingView';
import _ from 'lodash';
import moduleIconMap from 'multimedia/icons/moduleIconMap';
import { ActiveBranchContext } from 'providers';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { ModuleVables, TModuleType } from 'utils/vable';
import EditBoards from './EditBoards';

const AgentTemplateEditView = () => {
  const { branch } = useContext(ActiveBranchContext);
  const { moduleAction } = useParams<IUrlParams>();
  const moduleCaps = _.upperCase(moduleAction).replace(' ', '_') as TModuleType;

  if (!branch.model?.ready) return <LoadingView />;

  return (
    <ViewContainer>
      <Nav
        contextName={ModuleVables.ModuleTypes[moduleCaps].label}
        contextImg={moduleIconMap[moduleCaps]}
      ></Nav>
      <ViewPort minWidth={650}>
        <EditBoards />
      </ViewPort>
      <SpacecraftDialog />
    </ViewContainer>
  );
};

export default AgentTemplateEditView;
