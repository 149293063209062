import { TChartSpec } from 'components/general/types';

const statsEnergyStorageChartSpec: TChartSpec = [
  {
    name: 'Energy Storage Overview',
    subtitle: 'Overview of battery performance metrics',
    plots: [
      {
        type: 'table',
        variables: [
          {
            legend: [
              'Average State of Charge',
              'Minimum State of Charge',
              'Minimum Discharge Current Margin',
            ],
            keys: [
              'powerProcessor.battery.soc',
              'powerProcessor.battery.soc',
              'powerProcessor.battery.dischargeCurrentMargin',
            ],
            ops: ['avg', 'min', 'min'],
            unit: 'pct',
          },
          {
            legend: ['Max Charge Current', 'Max Discharge Current'],
            keys: [
              'powerProcessor.battery.current',
              'powerProcessor.battery.current',
            ],
            ops: ['positiveMax', 'negativeMax'],
            unit: 'a',
          },
        ],
      },
    ],
  },
];

export default statsEnergyStorageChartSpec;
