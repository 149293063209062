import { TChartSpec } from 'components/general/types';

const tsComponentTempsChartSpec: TChartSpec = [
  {
    name: '"{name}"',
    subtitle: '',
    each: 'Subsystem',
    plots: [
      {
        type: 'line',
        label: 'Temperature',
        unit: 'C',
        variables: [
          {
            keys: ['components.$each.temperature.degC'],
            legend: ['"{name}" Temp'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Hot Margin',
        unit: 'C',
        variables: [
          {
            keys: ['components.$each.hotMargin'],
            legend: ['"{name}" Hot Margin'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Cold Margin',
        unit: 'C',
        variables: [
          {
            keys: ['components.$each.coldMargin'],
            legend: ['"{name}" Cold Margin'],
          },
        ],
      },
    ],
  },
];

export default tsComponentTempsChartSpec;
