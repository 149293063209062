import { IWorkspace } from 'components/general/types';
import { useSelector } from 'react-redux';
import { useSelectById } from './stateHooks';

// Returns the latest job in a simulation, based on a redux value that is set in ActiveBranchProvider
// (or reset by SimulationControls in ScenarioView)
const useWorkspace = () => {
  // TS ignore because we added ui to state
  //@ts-ignore: next-line
  const activeWorkspaceId = useSelector((state) => state.ui.missionExplorer.activeWorkspaceId);
  const workspace = useSelectById('Workspace', activeWorkspaceId) as IWorkspace;

  return workspace;
};

export default useWorkspace;
