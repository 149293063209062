import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loading: {
    color: theme.palette.text.tertiary,
    textAlign: 'center',
    margin: 50,
    // Ellipsis loading animation
    '&:after': {
      overflow: 'hidden',
      display: 'inline-block',
      verticalAlign: 'bottom',
      animation: '$ellipsis steps(4,end) 1200ms infinite',
      animationFillMode: 'both',
      animationDirection: 'alternate-reverse',
      content: '"..."',
      width: 0,
    },
  },
  '@keyframes ellipsis': {
    to: {
      width: 18,
    },
  },
}));

export default useStyles;
