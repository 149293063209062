import { TChartSpec } from 'components/general/types';

const tsPowGenerationChartSpec: TChartSpec = [
  {
    name: 'Power Generation Overview',
    subtitle: 'Total power for all solar arrays',
    plots: [
      {
        type: 'line',
        label: 'Power',
        unit: 'W',
        labelRight: 'Utilization',
        variables: [
          {
            keys: ['totalSolarPowerGeneration', 'totalMaxSolarPower'],
            legend: ['Total Power', 'Total Available Power'],
          },
          {
            keys: ['totalSolarPowerUtilization'],
            legend: ['Total Utilization'],
            right: true,
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Solar Array',
    subtitle: 'For the array and all constituent solar panels',
    each: 'SolarArray',
    plots: [
      {
        type: 'line',
        name: 'Operating Point',
        label: 'Current',
        unit: 'A',
        labelRight: 'Voltage',
        unitRight: 'V',
        variables: [
          {
            keys: ['current'],
            legend: ['Current'],
          },
          {
            keys: ['voltage'],
            legend: ['Voltage'],
            right: true,
          },
        ],
      },
      {
        type: 'line',
        name: 'Power Generation',
        label: 'Power',
        unit: 'W',
        labelRight: 'Utilization',
        variables: [
          {
            keys: ['power', 'maxPower'],
            legend: ['Power', 'Available Power'],
          },
          {
            keys: ['utilization'],
            legend: ['Utilization'],
            right: true,
          },
        ],
      },
      {
        type: 'line',
        name: 'Current By Panel',
        label: 'Current',
        unit: 'A',
        variables: [
          {
            keys: ['panels.$each.current'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        name: 'Voltage By Panel',
        label: 'Voltage',
        unit: 'V',
        variables: [
          {
            keys: ['panels.$each.voltage'],
            legend: ['{name}'],
          },
        ],
      },
      {
        type: 'line',
        name: 'Power By Panel',
        label: 'Power',
        unit: 'W',
        variables: [
          {
            keys: ['panels.$each.power'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
];

export default tsPowGenerationChartSpec;
