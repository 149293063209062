import { useMemo, useContext, useCallback } from 'react';
import { ISolarPanel, ISolarCell, ISubsystem } from 'components/general/types/power';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm, useActiveEntities } from 'hooks';
import useGuidance from './guidance';
import InputAdornment from '@material-ui/core/InputAdornment';
import SpacecraftAccent from 'components/general/Accent/variants/SpacecraftAccent';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { SpacecraftContext } from 'providers';
import validation from './validation';
import { PowerAccent } from 'components/general/Accent/variants';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { ISelectOption } from 'components/general/types';
import { ComponentVables, SubsystemVables } from 'utils/vable';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import { translateIn } from 'utils/forms';
import getThermalProps from 'hooks/getThermalProps';
import { ISurface } from 'components/general/types/spacecraft';

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  surface: ISelectOption | '';
  cell: ISelectOption | '';
  numSeries: number | '';
  numParallel: number | '';
  blockingDiodeDrop: number;
  blockingDiodeDropBool: boolean;
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  surface: '',
  cell: '',
  numSeries: '',
  numParallel: '',
  blockingDiodeDrop: 0,
  blockingDiodeDropBool: false,
};

interface IProps {
  control: TEntityDialogControl<ISolarPanel>;
}

const Dialog = (props: IProps) => {
  // Handle props
  const { control } = props;

  // Grab entities, actions, and context
  const { setSpacecraftDialogConfig, SpacecraftTabs } = useContext(SpacecraftContext);
  const { solarCells, surfaces, subsystems } = useActiveEntities();

  // Set up styles
  const classes = useStyles();

  const solarCellsList = useMemo(
    () =>
      solarCells.map((solarCell: ISolarCell) => {
        return { value: solarCell.id, label: solarCell.partNumber };
      }),
    [solarCells]
  );

  const surfacesList = useMemo(
    () =>
      surfaces.map((surface: ISurface) => {
        return { value: surface.id, label: surface.name };
      }),
    [surfaces]
  );

  const options = useMemo(() => {
    return {
      cell: solarCellsList,
      surface: surfacesList,
    };
  }, [surfacesList, solarCellsList]);

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const customTranslateIn = useCallback((solarPanel, defaultValues, options) => {
    // Prefill out checkbox if the solar panel has a blocking diode to display value if a value other than 0 is saved
    solarPanel.blockingDiodeDropBool = !!solarPanel.blockingDiodeDrop;
    return translateIn(solarPanel, defaultValues, options);
  }, []);

  const entityForm = useEntityForm<ISolarPanel, IForm & typeof thermalDefaultValues>({
    entityTypeText: 'Solar Panel',
    overrideEntityText: 'Component',
    entityType: { type: ComponentVables.Type.SolarPanel.value },
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: {
      subsystem: subsystems.find(
        (system: ISubsystem) => system.category === SubsystemVables.Categories.POWER.value
      )?.id,
      type: 'SolarPanel',
    },
    valuesToRemove: ['blockingDiodeDropBool'],
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
      allowedEmptyFields: ['manufacturer', 'partNumber'],
      translateIn: customTranslateIn,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, setFieldValue, values } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            type="text"
            placeholder="Solar Panel Name"
            label="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('partNumber')}
            placeholder="Part Number"
            label="Part Number"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            placeholder="Manufacturer"
            label="Manufacturer"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <SpacecraftAccent
            header="External Surface"
            onAddAction={() =>
              setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
            }
          >
            <LabeledSelect
              {...getFieldProps('surface')}
              label="Installed On"
              options={options.surface}
              noOptionsMessage={() => 'Create a Surface'}
            />
          </SpacecraftAccent>
          <PowerAccent header="Solar Cell">
            <LabeledSelect
              {...getFieldProps('cell')}
              label="Solar Cell"
              options={options.cell}
              noOptionsMessage={() => 'Create a Solar Cell'}
            />
          </PowerAccent>
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('numSeries')}
            type="number"
            label="Number of Cells in Series"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('numParallel')}
            type="number"
            label="Number of Cells in Parallel"
          />
        </div>
        <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
        <div className={classes.inputGroup}>
          <LabeledCheckbox
            {...getFieldProps('blockingDiodeDropBool')}
            label="Add Blocking Diode"
            formikOnChange={() => {
              setFieldValue('blockingDiodeDrop', defaultValues.blockingDiodeDrop);
            }}
          />
          {values.blockingDiodeDropBool && (
            <LabeledInput
              {...getFieldProps('blockingDiodeDrop')}
              endAdornment={<InputAdornment position="end">V</InputAdornment>}
              type="number"
              label="Blocking Diode Voltage"
            />
          )}
        </div>
      </div>
    </EntityDialog>
  );
};

export default Dialog;
