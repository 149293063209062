/* Application Config
 * Production and Development
 */

import { WorkspaceVables } from 'utils/vable';

const {
  REACT_APP_SEDARO_API_URL,
  REACT_APP_VERSION_NUMBER,
  REACT_APP_CES,
  REACT_APP_DISABLE_RECAPTCHA,
  REACT_APP_CESIUM_ACCESS_TOKEN,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_DOCS_URL,
  NODE_ENV,
} = process.env;

// Returns true if environment is Production, false otherwise
export function isProd() {
  return NODE_ENV === 'production';
}

export const IS_CES = Boolean(Number(REACT_APP_CES));
export const DISABLED_RECAPTCHA = Boolean(Number(REACT_APP_DISABLE_RECAPTCHA));

// reCAPTCHA Site Key
export const RECAPTCHA_SITE_KEY = isProd()
  ? '6Ld48rMbAAAAAG-fiu_UYlWgwabgF7G9RSH4o9nL'
  : '6LfE9bMbAAAAAEoV_AVrT-ILyorzy52zmnTeF7l7';
export const RECAPTCHA_PRIVACY_URL = 'https://policies.google.com/privacy';
export const RECAPTCHA_TERMS_URL = 'https://policies.google.com/terms';

// reusable url portions
const localhost = 'http://localhost:';
const companyUrl = (base = 'www') => `https://${base}.sedaro.com`;

// URL for the Sedaro API
export const SATELLITE_API_URL = isProd() ? REACT_APP_SEDARO_API_URL : localhost + '80';

export const TERMS_OF_USE_URL = companyUrl() + '/terms-of-use';
export const PRIVACY_POLICY_URL = companyUrl() + '/privacy-policy';
export const LICENSE_AGREEMENT_URL = companyUrl() + '/license-agreement';
export const DOCUMENTATION_URL =
  REACT_APP_DOCS_URL || (isProd() ? companyUrl('docs') : localhost + '4000');
export const COMMUNITY_SLACK_URL =
  'https://join.slack.com/t/sedaro-community/shared_invite/zt-1jps4i711-mXy88AZQ9AV7YcEXr8x7Ow';

// contact
const atCompanyEmailCom = '@sedarotech.com';
export const SUPPORT_EMAIL = 'support' + atCompanyEmailCom;
export const SALES_EMAIL = 'sales' + atCompanyEmailCom;
export const SUPPORT_PHONE = '+1.781.573.3276';
export const FEEDBACK_FORM_URL =
  'https://forms.clickup.com/36141388/f/12eyac-2087/OLAAXL4VLFP2IV171I';

export const timing = {
  lineAnimationDuration: 600,
  dragAndDropHighlightDuration: 0.25,
};

export const DATA_LIMIT = 2500;
export const CACHE_DATA = false;

// strings used in menus
export const ITEMS = 'items';
export const SUB_ITEMS = 'subItems';
export const TITLE = 'title';
export const CHART_SPEC = 'chartSpec';
export const TOGGLE = 'toggle';
export const VERSION_NUMBER = REACT_APP_VERSION_NUMBER || '0.0.0';

export const hotkeys = {
  HOME: { keys: 'H', description: 'View list of workspaces' },
  // TODO: Implement hotkey for returning to current workspace
  WORKSPACE: { keys: 'w', description: 'Go to current workspace' },
  BRANCHES: { keys: 'Q', description: 'Go to Branches' },
  SIMULATE: { keys: 'R', description: 'Run/Abort Simulation' },
  FULLSCREEN: { keys: 'F', description: 'Fullscreen Playback' },
  PLAYBACK_PLAY: { keys: 'Space, K', description: 'Play/Pause Playback' },
  PLAYBACK_FORWARD: { keys: 'Right, Shift+Right, L, Shift+L', description: 'Forward Playback' },
  PLAYBACK_REWIND: { keys: 'Left, Shift+Left, J, Shift+J', description: 'Rewind Playback' },
  SC_DIALOG: { keys: 'S', description: 'Edit Spacecraft' },
};

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;

export const GENERIC_ERROR_MESSAGE =
  'Something went wrong. Please wait a moment and try again, or contact us if this issue persists.';

// Event names for Google Analytics
// Recommended ones here: https://developers.google.com/tag-platform/gtagjs/reference/events
export const gaEvents = {
  EXCEPTION: 'exception',
  JOIN_WORKSPACE: 'join_group',
  SHARE: 'share',
  SIGNUP: 'sign_up',
  SIM_START: 'level_start',
  SIM_ABORT: 'level_end',
};

export const ItemTypes = {
  REPO: 'repo',
  // Add more types here
};

export const EXPIRED_WORKSPACE_PERMISSIONS = [
  WorkspaceVables.Permission.VIEW_WORKSPACE_MEMBERS,
  WorkspaceVables.Permission.ASSIGN_REVOKE_WORKSPACE_ROLE,
  WorkspaceVables.Permission.DELETE_WORKSPACE,
  WorkspaceVables.Permission.VIEW_REPOSITORY,
  WorkspaceVables.Permission.SUBMIT_PAYMENT,
  WorkspaceVables.Permission.VIEW_PAYMENT_HISTORY,
].reduce((acc, curr) => acc | curr, 0);

export const CESIUM_ACCESS_TOKEN =
  REACT_APP_CESIUM_ACCESS_TOKEN ||
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzNjFiMjM0MS1hOWE5LTQzOTYtOGVjMy04NWVlMzFmNzQxNzAiLCJpZCI6OTkyMTgsImlhdCI6MTY5MDc2NTQ0MH0.tObBoju92LdmdrENpDVcLLDAZj2a4ZBSv7nLIKUApRE';
export const GOOGLE_MAPS_API_KEY =
  REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyB6CnqD7vGX69PffWXQiH3zLDHyvhUE-mQ';
