import {
  faArrowTrendUp,
  faArrowsAlt,
  faArrowsToCircle,
  faCameraRotate,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClock,
  faCube,
  faCubes,
  faDotCircle,
  faEyeLowVision,
  faGear,
  faLightbulb,
  faLocationDot,
  faSun,
  faTags,
  faTowerBroadcast,
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickAwayListener, Grid, Tooltip } from '@material-ui/core';
import * as Cesium from 'cesium';
import { wGroupIndicesAgentCustom } from 'components/AgentAnalyzeView/menu/custom';
import StyledButton from 'components/general/StyledButton';
import StyledSlider from 'components/general/StyledSlider';
import LabeledSelect from 'components/general/inputs/LabeledSelect/index.jsx';
import Widget from 'components/general/widgets/Widget';
import { CESIUM_ACCESS_TOKEN, GOOGLE_MAPS_API_KEY } from 'config';
import { useActiveEntities } from 'hooks';
import _, { noop } from 'lodash';
import { ContextNavContext, TimeContext, useDataContext } from 'providers';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Camera,
  CameraFlyTo,
  Clock,
  CylinderGraphics,
  EllipsoidGraphics,
  Entity,
  Globe,
  LabelGraphics,
  PolylineGraphics,
  Scene,
  ScreenSpaceCameraController,
  ShadowMap,
  SkyAtmosphere,
  SkyBox,
  Sun,
  Viewer,
} from 'resium';
import { routePathsCommon } from 'routes';
import { a2Period } from 'utils/orbit';
import { jd2Mjd, mjd2Moment } from 'utils/time';
import { cesiumClockMultiplierScaleDefault } from '../general/constants';
import TargetEntity from './TargetEntity';
import Moon from './moon';
import useStyles from './styles';
import { perigeeCadScale } from './utils';

Cesium.Ion.defaultAccessToken = CESIUM_ACCESS_TOKEN;
try {
  Cesium.GoogleMaps.defaultApiKey = GOOGLE_MAPS_API_KEY;
} catch (e) {
  // window.location.reload(true); // bust cache
  // pass
}

const terrainOptions = [
  {
    label: 'WGS84 Ellipsoid',
  },
  {
    label: 'Google Photorealistic 3D Tiles',
    primitives: async () => [await Cesium.createGooglePhotorealistic3DTileset()],
    disabledImagerySelect: true,
  },
  {
    label: 'Cesium World Terrain',
    terrainProvider: async () =>
      await Cesium.createWorldTerrainAsync({
        requestWaterMask: true,
        requestVertexNormals: true,
      }),
  },
  {
    label: 'Cesium World Terrain w/ OSM Buildings',
    primitives: async () => [await Cesium.createOsmBuildingsAsync()],
    terrainProvider: async () =>
      await Cesium.createWorldTerrainAsync({
        requestWaterMask: true,
        requestVertexNormals: true,
      }),
  },
].map((v, i) => ({ value: i, ...v }));
const imageryOptions = [
  {
    label: 'Bing Maps Arial',
  },
  {
    label: 'Bing Maps Arial w/ Labels',
    imagery: async () =>
      await Cesium.createWorldImageryAsync({
        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
      }),
  },
  {
    label: 'Bing Maps Road',
    imagery: async () =>
      await Cesium.createWorldImageryAsync({
        style: Cesium.IonWorldImageryStyle.ROAD,
      }),
  },
  {
    label: 'OpenStreetMaps',
    imagery: async () => new Cesium.OpenStreetMapImageryProvider(),
  },
  {
    label: 'ArcGIS World Street Maps',
    imagery: async () =>
      Cesium.ArcGisMapServerImageryProvider.fromUrl(
        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer'
      ),
  },
].map((v, i) => ({ value: i, ...v }));

const scenarioPlaybackRegex = new RegExp(
  // lint thinks escapes here are unneccesary b/c doesn't know it's becoming regex
  `/${routePathsCommon.SCENARIO}/[a-zA-Z0-9_-]*/${routePathsCommon.ANALYZE}/.*` // eslint-disable-line
);

const flashlight = new Cesium.DirectionalLight({
  intensity: 5,
  direction: new Cesium.Cartesian3(0, 1, 0),
});

const cesiumLabelTextScale = 0.45;
const cesiumBfVectorLabelTextScale = 0.5;
const cesiumBfVectorLineWidth = 8;
const cesiumOrbitTailFactor = 0.975;
const cesiumSatOrbitColor = Cesium.Color.LIMEGREEN;
const cesiumBfVectorColor = Cesium.Color.CYAN;
// REF: sunTrackingVariables
// TODO: Comment cesiumSatOrbitLineWidth back in when ready
// const cesiumSurfaceVectorColor = Cesium.Color.YELLOW;
const cesiumSatOrbitLineWidth = 3;
// Leap seconds to offset when comparing UTC (Coordinated Universal Time) and TAI (International
// Atomic Time) This is needed because Cesium.JulianDate stores time in TAI, but our data is in
// UTC. The last item in the leapSeconds array is always the most current # leap seconds.
const secondsDiffUtcToTai =
  Cesium.JulianDate.leapSeconds[Cesium.JulianDate.leapSeconds.length - 1].offset;

// ==================================================================================================================
// Other
// ==================================================================================================================

const IndexedClock = forwardRef((props, ref) => {
  let { startTime: temp, animate, ...rest } = props;

  const { time } = useContext(TimeContext);
  const { startTime: globalStartTime } = useDataContext();

  const startTime = Cesium.JulianDate.fromIso8601(mjd2Moment(globalStartTime).format());
  const [currentTime, setCurrentTime] = useState(startTime);

  useEffect(() => {
    if (time !== globalStartTime) {
      setCurrentTime(Cesium.JulianDate.fromIso8601(mjd2Moment(time).format()));
    }
  }, []); // eslint-disable-line

  return (
    <Clock
      ref={ref}
      startTime={startTime}
      currentTime={currentTime}
      {...rest}
      shouldAnimate={animate}
    />
  );
});
IndexedClock.displayName = 'IndexedClock';

// ==================================================================================================================
// Generate Mission Module Playback Viewer
// ==================================================================================================================

const PlaybackViewer = (props) => {
  const {
    viewerRef,
    cameraRef,
    clockRef,
    satelliteEntityRef,
    initiallyTrack = true,
    contextValues,
    animate,
    loop,
  } = props;

  const { repo } = useActiveEntities();
  const { pathname } = useLocation();
  const isScenarioPlayback = useMemo(() => scenarioPlaybackRegex.test(pathname), [pathname]);

  const [showFollowSc, setShowFollowSc] = useState(true);
  const [followSc, setFollowSc] = useState(!isScenarioPlayback);
  const [showBfVectors, setShowBfVectors] = useState(true);
  const [showFovs, setShowFovs] = useState(true);
  const [showTargetNames, setShowTargetNames] = useState(true);
  const [showCommsLines, setShowCommsLines] = useState(true);
  const [showOrbitTails, setShowOrbitTails] = useState(true);
  const [showAgentModels, setShowAgentModels] = useState(true);
  const [cadScaleMultiplier, setCadScaleMultiplier] = useState(1);
  const [scaleToRealSize, setScaleToRealSize] = useState(false);
  const [inactiveAgentScaleMultiplier, setInactiveAgentScaleMultiplier] = useState(1);
  const [fovScaleMultiplier, setFovScaleMultiplier] = useState(1);
  const [labelScaleMultiplier, setLabelScaleMultiplier] = useState(1);
  const [useFlashlight, setUseFlashlight] = useState(!isScenarioPlayback);
  const [useEciCamera, setUseEciCamera] = useState(!followSc);
  const [showWayPoints, setShowWayPoints] = useState([true, true, true]);
  const [showWayPaths, setShowWayPaths] = useState([true, true, true]);

  const moonRef = useRef(null);
  const playbackViewerContentRef = useRef(null);

  useEffect(() => {
    if (!viewerRef.current) return;
    viewerRef.current.cesiumElement.trackedEntityChanged.addEventListener(function (entity) {
      if (entity !== undefined) {
        setUseEciCamera(false);
        setFollowSc(true);
      } else {
        cameraRef.current.cesiumElement.flyHome();
        setUseEciCamera(true);
        setFollowSc(false);
      }
    });
    let e =
      viewerRef.current.cesiumElement._container.children[0].children[1].children[0].children[0];
    if (e.className === 'cesium-credit-logoContainer') {
      e.style.display = 'none';
    }
    if (viewerRef.current.cesiumElement._animation) {
      viewerRef.current.cesiumElement._animation.container.style.visibility = 'hidden';
    }
    // remove the event handler for double clicking to zoom in on entities
    if (viewerRef.current.cesiumElement.cesiumWidget) {
      viewerRef.current.cesiumElement.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );
    }
  }, [viewerRef, cameraRef]);

  const [terrain, setTerrain] = useState({ selectedValue: terrainOptions[0] });
  const [imagery, setImagery] = useState({ selectedValue: imageryOptions[0] });

  const updateImagery = useCallback(
    (selection) => {
      if (viewerRef?.current) {
        const viewer = viewerRef?.current.cesiumElement;
        if (imagery.imagery) viewer.imageryLayers.remove(imagery.imagery);
        (async () => {
          const newImagery = { selectedValue: selection };
          newImagery.imagery =
            selection.imagery &&
            new Cesium.ImageryLayer(
              (await selection.imagery()) || (await Cesium.createWorldImageryAsync())
            );
          if (newImagery.imagery) viewer.imageryLayers.add(newImagery.imagery);
          setImagery(newImagery);
        })();
      }
    },
    [viewerRef, imagery]
  );

  const updateTerrain = useCallback(
    (selection) => {
      if (viewerRef?.current) {
        const viewer = viewerRef?.current.cesiumElement;
        if (terrain.primitives) viewer.scene.primitives.remove(...terrain.primitives);
        (async () => {
          const newTerrain = { selectedValue: selection };
          if (selection.primitives) {
            newTerrain.primitives = await selection.primitives();
            viewer.scene.primitives.add(...newTerrain.primitives);
          }
          viewer.terrainProvider =
            (selection.terrainProvider && (await selection.terrainProvider())) ||
            new Cesium.EllipsoidTerrainProvider();
          setTerrain(newTerrain);
          updateImagery(imagery.selectedValue); // Also update imagery in case the selected terrain removed it
        })();
      }
    },
    [viewerRef, terrain, imagery, updateImagery]
  );

  // // This is needed for when `requestRenderMode` below is set to `true` -- we need to manually trigger a rerender.
  // useEffect(() => {
  //   if (sceneRef.current) {
  //     sceneRef.current.cesiumElement.requestRender();
  //   }
  // }, [cesiumLabels]);

  const {
    TimeContext: { setTime },
    DataContext: {
      // REF: sunTrackingVariables
      // TODO: Comment data back in when ready
      // data,
      cesiumData,
      perigeeAlt,
      calculateMultiplier,
      orbitalElementsSeries,
      model,
    },
  } = contextValues;

  const [fullHeight, setFullHeight] = useState(400);
  const [extraZoom2D, setExtraZoom2D] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const hasTerrestrialAgent = useMemo(
    () => cesiumData.targets?.some((t) => t.type === 'TerrestrialTarget'),
    [cesiumData]
  );

  const cadScaleFactor = model.cadScaleFactor || 1;
  const cadScale = useMemo(() => {
    return perigeeCadScale(perigeeAlt, cadScaleFactor) * cadScaleMultiplier;
  }, [perigeeAlt, cadScaleFactor, cadScaleMultiplier]);
  function scaleCesiumVectorData(prevScaleValue, nextScaleValue) {
    cesiumData.setCadScalar((prev) => prev * (prevScaleValue / nextScaleValue));
  }

  const classes = useStyles();

  useEffect(() => {
    if (initiallyTrack && viewerRef.current && satelliteEntityRef.current) {
      if (!firstLoad) {
        setFirstLoad(true);
      } else {
        viewerRef.current.cesiumElement.trackedEntity = satelliteEntityRef.current.cesiumElement;
      }
    }
  }, [firstLoad, setFirstLoad, satelliteEntityRef, viewerRef, initiallyTrack]);

  useEffect(() => {
    const fitHeight = true;
    if (fitHeight) {
      const el = document.getElementById('viewport');
      const observer = new ResizeObserver((entries) => {
        setFullHeight(el.offsetHeight - 6 - 6 - 6);
      });
      observer.observe(el);
      return () => observer.disconnect(); // disconnect observer on unmount
    }
  }, []);

  const updatePlaybackState = useCallback(
    (currentTime) => {
      const time = jd2Mjd(
        // `Cesium.JulianDate` time is in TAI, & `data[#].time` is in UTC. TAI is `secondsDiffUtcToTai`
        // seconds ahead of UTC (37 as of 2021). So, take away leap seconds to convert back to UTC in
        // order to accurately search our `data` for the closest index.
        currentTime.dayNumber + (currentTime.secondsOfDay - secondsDiffUtcToTai) / 86400
      );
      setTime(time);
    },
    [setTime]
  );

  const [currTimeListenerInitialized, setCurrTimeListenerInitialized] = useState(false);
  useEffect(() => {
    // create listener to currentTime, use to trigger `updatePlaybackState`. We previously used onTick or onPostRender
    // to trigger updatePlaybackState, but this caused lag in Cesium animation while updatePlaybackState was executing
    if (!currTimeListenerInitialized && viewerRef.current) {
      Cesium.knockout
        .getObservable(viewerRef.current.cesiumElement.clockViewModel, 'currentTime')
        .subscribe(updatePlaybackState);
      setCurrTimeListenerInitialized(true);
    }
  }, [viewerRef, updatePlaybackState, currTimeListenerInitialized, setCurrTimeListenerInitialized]);

  const semiMajor = useMemo(() => {
    return orbitalElementsSeries.a?.length && orbitalElementsSeries.a[0] > 0
      ? orbitalElementsSeries.a[0]
      : 6378;
  }, [orbitalElementsSeries]);

  const bodyFrameVectors = useMemo(() => {
    if (!cesiumData.bfVectors) return [];
    return cesiumData.bfVectors.map((bfVector) => {
      const combinedMultiplier = Math.sqrt(
        Math.max(0.3, cadScaleMultiplier) * labelScaleMultiplier * fovScaleMultiplier
      );
      return [
        <Entity
          key={bfVector.name}
          name={`Body Frame Vector: ${bfVector.name}`}
          availability={
            new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: cesiumData.startTime,
                stop: cesiumData.stopTime,
              }),
            ])
          }
          position={bfVector.bfVectorPos2}
          show={
            viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
            Cesium.SceneMode.SCENE2D
          }
        >
          <PolylineGraphics
            positions={
              new Cesium.PositionPropertyArray([
                cesiumData.satellite.position3D,
                bfVector.bfVectorPos2,
              ])
            }
            show={showBfVectors}
            distanceDisplayCondition={{ near: 0, far: 50000000 }}
            width={cesiumBfVectorLineWidth}
            material={new Cesium.PolylineArrowMaterialProperty(cesiumBfVectorColor)}
            arcType={Cesium.ArcType.NONE}
          />
        </Entity>,
        <Entity
          key={bfVector.name + 'Label'}
          name={bfVector.name}
          availability={
            new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: cesiumData.startTime,
                stop: cesiumData.stopTime,
              }),
            ])
          }
          position={bfVector.bfVectorPos2Longer}
          show={
            viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
            Cesium.SceneMode.SCENE2D
          }
        >
          <LabelGraphics
            text={bfVector.name}
            show={showBfVectors}
            distanceDisplayCondition={{ near: 0, far: 50000000 }}
            scale={combinedMultiplier}
            fillColor={cesiumBfVectorColor}
            scaleByDistance={
              new Cesium.NearFarScalar(
                6378 * 1000,
                cesiumBfVectorLabelTextScale,
                semiMajor * 1000 * 20,
                cesiumBfVectorLabelTextScale / 10
              )
            }
          />
        </Entity>,
      ];
    });
  }, [
    cesiumData,
    showBfVectors,
    viewerRef,
    semiMajor,
    cadScaleMultiplier,
    labelScaleMultiplier,
    fovScaleMultiplier,
  ]);

  const sunTrackingSurfaces = useMemo(() => {
    // TODO: Return real data below
    // REF: sunTrackingVariables

    return [];
    // return cesiumData.sunTrackingSurfaces.map((surface) => {
    //   return [
    //     <Entity
    //       key={surface.name}
    //       name={`Surface: ${surface.name}`}
    //       availability={
    //         new Cesium.TimeIntervalCollection([
    //           new Cesium.TimeInterval({
    //             start: cesiumData.startTime,
    //             stop: cesiumData.stopTime,
    //           }),
    //         ])
    //       }
    //       position={surface.originPoint}
    //       show={
    //         viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
    //         Cesium.SceneMode.SCENE2D
    //       }
    //     >
    //       <PolylineGraphics
    //         positions={new Cesium.PositionPropertyArray([surface.originPoint, surface.endPoint])}
    //         show={showBfVectors}
    //         width={cesiumBfVectorLineWidth}
    //         material={new Cesium.PolylineArrowMaterialProperty(cesiumSurfaceVectorColor)}
    //         arcType={Cesium.ArcType.NONE}
    //       />
    //     </Entity>,
    //     <Entity
    //       key={surface.name + 'Label'}
    //       name={surface.name}
    //       availability={
    //         new Cesium.TimeIntervalCollection([
    //           new Cesium.TimeInterval({
    //             start: cesiumData.startTime,
    //             stop: cesiumData.stopTime,
    //           }),
    //         ])
    //       }
    //       position={surface.labelPoint}
    //       show={
    //         viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
    //         Cesium.SceneMode.SCENE2D
    //       }
    //     >
    //       <LabelGraphics
    //         text={surface.name}
    //         show={showBfVectors}
    //         fillColor={cesiumSurfaceVectorColor}
    //         scaleByDistance={
    //           new Cesium.NearFarScalar(
    //             6378 * 1000,
    //             cesiumBfVectorLabelTextScale,
    //             data[0].orbitalElements.a * 1000 * 20,
    //             cesiumBfVectorLabelTextScale / 10
    //           )
    //         }
    //       />
    //     </Entity>,
    //   ];
    // });
  }, []); // cesiumData, showBfVectors, data, viewerRef

  const targets = useMemo(() => {
    // console.log('TARGETS', cesiumData.targets);
    if (!cesiumData.targets) return [];
    return cesiumData.targets.map((target) => (
      <TargetEntity
        key={`${target.type.charAt(0)}T_${target.name}`}
        target={target}
        data={cesiumData}
        showOrbitTails={showOrbitTails}
        orbitTailFactor={cesiumOrbitTailFactor}
        showLabels={showTargetNames}
        showComms={showCommsLines}
        showWaypoints={true}
        labelTextScale={cesiumLabelTextScale * labelScaleMultiplier}
        viewerRef={viewerRef}
        showModels={showAgentModels}
        cadSignedUrl={target.cadSignedUrl}
        cadScaleFactor={target.cadScaleFactor * inactiveAgentScaleMultiplier}
        scaleToRealSize={scaleToRealSize}
        showWayPoints={showWayPoints}
        showWayPaths={showWayPaths}
      />
    ));
  }, [
    cesiumData,
    showTargetNames,
    showCommsLines,
    inactiveAgentScaleMultiplier,
    showOrbitTails,
    viewerRef,
    showAgentModels,
    labelScaleMultiplier,
    scaleToRealSize,
    showWayPoints,
    showWayPaths,
  ]);
  const [openMenu, setOpenMenu] = useState(false);

  const sensors = useMemo(() => {
    if (!cesiumData.fovs) return [];
    return cesiumData.fovs.map((field) => {
      const commonEntityProps = {
        key: field.name,
        name: `FoV: ${field.name}`,
        position: field.position3D,
        orientation: field.quaternion,
      };
      const commonGraphicProps = {
        material: Cesium.Color.PURPLE.withAlpha(0.25),
        outline: false,
      };

      if (
        field.type === 'RectangularFieldOfView' &&
        field.heightHalfAngle.deg < 90 &&
        field.widthHalfAngle.deg < 90
      ) {
        return (
          <Entity {...commonEntityProps} key={field.name}>
            <EllipsoidGraphics
              {...commonGraphicProps}
              // #TODO: May want to remove dynamically determined radii every timestep, potentially user defined length
              radii={cesiumData.sensorLengthCartesian}
              innerRadii={new Cesium.Cartesian3(10.0, 10.0, 10.0)}
              stackPartitions={3}
              slicePartitions={3}
              subdivisions={3}
              minimumClock={((90 - field.heightHalfAngle.deg) * Math.PI) / 180}
              maximumClock={((90 + field.heightHalfAngle.deg) * Math.PI) / 180}
              minimumCone={((90 - field.widthHalfAngle.deg) * Math.PI) / 180}
              maximumCone={((90 + field.widthHalfAngle.deg) * Math.PI) / 180}
              show={showFovs}
              distanceDisplayCondition={{ near: 0, far: 50000000 }}
            />
          </Entity>
        );
      } else if (field.type === 'CircularFieldOfView' && field.halfAngle.deg < 90) {
        return (
          <Entity {...commonEntityProps} key={field.name}>
            <CylinderGraphics
              {...commonGraphicProps}
              // #TODO: May want to remove dynamically determined length every timestep, potentially user defined length
              length={cesiumData.sensorLengthMag}
              heightReference={Cesium.HeightReference.CLAMPED_TO_GROUND}
              bottomRadius={field.radius}
              topRadius={0}
              show={showFovs}
              distanceDisplayCondition={{ near: 0, far: 50000000 }}
            />
          </Entity>
        );
      }
      return null;
    });
  }, [cesiumData, showFovs]);

  const activeKey = useContext(ContextNavContext)?.state?.activeKey;

  function CubeThumbComponentIcon(props) {
    const { children, multi, ...other } = props;
    return (
      <span {...other}>
        {children}
        <FontAwesomeIcon icon={multi ? faCubes : faCube} />
      </span>
    );
  }

  function TagThumbComponentIcon(props) {
    const { children, ...other } = props;
    return (
      <span {...other}>
        {children}
        <FontAwesomeIcon icon={faTags} />
      </span>
    );
  }

  function ArrowsThumbComponentIcon(props) {
    const { children, ...other } = props;
    return (
      <span {...other}>
        {children}
        <FontAwesomeIcon icon={faArrowsAlt} />
      </span>
    );
  }

  return (
    <Widget className={classes.playbackViewer} padding="narrow" minWidth={0}>
      <div className={classes.playbackViewerContent} ref={playbackViewerContentRef}>
        <Viewer
          className={classes.cesiumViewer}
          style={{
            // Improve this
            height: activeKey === wGroupIndicesAgentCustom.PLAYBACK ? '38.75rem' : fullHeight,
          }}
          ref={viewerRef}
          navigationHelpButton={true}
          timeline={false}
          fullscreenButton={false}
          baseLayerPicker={false}
          sceneModePicker={true}
          trackedEntity={isScenarioPlayback ? null : satelliteEntityRef.current?.cesiumElement}
          //extend={Cesium.viewerCesiumInspectorMixin}
        >
          <SkyAtmosphere show={true} />
          <SkyBox show={true} />
          <ShadowMap darkness={0} />
          <Camera ref={cameraRef} />
          <ScreenSpaceCameraController enableLook={!useEciCamera} />
          <IndexedClock
            ref={clockRef}
            startTime={cesiumData.startTime}
            stopTime={cesiumData.stopTime}
            multiplier={calculateMultiplier(cesiumClockMultiplierScaleDefault)}
            clockRange={loop ? Cesium.ClockRange.LOOP_STOP : Cesium.ClockRange.CLAMPED}
            animate={animate}
          />
          <Globe
            dynamicAtmosphereLighting={true}
            dynamicAtmosphereLightingFromSun={!useFlashlight}
            baseColor={Cesium.Color.SKYBLUE}
            enableLighting={true}
            depthTestAgainstTerrain={false}
          />
          <Sun />
          <Moon onlySunLighting={false} />
          <Scene
            // set `requestRenderMode` to `true` to trigger `onPostRender` only when the frame changes rather than 60 times / second
            // also make sure to comment back in the useEffect above that requests a rerender when cesiumLabels change
            // requestRenderMode={true}
            onMorphStart={() => {
              // only show "Follow Spacecraft" button when in 3d view
              viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
              let currentlyOn3dView =
                viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode ===
                Cesium.SceneMode.SCENE3D;
              setShowFollowSc(currentlyOn3dView);
              // Pause playback when transitioning views to make the animation more smooth
            }}
            // When switching to SceneMode.SCENE2D view, camera zooms in at the end of scene transition.
            // This likely comes from an oddity in the Cesium library.
            //    onMorphComplete() here handles a subsequent zoom out.
            onMorphComplete={function () {
              window.requestAnimationFrame(function () {
                // requestAnimationFrame() is necessary to ensure the zoom out happens AFTER the zoom in.
                if (
                  viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode ===
                  Cesium.SceneMode.SCENE2D
                ) {
                  // Toggle the zoom (CameraFlyTo element below) if Scene switched to 2D
                  setExtraZoom2D(true);
                } else {
                  // Turn off the zoom if Scene switch to 3D or Columbus
                  setExtraZoom2D(false);
                }
              });
            }}
            onPreRender={(scene) => {
              if (useFlashlight) {
                scene.light.direction = Cesium.Cartesian3.clone(
                  scene.camera.directionWC,
                  scene.light.direction
                );
              }
            }}
            // Camera view in ECI reference frame (Earth spins)
            onPostUpdate={
              useEciCamera && !viewerRef.current?.cesiumElement.trackedEntity
                ? (scene, time) => {
                    if (scene.mode !== Cesium.SceneMode.SCENE3D) {
                      return;
                    }

                    const icrfToFixed = Cesium.Transforms.computeIcrfToFixedMatrix(time);
                    if (Cesium.defined(icrfToFixed)) {
                      const camera = scene.camera;
                      const offset = Cesium.Cartesian3.clone(camera.position);
                      const transform = Cesium.Matrix4.fromRotationTranslation(icrfToFixed);
                      camera.lookAtTransform(transform, offset);
                    }
                  }
                : noop
            }
            light={useFlashlight ? flashlight : Cesium.SunLight()}
          />
          {extraZoom2D && (
            // Toggled by changing scene to 2D
            <CameraFlyTo
              destination={Cesium.Cartesian3.fromDegrees(0, 0, 40000000)}
              duration={1} // Edit for duration of zoom animation, 0 for no animation, default is ~3
            />
          )}
          {cesiumData.moonPosition && (
            <Entity position={cesiumData.moonPosition} name="Moon" ref={moonRef}>
              <LabelGraphics
                text="Moon"
                scale={cesiumLabelTextScale}
                fillColor={cesiumSatOrbitColor}
                // pixelOffset={Cesium.Cartesian2.fromElements(80, 80, new Cesium.Cartesian2())}
                show={true}
              />
            </Entity>
          )}
          {!_.isEmpty(model) && cesiumData.satellite && (
            <Entity
              ref={satelliteEntityRef}
              name={model.type !== 'TerrestrialVehicle' ? 'Satellite' : 'Terrestrial Vehicle'}
              availability={
                new Cesium.TimeIntervalCollection([
                  new Cesium.TimeInterval({
                    start: cesiumData.startTime,
                    stop: cesiumData.stopTime,
                  }),
                ])
              }
              position={cesiumData.satellite.position3D}
              orientation={cesiumData.satellite.quaternion}
              model={{
                uri: model.cadSignedUrl,
                show: showAgentModels,
                // shadows: Cesium.ShadowMode.DISABLED,
                // luminanceAtZenith: 0,
                // imageBasedLightingFactor: new Cesium.Cartesian2(0.5, 0.5),
                // minimumPixelSize: 150,
                // maximumScale: 2000000 * cadScaleFactor * cadScaleMultiplier, // cadModelScaleFactor is now included in the 2E6
                // Two different linear fits are used based on perigee altitude (y = mx + b)
                scale: scaleToRealSize ? 1 : cadScale,
              }}
              point={{
                pixelSize: 15,
                color: cesiumSatOrbitColor,
                show: !showAgentModels,
              }}
              path={
                model.type !== 'TerrestrialVehicle'
                  ? {
                      // resolution prop for path is the max seconds to step when sampling the position, so we set to infinity to not limit the sampling
                      resolution: Infinity,
                      show: showOrbitTails,
                      trailTime: a2Period(semiMajor) * cesiumOrbitTailFactor,
                      leadTime: 0,
                      material: cesiumSatOrbitColor,
                      width: cesiumSatOrbitLineWidth,
                    }
                  : undefined
              }
            >
              <LabelGraphics
                text={repo.name}
                scale={cesiumLabelTextScale}
                fillColor={cesiumSatOrbitColor}
                pixelOffset={Cesium.Cartesian2.fromElements(80, 80, new Cesium.Cartesian2())}
                show={false}
              />
            </Entity>
          )}
          {targets}
          {bodyFrameVectors}
          {sunTrackingSurfaces}
          {sensors}
        </Viewer>
        <div className={classes.menuButton}>
          <ClickAwayListener
            onClickAway={() => {
              setOpenMenu(false);
            }}
          >
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => {
                  setOpenMenu(false);
                }}
                open={openMenu}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive
                arrow
                placement="right-start"
                title={
                  <div>
                    {
                      // Slider for active agent cad model, if there is one
                      !_.isEmpty(model) && (
                        <Tooltip title="Scale Focused Agent's CAD Model">
                          <StyledSlider
                            className={classes.cadScaleSlider}
                            value={cadScaleMultiplier}
                            ThumbComponent={CubeThumbComponentIcon}
                            min={0.01}
                            max={2}
                            step={10 ** -10}
                            onChange={(e, v) => {
                              scaleCesiumVectorData(v, cadScaleMultiplier);
                              setCadScaleMultiplier(v);
                            }}
                            disabled={scaleToRealSize}
                          />
                        </Tooltip>
                      )
                    }
                    {
                      // Slider for inactive agent cad models, if there are any
                      cesiumData.targets.some((t) => Boolean(t.cadSignedUrl)) && (
                        <Tooltip title="Scale Unfocused Agents' CAD Models">
                          <StyledSlider
                            className={classes.cadScaleSlider}
                            value={inactiveAgentScaleMultiplier}
                            ThumbComponent={(props) =>
                              CubeThumbComponentIcon({ ...props, multi: true })
                            }
                            min={0.01}
                            max={2}
                            step={10 ** -10}
                            onChange={(e, v) => {
                              setInactiveAgentScaleMultiplier(v);
                            }}
                            disabled={scaleToRealSize}
                          />
                        </Tooltip>
                      )
                    }
                    {
                      // Slider for FoVs, BFVs
                      !_.isEmpty(model) && bodyFrameVectors.length + sensors.length > 0 && (
                        <Tooltip title="Scale Fields of View and Body Frame Vectors">
                          <StyledSlider
                            className={classes.cadScaleSlider}
                            value={fovScaleMultiplier}
                            ThumbComponent={ArrowsThumbComponentIcon}
                            min={0.1}
                            max={2}
                            step={10 ** -4}
                            onChange={(e, v) => {
                              scaleCesiumVectorData(v, fovScaleMultiplier);
                              setFovScaleMultiplier(v);
                            }}
                          />
                        </Tooltip>
                      )
                    }
                    <Tooltip title="Scale Labels">
                      <StyledSlider
                        className={classes.cadScaleSlider}
                        value={labelScaleMultiplier}
                        ThumbComponent={TagThumbComponentIcon}
                        min={0.5}
                        max={2.5}
                        step={2 * 10 ** -5}
                        onChange={(e, v) => {
                          setLabelScaleMultiplier(v);
                        }}
                      />
                    </Tooltip>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          setUseFlashlight((prev) => !prev);
                        }}
                        off={!useFlashlight}
                        dontDisableInReadOnly
                        fullWidth
                        tooltip="Toggle Light Source"
                      >
                        <div>
                          {useFlashlight ? (
                            <FontAwesomeIcon icon={faLightbulb} />
                          ) : (
                            <FontAwesomeIcon icon={faSun} />
                          )}
                        </div>
                      </StyledButton>
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          if (viewerRef.current?.cesiumElement.trackedEntity) {
                            viewerRef.current.cesiumElement.trackedEntity = undefined;
                          } else setUseEciCamera((prev) => !prev);
                        }}
                        off={!useEciCamera}
                        dontDisableInReadOnly
                        fullWidth
                        tooltip={!useEciCamera ? 'Use ECI Camera' : 'Use ECEF Camera'}
                      >
                        <div>
                          <FontAwesomeIcon icon={faCameraRotate} />
                        </div>
                      </StyledButton>
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          setScaleToRealSize((prev) => !prev);
                          scaleToRealSize
                            ? scaleCesiumVectorData(1, 1 / cadScale)
                            : scaleCesiumVectorData(1 / cadScale, 1);
                        }}
                        off={!scaleToRealSize}
                        dontDisableInReadOnly
                        fullWidth
                        tooltip="Scale Models to Real Size"
                      >
                        <div>
                          <FontAwesomeIcon icon={faArrowsToCircle} />
                        </div>
                      </StyledButton>
                      {!isScenarioPlayback && (
                        <StyledButton
                          className={classes.viewerButton}
                          min
                          type="button"
                          onClick={() => {
                            setShowBfVectors((prev) => !prev);
                          }}
                          off={!showBfVectors}
                          dontDisableInReadOnly
                          fullWidth
                          tooltip="Show Body Frame Vectors"
                        >
                          <div>
                            <FontAwesomeIcon icon={faArrowsAlt} />
                          </div>
                        </StyledButton>
                      )}
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          setShowTargetNames((prev) => !prev);
                        }}
                        off={!showTargetNames}
                        dontDisableInReadOnly
                        fullWidth
                        tooltip="Show Agent Labels"
                      >
                        <div>
                          <FontAwesomeIcon icon={faTags} />
                        </div>
                      </StyledButton>
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          setShowCommsLines((prev) => !prev);
                        }}
                        off={!showCommsLines}
                        dontDisableInReadOnly
                        fullWidth
                        tooltip="Show Communication Lines"
                      >
                        <div>
                          <FontAwesomeIcon icon={faTowerBroadcast} />
                        </div>
                      </StyledButton>
                      {!isScenarioPlayback && (
                        <StyledButton
                          className={classes.viewerButton}
                          min
                          type="button"
                          onClick={() => {
                            setShowFovs((prev) => !prev);
                          }}
                          off={!showFovs}
                          dontDisableInReadOnly
                          fullWidth
                          tooltip="Show Fields of View"
                        >
                          <div>
                            <FontAwesomeIcon icon={faDotCircle} />
                          </div>
                        </StyledButton>
                      )}
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          setShowOrbitTails((prev) => !prev);
                        }}
                        off={!showOrbitTails}
                        dontDisableInReadOnly
                        fullWidth
                        tooltip="Show Orbit Tails"
                      >
                        <div>
                          <FontAwesomeIcon icon={faCircleNotch} />
                        </div>
                      </StyledButton>
                      {viewerRef.current && showFollowSc && (
                        <>
                          {!isScenarioPlayback && (
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                // If the satellite is currently tracked, setting it back to undefined resets the view to not follow it. Otherwise track the satellite
                                if (
                                  viewerRef.current?.cesiumElement.trackedEntity ===
                                  satelliteEntityRef.current?.cesiumElement
                                ) {
                                  viewerRef.current.cesiumElement.trackedEntity = undefined;
                                } else {
                                  // cancelFlight will cancel the current flight if there is one, otherwise it does nothing. This protects against the user clicking the button before the flight finishes
                                  cameraRef.current.cesiumElement.cancelFlight();
                                  viewerRef.current.cesiumElement.trackedEntity =
                                    satelliteEntityRef.current.cesiumElement;
                                }
                              }}
                              off={!followSc}
                              dontDisableInReadOnly
                              fullWidth
                              tooltip="Follow Satellite"
                            >
                              <div>
                                <FontAwesomeIcon icon={faVideoCamera} />
                              </div>
                            </StyledButton>
                          )}
                        </>
                      )}
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          setShowAgentModels((prev) => !prev);
                        }}
                        off={!showAgentModels}
                        dontDisableInReadOnly
                        fullWidth
                        tooltip="Show Agent 3D Models"
                      >
                        <div>
                          <FontAwesomeIcon icon={faEyeLowVision} />
                        </div>
                      </StyledButton>
                    </Grid>
                    {/* Show/Hide terrestrial agent waypoints/paths */}
                    {hasTerrestrialAgent && (
                      <div>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item xs={4} md={5}>
                              <StyledButton
                                className={classes.viewerButton}
                                min
                                type="button"
                                onClick={() => {
                                  showWayPoints.reduce((sum, next) => sum && next, true)
                                    ? setShowWayPoints([false, false, false])
                                    : setShowWayPoints([true, true, true]);
                                }}
                                off={!showWayPoints.reduce((sum, next) => sum && next, true)}
                                dontDisableInReadOnly
                                tooltip="Show All Waypoints"
                                fullWidth
                              >
                                <div>
                                  <FontAwesomeIcon icon={faLocationDot} />
                                </div>
                              </StyledButton>
                            </Grid>
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                setShowWayPoints((prev) => [!prev[0], prev[1], prev[2]]);
                              }}
                              off={!showWayPoints[0]}
                              dontDisableInReadOnly
                              tooltip="Show Passed Waypoints"
                              fullWidth
                            >
                              <div>
                                <FontAwesomeIcon icon={faCircleCheck} />
                              </div>
                            </StyledButton>
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                setShowWayPoints((prev) => [prev[0], !prev[1], prev[2]]);
                              }}
                              off={!showWayPoints[1]}
                              dontDisableInReadOnly
                              tooltip="Show In Progress Waypoints"
                              fullWidth
                            >
                              <div>
                                <FontAwesomeIcon icon={faClock} />
                              </div>
                            </StyledButton>
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                setShowWayPoints((prev) => [prev[0], prev[1], !prev[2]]);
                              }}
                              off={!showWayPoints[2]}
                              dontDisableInReadOnly
                              tooltip="Show Remaining Waypoints"
                              fullWidth
                            >
                              <div>
                                <FontAwesomeIcon icon={faCircleXmark} />
                              </div>
                            </StyledButton>
                          </Grid>

                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item xs={4} md={5}>
                              <StyledButton
                                className={classes.viewerButton}
                                min
                                type="button"
                                onClick={() => {
                                  showWayPaths.reduce((sum, next) => sum && next, true)
                                    ? setShowWayPaths([false, false, false])
                                    : setShowWayPaths([true, true, true]);
                                }}
                                off={!showWayPaths.reduce((sum, next) => sum && next, true)}
                                dontDisableInReadOnly
                                tooltip="Show All Legs"
                                fullWidth
                              >
                                <div>
                                  <FontAwesomeIcon icon={faArrowTrendUp} />
                                </div>
                              </StyledButton>
                            </Grid>
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                setShowWayPaths((prev) => [!prev[0], prev[1], prev[2]]);
                              }}
                              off={!showWayPaths[0]}
                              dontDisableInReadOnly
                              tooltip="Show Completed Legs"
                              fullWidth
                            >
                              <div>
                                <FontAwesomeIcon icon={faCircleCheck} />
                              </div>
                            </StyledButton>
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                setShowWayPaths((prev) => [prev[0], !prev[1], prev[2]]);
                              }}
                              off={!showWayPaths[1]}
                              dontDisableInReadOnly
                              tooltip="Show In Progress Legs"
                              fullWidth
                            >
                              <div>
                                <FontAwesomeIcon icon={faClock} />
                              </div>
                            </StyledButton>
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                setShowWayPaths((prev) => [prev[0], prev[1], !prev[2]]);
                              }}
                              off={!showWayPaths[2]}
                              dontDisableInReadOnly
                              tooltip="Show Remaining Legs "
                              fullWidth
                            >
                              <div>
                                <FontAwesomeIcon icon={faCircleXmark} />
                              </div>
                            </StyledButton>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <div className={classes.selectWrapper}>
                      <LabeledSelect
                        label="Terrain"
                        name="terrain-dd"
                        options={terrainOptions}
                        value={terrain.selectedValue}
                        onChange={(option) => updateTerrain(option)}
                        dontDisableInReadOnly
                      />
                      <LabeledSelect
                        label="Imagery"
                        name="imagery-dd"
                        options={imageryOptions}
                        value={imagery.selectedValue}
                        onChange={(option) => updateImagery(option)}
                        dontDisableInReadOnly
                        disabled={terrain.selectedValue.disabledImagerySelect}
                      />
                    </div>
                  </div>
                }
              >
                <div>
                  <StyledButton
                    className={classes.viewerButton}
                    min
                    type="button"
                    onClick={() => {
                      setOpenMenu((prev) => !prev);
                    }}
                    dontDisableInReadOnly
                    fullWidth
                  >
                    <div>
                      <FontAwesomeIcon icon={faGear} />
                    </div>
                  </StyledButton>
                </div>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </Widget>
  );
};

const withReducedContext = (WrappedComponent) => {
  const Result = (props) => {
    const { setTime } = useContext(TimeContext);
    const {
      activeAgentData: {
        cesiumData,
        perigeeAlt,
        calculateMultiplier,
        orbitalElementsSeries,
        staticModel: model,
      },
    } = useAnalyticsContext();

    const contextValues = useMemo(() => {
      return {
        TimeContext: { setTime },
        DataContext: {
          cesiumData,
          perigeeAlt,
          calculateMultiplier,
          orbitalElementsSeries,
          model,
        },
      };
    }, [setTime, cesiumData, perigeeAlt, calculateMultiplier, orbitalElementsSeries, model]);

    return <WrappedComponent {...props} contextValues={contextValues} />;
  };
  Result.displayName = 'WithReducedContext';
  return Result;
};

export default withReducedContext(memo(PlaybackViewer));
