import { wGroupIndicesWorkspace } from 'components/RootView/menu';
import LoadingInlay from 'components/general/LoadingInlay';
import RowedExplorer from 'components/general/RowedExplorer';
import Row from 'components/general/RowedExplorer/Row';
import ViewPortInlay from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import { IRole } from 'components/general/types';
import { useEntityDialogControl, useSelectByIds } from 'hooks';
import useWorkspace from 'hooks/useWorkspace';
import { WorkspaceContext } from 'providers';
import { useContext } from 'react';
import RoleDialog from './RoleDialog';

const RolesBoard = () => {
  const workspace = useWorkspace();
  const roles = useSelectByIds('Role', workspace.roles);
  const { loading } = useContext(WorkspaceContext);

  const roleDialogControl = useEntityDialogControl<IRole>();
  const {
    openDialogForExisting,
    dialogConfig: { open },
  } = roleDialogControl;

  return (
    <WGroup index={wGroupIndicesWorkspace.ROLES} masonryConfig={{}}>
      <RowedExplorer rowsTitle="Roles">
        {loading ? (
          <LoadingInlay text="Your workspace is loading" />
        ) : roles.length ? (
          roles
            .sort((r1, r2) => r2.permissions - r1.permissions)
            .map((role) => (
              <Row
                key={role.id}
                name={`${role.name}`}
                description={`ID: ${role.id}`}
                onClick={() => openDialogForExisting(role, 'edit')}
              />
            ))
        ) : (
          <ViewPortInlay text="No Roles." />
        )}
        <ViewPortInlay text={'Custom roles coming soon...'} />
      </RowedExplorer>
      {open && <RoleDialog control={roleDialogControl} />}
    </WGroup>
  );
};

export default RolesBoard;
