import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledDateTimePicker from 'components/general/inputs/LabeledPickers/LabeledDateTimePicker';
import { IApiKey } from 'components/general/types';
import { useFormikForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { translateOut as defaultTranslateOut } from 'utils/forms';
import * as Yup from 'yup';
// The parts of IApiKey that a user defines themselves,
// plus a boolean on the form to toggle whether the date picker
// for 'expires' should appear, or if 'expires' should just be "never"
export interface IApiKeyForm {
  name: string;
  expires: string | Moment;
  doesExpire: boolean;
}

interface IProps {
  dialogControl: TEntityDialogControl<IApiKey>;
  loading: boolean;
  // onCreate and onRevoke perform the actual dispatch actions to the API
  onCreate: (values: IApiKeyForm) => void;
  onRevoke: () => void;
  // createdKey is the brand new key that fleetingly appears on the front-end,
  // to display after creation action is initiated
  createdKey: string;
  setCreatedKey: Dispatch<SetStateAction<string>>;
}

const keySchema = Yup.object().shape({
  name: Yup.string().required('An API key name is required.'),
  expires: Yup.mixed()
    .when('doesExpire', {
      is: true,
      then: Yup.mixed().test(
        'test-moment',
        'Select or enter a valid date and time.',
        async (val: string | Moment) => {
          if (!val) return false;

          const dateRegex =
            /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{0,4}Z|\d{4}-\d{2}-\d{2} {2}\d{2}:\d{2}:\d{2})$/;
          if (typeof val === 'string') return dateRegex.test(val);
          else return dateRegex.test(val.toISOString());
        }
      ),
    })
    .required('Select an expiration date and time.'),
});

const ApiKeyDialog = ({
  dialogControl,
  loading,
  onCreate,
  onRevoke,
  createdKey,
  setCreatedKey,
}: IProps) => {
  const {
    dialogConfig: { open, action, entity: apiKey },
    closeDialog,
  } = dialogControl;

  const [clipboard, setClipboard] = useState(faClipboard);

  // Set new min expiration date value every time dialog is opened
  const defaultValues: IApiKeyForm = useMemo(
    () => ({
      name: '',
      doesExpire: false,
      expires: moment().toISOString(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  const translateOut = useCallback((apiKey) => {
    // Set 'expires' field to 'never' if necessary
    // Assumes values.expires can be a moment object or a string
    if (apiKey.doesExpire)
      apiKey.expires =
        typeof apiKey.expires === 'string' ? apiKey.expires : apiKey.expires.toJSON();
    else apiKey.expires = 'never';

    return defaultTranslateOut(apiKey, [], {});
  }, []);

  const { formik } = useFormikForm(
    defaultValues,
    action === 'create' ? onCreate : onRevoke,
    // "Revoke" is a patch, not a delete – so disable the validation schema when revoking
    action === 'create' ? keySchema : Yup.object(),
    defaultValues,
    {
      translateOut,
    }
  );
  const { handleSubmit, getFieldProps, values, resetForm } = formik;

  return (
    <>
      <Dialog
        prompt={`${action === 'create' ? 'Create an' : 'Confirm: Revoke'} API Key`}
        open={open}
        onSubmit={handleSubmit}
        submitActionText={action === 'create' ? 'Save' : 'Revoke'}
        loading={loading}
        onClose={() => {
          closeDialog();
          resetForm();
        }}
        small={action === 'create'}
      >
        {action === 'create' ? (
          <>
            <LabeledInput
              label="Name"
              placeholder="Key Name"
              {...getFieldProps('name')}
              autoFocus
            ></LabeledInput>
            <LabeledCheckbox label="Expires?" {...getFieldProps('doesExpire')} />
            {values.doesExpire && (
              <LabeledDateTimePicker
                {...getFieldProps('expires')}
                label="Expiration Date & Time (UTC)"
                minDate={defaultValues.expires}
              />
            )}
          </>
        ) : (
          <p>Are you sure you'd like to revoke API key: '{apiKey?.name}'? This cannot be undone.</p>
        )}
      </Dialog>
      {createdKey && (
        <Dialog
          prompt="Your new API key"
          open={Boolean(createdKey)}
          onClose={() => {
            setCreatedKey('');
            resetForm();
            setClipboard(faClipboard);
            dialogControl.closeDialog();
          }}
          noButtons
        >
          <p>
            You can use this key to access Sedaro's API programmatically. It can be revoked at any
            time.
          </p>
          <div style={{ display: 'flex', textAlign: 'center', wordBreak: 'break-all' }}>
            {/* Key */}
            <h2>{createdKey}</h2>
            {/* Copy to clipboard button */}
            <Tooltip arrow title="Copy to clipboard">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(createdKey);
                  setClipboard(faClipboardCheck);
                }}
                color={clipboard === faClipboard ? 'default' : 'primary'}
              >
                <FontAwesomeIcon icon={clipboard} />
              </IconButton>
            </Tooltip>
          </div>
          <p>
            <strong>Note:</strong> After you close this dialog, your key will be irrecoverable.
            Please copy it safely and securely.
            <br />
            <strong>Caution:</strong> Do not share this key. It identifies you as the user and
            should be kept as private as your account password.
          </p>
        </Dialog>
      )}
    </>
  );
};

export default ApiKeyDialog;
