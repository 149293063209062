import { useMemo } from 'react';
import theme from 'theme';
//@ts-ignore
import { ILink, INode } from '@nivo/sankey';
import { IComponent, ISolarArray, ISolarPanel, ISubsystem } from 'components/general/types/power';
import { TCompiledModel } from 'middleware/SatelliteApi/template';
import { precision } from 'utils/units';

export interface ISankeyData {
  sankeySeriesData: {
    nodes: INode;
    links: ILink;
  };
  powerGenerated: number;
}

const powerProcessorStr = 'PP';
const batteryStr = 'Battery';
const linkColor = 'black';

export const createBatteryLink = (power: number) => {
  if (power < 0) {
    return {
      source: batteryStr,
      startColor: linkColor,
      endColor: linkColor,
      target: powerProcessorStr,
      value: precision(power * -1),
    };
  } else {
    return {
      source: powerProcessorStr,
      startColor: linkColor,
      endColor: linkColor,
      target: batteryStr,
      value: precision(power),
    };
  }
};

enum EntityTypes {
  SOLAR_PANEL = 'panel',
  SOLAR_ARRAY = 'array',
  PROCESSOR = 'processor',
  SUBSYSTEM = 'subsystem',
  COMPONENT = 'component',
  BATTERY = 'battery',
}

export const colorMap = {
  panel: 'rgb(244, 196, 48)',
  array: 'rgb(228,208, 100)',
  processor: theme.palette.primary.dark,
  subsystem: 'rgb(84, 143, 89)',
  component: 'rgb(107, 142, 78)',
  battery: 'rgb(125, 92, 217)',
};

export const createNodesAndLinks = (model: TCompiledModel): ISankeyData => {
  // Keep track of total power generated to use on Power Input => Power Output link
  let powerGenerated = 0;

  // nodes represent all the entities
  // links represent the power used between them
  const nodes: INode[] = [];
  const links: ILink[] = [];

  // Loop through solar arrays to access each solar array and access their related solar panels
  model.SolarArray.all().forEach((solarArray: ISolarArray) => {
    solarArray.panels.forEach((solarPanel: ISolarPanel) => {
      // Key into the solar panel object for each solar panel ID in the panels field of each solar array
      const solarPanelPower = solarPanel.power;
      // If powerGenerated is 0 then skip and don't add links or nodes
      if (solarPanelPower && solarPanelPower > 1e-6) {
        // type panel is used for the color map
        nodes.push({
          id: EntityTypes.SOLAR_PANEL + '_' + solarPanel.id,
          label: solarPanel.name,
          type: EntityTypes.SOLAR_PANEL,
        });
        links.push({
          source: EntityTypes.SOLAR_PANEL + '_' + solarPanel.id,
          target: EntityTypes.SOLAR_ARRAY + '_' + solarArray.id,
          startColor: linkColor,
          endColor: linkColor,
          value: precision(solarPanelPower),
        });
      }
    });
    // after having looped through all panels create the link from the array to the power input
    // Skip creating the array if the total power is 0
    const solarArrayPower = solarArray.power;
    if (solarArrayPower && solarArrayPower > 1e-6) {
      nodes.push({
        id: EntityTypes.SOLAR_ARRAY + '_' + solarArray.id,
        label: solarArray.name,
        type: EntityTypes.SOLAR_ARRAY,
      });
      // ('SOLAR_ARRAY_{id}');
      links.push({
        source: EntityTypes.SOLAR_ARRAY + '_' + solarArray.id,
        startColor: linkColor,
        endColor: linkColor,
        target: powerProcessorStr,
        value: precision(solarArrayPower),
      });
      powerGenerated += solarArrayPower;
    }
  });

  // Account for battery power in total power calculation
  // Add battery node and link
  const battery = model.powerProcessor?.battery;
  const batteryPower = battery?.power;
  if (batteryPower && batteryPower !== 0) {
    // If the battery power is negative, the battery is discharging power and should be added to power generated
    if (batteryPower < 0) {
      powerGenerated += -batteryPower;
    }
    nodes.push({
      id: batteryStr,
      label: batteryStr,
      type: EntityTypes.BATTERY,
    });
    links.push(createBatteryLink(batteryPower));
  }

  if (powerGenerated !== 0) {
    // Create Power Processor Node
    nodes.push({
      id: powerProcessorStr,
      label: powerProcessorStr,
      type: EntityTypes.PROCESSOR,
      tooltipLabel: 'Power Processor',
    });
  }

  // Opposite direction
  // Each component has loads that make up their power consumption
  // So each subsystem adds the power of all components, which add the power of all loads for that component

  // Loop through subsystems to get their related components
  model.Subsystem.all().forEach((subsystem: ISubsystem) => {
    // Check if the subsystem has been simulated and has series data
    subsystem.components.forEach((component: IComponent) => {
      const componentPower = component.powerConsumed ?? 0;
      // If the component powerConsumed is greater than 0, create it's node and link
      if (componentPower > 0) {
        nodes.push({
          id: EntityTypes.COMPONENT + '_' + component.id,
          label: component.name,
          type: EntityTypes.COMPONENT,
        });
        links.push({
          source: EntityTypes.SUBSYSTEM + '_' + subsystem.id,
          target: EntityTypes.COMPONENT + '_' + component.id,
          startColor: linkColor,
          endColor: linkColor,
          value: precision(componentPower),
        });
      }
    });
    // If the subsytem power is greater than 0 create it's node and link
    const subPowerConsumed = subsystem.powerConsumed;

    if (subPowerConsumed && subPowerConsumed > 0) {
      nodes.push({
        id: EntityTypes.SUBSYSTEM + '_' + subsystem.id,
        label: subsystem.name,
        type: EntityTypes.SUBSYSTEM,
      });
      links.push({
        source: powerProcessorStr,
        target: EntityTypes.SUBSYSTEM + '_' + subsystem.id,
        startColor: linkColor,
        endColor: linkColor,
        value: precision(subPowerConsumed),
      });
    }
  });

  return { sankeySeriesData: { nodes, links }, powerGenerated };
};

export const useSankeySeriesData = (model: TCompiledModel) => {
  return useMemo(() => createNodesAndLinks(model), [model]);
};
