import { IconButton, Tooltip } from '@material-ui/core';
import { Add, DeleteForever, Save } from '@material-ui/icons';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import {
  IDispatchFailureCallback,
  ILayout,
  ILayoutSelectOption,
  INode,
} from 'components/general/types/layout';
import { IComponent } from 'components/general/types/power';
import { ISurface } from 'components/general/types/spacecraft';
import { IThermalInterface } from 'components/general/types/thermal';
import { IThermalUIEdge, IThermalUINode } from 'components/general/types/thermalUI';
import Widget from 'components/general/widgets/Widget';
import { useActiveEntities, useEntityDialogControl, useSnackbar } from 'hooks';
import _ from 'lodash';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { TCompiledModel, useModel } from 'middleware/SatelliteApi/template';
import { MomentContext, PlaybackStatusContext } from 'providers';
import { DataContext } from 'providers/DataProvider';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSearchParams } from 'routes';
import { isCooler } from '../../thermaldata';
import LayoutDialog from './LayoutDialog';
import ThermalMap from './ThermalMap';
import { useStylesInterface, useStylesToolTipTitle } from './styles';

const defaultNodeStyles = {
  backgroundColor: 'white',
  width: 80,
  height: 50,
  lineHeight: '10px',
  textAlign: 'center',
};

/**
 * Create nodes in format needed for ELK/ReactFlow
 * Adds sub-nodes for Coolers
 * @param e //entities
 * @returns
 */
const toNodes = (
  e: (IComponent & { nodeType?: string }) | (ISurface & { nodeType?: string })
): IThermalUINode[] => {
  const nodes: IThermalUINode[] = [
    {
      id: e.id.toString(),
      data: { label: e.name, nodeType: e.nodeType },
      type: 'temp',
      style: {
        ...defaultNodeStyles,
      },
    },
  ];
  if (isCooler(e)) {
    nodes[0].id = nodes[0].id + '::group';
    nodes[0].type = 'temp-group';
    nodes[0].style.width = defaultNodeStyles.width * 2 + 30;
    nodes[0].style.paddingTop = 40;
    nodes[0].data = { label: 'Group', hasNestedComponents: true, nodeType: 'Cooler' };
    const defaultCoolerStyles = {
      parentNode: nodes[0].id,
      extent: 'parent',
      draggable: false,
      style: {
        ...defaultNodeStyles,
        height: 30,
      },
    };
    nodes[0].style.height = defaultCoolerStyles.style.height + 20 + nodes[0].style.paddingTop;
    nodes.push({
      id: e.id.toString() + '::nestedComponentA',
      data: {
        label: 'Regulated',
        isNestedComponent: true,
        isPrimaryNestedComponent: true,
        nodeType: 'Child',
      },
      position: { x: 20 + defaultNodeStyles.width, y: 10 },
      ignoreLayout: true,
      type: 'child',
      ...defaultCoolerStyles,
    });
    nodes.push({
      id: e.id.toString() + '::nestedComponentB',
      data: { label: 'Sink', isNestedComponent: true, nodeType: 'Child' },
      position: { x: 10, y: 10 },
      ignoreLayout: true,
      type: 'child',
      ...defaultCoolerStyles,
    });
  }
  return nodes;
};

/**
 * Create edge entities in format needed for ELK/ReactFlow
 * Tests for Cooler (e.type === 'Cooler'). Then, looks at interface definition.
 * If the interface.CoolerA/B is populated, the edge will connect to nestedComponentA ("Regulated")
 * If the interface.ComponentA/B is populated, the edge will connect to nestedComponentB ("Sink")
 * @param i //interface
 * @param nodeLookup
 * @returns
 */
const toEdges = (
  i: IThermalInterface,
  nodeLookup: { [id: string]: IComponent | ISurface }
): IThermalUIEdge[] => {
  const sideA =
    i.componentA?.id.toString() || i.surfaceA?.id.toString() || i.coolerA?.id.toString();
  const sideB =
    i.componentB?.id.toString() || i.surfaceB?.id.toString() || i.coolerB?.id.toString();
  const sideACooler = sideA && isCooler(nodeLookup[sideA]);
  const sideBCooler = sideB && isCooler(nodeLookup[sideB]);

  let source;
  if (i.coolerA) {
    source = i.coolerA.id + '::nestedComponentA';
  } else if (i.componentA && sideACooler) {
    source = i.componentA.id + '::nestedComponentB';
  } else {
    source = i.componentA?.id.toString() || i.surfaceA?.id.toString();
  }

  let target;
  if (i.coolerB) {
    target = i.coolerB.id + '::nestedComponentA';
  } else if (i.componentB && isCooler(nodeLookup[i.componentB.id])) {
    target = i.componentB.id + '::nestedComponentB';
  } else {
    target = i.componentB?.id.toString() || i.surfaceB?.id.toString();
  }

  const edges: IThermalUIEdge[] = [
    {
      id: i.id.toString(),
      source: source,
      target: target,
      label: i.name,
      animated: true,
      type: 'floating',
      zIndex: 10000,
      hidden: false,
      data: i,
    },
  ];
  if (sideACooler || sideBCooler) {
    edges.push({
      id: i.id.toString(),
      source: sideACooler ? sideA + '::group' : sideA,
      target: sideBCooler ? sideB + '::group' : sideB,
      hidden: true,
    });
  }
  return edges;
};

const ToolTipTitle = ({ label }: { label: string }) => {
  const classes = useStylesToolTipTitle();
  return (
    <div className={classes.root} style={{ textAlign: 'left' }}>
      <p>{label}</p>
    </div>
  );
};

/**
 * Intakes the list of available layouts and converts them from arrays to named objects
 * @param _layouts
 * @returns
 */
const convertLayoutNodesToObjects = (
  _layouts: ILayout[]
): { layouts: ILayout[]; layoutSelectOptions: ILayoutSelectOption[] } => {
  const layouts = _.cloneDeep(_layouts);
  const layoutList: { value: string; label: string; data: ILayout }[] = [];

  layouts.forEach((l: ILayout) => {
    const los = {} as { [key: string]: IThermalUINode };
    if (l.nodes && Array.isArray(l.nodes)) {
      l.nodes.forEach((n: INode) => {
        if (n.id && typeof n.id === 'string') {
          const bl = n.id as string;
          los[bl] = n.manySideData;
        }
      });
    }
    l.nodes = los;
    layoutList.push({ value: l.id!, label: l.name, data: l });
  });
  return { layouts: layouts, layoutSelectOptions: layoutList };
};

const getLastUsedLayout = (layouts: ILayout[]): ILayout | null => {
  if (layouts?.length > 0) {
    // Iterate and find the most recently used layout among all layouts
    const lastUsed = layouts.reduce((mostRecentlyUsedSoFar: ILayout, item: ILayout, i: number) => {
      if (item.lastUsed && mostRecentlyUsedSoFar.lastUsed) {
        return item.lastUsed >= mostRecentlyUsedSoFar.lastUsed ? item : mostRecentlyUsedSoFar;
      } else if (item.lastUsed) {
        return item;
      } else if (mostRecentlyUsedSoFar.lastUsed) {
        return mostRecentlyUsedSoFar;
      } else return layouts[0];
    }, layouts[0]);

    return lastUsed;
  }
  return null;
};

const max = 400;

interface IProps {
  model?: TCompiledModel;
  noWidget?: boolean;
  editable?: boolean;
}

const ThermalStateWidget = (props: IProps) => {
  let _layouts: ILayout[] = [];
  let { model } = useContext(MomentContext);
  const { agentId, start } = getSearchParams();
  let { branch } = useActiveEntities();
  const { staticModels } = useContext(DataContext) || {};
  const s = staticModels?.agents[agentId] || {};
  const agentModel = useModel(s);

  const { playbackStatus } = useContext(PlaybackStatusContext) || false;

  // Agent Template View
  if (props.model) {
    model = props.model;
    _layouts = model.ThermalDesignLayout.all();

    // Scenario View
  } else if (agentId && start) {
    _layouts = agentModel.ThermalDesignLayout.all();
    //@ts-ignore blocks exists on branch data
    branch = branch.data.blocks[agentId].templateRef;
  }

  const classes = useStylesInterface();

  const components = model.Component.all() as IComponent[];
  const interfaces = model.ThermalInterface.all() as unknown as IThermalInterface[];
  const surfaces = model.Surface.all() as ISurface[];
  const { layouts, layoutSelectOptions } = convertLayoutNodesToObjects(_layouts);

  const generateDefaultLayout = useCallback(() => {
    const now = new Date().getTime();
    const defaultLayout: ILayout = {
      name: 'First Layout',
      dateCreated: now,
      lastEdited: now,
      lastUsed: now,
      nodes: {},
    };
    layoutSelectOptions.push({ value: '1st', label: defaultLayout.name, data: defaultLayout });
    return defaultLayout;
  }, [layoutSelectOptions]);

  const layoutRef = useRef(getLastUsedLayout(layouts) || generateDefaultLayout());
  const [selectedLayout, setSelectedLayout] = useState({
    value: layoutRef.current.id || '1st',
    label: layoutRef.current.name,
    data: layoutRef.current,
  });
  const [layoutEdited, setLayoutEdited] = useState(false);

  const {
    DesignLayout: {
      actions: { createDesignLayout, updateDesignLayout, deleteDesignLayout },
    },
  } = SatelliteApi;

  const dispatch = useDispatch();
  const dialogControl = useEntityDialogControl<ILayout>();
  const { openDialogForNew, openDialogForExisting, closeDialog: onClose } = dialogControl;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Extract initial or updated temperatures from incoming data
  const t: { [id: string]: number } = useMemo(() => {
    const temps: { [id: string]: number } = {};
    components.forEach((comp) => {
      if (comp.temperature) {
        const temp =
          typeof comp.temperature === 'number' ? comp.temperature : comp.temperature.degC;
        temps[comp.id] = temp;
      }
    });
    surfaces.forEach((surf) => {
      if (surf.temperature) {
        const temp =
          typeof surf.temperature === 'number' ? surf.temperature : surf.temperature.degC;
        temps[surf.id] = temp;
      }
    });
    return temps;
  }, [components, surfaces]);

  // Convert incoming data model to format needed by ELK/ReactFlow
  const [n, nodeLookup] = useMemo(() => {
    const nodeLookup: {
      [id: string]: (IComponent & { nodeType?: string }) | (ISurface & { nodeType?: string });
    } = {};

    const augmentedNodes = [...components, ...surfaces]
      .map((item: (IComponent & { nodeType?: string }) | (ISurface & { nodeType?: string })) => {
        let nodeType = 'Component';
        if ('surfaceMaterial' in item) {
          nodeType = 'Surface';
        }
        item.nodeType = nodeType;
        nodeLookup[item.id] = item;
        return toNodes(item);
      })
      .flat();

    return [augmentedNodes, nodeLookup];
  }, [components, surfaces]);

  const e = useMemo(() => {
    return interfaces.flatMap((i) => toEdges(i, nodeLookup));
  }, [interfaces, nodeLookup]);

  const onLayoutAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    openDialogForNew();
  };

  const addLayout = useCallback(
    (newLayoutName) => {
      const now = new Date().getTime();
      const newLayout = {
        branchId: branch.id || branch,
        type: 'ThermalDesignLayout',
        ...layoutRef.current,
        name: newLayoutName,
        dateCreated: now,
        lastEdited: now,
        lastUsed: now,
      };
      delete newLayout.id;

      setLoading(true);
      dispatch(
        createDesignLayout({
          ...newLayout,
          branchId: branch.id || branch,
          type: 'ThermalDesignLayout',
          successCallback: (response: ILayout) => {
            onClose();
            enqueueSnackbar('Layout created successfully', {
              variant: 'success',
            });
            layoutRef.current = response;
            setSelectedLayout({ value: response.id!, label: response.name, data: response });
            setLayoutEdited(false);
            setLoading(false);
          },
          failureCallback: (response: IDispatchFailureCallback) => {
            enqueueSnackbar(response.error.message);
            setLoading(false);
          },
        })
      );
    },
    [enqueueSnackbar, createDesignLayout, dispatch, branch, onClose]
  );

  const onLayoutSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (layoutRef.current) {
      const l = layoutRef.current;
      if (l.name === 'First Layout' && l.dateCreated === l.lastEdited && l.id === undefined) {
        addLayout(l.name);
      } else {
        updateLayout(true, { value: l.id!, label: l.name, data: l });
      }
    }
  };

  const updateLayout = useCallback(
    (visibleToUser: boolean, layout: ILayoutSelectOption) => {
      const now = new Date().getTime();
      setLoading(true);
      dispatch(
        updateDesignLayout({
          ...layout.data,
          branchId: branch.id || branch,
          id: layout.value || selectedLayout.value,
          type: 'ThermalDesignLayout',
          lastUsed: now,
          lastEdited: visibleToUser ? now : layout.data.lastEdited,
          successCallback: (response: ILayout) => {
            if (visibleToUser) {
              enqueueSnackbar('Layout saved.', {
                variant: 'success',
              });
            }
            setLayoutEdited(false);
            setLoading(false);
          },
          failureCallback: (response: IDispatchFailureCallback) => {
            let errorMessage = response.error.message;
            if (response.error.message.includes('greater than or equal to')) {
              errorMessage = 'Error message.';
            }
            if (visibleToUser) {
              enqueueSnackbar(errorMessage);
            }
            setLoading(false);
          },
        })
      );
    },
    [enqueueSnackbar, dispatch, updateDesignLayout, branch, selectedLayout]
  );

  const onLayoutDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    openDialogForExisting(layoutRef.current, 'delete');
  };

  const deleteLayout = useCallback(() => {
    setLoading(true);
    dispatch(
      deleteDesignLayout({
        branchId: branch.id || branch,
        id: selectedLayout.value,
        successCallback: () => {
          onClose();
          enqueueSnackbar('Layout deleted.', {
            variant: 'success',
          });
          const otherLayouts = layouts.filter((l) => {
            return l.id !== selectedLayout.value;
          });
          const lastUsed = getLastUsedLayout(otherLayouts);
          if (lastUsed) {
            layoutRef.current = lastUsed;
            const l = { value: lastUsed.id!, label: lastUsed.name, data: lastUsed };
            setSelectedLayout(l);
            setLayoutEdited(false);
            updateLayout(false, l);
          } else {
            const defaultLayout = generateDefaultLayout();
            layoutRef.current = defaultLayout;
            setSelectedLayout({
              value: defaultLayout.id || '1st',
              label: defaultLayout.name,
              data: defaultLayout,
            });
          }
          setLoading(false);
        },
        failureCallback: (response: IDispatchFailureCallback) => {
          let errorMessage = response.error.message;
          if (response.error.message.includes('greater than or equal to')) {
            errorMessage = 'Error message.';
          }
          enqueueSnackbar(errorMessage);
          setLoading(false);
        },
      })
    );
  }, [
    onClose,
    enqueueSnackbar,
    dispatch,
    generateDefaultLayout,
    updateLayout,
    layouts,
    deleteDesignLayout,
    branch,
    selectedLayout,
  ]);

  const onLayoutSelect = useCallback(
    (selection: ILayoutSelectOption) => {
      selection.data.lastUsed = new Date().getTime();
      layoutRef.current = selection.data;
      setSelectedLayout(selection);
      updateLayout(false, selection);
    },
    [setSelectedLayout, updateLayout]
  );

  return (
    <>
      {props.noWidget ? (
        <>
          <div className={classes.layoutControlsWrapper}>
            <div className={classes.layoutSelectWrapper}>
              <LabeledSelect
                label="Layout"
                className={classes.layoutSelect}
                options={layoutSelectOptions}
                onChange={onLayoutSelect}
                value={selectedLayout}
              />
            </div>
            <div className={classes.buttonsWrapper}>
              <Tooltip title={<ToolTipTitle label={'Add New Layout'} />} placement="top" arrow>
                <span>
                  <IconButton className={classes.button} onClick={onLayoutAdd}>
                    <Add />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={<ToolTipTitle label={'Save Layout'} />} placement="top" arrow>
                <span>
                  {/* necessary to prevent disabling from disrupting ToolTip event listening */}
                  <IconButton
                    className={layoutEdited ? classes.buttonDirty : classes.button}
                    onClick={onLayoutSave}
                    disabled={!layoutEdited}
                  >
                    <Save />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={<ToolTipTitle label={'Delete Layout'} />} placement="top" arrow>
                <span>
                  {/* necessary to prevent disabling from disrupting ToolTip event listening */}
                  <IconButton
                    className={classes.button}
                    onClick={onLayoutDelete}
                    disabled={layoutSelectOptions.length <= 1}
                  >
                    <DeleteForever />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
          <ThermalMap
            //@ts-ignore - for a memoized, passing new nodes every time will always trigger a re-render and thus these kinds of children objects don't match the memoized type. However, we are handling the re-render manually, so we can ignore this type error.
            nodes={n}
            edges={e}
            temps={t}
            max={max}
            layout={layoutRef}
            selectedLayout={selectedLayout}
            setLayoutEdited={setLayoutEdited}
            editable={props.editable}
          />
          {dialogControl && dialogControl.dialogConfig.open && (
            <LayoutDialog
              control={dialogControl}
              loading={loading}
              deleteLayout={deleteLayout}
              addLayout={addLayout}
            />
          )}
        </>
      ) : (
        <Widget
          title="Thermal State"
          subtitle="State of Components, Temp Controllers, and Interfaces"
          collapsibleConfig
        >
          <div className={classes.layoutControlsWrapper}>
            <div className={classes.layoutSelectWrapper}>
              <LabeledSelect
                label="Layout"
                className={classes.layoutSelect}
                options={layoutSelectOptions}
                onChange={onLayoutSelect}
                value={selectedLayout}
                isDisabled={playbackStatus}
              />
            </div>
          </div>
          <ThermalMap
            //@ts-ignore - for a memoized, passing new nodes every time will always trigger a re-render and thus these kinds of children objects don't match the memoized type. However, we are handling the re-render manually, so we can ignore this type error.
            nodes={n}
            edges={e}
            temps={t}
            max={max}
            layout={layoutRef}
            selectedLayout={selectedLayout}
            setLayoutEdited={setLayoutEdited}
            editable={props.editable}
          />
          {dialogControl && dialogControl.dialogConfig.open && (
            <LayoutDialog
              control={dialogControl}
              loading={loading}
              deleteLayout={deleteLayout}
              addLayout={addLayout}
            />
          )}
        </Widget>
      )}
    </>
  );
};

export default ThermalStateWidget;
