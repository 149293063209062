import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { wGroupIndicesAgentCustom } from 'components/AgentAnalyzeView/menu/custom';
import ViewPortInlay from 'components/general/ViewPortInlay';
import { TBlockId } from 'components/general/types';
import {
  IAlgorithm,
  IMagnetorquer,
  IReactionWheel,
  ISensor,
  IThruster,
} from 'components/general/types/gnc';
import Widget from 'components/general/widgets/Widget';
import { ContextNavContext, MomentContext } from 'providers';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  ControlButton,
  Controls,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import { vectorNorm } from 'utils/math';
import { AlgorithmVables, ComponentVables, TAlgorithmTypes, isAc, isOd, isTc } from 'utils/vable';
import {
  ACTUATOR_MAGNETORQUER,
  ACTUATOR_REACTION_WHEEL,
  ACTUATOR_THRUSTER,
  IActuatorNode,
  IAlgoNode,
  ISensorNode,
  MAGNETIC_MOMENT,
  MOMENTUM,
  THRUST,
  TORQUE,
} from './general/types';
import usePrepNodesAndEdges, {
  elkNodePlacementStrategies,
  nodeTypes,
} from './usePrepNodesAndEdges';

const NUM_STRATEGIES = elkNodePlacementStrategies.length;

const GNCStateWidget = () => {
  const [placementStrategyIdx, setPlacementStrategyIdx] = useState(0);
  const togglePlacementStrategyIdx = () => {
    setPlacementStrategyIdx((prev) => (prev === NUM_STRATEGIES - 1 ? 0 : prev + 1));
  };

  const { model } = useContext(MomentContext);

  const actuatorNodes: IActuatorNode[] = useMemo(
    () =>
      (model.ReactionWheel.all() as IReactionWheel[])
        .map(
          (rw) =>
            ({
              id: rw.id,
              targets: [],
              sending: true,
              data: {
                title: rw.name,
                subtitle: ComponentVables.Type[rw.type]?.label,
                type: ACTUATOR_REACTION_WHEEL,
                percentages: {
                  [MOMENTUM]: Math.abs(rw.momentum / rw.ratedMomentum),
                  [TORQUE]: Math.abs(vectorNorm(rw.torque.eci || rw.torque) / rw.ratedTorque),
                },
              },
            } as IActuatorNode)
        )
        .concat(
          (model.Magnetorquer.all() as IMagnetorquer[]).map(
            (mt) =>
              ({
                id: mt.id,
                targets: [],
                sending: true,
                data: {
                  title: mt.name,
                  subtitle: ComponentVables.Type[mt.type]?.label,
                  type: ACTUATOR_MAGNETORQUER,
                  percentages: {
                    [MAGNETIC_MOMENT]: Math.abs(
                      (mt.magneticMoment ? mt.magneticMoment : 0) / mt.ratedMagneticMoment
                    ),
                  },
                },
              } as IActuatorNode)
          )
        )
        .concat(
          (model.Thruster.all() as IThruster[]).map(
            (thruster) =>
              ({
                id: thruster.id,
                targets: [],
                sending: true,
                data: {
                  title: thruster.name,
                  subtitle: ComponentVables.Type[thruster.type]?.label,
                  type: ACTUATOR_THRUSTER,
                  percentages: {
                    [THRUST]: (thruster.thrust ? thruster.thrust : 0) / thruster.maxThrust,
                  },
                },
              } as IActuatorNode)
          )
        ),
    [model]
  );

  const controlAlgoNodes: IAlgoNode[] = useMemo(
    () =>
      (model.Algorithm.all() as IAlgorithm[]).flatMap((algo) => {
        switch (true) {
          case isAc(algo): {
            const sending = (targetId: TBlockId) => {
              const type = actuatorNodes.find((e) => e.id === targetId)?.data.type;
              if (type === ACTUATOR_REACTION_WHEEL) return algo.reactionWheelCommands != null;
              else if (type === ACTUATOR_MAGNETORQUER) return algo.magnetorquerCommands != null;
              else if (type === ACTUATOR_THRUSTER) return algo.thrusters != null;
              else throw new Error('Unknown actuator type');
            };
            return {
              id: algo.id,
              targets: actuatorNodes
                .map((e: IActuatorNode) => e.id)
                .filter((e) => algo.actuators?.map((a) => a.id).includes(e)),
              sending,
              data: {
                title: algo.name,
                subtitle: 'Attitude Control Algorithm',
              },
            };
          }
          case isTc(algo): {
            return {
              id: algo.id,
              targets: actuatorNodes
                .map((e: IActuatorNode) => e.id)
                .filter((e) => algo.thrusters?.map((t) => t.id).includes(e)),
              sending: model.activeOpMode?.pointingMode?.tcAlgorithm?.id === algo.id,
              data: {
                title: algo.name,
                subtitle: 'Thrust Control Algorithm',
              },
            };
          }
          default:
            return []; // OD | AD Algo
        }
      }),
    [model, actuatorNodes]
  );

  const [adOdAlgoNodes, sensorNodes] = useMemo(() => {
    let odAlgo: TBlockId | null = null;
    let adAlgo: TBlockId | null = null;

    const asOdAlgos: IAlgoNode[] = [];
    for (const algo of model.Algorithm.all() as IAlgorithm[]) {
      if (isAc(algo) || isTc(algo)) continue; // Control Algo
      if (isOd(algo)) {
        odAlgo = algo.id;
      } else {
        adAlgo = algo.id;
      }
      const activeControlMode = model.activeOpMode?.pointingMode?.acAlgorithm?.id;
      const node = {
        id: algo.id,
        targets: [activeControlMode || 'none'],
        sending: (isOd(algo) ? algo.positionSolution : algo.attitudeSolution) != null,
        data: {
          title: algo.name,
          subtitle: AlgorithmVables.Type[algo.type as TAlgorithmTypes].label + ' Algorithm',
        },
      };
      if (isOd(algo)) {
        asOdAlgos.unshift(node);
      } else {
        asOdAlgos.push(node);
      }
    }

    const sensors: ISensorNode[] = [];
    for (const sensor of model.Sensor.all() as ISensor[]) {
      const type = ComponentVables.Type[sensor.type];
      const isOd = type.value === ComponentVables.Type.PositionSensor.value;
      // ts definition of ISensor is incorrect, as meausrement can have units. TODO: revisit after units
      // @ts-ignore
      const measurement = sensor.measurement?.rpm ? sensor.measurement.rpm : sensor.measurement;
      const isSending = !(measurement == null || (0 in measurement && measurement[0] == null));
      const node = {
        id: sensor.id,
        targets: [isOd ? odAlgo : adAlgo],
        sending: isSending,
        data: {
          title: sensor.name,
          subtitle: type?.label,
          conditions: sensor.conditions,
        },
      };
      if (isOd) {
        sensors.unshift(node as ISensorNode);
      } else {
        sensors.push(node as ISensorNode);
      }
    }
    return [asOdAlgos, sensors];
  }, [model]);

  const [preparedNodes, preparedEdges] = usePrepNodesAndEdges(
    sensorNodes,
    adOdAlgoNodes,
    controlAlgoNodes,
    actuatorNodes,
    placementStrategyIdx
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(preparedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(preparedEdges);

  useEffect(() => {
    setNodes(preparedNodes);
    setEdges(preparedEdges);
  }, [preparedNodes, preparedEdges, setNodes, setEdges]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
  const activeKey = useContext(ContextNavContext)?.state?.activeKey;

  return (
    <Widget
      title="GNC State"
      subtitle="State of Sensors, Algorithms, and Actuators"
      collapsibleConfig
    >
      {!nodes.length && <ViewPortInlay text="No GNC blocks to display" />}
      {nodes.length > 0 && (
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          style={{ height: activeKey === wGroupIndicesAgentCustom.PLAYBACK ? 590 : 700 }}
          attributionPosition="bottom-right"
          deleteKeyCode={[]}
        >
          <Controls style={{ left: -1 }}>
            <ControlButton onClick={togglePlacementStrategyIdx}>
              <Tooltip title="Toggle view" placement="right" arrow>
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </ControlButton>
          </Controls>
        </ReactFlow>
      )}
    </Widget>
  );
};

export default GNCStateWidget;
