import { TChartSpec } from 'components/general/types';

const tsSurfaceRadiativeHeatTransferChartSpec: TChartSpec = [
  {
    name: '"{name}" Radiative Heat Transfer',
    subtitle: '',
    each: 'Surface',
    plots: [
      {
        type: 'line',
        label: 'Thermal Dissipation Rate',
        unit: 'W',
        variables: [
          {
            keys: [
              'heatFlowRate',
              'spaceHeatFlowRate',
              'solarHeatFlowRate',
              'earthIrHeatFlowRate',
              'earthAlbedoHeatFlowRate',
            ],
            legend: ['Total', 'Space', 'Solar', 'Earth IR', 'Earth Albedo'],
          },
        ],
      },
    ],
  },
];

export default tsSurfaceRadiativeHeatTransferChartSpec;
