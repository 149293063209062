const guidance = {
  heading: 'Create, Import, and Edit Battery Packs',
  body: [
    {
      chunk:
        'Battery packs are used to assemble the battery that will power the satellite. The packs in this table compose the battery (or "Energy Storage System"), as configured by the parameters in the Battery Segment below, and each pack exists as a discrete Component within the system.',
    },
  ],
};

export default guidance;
