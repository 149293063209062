import { makeGuidance } from 'hooks';

const dataInterfaceDescription =
  'Data Interfaces model the flow of data, either internally, between Components in the Agent, or externally. Data only flows when the Data Interface is active. Indicate when the interface is active by clicking the "Always active" checkbox or by selecting a subset of OpModes.';

export const dataInterfaceGuidance = {
  _default: {
    heading: 'Data Interfaces',
    body: [{ chunk: dataInterfaceDescription }],
  },
  onBitRate: {
    heading: 'Bitrate',
    body: 'Indicate the speed at which data flows through this interface while it is active.',
  },
  source: {
    heading: 'Internal Data Interfaces',
    body: 'In an internal Data Interface, data flows from one Component (source) to another (sink). Note that Components without Data Storage may be included in a Data Interface; however, if the flow of data through such a Component is not net-zero, the simulation will be terminated, as this represents the loss of data.\nOptionally select a Data Bus, which will aggregate many data Data Interfaces for analytics.',
  },
  sink: { alias: 'source' },
  bus: { alias: 'source' },
  type: {
    heading: 'Interface Type',
    body: [
      {
        chunk:
          'Data Interfaces model the flow of data, either internally, between Components in the Agent, or externally. There are three kinds of external Data Interface.',
      },
      {
        subHeading: 'Passive Transmit',
        chunk:
          'Data is sent from the satellite if the linkTarget is in the Field Of View of the Directed Energy Device.',
      },
      {
        subHeading: 'Cooperative Transmit',
        chunk:
          'In addition to the requirements of Passive Transmit, this requires the linkTarget to have an active receive Data Interface in order for data to flow.',
      },
      {
        subHeading: 'Receive',
        chunk:
          'The Agent receives data from a linkTarget in a transmit Data Interface if the target is in the Field of View of the Directed Energy Device.',
      },
    ],
  },
  modem: {
    body: [
      {
        subHeading: 'Modem',
        chunk: 'In the future, a Modem will be configurable to manage external interfaces.',
      },
      {
        subHeading: 'Directed Energy Device',
        chunk: 'Associate the antenna or laser commms module that will be used for this interface.',
      },
      {
        subHeading: 'Link Target',
        chunk: 'Choose a target that is on the other side of the external interface.',
      },
    ],
  },
};

export const useGuidance = makeGuidance(dataInterfaceGuidance);

const dataInterfaceSegmentGuidance = {
  heading: 'Data Interfaces',
  body: [{ chunk: dataInterfaceDescription }],
};

export default dataInterfaceSegmentGuidance;
