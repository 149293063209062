import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Chip, Switch, Tooltip } from '@material-ui/core';
import { wGroupIndicesWorkspace } from 'components/RootView/menu';
import LoadingInlay from 'components/general/LoadingInlay';
import RowedExplorer from 'components/general/RowedExplorer';
import ViewPortInlay from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import { IMission, IProject } from 'components/general/types';
import { usePermissionCheck, useSelectByIds } from 'hooks';
import useEntityDialogControl from 'hooks/EntityDialogControlHook';
import useWorkspace from 'hooks/useWorkspace';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { WorkspaceContext } from 'providers/WorkspaceProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WorkspaceVables } from 'utils/vable';
import ProjectDialog from './ProjectDialog';
import ProjectRow from './ProjectRow';
import RepoDialog from './RepoDialog';
import RepoRow from './RepoRow';

const ReposBoard = () => {
  // Workspace
  const { loading, fetchWorkspace } = useContext(WorkspaceContext);
  const workspace = useWorkspace();

  // Utils
  const [showProjects, setShowProjects] = useState(!!workspace.projectViewOn);
  const canCreate = usePermissionCheck(WorkspaceVables.Permission.EDIT_REPOSITORY);
  const repoDialogControl = useEntityDialogControl<IMission>();
  const projectDialogControl = useEntityDialogControl<IProject>();
  const { Workspace } = SatelliteApi;
  const dispatch = useDispatch();

  // Models
  const projects = useSelectByIds('Project', workspace.projects);
  const repos = useSelectByIds('Mission', workspace.repositories).filter(
    (repo) => !(showProjects && repo.project)
  );

  // Project View vs Repo View toggle
  const setProjectViewState = useCallback(
    (projectViewOn: boolean) => {
      dispatch(Workspace.actions.setProjectViewState({ id: workspace.id, projectViewOn }));
      setShowProjects(projectViewOn);
    },
    [dispatch, Workspace, workspace]
  );

  useEffect(() => {
    if (workspace.projectViewOn === undefined) {
      setProjectViewState(true);
    }
  }, [workspace, setProjectViewState]);

  // Custom title component
  const workspaceTitle = (
    <>
      {workspace.name}
      <Tooltip
        title="Show Project View"
        PopperProps={{
          style: {
            marginTop: '-1em',
          },
        }}
      >
        <Switch
          color="primary"
          checked={showProjects}
          onChange={(e) => setProjectViewState(e.target.checked)}
        />
      </Tooltip>
    </>
  );

  return (
    <WGroup index={wGroupIndicesWorkspace.REPOSITORIES} masonryConfig={{}}>
      {
        /** Projects */
        showProjects && (
          <RowedExplorer
            name={workspaceTitle}
            description={workspace.description}
            rowsTitle="Projects"
            primaryButtonProps={
              canCreate
                ? {
                    icon: faAdd,
                    onClick: projectDialogControl.openDialogForNew,
                    tooltip: 'Create project',
                  }
                : undefined
            }
            secondaryButtonProps={{
              icon: faRefresh,
              onClick: fetchWorkspace,
              tooltip: 'Refresh repositories',
            }}
          >
            {loading ? (
              <LoadingInlay text="Your workspace is loading" />
            ) : projects.length ? (
              projects.map((project) => <ProjectRow key={project.id} project={project} />)
            ) : (
              <ViewPortInlay text="No Projects." />
            )}
          </RowedExplorer>
        )
      }
      {/** Repositories **/}
      <RowedExplorer
        name={!showProjects ? workspaceTitle : undefined}
        description={!showProjects ? workspace.description : undefined}
        rowsTitle="Repositories"
        smallTitle
        primaryButtonProps={
          canCreate
            ? {
                icon: faAdd,
                onClick: repoDialogControl.openDialogForNew,
                tooltip: 'Create repository',
              }
            : undefined
        }
        secondaryButtonProps={
          showProjects
            ? undefined
            : {
                icon: faRefresh,
                onClick: fetchWorkspace,
                tooltip: 'Refresh repositories',
              }
        }
      >
        {loading ? (
          <LoadingInlay text="Your workspace is loading" />
        ) : repos.length ? (
          repos.map((repo) => {
            const project = projects.find((p) => p.id === repo.project);
            return (
              <RepoRow
                key={repo.id}
                repo={repo}
                repoDialogControl={repoDialogControl}
                draggable={showProjects}
                tag={
                  // Tag to show project name when in repo view
                  project && <Chip label={project.name} size={'small'} style={{ height: '20px' }} />
                }
              />
            );
          })
        ) : (
          <ViewPortInlay text="No Repositories." />
        )}
      </RowedExplorer>
      <RepoDialog control={repoDialogControl} />
      <ProjectDialog control={projectDialogControl} />
    </WGroup>
  );
};

export default ReposBoard;
