import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LoadingView from 'components/LoadingView';
import WorkspaceDialog from 'components/RootView/WorkspaceDialog';
import {
  setActiveMissionVersionId,
  setActiveWorkspaceId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';
import Nav from 'components/general/Nav';
import StyledButton from 'components/general/StyledButton';
import StyledDivider from 'components/general/StyledDivider';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import { ILicense, IWorkspace } from 'components/general/types';
import { useEntityDialogControl, usePermissionCheck, useSelectById } from 'hooks';
import useWorkspace from 'hooks/useWorkspace';
import moment from 'moment';
import peopleImg from 'multimedia/icons/workspace.png';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { WorkspaceVables } from 'utils/vable';
import MembersBoard from './MembersBoard';
import ReposBoard from './ReposBoard';
import RolesBoard from './RolesBoard';

const WorkspaceView = () => {
  const dispatch = useDispatch();
  const { id: workspaceId } = useParams<{ id: string }>();
  const workspace = useWorkspace();
  const license = useSelectById('License', workspace?.license) as ILicense;
  const [canEdit, canDelete] = usePermissionCheck([
    WorkspaceVables.Permission.EDIT_WORKSPACE,
    WorkspaceVables.Permission.DELETE_WORKSPACE,
  ]);

  const workspaceDialogControl = useEntityDialogControl<IWorkspace>();
  const { openDialogForExisting: openDialogForExistingWorkspace } = workspaceDialogControl;

  // Unset active mission version ID whenever user navigates to repos. This unsets the nav bar.
  // Unset latest job ID so ActiveBranch provider knows to fetch a new one when switching between repos.
  useEffect(() => {
    dispatch(setActiveWorkspaceId(workspaceId));
    dispatch(setActiveMissionVersionId(undefined));
    dispatch(setLatestJobId(undefined));
  }, [dispatch, workspaceId]);

  if (!workspace?.id) return <LoadingView message="Sedaro is loading..." />;

  return (
    <ViewContainer>
      <Nav
        contextName="Workspace"
        contextImg={peopleImg}
        contextHeader={workspace.name}
        contextSubHeader={
          workspace?.isActive ?
            // Active workspace shows expiration date to admins
            (license &&
              (`License expire${moment(license.dateExpires).unix() > moment.now() / 1000 ? 's' : 'd'
                }: ${moment(license.dateExpires).local().format('MMM Do, YYYY')}`
              ))
            :
            // Inactive workspace shows "expired" message to all members
            [<StyledDivider key="divider" $margin="bottom" />,
              "Your workspace's license has expired. Please contact us at ",
            <strong key="sales">sales@sedarotech.com</strong>,
              " to renew your license and continue modeling with Sedaro."]
        }
        control={
          (canEdit || canDelete) && (
            <>
              {canEdit && (
                <StyledButton
                  type="button"
                  onClick={() => openDialogForExistingWorkspace(workspace, 'edit')}
                  fullWidth
                  style={{ marginTop: '10px' }}
                >
                  <EditIcon />
                  Edit Workspace
                </StyledButton>
              )}
              {canDelete && (
                <StyledButton
                  type="button"
                  onClick={() => openDialogForExistingWorkspace(workspace, 'delete')}
                  fullWidth
                  framed
                  style={{ marginTop: !canEdit && '10px' }}
                >
                  <DeleteIcon />
                  Delete Workspace
                </StyledButton>
              )}
            </>
          )
        }
      ></Nav>
      <ViewPort>
        <ReposBoard />
        <MembersBoard />
        <RolesBoard />
      </ViewPort>

      <WorkspaceDialog control={workspaceDialogControl} />
    </ViewContainer>
  );
};

export default WorkspaceView;
