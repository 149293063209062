import { IMenu } from 'components/general/types';
import { TITLE } from 'config';

export const COMING_SOON = 'coming-soon';

const aTComingSoonMenu: IMenu['items'] = {
  [COMING_SOON]: {
    [TITLE]: 'Coming soon...',
  },
};
export const wGroupIndicesATComingSoon = {
  COMING_SOON: `${COMING_SOON}*`,
};

export default aTComingSoonMenu;
