import { TChartSpec } from 'components/general/types';

const tsSurfaceTempsChartSpec: TChartSpec = [
  {
    name: '"{name}"',
    subtitle: '',
    each: 'Surface',
    plots: [
      {
        type: 'line',
        label: 'Temperature',
        unit: 'C',
        variables: [
          {
            keys: ['temperature.degC'],
            legend: ['"{name}" Temperature'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Hot Margin',
        unit: 'C',
        variables: [
          {
            keys: ['hotMargin'],
            legend: ['"{name}" Hot Margin'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Cold Margin',
        unit: 'C',
        variables: [
          {
            keys: ['coldMargin'],
            legend: ['"{name}" Cold Margin'],
          },
        ],
      },
    ],
  },
];

export default tsSurfaceTempsChartSpec;
