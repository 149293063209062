import { IColumn } from 'components/general/types';
import { IFuelReservoir } from 'components/general/types/gnc';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import FuelReservoirDialog from './FuelReservoirDialog';
import guidance from './guidance';

interface IProps {
  index: string;
}

const reservoirsColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
];

const FuelReservoirsSegment = ({ index }: IProps) => {
  const { fuelReservoirs } = useActiveEntities();

  return (
    <EntityTableSegment<IFuelReservoir>
      title="Fuel Reservoirs"
      index={index}
      guidance={guidance}
      entityColumns={reservoirsColumns}
      entityData={fuelReservoirs}
      DialogComponent={FuelReservoirDialog}
      modelName="fuelReservoir"
    ></EntityTableSegment>
  );
};

export default FuelReservoirsSegment;
