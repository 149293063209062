import { makeStyles } from '@material-ui/core/styles';

const resetStyles = {
  // reset -- make sure the span is styled as if it's not there
  margin: 0,
  padding: 0,
  border: 0,
  fontSize: '100%',
  font: 'inherit',
  verticalAlign: 'baseline',
};

const useStyles = makeStyles({
  outerSpan: {
    ...resetStyles,
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    columnGap: '0.3rem',
    position: 'relative',
  },
  innerSpan: {
    ...resetStyles,
    // expand the clickable area a little bit outside the icon
    position: 'relative',
    display: 'inline-block',
    zIndex: 1,
    padding: '0.10rem 0.40rem 0.35rem 0.2rem',
    margin: '-0.10rem -0.40rem -0.35rem -0.2rem',
  },
});

export default useStyles;
