import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routePathsCommon } from 'routes';
import { WorkspaceVables } from 'utils/vable';
import { usePermissionCheck } from '.';
const { AGENT_TEMPLATE_EDIT, SCENARIO, AGENT_ANALYZE } = routePathsCommon;

/**
 *
 * @returns `true` if user is currently in AGENT_TEMPLATE_EDIT, SCENARIO, or AGENT_ANALYZE viewing a branch they don't
 * have access to edit (i.e. via guest role or shareable links), otherwise returns `false`.
 */
const useInReadOnlyBranch = (): boolean => {
  const { pathname } = useLocation();

  const inReadOnlyAllowedView = [AGENT_TEMPLATE_EDIT, SCENARIO, AGENT_ANALYZE].includes(
    pathname.split('/')[1]
  );
  const canEdit = usePermissionCheck(WorkspaceVables.Permission.EDIT_BRANCH);

  return useMemo(() => inReadOnlyAllowedView && !canEdit, [inReadOnlyAllowedView, canEdit]);
};

export default useInReadOnlyBranch;
