import { makeGuidance } from 'hooks';

const latAltNote = ' Latitude and altitude are geodetic and based on the WGS84 ellipsoid. ';
const rangeNote = ' Range is from your satellite to the target. ';
const losNote =
  ' Line of sight is true/false and indicates whether a line from your satellite to the target is blocked by the solid Earth. Line of sight is computed based on WGS84 ellipsoid polar Earth radius. ';
const oeNote =
  ' True anomaly is actually argument of latitude if your satellite orbit is circular-inclined and true longitude if your orbit is circular-equatorial. ';

const compareGuidance = {
  heading: 'Compare Condition',
  body: [
    {
      chunk:
        'Compare conditions use an equality or inequality relationship to compare two quantities. Comparisons may be created between a combination of satellite, target, and target group parameters and constant values.\n In order to allow only supported combinations of parameters, "Date and Time" and Target Group parameters are only available under Parameter A. Likewise, constant (scalar) values are only permitted under Parameter B.',
    },
    {
      subHeading: 'Relationship',
      chunk:
        'Select a relationship to enforce between your selected parameters. \nThe compliance state of your condition will be determined as: \nA *Relationship* B',
    },
  ],
};

const tgBase = {
  chunk:
    'In addition to the selection of a target parameter, target group conditions require a "roller", which is a method to combine the individual target parameters into a single comparable quantity. Select an appropriate target parameter as usual and then choose a Group Roller to complete your condition.\nAdditionally, target group conditions can optionally be used to filter the target group for active target eligibility. Either check "Filters target group" or add this condition to "Filter Conditions" in the target group.',
};

const rollerChunk = {
  subHeading: 'Group Roller',
  chunk:
    'The rollers "any" and "all" evaluate the relationship target-wise and return true if any or all of the relationships are true, respectively. The respective negations are provided by the "not all" and "not any" rollers. The "count" roller does the same target-wise comparison and performs another comparison between the number of "true" relationships and a user-defined value.\nThe remaining rollers perform the eponymous operation on the array of target parameters before comparing to Parameter B.',
};

const tgGuidance = (extras) => {
  const body = [tgBase];
  if (extras) body.push(...extras);
  return {
    heading: 'Target Group Condition',
    body: body,
  };
};

const toChunk = (guidance) => {
  return {
    subHeading: guidance.heading,
    chunk: guidance.body,
  };
};

const countResolve = (values) => {
  if (values?.topLevelConditionType?.value === 'COMPARE') {
    return {
      heading: 'Count Roller',
      body: 'The "count" roller does a target-wise comparison and compares:\n (the number of "true" relationships) *Count Relationship* (Count Value).',
    };
  } else {
    return {
      heading: 'Count Operator',
      body: 'The "count" operator compares:\n (the number of "true" conditions) *Count Relationship* (Count Value).',
    };
  }
};

const useGuidance = makeGuidance({
  _default: {
    heading: 'Conditions',
    body: [
      {
        chunk:
          'Conditions are blocks which evaluate to "true" or "false" at a given moment. These can be used to determine the active operational mode, filter targets in a target group, terminate the simulation, and more.',
      },
      {
        subHeading: 'Condition Types',
        chunk:
          'Compare conditions use an equality or inequality relationship to compare two quantities. Comparisons may be created between a combination of satellite, target, and target group parameters and constant values.',
      },
      {
        chunk:
          'Field of view conditions evaluate whether a target, vector, or celestial body lies within a chosen field of view. These can be negated to create keep-outs.',
      },
      {
        chunk: 'Compound conditions combine multiple previously defined conditions into one.',
      },
      {
        chunk:
          'Same target multi-conditions combine multiple conditions involving a single target group into one and evaluate to "true" only if all of the conditions are "true" for at least one target.',
      },
    ],
  },

  operator: {
    heading: 'Compound Condition',
    body: 'Compound conditions combine multiple previously defined conditions into one. Select the conditions that you would like to combine and the Compound Operator which will combine condition-wise compliance into a single value. Compound conditions are negatable by using the "Negate" checkbox.',
  },

  name: { alias: '_default' },

  relationship: compareGuidance,

  paramACategory: compareGuidance,

  paramA: (values, paramCatKey, tgtKey) => {
    const catKey = paramCatKey ? paramCatKey : 'paramACategory';
    const isTg =
      catKey === 'paramACategory' &&
      values?.compareTargetA?.assumptions?.paramACategory === 'TARGET_GROUP';
    if (values[catKey].value === 'SATELLITE') {
      return {
        heading: 'Satellite Parameters',
        body:
          "Beta angle is the angle between the Sun-direction and its projection onto your satellite's orbital plane.\n" +
          oeNote +
          '\n' +
          losNote +
          '\n' +
          latAltNote,
      };
    }
    let tgtType = values[tgtKey ? tgtKey : 'compareTargetA'].value.type;
    let targetGuidance;
    if (tgtType === 'GroundTarget') {
      targetGuidance = {
        heading: 'Ground Target Parameters',
        body:
          'Elevation angles are geodetic and based on the WGS84 ellipsoid.\n' +
          losNote +
          '\n' +
          rangeNote,
      };
    } else if (tgtType === 'CelestialTarget') {
      targetGuidance = {
        heading: 'Celestial Target Parameters',
        body: losNote + '\n' + rangeNote + '\n' + latAltNote,
      };
    } else {
      targetGuidance = {
        heading: 'Space Target Parameters',
        body: oeNote + '\n' + losNote + '\n' + latAltNote + '\n' + rangeNote,
      };
    }
    return isTg ? tgGuidance([toChunk(targetGuidance)]) : targetGuidance;
  },

  compareTargetA: {
    heading: 'Target Parameter',
    body: 'Select one of your targets or target groups from the dropdown.',
  },

  paramBCategory: compareGuidance,

  paramB: {
    alias: 'paramA',
    props: ['paramBCategory', 'compareTargetB'],
  },

  compareTargetB: {
    heading: 'Target Parameter',
    body: 'Select one of your targets from the dropdown.',
  },

  groupRoller: tgGuidance([rollerChunk]),

  fieldOfView: {
    heading: 'Field Of View Condition',
    body: 'Field of view conditions evaluate whether a target, vector, or celestial body lies within a field of view. Select "Negate" to instead evaluate whether the target/vector/body is outside of the field of view.\nThe FOV evaluation for targets includes checking for eclipse by the Earth, moon, and other celestial bodies, evaluating to false if the target is blocked. Evaluation of FOV conditions for celestial targets returns a seperate state when the body is partially in the field of view.\nEvaluation of vector in FOV does not take eclipsing into account.',
  },

  fovTarget: {
    alias: 'fieldOfView',
  },

  countRelationship: countResolve,

  countValue: countResolve,

  targetGroupField: {
    heading: 'Same Target Multi-Condition',
    body: 'Same target multi-conditions combine multiple conditions involving a single target group into one and evaluate to "true" only if all of the conditions are "true" for at least one target. For instance, if a ground target can only be imaged during certain local time and satellite elevation conditions, combining the two conditions into a Same Target Multi-Condition will allow ConOps logic to access whether any target can be imaged.\nIt is often convenient to associate a condition of this type to a Target Group as a Filter Condition to enforce multiple conditions on the active target.',
  },
});
export default useGuidance;
