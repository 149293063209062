import { useCallback, useMemo, useState } from 'react';
import EntitySegment from 'components/general/wizards/EntitySegment';
import { useActiveEntities, useAppDispatch, useFormikForm, useSnackbar } from 'hooks';
import { IClockConfig, IGuidanceCard } from 'components/general/types';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import validation from './validation';
import useGuidance from './guidance';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import LabeledDateTimePicker from 'components/general/inputs/LabeledPickers/LabeledDateTimePicker';
import { Moment } from 'moment';

interface IProps {
  index: string;
}
interface IForm {
  startTime: string | Moment;
  stopTime: string | Moment;
  realTime: boolean;
}

const defaultValues: IForm = {
  startTime: '',
  stopTime: '',
  realTime: false,
};

const TimeSegment = ({ index }: IProps) => {
  const { branch, clockConfig } = useActiveEntities();
  const filteredConfig = useMemo(
    () =>
      clockConfig
        ? {
            ...clockConfig,
            startTime: clockConfig?.startTime,
            stopTime: clockConfig?.stopTime,
          }
        : (defaultValues as IClockConfig),
    [clockConfig]
  );
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const {
    ClockConfig: {
      actions: { createClockConfig, updateClockConfig },
    },
  } = SatelliteApi;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const successCallback = useCallback(() => {
    enqueueSnackbar('Time updated successfully', {
      variant: 'success',
    });
    setLoading(false);
  }, [enqueueSnackbar, setLoading]);

  const failureCallback = useCallback(
    (response: { error: { message: string } }) => {
      const errorMessage =
        response?.error?.message ||
        'Something went wrong. Please contact us if this issue persists.';
      enqueueSnackbar(errorMessage);
      setLoading(false);
    },
    [enqueueSnackbar, setLoading]
  );

  const { formik, guidance } = useFormikForm<IClockConfig, IForm>(
    defaultValues,
    (values: IForm) => {
      setLoading(true);
      dispatch(
        clockConfig === undefined
          ? createClockConfig({
              ...values,
              startTime: values.startTime,
              stopTime: values.stopTime,
              branchId: branch.id,
              type: 'ClockConfig',
              successCallback: (response: IClockConfig) => {
                dispatch(
                  updateClockConfig({
                    rootValues: { clockConfig: response.id },
                    branchId: branch.id,
                    successCallback,
                    failureCallback,
                  })
                );
              },
              failureCallback,
            })
          : updateClockConfig({
              ...values,
              startTime: values.startTime,
              stopTime: values.stopTime,
              id: clockConfig.id,
              branchId: branch.id,
              type: 'ClockConfig',
              successCallback,
              failureCallback,
            })
      );
    },
    validation,
    filteredConfig,
    { useGuidance, datetimes: ['startTime', 'stopTime'] }
  );

  const { handleSubmit, getFieldProps, resetForm, dirty, values } = formik;

  // minDate must be defined for the stopEpoch datetimepicker component
  // If the user enters the stopEpoch before startEpoch we set the minimum date so it's not null
  const minDate = useMemo(() => {
    return values.startTime || new Date('January 1, 1970 00:00:00');
  }, [values]);

  return (
    <EntitySegment
      title="Time"
      index={index}
      // Guidance is optional for the formik hook, so we typecast it since it will be returned whenever we pass the hook useGuidance
      guidance={guidance as IGuidanceCard}
      onSubmit={handleSubmit}
      onReset={resetForm}
      disableSubmit={!dirty}
      loading={loading}
      xray={{ ...clockConfig, ...values }}
    >
      <div className={classes.inputs}>
        <LabeledDateTimePicker label="Start Date & Time (UTC)" {...getFieldProps('startTime')} />
        <LabeledDateTimePicker
          label="End Date & Time (UTC)"
          minDate={minDate}
          {...getFieldProps('stopTime')}
        />
      </div>
    </EntitySegment>
  );
};

export default TimeSegment;
