import { FilesInput } from '@babylonjs/core';
import '@babylonjs/loaders';
import { useCallback } from 'react';
import { Model } from 'react-babylonjs';
import { deg2Rad } from 'utils/math';

const CADModel = (props) => {
  let {
    file,
    setCadScaleFactor,
    setModelAxisSizes,
    model,
    // unsure why useSnackbar() is not working here or in parent component. Had to pass down enqueueSnackbar as a prop from AttitudeDisplay.
    enqueueSnackbar,
    setModelLoadErrorUrl,
    modelLoadErrorUrl,
  } = props;

  // using snackbar from provider seems to break onModelError
  // const { enqueueSnackbar } = useSnackbar();

  const onModelLoaded = (model) => {
    const box = model.boundingInfo.boundingBox; // gets the bounding box of the model
    const axisSizes = box.maximum.subtract(box.minimum); // subtracts the minimum vector from the maximum vector to get the size of the principal axes
    const axisSizesObject = {
      x: axisSizes.x,
      y: axisSizes.z, // y and z are flipped because Babylon.js never properly learned its ABCs (I have no idea why)
      z: axisSizes.y,
    };
    setModelAxisSizes && setModelAxisSizes(axisSizesObject);
    const maxVectorDistance = Math.max(axisSizes.x, axisSizes.y, axisSizes.z); // returns the largest axis size
    setCadScaleFactor && setCadScaleFactor(1 / maxVectorDistance);
    model.rootMesh.scaling.scaleInPlace(1 / maxVectorDistance);
    model.rootMesh.rotation.x = deg2Rad(90);
  };

  // file name must be lowercase otherwise the babylon preview will break
  const fileName = file.fileName?.toLowerCase();
  const fileIsBlob = typeof file.fileUrl !== 'string';

  const onModelError = useCallback(() => {
    enqueueSnackbar(
      'There was an error loading your file. Please check that your file is not corrupted.'
    );

    // triggers resetting the cad model url and filename back to the previous model in CADModelPanel if there is an error
    // see useEffect in CADModelPanel where setCadModelPreview is called for explanation
    setModelLoadErrorUrl(fileIsBlob ? 'file:' : model.cadSignedUrl);
  }, [enqueueSnackbar, setModelLoadErrorUrl, fileIsBlob, model.cadSignedUrl]);

  FilesInput.FilesToLoad[fileName] = file.fileUrl;
  return (
    <Model
      reportProgress
      onModelLoaded={onModelLoaded}
      onModelError={onModelError}
      rootUrl={modelLoadErrorUrl ? model.cadSignedUrl : fileIsBlob ? 'file:' : file.fileUrl}
      sceneFilename={fileIsBlob && !modelLoadErrorUrl ? fileName : ''}
    />
  );
};

export default CADModel;
