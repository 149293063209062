import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';
import { makeGuidance } from 'hooks';

type TGuidanceBody = { chunk: string; subHeading?: string };

const tanksHeading = 'Create and Edit Fuel Tank';

const tanksMainParagraph =
  'Fuel tanks are the physical components which comprise Fuel Reservoirs, from which thrusters consume fuel.';

const defaultGuidance = {
  heading: tanksHeading,
  body: [
    {
      chunk: tanksMainParagraph,
    },
    {
      subHeading: 'Fuel Capacity',
      chunk:
        'Include the dry mass of the tank in the total spacecraft mass, and use this field to specify how much fuel this tank can hold.',
    },
    {
      subHeading: 'Initial Fuel Mass',
      chunk:
        'Use this field to specify how much fuel the tank holds initially. Input must be less than the fuel capacity.',
    },
    {
      subHeading: 'Priority',
      chunk:
        'When a thruster draws from a Fuel Reservoir, tanks with a higher (larger) priority are used first. Multiple tanks can have the same priority, in which case they will drained at the same rate.',
    },
    {
      subHeading: 'Tank Shape',
      chunk:
        'The tank shape is used to dynamically calculate the inertia of the spacecraft as the fuel mass changes. Currently, the fuel is assumed to be uniformly distributed within the tank.',
    },
  ],
};

const defaultPlus = (extra: TGuidanceBody[]) => {
  const body = defaultGuidance.body.concat(extra);
  return { heading: defaultGuidance.heading, body: body };
};

export const fuelTanksGuidance = {
  _default: defaultGuidance,
  bodyFrameVector: {
    heading: 'Torque Axis',
    body: [
      {
        subHeading: 'For Reaction Wheels:',
        chunk:
          'Input the torque body frame vector: the direction around which the reaction wheel spins and produces torque on the spacecraft.',
      },
      {
        subHeading: 'For Magnetorquers:',
        chunk:
          ' Input the vector along the magnetorquer: this is the direction of the magnetic moment. The produced torque will always be orthogonal to this vector.',
      },
    ],
  },
  diameter: defaultPlus([{ chunk: 'Spherical tanks are characterized by their inner diameter.' }]),
  capDiameter: defaultPlus([
    {
      chunk:
        'Spherocylinder tanks are the shape of a cylinder with a hemishpere on each end. Specify the length of the cylindrical portion, the inner diameter of the hemispherical caps, and a body frame vector parallel to the cylinder axis.',
    },
  ]),
  cylinderLength: { alias: 'capDiameter' },
  ...componentThermalGuidance,
};

export const useGuidance = makeGuidance(fuelTanksGuidance);

const fuelTankSegmentGuidance = {
  heading: 'Fuel Tanks',
  body: [
    {
      chunk: tanksMainParagraph,
    },
  ],
};

export default fuelTankSegmentGuidance;
