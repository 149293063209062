import { InputAdornment } from '@material-ui/core';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledMatrix from 'components/general/inputs/LabeledMatrix';
import { IAgentTemplateRoot, IGuidanceCard } from 'components/general/types';
import EntitySegment from 'components/general/wizards/EntitySegment';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useAppDispatch, useFormikForm, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useState } from 'react';
import useGuidance from './guidance';
import validation from './validation';

interface IProps {
  index: string;
}
interface IForm {
  mass: number | '';
  inertia: (number | '')[][];
}

const defaultValues: IForm = {
  mass: '',
  inertia: [
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
  ],
};

const SpacecraftSegment = ({ index }: IProps) => {
  const { branch, model } = useActiveEntities();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  // TODO: Can we use useEntityForm instead of useFormikForm here?
  // Or can we make something similar that lets us remove dispatch() from this file?
  // Other segments with no dialog, like PowerProcessorSegment, would also get cleaned up
  const {
    Block: {
      actions: { updateBlock },
    },
  } = SatelliteApi;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { formik, guidance } = useFormikForm<IAgentTemplateRoot, IForm>(
    defaultValues,
    (values: IForm) => {
      setLoading(true);
      dispatch(
        updateBlock({
          rootValues: values,
          branchId: branch.id,
          successCallback: () => {
            enqueueSnackbar('Spacecraft updated successfully', {
              variant: 'success',
            });
            setLoading(false);
          },

          failureCallback: (response: { error: { message: string } }) => {
            const errorMessage = response.error.message;
            enqueueSnackbar(errorMessage);
            setLoading(false);
          },
        })
      );
    },
    validation,
    model,
    { useGuidance }
  );

  const { handleSubmit, getFieldProps, resetForm, dirty, values } = formik;

  return (
    <EntitySegment
      title="Spacecraft Mass Properties"
      index={index}
      // Guidance is optional for the formik hook, so we typecast it since it will be returned whenever we pass the hook useGuidance
      guidance={guidance as IGuidanceCard}
      onSubmit={handleSubmit}
      onReset={resetForm}
      disableSubmit={!dirty}
      loading={loading}
      xray={{ ...model, ...values }}
    >
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            label="Dry Mass"
            {...getFieldProps('mass')}
            type="number"
            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
          />
        </div>
      </div>
      <div className={classes.largeWidth}>
        <LabeledMatrix
          label="Dry Inertia (kg·m²)"
          {...getFieldProps('inertia')}
          value={defaultValues.inertia.map((row, i) =>
            row.map((col, j) => {
              return values.inertia[i] && typeof values.inertia[i][j] === 'number'
                ? values.inertia[i][j]
                : '';
            })
          )}
        />
      </div>
    </EntitySegment>
  );
};

export default SpacecraftSegment;
