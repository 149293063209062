import { IErrorResponse, IGenericObject, IMissionVersion } from 'components/general/types';
import { useEntityDialogControl, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

export interface ICommit {
  author: string;
  message: string;
  time: string;
  hexsha: string;
}

/**
 * Designed for use in the BranchExplorer, to extract out logic connected only to the history dialog
 *
 * @returns object to be desctructured: {`dialogControl`, `fetchGitHist`, `commitHist`, `loadingGitHistBrId` }
 */
const useGitHistory = () => {
  const [commitHist, setCommitHist] = useState<ICommit[]>([]);
  // this is set to branch.id when that branch's git history is being loaded so the proper "History" button will show the loading spinner. Defaults to 0 (to not match up with any branch id)
  const [loadingGitHistBrId, setLoadingGitHistBrId] = useState('');

  const dialogControl = useEntityDialogControl<IMissionVersion>();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    MissionVersion: {
      actions: { getGitHistory },
    },
  } = SatelliteApi;

  const fetchGitHist = useCallback(
    (branch: IMissionVersion) => {
      setLoadingGitHistBrId(branch.id);
      dispatch(
        getGitHistory({
          id: branch.id,
          successCallback: (response: IGenericObject) => {
            dialogControl.openDialogForExisting(branch, 'create');
            setCommitHist(response.commits);
            setLoadingGitHistBrId('');
          },
          failureCallback: (response: IErrorResponse) => {
            enqueueSnackbar(response.error.message);
            dialogControl.closeDialog();
            setLoadingGitHistBrId('');
          },
        })
      );
    },
    [dispatch, enqueueSnackbar, getGitHistory, setCommitHist, setLoadingGitHistBrId, dialogControl]
  );

  return { historyDialogControl: dialogControl, fetchGitHist, commitHist, loadingGitHistBrId };
};

export default useGitHistory;
