import { useActiveEntities } from 'hooks';
import { IColumn } from 'components/general/types';
import { ActuatorVables, TActuatorTypes } from 'utils/vable';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { IActuator } from 'components/general/types/gnc';
import guidance from './guidance';
import ActuatorDialog from './ActuatorDialog';

interface IProps {
  index: string;
}

const actuatorsColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (row) => ActuatorVables.Type[row.type as TActuatorTypes].label,
  },
];

const ActuatorsSegment = ({ index }: IProps) => {
  const { actuators } = useActiveEntities();

  return (
    <EntityTableSegment<IActuator>
      title="Actuators"
      index={index}
      guidance={guidance}
      entityColumns={actuatorsColumns}
      entityData={actuators}
      DialogComponent={ActuatorDialog}
      modelName="actuator"
    ></EntityTableSegment>
  );
};

export default ActuatorsSegment;
