import { SUB_ITEMS, TITLE, TOGGLE } from 'config';
import { IMenu } from 'components/general/types';
import { SatelliteApi } from 'middleware/SatelliteApi/api';

export const POWER = 'power';
const BATTERY_CELLS = 'battery-cells';
const BATTERY_PACKS = 'battery-packs';
const BATTERY_SYSTEM = 'battery-system';
const BUS_REGULATORS = 'bus-regulators';
export const ENERGY_STORAGE = 'energy-storage';
export const SUBSYSTEM_LOADING = 'subsystem-loading';
export const POWER_GENERATION = 'power-generation';
export const POWER_PROCESSING = 'power-processing';
const SOLAR_ARRAYS = 'solar-arrays';
const SOLAR_CELLS = 'solar-cells';
const SOLAR_PANELS = 'solar-panels';
const POWER_CONTROLLER = 'power-controller';

const {
  Satellite: {
    actions: { updateSatellite },
  },
} = SatelliteApi;

const aTPowerMenu: IMenu['items'] = {
  [POWER]: {
    [TITLE]: 'Power',
    [TOGGLE]: { label: 'Simulate Power Module (on/off)', action: updateSatellite },
    [SUB_ITEMS]: {
      [POWER_PROCESSING]: {
        [TITLE]: 'Power Processing',
        [SUB_ITEMS]: {
          [POWER_CONTROLLER]: {
            [TITLE]: 'Power Controller',
          },
          [BUS_REGULATORS]: {
            [TITLE]: 'Bus Regulators',
          },
        },
      },
      [POWER_GENERATION]: {
        [TITLE]: 'Power Generation',
        [SUB_ITEMS]: {
          [SOLAR_CELLS]: {
            [TITLE]: 'Solar Cells',
          },
          [SOLAR_PANELS]: {
            [TITLE]: 'Solar Panels',
          },
          [SOLAR_ARRAYS]: {
            [TITLE]: 'Solar Arrays',
          },
        },
      },
      [ENERGY_STORAGE]: {
        [TITLE]: 'Energy Storage',
        [SUB_ITEMS]: {
          [BATTERY_CELLS]: {
            [TITLE]: 'Battery Cells',
          },
          [BATTERY_PACKS]: {
            [TITLE]: 'Battery Packs',
          },
          [BATTERY_SYSTEM]: {
            [TITLE]: 'Battery System',
          },
        },
      },
      [SUBSYSTEM_LOADING]: {
        [TITLE]: 'Subsystem Loading',
        [SUB_ITEMS]: {}, // this is where subsystems will be dynamically added
      },
    },
  },
};

export const wGroupIndicesATPower = {
  POWER: `${POWER}*`,
  POWER_PP: `${POWER}.${POWER_PROCESSING}*`,
  POWER_PP_PC: `${POWER}.${POWER_PROCESSING}.${POWER_CONTROLLER}`,
  POWER_PP_BR: `${POWER}.${POWER_PROCESSING}.${BUS_REGULATORS}`,
  POWER_PG: `${POWER}.${POWER_GENERATION}*`,
  POWER_PG_SC: `${POWER}.${POWER_GENERATION}.${SOLAR_CELLS}`,
  POWER_PG_SP: `${POWER}.${POWER_GENERATION}.${SOLAR_PANELS}`,
  POWER_PG_SA: `${POWER}.${POWER_GENERATION}.${SOLAR_ARRAYS}`,
  POWER_ES: `${POWER}.${ENERGY_STORAGE}*`,
  POWER_ES_BC: `${POWER}.${ENERGY_STORAGE}.${BATTERY_CELLS}`,
  POWER_ES_BP: `${POWER}.${ENERGY_STORAGE}.${BATTERY_PACKS}`,
  POWER_ES_BS: `${POWER}.${ENERGY_STORAGE}.${BATTERY_SYSTEM}`,
  POWER_SSL: `${POWER}.${SUBSYSTEM_LOADING}*`,
};

export default aTPowerMenu;
