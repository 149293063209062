import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from 'theme';
import { ellipsesOverflow } from '../sharedStyles';

interface IProps {
  truncateTitleOverflow: boolean;
  truncateSubtitleOverflow: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.dark,
    border: `1px solid ${theme.palette.background.darkest}`,
    padding: 4,
    borderRadius,
    width: 86,
    minHeight: 48,
    '& p': {
      fontSize: 8,
      lineHeight: '8px',
      padding: '1.5px 0',
      margin: 0,
    },
    boxShadow: 'rgba(0, 0, 0, 0.3) 3px 3px 3px',
  },
  title: ({ truncateTitleOverflow }: IProps) => ({
    color: theme.palette.text.primary,
    ...(truncateTitleOverflow ? ellipsesOverflow : {}),
  }),
  subtitle: ({ truncateSubtitleOverflow }: IProps) => ({
    color: theme.palette.text.secondary,
    ...(truncateSubtitleOverflow ? ellipsesOverflow : {}),
  }),
}));

export default useStyles;
