import { Fragment } from 'react';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import useGuidance from './guidance';
import InputAdornment from '@material-ui/core/InputAdornment';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import * as Yup from 'yup';
import { useActiveEntities, useEntityForm } from 'hooks';
import AttitudeDisplay from 'components/general/SpacecraftDialog/general/AttitudeDisplay';

const validateBfVector = (values) => {
  let result = false;
  if (values.definitionType === 'VECTOR')
    if (
      // The following lines are intentionally `==` given that the input values are stored as strings prior to translateOut()
      // eslint-disable-next-line eqeqeq
      values.definitionParams.vector[0] == 0 &&
      // eslint-disable-next-line eqeqeq
      values.definitionParams.vector[1] == 0 &&
      // eslint-disable-next-line eqeqeq
      values.definitionParams.vector[2] == 0
    ) {
      result = 'Vector must have non-zero magnitude';
    }
  return result;
};

const defaultValues = {
  name: '',
  definitionType: '',
  definitionParams: {
    theta: '',
    phi: '',
    vector: ['', '', ''],
  },
};

const bodyFrameSchema = Yup.object().shape({
  name: Yup.string()
    .required('A body frame vector name is required.')
    .max(32, 'Body frame vector name must be no more than 32 characters.'),
  definitionType: Yup.object().required('Select a vector definition type.'),
  definitionParams: Yup.object()
    .when('definitionType', {
      is: (definitionType) => definitionType?.value === 'SPHERICAL_ANGLES',
      then: Yup.object({
        theta: Yup.number().required('Angle to z-axis is required.'),
        phi: Yup.number().required('Angle from x-axis to xy-projection is required.'),
      }),
    })
    .when('definitionType', {
      is: (definitionType) => definitionType?.value === 'VECTOR',
      then: Yup.object({
        vector: Yup.array()
          .of(Yup.number())
          .test('test each field', 'All directional components are required.', (values) => {
            for (let value of values) {
              // Ensure each value has been entered and return false to fail the test if not
              if (value === null || value === undefined || value === '') {
                return false;
              }
            }
            return true;
          }),
      }),
    }),
});

const bfvOptions = {
  definitionType: [
    { value: 'SPHERICAL_ANGLES', label: 'Spherical Angles' },
    { value: 'VECTOR', label: 'Vector' },
  ],
};

const BodyFrameVectorDialog = (props) => {
  const { control } = props;
  const { model } = useActiveEntities();

  const classes = useStyles();

  const entityForm = useEntityForm({
    entityTypeText: 'Body Frame Vector',
    entityDialogControl: control,
    validateForm: validateBfVector,
    defaultValues,
    additionalCreateValues: { type: 'BodyFrameVector' },
    validationSchema: bodyFrameSchema,
    formikOptionalParams: {
      useGuidance,
      options: bfvOptions,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, setValues, values } = formik;
  const { definitionType } = values;

  return (
    <EntityDialog
      entityForm={entityForm}
      xlarge={true}
      childrenRight={
        <AttitudeDisplay
          bodyFrameVectors={[values]}
          file={{
            fileUrl: model.cadSignedUrl,
            fileName: model.cadFileName,
          }}
          rightSide={true}
        />
      }
    >
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Body Frame Vector Name"
            type="text"
            placeholder="Body Frame Vector Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('definitionType')}
            formikOnChange={(val) => {
              // if switching definitionType we want to clear the previous values
              if (val.value !== definitionType) {
                setValues({
                  ...defaultValues,
                  name: values.name,
                });
              }
            }}
            label="Define Vector Using:"
            options={bfvOptions.definitionType}
          />
          <div className={classes.indent}>
            {definitionType?.value === 'SPHERICAL_ANGLES' && (
              <Fragment>
                <LabeledInput
                  {...getFieldProps('definitionParams.theta')}
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  label="Angle to z-Axis, &theta;"
                />
                <LabeledInput
                  {...getFieldProps('definitionParams.phi')}
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  label="Angle from x-Axis to xy-Projection, &phi;"
                />
              </Fragment>
            )}
            {definitionType?.value === 'VECTOR' && (
              <Fragment>
                <LabeledInput
                  {...getFieldProps('definitionParams.vector.0')}
                  type="number"
                  label="x-Component"
                />
                <LabeledInput
                  {...getFieldProps('definitionParams.vector.1')}
                  type="number"
                  label="y-Component"
                />
                <LabeledInput
                  {...getFieldProps('definitionParams.vector.2')}
                  type="number"
                  label="z-Component"
                />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </EntityDialog>
  );
};

export default BodyFrameVectorDialog;
