import { TChartSpec } from 'components/general/types';

const statsThermalInterfaceChartSpec: TChartSpec = [
  {
    name: 'Thermal Interface Average Temperature Delta',
    subtitle: '(Absolute values)',
    plots: [
      {
        type: 'horizontal-bar',
        unit: 'c',
        variables: [
          {
            each: 'ThermalInterface',
            legend: ['{name}'],
            keys: ['tempDelta'],
            ops: ['absAvg'],
          },
        ],
      },
    ],
  },
  {
    name: 'Thermal Interface Minimum Hot Margin',
    plots: [
      {
        type: 'margin',
        unit: 'c',
        variables: [
          {
            each: 'ThermalInterface',
            legend: ['{name}'],
            keys: ['hotMargin'],
            ops: ['min'],
          },
        ],
      },
    ],
  },
  {
    name: 'Thermal Interface Minimum Cold Margin',
    plots: [
      {
        type: 'margin',
        unit: 'c',
        variables: [
          {
            each: 'ThermalInterface',
            legend: ['{name}'],
            keys: ['coldMargin'],
            ops: ['min'],
          },
        ],
      },
    ],
  },
];

export default statsThermalInterfaceChartSpec;
