/* Application Routes
 * Single source for all routes/paths for use within the application
 */

import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const routePathsCommon = {
  AGENT_TEMPLATE_EDIT: 'agent-template-edit',
  AGENT_ANALYZE: 'agent-analyze',
  SCENARIO: 'scenario',
  ANALYZE: 'analyze',
  ATTITUDE: 'attitude',
  EDIT: 'edit',
  ENERGY_BALANCE: 'energy-balance',
  FSS: 'fs-statistics',
  MISSION_DESIGNER: 'mission-designer',
  PLAYBACK: 'playback',
  REPOSITORIES: 'repositories',
  TS: 'time-series',
  WORKSPACE: 'workspace',
  WS: 'window-statistics',
};

const createModuleRoute = (base?: string) => {
  return (id: string, moduleAction: string, subKey?: string, subSubKey?: string | number) => {
    let r = `/${base || ':moduleType'}/${id || ':id'}/${moduleAction || ':moduleAction'}`;
    if (!(id && moduleAction)) {
      r += '/:subKey?/:subSubKey?';
    } else {
      r += `${subKey ? `/${subKey}${subSubKey ? `/${subSubKey}` : ''}` : ''}`;
    }
    return r;
  };
};

const createRepoBranchRoute = () => (id: string) => `/repositories/${id || ':id'}`;
const createAgentAnalyzeSelectAgentRoute = () => (id: string) =>
  `/scenario/${id || ':id'}/analyze/agents`;

const Routes = {
  ROOT: () => '/',
  WORKSPACE: createModuleRoute(routePathsCommon.WORKSPACE),
  PROJECT: (id: string) => `/projects/${id || ':id'}`,
  LOGIN: () => '/login',
  SIGNUP: () => '/signup',
  ACCOUNT: () => '/account',
  WELCOME: () => '/welcome',
  LOADING: () => '/loading',
  MODULE: createModuleRoute(),
  MISSION_DESIGNER: createModuleRoute(routePathsCommon.MISSION_DESIGNER),
  ENERGY_BALANCE: createModuleRoute(routePathsCommon.ENERGY_BALANCE),
  AGENT_TEMPLATE_EDIT: createModuleRoute(routePathsCommon.AGENT_TEMPLATE_EDIT),
  AGENT_ANALYZE: createModuleRoute(routePathsCommon.AGENT_ANALYZE),
  SCENARIO: createModuleRoute(routePathsCommon.SCENARIO),
  SHARE_AUTH: () => '/share-auth',
  HELP: () => '/help',
  VERIFY: () => '/verify/:token',
  AUTHORIZE: () => '/authorize/:token',
  PASSWORD_RESET: () => '/password-reset/:token',
  NEW_MEMBER: () => '/new-member/:token',
  NOT_FOUND: () => '/not-found',
  BRANCH: createRepoBranchRoute(),
  AGENT_ANALYZE_AGENT_SELECTION: createAgentAnalyzeSelectAgentRoute(),
};

/**
 * Returns an object of key/value pairs containing any search params currently in the url.
 *
 * Note: if we update react router, we can use the useSearchParams hook: https://reactrouter.com/docs/en/v6/api#usesearchparams
 * However, this function is used outside of functional components/custom hooks (such as in`getMissionVersionAndMakeActive`)
 * in some places where a hook would not work, so it may be better to stick with this still.
 *
 * Additionally, since our url actually puts everything in the fragment (everything after the fragment identifier, or
 * hash - `#`), we also can't use built in functionality from `window.location.search` or `URLSearchParams`. This fact
 * would likely also prevent `useSearchParams` from working anyways.
 */
export const getSearchParams = () => {
  const urlHash = window.location.hash;

  if (!urlHash.includes('?')) return {};

  const searchParamsArr = urlHash.split('?')[1].split('&');
  const obj: { [key: string]: string } = {};

  for (const sp of searchParamsArr) {
    const [key, val] = sp.split('=');
    obj[key] = val;
  }

  return obj;
};

export const useSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => getSearchParams(), [search]); // eslint-disable-line react-hooks/exhaustive-deps
  const setSearch = useCallback(
    (params: { [key: string]: string | number }) => {
      const searchParams = new URLSearchParams(search);
      for (const key in params) {
        searchParams.set(key, String(params[key]));
      }
      history.replace({ search: searchParams.toString() });
    },
    [search, history]
  );

  return [searchParams, setSearch] as const; // https://fettblog.eu/typescript-react-typeing-custom-hooks/
};

export default Routes;
