import * as Yup from 'yup';

const operatorOrRoller = (groupRoller, operator) =>
  groupRoller?.value === 'COUNT' || operator?.value === 'COUNT';

const conditionSchema = Yup.object().shape({
  name: Yup.string()
    .max(32, 'Condition name must be no more than 32 characters.')
    .required('A condition name is required'),

  topLevelConditionType: Yup.object().required('Select a condition type.'),

  // Required for comparison conditions

  paramACategory: Yup.object().when('topLevelConditionType', {
    is: (paramACategory) => paramACategory?.value === 'COMPARE',
    then: Yup.object().required('Select a parameter A type'),
  }),

  paramBCategory: Yup.object().when('topLevelConditionType', {
    is: (paramBCategory) => paramBCategory?.value === 'COMPARE',
    then: Yup.object().required('Select a parameter B type'),
  }),

  relationship: Yup.object().when('topLevelConditionType', {
    is: (relationship) => relationship?.value === 'COMPARE',
    then: Yup.object().required('Select a relationship'),
  }),

  compareTargetA: Yup.object().when('paramACategory', {
    is: (compareTargetA) => compareTargetA?.value === 'TARGET',
    then: Yup.object().required('Select a target A'),
  }),

  paramA: Yup.object().when(['paramACategory', 'topLevelConditionType'], {
    is: (paramACategory, topLevelConditionType) =>
      paramACategory?.value !== 'TIME' && topLevelConditionType?.value === 'COMPARE',
    then: Yup.object().required('Select a parameter A'),
  }),

  compareTargetB: Yup.object().when('paramBCategory', {
    is: (compareTargetB) => compareTargetB?.value === 'TARGET',
    then: Yup.object().required('Select a target B'),
  }),

  paramB: Yup.object().when(['paramBCategory', 'topLevelConditionType'], {
    is: (paramBCategory, topLevelConditionType) =>
      paramBCategory?.value !== 'SCALAR' && topLevelConditionType?.value === 'COMPARE',
    then: Yup.object().required('Select a parameter B'),
  }),

  groupRoller: Yup.object().when(['compareTargetA'], {
    is: (compareTargetA) => compareTargetA?.assumptions?.paramACategory === 'TARGET_GROUP',
    then: Yup.object().required('Select a Group Roller'),
  }),

  // REF 1: see below
  countRelationship: Yup.object().when(['groupRoller', 'operator'], {
    is: operatorOrRoller,
    then: Yup.object().required('Select a Count Relationship'),
  }),

  countValue: Yup.number().when(['groupRoller', 'operator'], {
    is: operatorOrRoller,
    then: Yup.number()
      .required('Select a Count Value')
      .integer('Count value must be a positive integer.')
      .min(1, 'Count value must be a positive integer.'),
  }),

  // Scalar can be multiple types so we must used the mixed data type from Yup and validate conditionally based on other fields.
  scalar: Yup.mixed()
    // Scalar should be a regular input
    .when(['paramBCategory', 'paramA'], {
      is: (paramBCat, paramA) => {
        return (
          paramBCat?.value === 'SCALAR' &&
          paramA?.value !== 'LO_SIGHT' &&
          paramA?.value !== 'SHADOW'
        );
      },
      then: Yup.mixed().required('A constant value is required.'),
    })

    // Mission time required for scalar
    .when(['paramACategory', 'paramBCategory'], {
      is: (paramACat, paramBCat) => {
        return paramACat?.value === 'TIME' && paramBCat?.value === 'SCALAR';
      },
      then: Yup.mixed()
        .nullable()
        .required('A mission time is required.')
        .test('validateTime', 'Mission time is invalid.', (val) => val?.isValid()),
    })

    // Percent should be within 0 and 100
    .when('paramA', {
      is: (paramA) => paramA?.units === '%',
      then: Yup.mixed()
        .required('A constant value is required.')
        .test(
          'properPercentageRange',
          'Percentage must be between 0 and 100, inclusive.',
          (val) => typeof val === 'number' && val >= 0 && val <= 100
        ),
    }),

  // Required fields for field of view conditions

  fieldOfView: Yup.object().when('topLevelConditionType', {
    is: (topLevelConditionType) => topLevelConditionType?.value === 'FOV',
    then: Yup.object().required('Select a Field Of View'),
  }),

  fovTarget: Yup.object().when('topLevelConditionType', {
    is: (topLevelConditionType) => topLevelConditionType?.value === 'FOV',
    then: Yup.object().required('Select a Target/Vector in FOV.'),
  }),

  // Required field for Compound Condition. REF 1 NOTE: For count the appropriate params are enforced above.
  operator: Yup.object().when('topLevelConditionType', {
    is: (topLevelConditionType) => topLevelConditionType?.value === 'MULTI',
    then: Yup.object().required('Select a Compound Operator'),
  }),

  targetGroupField: Yup.object().when('topLevelConditionType', {
    is: (topLevelConditionType) => topLevelConditionType?.value === 'SAME',
    then: Yup.object().required('Select a Target Group'),
  }),
});

export default conditionSchema;
