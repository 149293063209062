import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  changesContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    minHeight: 40,
    maxHeight: '70vh',
    marginTop: 15,
    overflowY: 'auto',
    borderRadius: 5,
    '& p': { whiteSpace: 'pre-wrap' },
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    width: '50%',
    textAlign: 'center',
  },
}));

export default useStyles;
