import { IBaseEntity } from 'components/general/types';
import { isProd } from 'config';
import _ from 'lodash';
import { TCompiledModel, useModel } from 'middleware/SatelliteApi/template';
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { getSearchParams } from 'routes';
import { DataContext } from './DataProvider';
import { TimeContext } from './TimeProvider';

interface IMomentContext {
  model: TCompiledModel;
}

interface IProps {
  children: ReactNode;
}

const defaultState = {
  model: new Proxy({}, {}) as TCompiledModel,
};

/**
 * Provides all the data for a scenario at a given moment.
 * - Includes all uncompiled models _with_ data at that moment.
 * - If currently examining an agent, provides the compiled model for that agent at that moment.
 *
 * Subscribing to this context implicitly subscribes a component to:
 * - ActiveBranchContext: provides the model for the active agent
 * - DataContext: provides full sim data
 * - TimeContext: provides time value of current moment
 */
export const MomentContext = createContext<IMomentContext>(defaultState);

const MomentProvider = ({ children }: IProps) => {
  const { staticModels, queryData } = useContext(DataContext);
  const scenarioModel = useModel(staticModels.scenario);
  const { time } = useContext(TimeContext);

  const { agentId } = getSearchParams();

  // Create uncompiled model that includes time data
  const _model = useMemo(() => {
    const agent = scenarioModel.Agent.byId(agentId);
    if (agent && agent.templateRef) {
      const simulatedAgentId = agentId;
      const slice = queryData(time, simulatedAgentId);
      const agentTemplateModel = staticModels.agents[agentId];
      if (agentTemplateModel) {
        // Sep all non numeric keys
        const _blocksById: { [key: string]: IBaseEntity } = {};
        const _root: { [key: string]: IBaseEntity } = {};
        for (const k in slice[simulatedAgentId]) {
          if (!agentTemplateModel._blocksById[k]) {
            _root[k] = slice[simulatedAgentId][k];
          } else {
            _blocksById[k] = slice[simulatedAgentId][k];
          }
        }
        return {
          ...agentTemplateModel,
          _blocksById: _.merge(agentTemplateModel._blocksById, _blocksById),
          _root: _.merge(agentTemplateModel._root, _root),
        };
      }
    }
    return {};
  }, [queryData, scenarioModel, staticModels, time, agentId]);

  // Compile model for the active agent
  const model = useModel(_model);

  useEffect(() => {
    if (!isProd() && model && model.describe) {
      model.describe();
    }
  }, [model]);

  const value = useMemo(() => {
    return {
      model,
    };
  }, [model]);

  return <MomentContext.Provider value={value}>{children}</MomentContext.Provider>;
};

export default MomentProvider;
