import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { IDataHandlingDevice } from 'components/general/types/dataHandling';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import getThermalProps from 'hooks/getThermalProps';
import { useMemo } from 'react';
import { translateIn, translateOut } from 'utils/forms';
import { DataHandlingDeviceVables, SubsystemVables } from 'utils/vable';
import { useGuidance } from './guidance';
import validation from './validation';

interface IForm {
  name: string;
  type: ISelectOption | '';
  fieldOfView: ISelectOption | '';
  manufacturer: string;
  partNumber: string;
}

interface IProps {
  control: TEntityDialogControl<IDataHandlingDevice>;
}

const defaultValues: IForm = {
  name: '',
  type: '',
  fieldOfView: '',
  manufacturer: '',
  partNumber: '',
};

const DeviceDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action },
  } = control;
  const { fieldsOfView, subsystems } = useActiveEntities();
  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const classes = useStyles();

  const fovOptions = fieldsOfView.map((fov) => {
    return { value: fov.id, label: fov.name };
  });

  const options = useMemo(() => {
    return {
      type: DataHandlingDeviceVables.Type.options,
      fieldOfView: fovOptions,
    };
  }, [fovOptions]);

  const entityForm = useEntityForm<IDataHandlingDevice, IForm>({
    entityTypeText: 'Data Handling Device',
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: {
      subsystem: subsystems.find(
        (s) => s.category === SubsystemVables.Categories.DATA_HANDLING.value
      )?.id,
    },
    validationSchema: validation,
    formikOptionalParams: {
      options,
      useGuidance,
      translateIn,
      translateOut,
    },
  });
  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <LabeledInput
          {...getFieldProps('name')}
          label="Device Name"
          type="text"
          placeholder="Name"
          autoFocus
        />
        <LabeledSelect
          {...getFieldProps('type')}
          label="Device Type"
          options={options.type}
          isDisabled={action !== 'create'}
        />
        {values?.type && values.type?.value !== DataHandlingDeviceVables.Type.Modem.value && (
          <LabeledSelect
            {...getFieldProps('fieldOfView')}
            label="Field Of View"
            options={options.fieldOfView}
          />
        )}
      </div>
      <div className={classes.inputGroup}>
        <LabeledInput
          {...getFieldProps('manufacturer')}
          label="Manufacturer"
          placeholder="Manufacturer"
          type="text"
          optional
        />
        <LabeledInput
          {...getFieldProps('partNumber')}
          label="Part Number"
          placeholder="Part Number"
          type="text"
          optional
        />
      </div>
      <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
    </EntityDialog>
  );
};

export default DeviceDialog;
