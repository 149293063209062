import { useActiveEntities } from 'hooks';
import { IColumn } from 'components/general/types';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { IAlgorithm } from 'components/general/types/gnc';
import guidance from './guidance';
import AlgorithmDialog from './AlgorithmDialog';
import { AlgorithmVables, TAlgorithmTypes } from 'utils/vable';

interface IProps {
  index: string;
}

const actuatorsColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (row: IAlgorithm) =>
      AlgorithmVables.Type[row.type as TAlgorithmTypes].label,
  },
  // {  // TODO: Re-enable this when update rate works
  //   title: 'Update Rate',
  //   render: (row: IAlgorithm) => `${row.rate} Hz`,
  // },
];

const AlgorithmsSegment = ({ index }: IProps) => {
  const { algorithms } = useActiveEntities();

  return (
    <EntityTableSegment<IAlgorithm>
      title="Algorithms"
      index={index}
      guidance={guidance}
      entityColumns={actuatorsColumns}
      entityData={algorithms}
      DialogComponent={AlgorithmDialog}
      modelName="algorithm"
    ></EntityTableSegment>
  );
};

export default AlgorithmsSegment;
