import {
  statsDissipationChartSpec,
  statsEnergyStorageChartSpec,
  statsPowGenerationChartSpec,
  statsPowProcessingChartSpec,
  statsSubsysLoadingChartSpec,
} from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/stats';
import {
  tsDissipationChartSpec,
  tsEnergyStorageChartSpec,
  tsPowGenerationChartSpec,
  tsPowProcessingChartSpec,
  tsSubsysLoadingChartSpec,
} from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/timeSeries';
import {
  ENERGY_STORAGE,
  POWER,
  POWER_GENERATION,
  POWER_PROCESSING,
  SUBSYSTEM_LOADING,
} from 'components/AgentTemplateEditView/menu/power';
import { IMenu } from 'components/general/types';
import { CHART_SPEC, SUB_ITEMS, TITLE } from 'config';
import { routePathsCommon } from 'routes';

const { PLAYBACK, TS, WS, FSS } = routePathsCommon;

const DISSIPATION = 'dissipation';

const agentPowerMenu: IMenu['items'] = {
  [POWER]: {
    [TITLE]: 'Power',
    [SUB_ITEMS]: {
      [PLAYBACK]: {
        [TITLE]: 'Playback',
      },
      [TS]: {
        [TITLE]: 'Time Series',
        [SUB_ITEMS]: {
          [POWER_GENERATION]: {
            [TITLE]: 'Power Generation',
            [CHART_SPEC]: tsPowGenerationChartSpec,
          },
          [ENERGY_STORAGE]: {
            [TITLE]: 'Energy Storage',
            [CHART_SPEC]: tsEnergyStorageChartSpec,
          },
          [POWER_PROCESSING]: {
            [TITLE]: 'Power Processing',
            [CHART_SPEC]: tsPowProcessingChartSpec,
          },
          [SUBSYSTEM_LOADING]: {
            [TITLE]: 'Subsystem Loading',
            [CHART_SPEC]: tsSubsysLoadingChartSpec,
          },
          [DISSIPATION]: {
            [TITLE]: 'Dissipation',
            [CHART_SPEC]: tsDissipationChartSpec,
          },
        },
      },
      [WS]: {
        [TITLE]: 'Window Statistics',
        [SUB_ITEMS]: {
          [POWER_GENERATION]: {
            [TITLE]: 'Power Generation',
            [CHART_SPEC]: statsPowGenerationChartSpec,
          },
          [ENERGY_STORAGE]: {
            [TITLE]: 'Energy Storage',
            [CHART_SPEC]: statsEnergyStorageChartSpec,
          },
          [POWER_PROCESSING]: {
            [TITLE]: 'Power Processing',
            [CHART_SPEC]: statsPowProcessingChartSpec,
          },
          [SUBSYSTEM_LOADING]: {
            [TITLE]: 'Subsystem Loading',
            [CHART_SPEC]: statsSubsysLoadingChartSpec,
          },
          [DISSIPATION]: {
            [TITLE]: 'Dissipation',
            [CHART_SPEC]: statsDissipationChartSpec,
          },
        },
      },
      // [FSS]: {
      //   [TITLE]: 'Full Simulation Statistics',
      //   [SUB_ITEMS]: {
      //     [POWER_PROCESSING]: {
      //       [TITLE]: 'Power Processing',
      //     },
      //     [POWER_GENERATION]: {
      //       [TITLE]: 'Power Generation',
      //     },
      //     [ENERGY_STORAGE]: {
      //       [TITLE]: 'Energy Storage',
      //     },
      //     [SUBSYSTEM_LOADING]: {
      //       [TITLE]: 'Subsystem Loading',
      //     },
      //     [DISSIPATION]: {
      //       [TITLE]: 'Dissipation',
      //     },
      //   },
      // },
    },
  },
};

export const wGroupIndicesAgentPower = {
  POWER: `${POWER}*`,
  PLAYBACK: `${POWER}.${PLAYBACK}`,
  TS_PP: `${POWER}.${TS}.${POWER_PROCESSING}`,
  TS_PG: `${POWER}.${TS}.${POWER_GENERATION}`,
  TS_ES: `${POWER}.${TS}.${ENERGY_STORAGE}`,
  TS_SSL: `${POWER}.${TS}.${SUBSYSTEM_LOADING}`,
  TS_DIS: `${POWER}.${TS}.${DISSIPATION}`,
  WS_PP: `${POWER}.${WS}.${POWER_PROCESSING}`,
  WS_PG: `${POWER}.${WS}.${POWER_GENERATION}`,
  WS_ES: `${POWER}.${WS}.${ENERGY_STORAGE}`,
  WS_SSL: `${POWER}.${WS}.${SUBSYSTEM_LOADING}`,
  WS_DIS: `${POWER}.${WS}.${DISSIPATION}`,
  FSS_PP: `${POWER}.${FSS}.${POWER_PROCESSING}`,
  FSS_PG: `${POWER}.${FSS}.${POWER_GENERATION}`,
  FSS_ES: `${POWER}.${FSS}.${ENERGY_STORAGE}`,
  FSS_SSL: `${POWER}.${FSS}.${SUBSYSTEM_LOADING}`,
  FSS_DIS: `${POWER}.${FSS}.${DISSIPATION}`,
};

export default agentPowerMenu;
