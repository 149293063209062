import { IconDefinition, faSatellite, faTruckPlane } from '@fortawesome/free-solid-svg-icons';
import aTCDHMenu from 'components/AgentTemplateEditView/menu/cdh';
import aTComingSoonMenu from 'components/AgentTemplateEditView/menu/comingSoon';
import aTDataHandlingMenu from 'components/AgentTemplateEditView/menu/dataHandling';
import aTGNCMenu from 'components/AgentTemplateEditView/menu/gnc';
import aTPowerMenu, { POWER, SUBSYSTEM_LOADING } from 'components/AgentTemplateEditView/menu/power';
import aTTargetsMenu from 'components/AgentTemplateEditView/menu/targets';
import aTThermalMenu from 'components/AgentTemplateEditView/menu/thermal';
import { IMenu, ISelectOption } from 'components/general/types';
import { ITEMS, SUB_ITEMS } from 'config';
import _ from 'lodash';
import mdLogo from 'multimedia/icons/md.png';
import { RepoVables, TRepoTypesKeys } from 'utils/vable';
const { RepoTypes } = RepoVables;

interface IMmTypeAndLabel extends ISelectOption {
  value: TMetamodelTypes;
}

// =====================================================================================================================
// To add a new "Metamodel" type, expand TMetamodelTypes and create a new entry in `metamodelTypesRegistry` below
// This will add it to the `RepoDialog` options and the `MetamodelTypeIcon`
// =====================================================================================================================
export type TMetamodelTypes = 'Spacecraft' | 'Scenario' | 'TerrestrialVehicle';

type TMetamodelTypesRegistry = {
  [key in TMetamodelTypes]: {
    repoType: TRepoTypesKeys;
    iconSrc: IconDefinition | string;
    label?: string; // when omitted, defaults to "_.startCase()" of the key (TimeMachine -> "Time Machine")

    // The following two fields are used to populate the `AgentTemplateEditView` nav bar
    menu?: IMenu['items']; // WARN: Only empty for Scenarios, should be populated for Agent Templates
    dynamicItems?: {
      entityKey: string;
      keysToLoc: string;
    }[];
  };
};

const metamodelTypesRegistry: TMetamodelTypesRegistry = {
  Spacecraft: {
    repoType: RepoTypes.AGENT_TEMPLATE.value,
    iconSrc: faSatellite,
    dynamicItems: [
      {
        entityKey: 'subsystems',
        keysToLoc: `${ITEMS}.${POWER}.${SUB_ITEMS}.${SUBSYSTEM_LOADING}.${SUB_ITEMS}`,
      },
    ],
    menu: {
      ...aTTargetsMenu,
      ...aTGNCMenu,
      ...aTCDHMenu,
      ...aTDataHandlingMenu,
      ...aTPowerMenu,
      ...aTThermalMenu,
    },
  },
  TerrestrialVehicle: {
    repoType: RepoTypes.AGENT_TEMPLATE.value,
    iconSrc: faTruckPlane,
    menu: {
      ...aTComingSoonMenu,
    },
  },
  Scenario: { repoType: RepoTypes.SCENARIO.value, iconSrc: mdLogo },
};

// =====================================================================================================================
// Helper functions based on mmTypesInfo below here
// =====================================================================================================================

// expand metamodelTypesRegistry to include label if not provided
/** Object containing all Metamodel types as keys and values of an object with `repoType`, `iconSrc`, and `label`  */
const mmTypesInfo = _.mapValues(metamodelTypesRegistry, (info, mmType) => ({
  label: _.startCase(mmType),
  ...info,
}));

/** map of `repoType` to list of metamodel ISelectOptions */
const _rToMm = {} as { [Properties in TRepoTypesKeys]: IMmTypeAndLabel[] };
for (const [mmType, { repoType, label }] of Object.entries(mmTypesInfo)) {
  if (!_rToMm[repoType]) {
    _rToMm[repoType] = [];
  }
  _rToMm[repoType].push({ value: mmType as TMetamodelTypes, label: label as string });
}

/** Nested options for the repo dialog */
const mmTypeOptions = _.map(_rToMm, (mmTypeAndLabels, repoType: TRepoTypesKeys) => ({
  label: RepoTypes[repoType].label,
  options: mmTypeAndLabels,
}));

export { mmTypeOptions, mmTypesInfo };
