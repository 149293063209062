import Chart from 'components/general/chartComponents/Chart';
import { timing } from 'config';
import type { EChartOption } from 'echarts';
import { finalizeStat } from 'providers/DataProvider/stats';
import { useCallback, useMemo } from 'react';
import theme, { shade } from 'theme';
import { TUnit, precision, represent } from 'utils/units';
import { IGenericObject, TPlotVariable } from '../types';

interface IProps {
  title?: string;
  baseUnit?: TUnit;
  variables: TPlotVariable[];
  statsData: IGenericObject;
}

const statsToPieData = (statsData: IGenericObject, variables: TPlotVariable[]) => {
  return variables
    .flatMap((line) => {
      const statSet = finalizeStat(statsData, line.yKey);
      return Object.entries(statSet).map(([key, stat]) => ({
        name: line.name,
        value: stat.integral,
      }));
    })
    .sort(function (a, b) {
      return a.value - b.value;
    });
};

let colors = Object.values(theme.palette.charts.primary);
colors = colors.concat(colors.map((hex) => shade(hex, 30)));

const PieChart = ({ title, baseUnit, variables, statsData }: IProps) => {
  const repr = useCallback(
    (v: number | string) => baseUnit ? represent(Number(v), baseUnit) : precision(Number(v)),
    [baseUnit]
  );
  const option = useMemo(() => {
    const data = statsToPieData(statsData, variables);
    const total = data.reduce((acc, { value }) => acc + value, 0);
    return {
      color: colors,
      textStyle: {
        color: theme.palette.background.contrastText,
        ...theme.typography.body,
      },
      animationDuration: timing.lineAnimationDuration,
      toolbox: {
        show: true,
        feature: {
          // mark: { show: true },
          dataView: {
            show: true,
            readOnly: true,
            lang: [' ', 'Close', 'Reset'],
            backgroundColor: theme.palette.background.main,
            textColor: theme.palette.background.contrastText,
            buttonColor: theme.palette.primary.main,
            textareaColor: theme.palette.background.main,
            textareaBorderColor: theme.palette.background.main,
            optionToContent: (options: IGenericObject) => {
              const data = options.series[0].data;
              let table =
                '<table style="width:100%;text-align:left;user-select:text"><tbody><tr>' +
                '<td><b>Label</b></td>' +
                '<td><b>Value (' +
                baseUnit +
                ')</b></td>' +
                '</tr>';
              for (let i = 0; i < data.length; i++) {
                table +=
                  '<tr>' +
                  '<td>' +
                  data[i].name +
                  '</td>' +
                  '<td>' +
                  data[i].value +
                  '</td>' +
                  '</tr>';
              }
              table += '</tbody></table>';
              return table;
            },
          },
          restore: { show: true },
          saveAsImage: {
            show: true,
            backgroundColor: theme.palette.background.main,
            name: title || 'Sedaro Pie Chart',
            pixelRatio: 2, // saved image to displayed container resolution ratio
          },
        },
      },
      series: [
        {
          type: 'pie',
          radius: [60, 120],
          center: ['50%', '50%'],
          itemStyle: {
            borderRadius: 2,
            borderColor: theme.palette.background.main,
            borderWidth: 2,
          },
          data,
          labelLayout: function (params: IGenericObject) {
            // This is a very complicated way of only showing the label lines for labels that are truthy
            // Currently labels are set to falsey (and therefore not rendered) if less than 1%
            if (params.text) return null;
            const points = params.labelLinePoints;
            points[2][0] = params.labelRect.x;
            return {
              labelLinePoints: [points],
            };
          },
        } as EChartOption.Series,
      ],
      tooltip: {
        trigger: 'item',
        axisPointer: {
          lineStyle: {
            type: 'solid',
          },
        },
        backgroundColor: theme.palette.background.light,
        borderWidth: 0,
        textStyle: {
          color: theme.palette.text.primary,
        },
        extraCssText: `box-shadow: ${theme.shadows[5]};`,
        valueFormatter: (value: number | string) => repr(value),
      },
      title: {
        text: title,
        top: 10,
        left: 'center',
        textStyle: {
          color: theme.palette.background.contrastText,
          ...theme.typography.h3,
        },
      },
      label: {
        alignTo: 'labelLine',
        formatter: (params: IGenericObject) => {
          if (total !== 0 && params.percent < 1) return '';
          return `{name|${params.name}}\n{sublabel|${params.percent}% | ${repr(params.value)}}`;
        }, // `sublabel` is a key in `rich`
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15,
        rich: {
          sublabel: {
            fontSize: 12,
            color: '#AAA',
          },
        },
        color: theme.palette.background.contrastText,
      },
      labelLine: {
        show: false,
        length: 15,
        length2: 0,
        maxSurfaceAngle: 80,
      },
      legend: {
        bottom: 'bottom',
        textStyle: {
          ...theme.typography.body,
          color: theme.palette.background.contrastText,
          fontSize: 12,
        },
        inactiveColor: theme.palette.action.disabled,
        itemHeight: 10,
        itemWidth: 20,
      },
    } as EChartOption;
  }, [variables, statsData, title, baseUnit, repr]);

  return (
    <Chart
      option={option}
      style={{
        height: 400,
        marginTop: 15,
        marginBottom: 15,
        width: 'auto!important',
      }}
      titled={Boolean(title)}
      withZoom={false}
      initialHeight={400}
    />
  );
};

export default PieChart;
