import { TChartSpec } from 'components/general/types';

const tsAdodDPSchema: TChartSpec = [
  {
    name: 'Attitude and Attitude Solution',
    subtitle: 'Estimate minus truth from attitude determination (AD) algorithms',
    plots: [
      {
        type: 'line',
        label: 'ECI Error Angles',
        unit: 'rad',
        variables: [
          {
            keys: ['attitudeSolutionError.0', 'attitudeSolutionError.1', 'attitudeSolutionError.2'],
            legend: [
              'AD Solution Error: Yaw',
              'AD Solution Error: Pitch',
              'AD Solution Error: Roll',
            ],
          },
        ],
      },
      {
        type: 'line',
        label: 'Body Frame Angular Velocity Error',
        unit: 'rpm',
        variables: [
          {
            keys: [
              'angularVelocitySolutionError.rpm.0',
              'angularVelocitySolutionError.rpm.1',
              'angularVelocitySolutionError.rpm.2',
            ],
            legend: [
              'AV Solution Error: Axis-x',
              'AV Solution Error: Axis-y',
              'AV Solution Error: Axis-z',
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Orbit and Orbit Solution',
    subtitle: 'Estimate minus truth from orbit determination (OD) algorithms',
    plots: [
      {
        type: 'line',
        label: 'ECI Position Error',
        unit: 'km',
        variables: [
          {
            keys: ['positionSolutionError.0', 'positionSolutionError.1', 'positionSolutionError.2'],
            legend: [
              'OD Solution Error: x-Position',
              'OD Solution Error: y-Position',
              'OD Solution Error: z-Position',
            ],
          },
        ],
      },
      {
        type: 'line',
        label: 'ECI Velocity Error',
        unit: 'km/s',
        variables: [
          {
            keys: ['velocitySolutionError.0', 'velocitySolutionError.1', 'velocitySolutionError.2'],
            legend: [
              'OD Solution Error: x-Velocity',
              'OD Solution Error: y-Velocity',
              'OD Solution Error: z-Velocity',
            ],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Direction Sensor',
    each: 'DirectionSensor',
    plots: [
      {
        type: 'line',
        label: 'Unit Vector Components, S/C Body-Frame',
        variables: [
          {
            keys: [
              'truth.0',
              'truth.1',
              'truth.2',
              'measurement.0',
              'measurement.1',
              'measurement.2',
            ],
            legend: [
              'Truth: x-Component',
              'Truth: y-Component',
              'Truth: z-Component',
              'Measurement: x-Component',
              'Measurement: y-Component',
              'Measurement: z-Component',
            ],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Vector Sensor',
    each: 'VectorSensor',
    plots: [
      {
        type: 'line',
        label: 'Vector Components, S/C Body Frame',
        variables: [
          {
            keys: [
              'referenceVector.truth.eci.0',
              'referenceVector.truth.eci.1',
              'referenceVector.truth.eci.2',
              'measurement.0',
              'measurement.1',
              'measurement.2',
            ],
            legend: [
              'Truth: x-Component',
              'Truth: y-Component',
              'Truth: z-Component',
              'Measurement: x-Component',
              'Measurement: y-Component',
              'Measurement: z-Component',
            ],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Angular Velocity Sensor',
    each: 'AngularVelocitySensor',
    plots: [
      {
        type: 'line',
        label: 'Rates, S/C Body Frame',
        unit: 'rpm',
        variables: [
          {
            keys: [
              'root.angularVelocity.rpm.0',
              'root.angularVelocity.rpm.1',
              'root.angularVelocity.rpm.2',
              'measurement.rpm.0',
              'measurement.rpm.1',
              'measurement.rpm.2',
            ],
            legend: [
              'Truth: x-Component',
              'Truth: y-Component',
              'Truth: z-Component',
              'Measurement: x-Component',
              'Measurement: y-Component',
              'Measurement: z-Component',
            ],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Position Sensor',
    each: 'PositionSensor',
    subtitle: '',
    plots: [
      {
        type: 'line',
        label: 'Position, ECI',
        unit: 'km',
        variables: [
          {
            keys: [
              'root.position.eci.0',
              'root.position.eci.1',
              'root.position.eci.2',
              'measurement.0',
              'measurement.1',
              'measurement.2',
            ],
            legend: [
              'Truth: x-Position',
              'Truth: y-Position',
              'Truth: z-Position',
              'Measurement: x-Position',
              'Measurement: y-Position',
              'Measurement: z-Position',
            ],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Optical Attitude Sensor',
    each: 'OpticalAttitudeSensor',
    plots: [
      {
        type: 'line',
        label: 'Quaternion Components, ECI-to-S/C Body Frame',
        variables: [
          {
            keys: [
              'root.attitude.body_eci.0',
              'root.attitude.body_eci.1',
              'root.attitude.body_eci.2',
              'root.attitude.body_eci.3',
              'measurement.0',
              'measurement.1',
              'measurement.2',
              'measurement.3',
            ],
            legend: [
              'Truth: Axis-x',
              'Truth: Axis-y',
              'Truth: Axis-z',
              'Truth: Scalar',
              'Measurement: Axis-x',
              'Measurement: Axis-y',
              'Measurement: Axis-z',
              'Measurement: Scalar',
            ],
          },
        ],
      },
    ],
  },
];

export default tsAdodDPSchema;
