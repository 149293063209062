import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';
import { makeGuidance } from 'hooks';

const deviceDescription =
  'Use this table to create and edit devices that are used for handling data.\nDirected energy devices such as antennas and laser communications modules are used to communicate with other agents and external interfaces.\nModems are used to configure external interfaces and to manage connections via directed energy devices.';

export const deviceGuidance = {
  _default: {
    heading: 'Data Handling Devices',
    body: [
      {
        subHeading: 'Antennas and Laser Comm Modules',
        chunk:
          'Directed energy devices such as antennas and laser communications modules are used to communicate with other agents and external interfaces. Associate a Field Of View and assign this Component to an external Data Interface to communicate with other Agents.',
      },
      {
        subHeading: 'Modems',
        chunk:
          'Modems are used to configure external interfaces and to manage connections via directed energy devices. Configuration is not currently available, so for now create at least one modem to associate to external Data Interfaces.',
      },
    ],
  },
  ...componentThermalGuidance,
};

export const useGuidance = makeGuidance(deviceGuidance);

const deviceSegmentGuidance = {
  heading: 'Data Handling Devices',
  body: [{ chunk: deviceDescription }],
};

export default deviceSegmentGuidance;
