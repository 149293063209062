import { TChartSpec } from 'components/general/types';

const tsTargetChartSpec: TChartSpec = [
  {
    name: 'Line Of Sight',
    plots: [
      {
        name: 'Ground Target',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['GroundTarget.$each.lineOfSight'],
            legend: ['{name}'],
          },
        ],
      },
      {
        name: 'Space Target',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['SpaceTarget.$each.lineOfSight'],
            legend: ['{name}'],
          },
        ],
      },
      {
        name: 'Celestial Target',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['CelestialTarget.$each.lineOfSight'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Shadow',
    plots: [
      {
        name: 'Ground Target',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['GroundTarget.$each.shadow'],
            legend: ['{name}'],
          },
        ],
      },
      {
        name: 'Space Target',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['SpaceTarget.$each.shadow'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Ground Target: "{name}"',
    each: 'GroundTarget',
    plots: [
      {
        name: 'Azimuth, Elevation, and Range',
        type: 'line',
        label: 'Angle',
        unit: 'deg',
        labelRight: 'Range',
        unitRight: 'km',
        variables: [
          {
            keys: ['agentAzimuth.deg', 'agentElevation.deg'],
            legend: ['Azimuth', 'Elevation'],
          },
          {
            keys: ['range.km'],
            legend: ['Range'],
            right: true,
          },
        ],
      },
      {
        name: 'Solar Location and Sidereal Time',
        type: 'line',
        label: 'Angle',
        unit: 'deg',
        labelRight: 'Local Sidereal Time',
        unitRight: 'deg',
        variables: [
          {
            keys: ['solarAz.deg', 'solarEl.deg'],
            legend: ['Solar Azimuth', 'Solar Elevation'],
          },
          {
            keys: ['lst.deg'],
            legend: ['Local Sidereal Time'],
            right: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Space Target: "{name}"',
    each: 'SpaceTarget',
    plots: [
      {
        name: 'Beta Angle and Range',
        type: 'line',
        label: 'Angle',
        unit: 'deg',
        labelRight: 'Range',
        unitRight: 'km',
        variables: [
          {
            keys: ['beta.deg'],
            legend: ['Beta Angle'],
          },
          {
            keys: ['range.km'],
            legend: ['Range'],
            right: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Celestial Target: "{name}"',
    each: 'CelestialTarget',
    plots: [
      {
        name: 'Azimuth, Elevation, and Access',
        type: 'line',
        label: 'Angle',
        unit: 'deg',
        labelRight: 'Status',
        variables: [
          {
            keys: ['azimuth.deg', 'elevation.deg'],
            legend: ['Azimuth', 'Elevation'],
          },
          {
            keys: ['lineOfSight'],
            legend: ['Line Of Sight'],
            right: true,
          },
        ],
      },
    ],
  },
];

export default tsTargetChartSpec;
