import { createContext, ReactNode, useState } from 'react';

interface IPlaybackStatusContext {
  playbackStatus: boolean;
  setPlaybackStatus(status: boolean): void;
}

interface IProps {
  children: ReactNode;
}
const defaultState = {
  playbackStatus: false,
  setPlaybackStatus: (status: boolean) => {
    //intentionally empty
  },
};

/**
 * Provides the current playback status.
 * - If playback is active, e.g. has not started, is paused, or is stopped, the value is false.
 * - If playback is currently active, the value is true.
 */
export const PlaybackStatusContext = createContext<IPlaybackStatusContext>(defaultState);

const PlaybackStatusProvider = ({ children }: IProps) => {
  const [playbackStatus, setPlaybackStatus] = useState(false);

  return (
    <PlaybackStatusContext.Provider value={{ playbackStatus, setPlaybackStatus }}>
      {children}
    </PlaybackStatusContext.Provider>
  );
};

export default PlaybackStatusProvider;
