import { ISelectOption } from 'components/general/types';
import { ReferenceVectorVables } from 'utils/vable';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  type: Yup.object().required('Vector type is required.'),
  celestialPointingDirection: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ReferenceVectorVables.Type.CelestialVector.value,
    then: Yup.object().required('Celestial pointing direction is required.'),
  }),
  localPointingDirection: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ReferenceVectorVables.Type.LocalVector.value,
    then: Yup.object().required('Local pointing direction is required.'),
  }),
  referenceTarget: Yup.object().when(['type'], {
    is: (type: ISelectOption) =>
      type?.value === ReferenceVectorVables.Type.TargetVector.value,
    then: Yup.object().required('Target is required.'),
  }),
  targetGroup: Yup.object().when(['type'], {
    is: (type: ISelectOption) =>
      type?.value === ReferenceVectorVables.Type.TargetGroupVector.value,
    then: Yup.object().required('Target group is required.'),
  }),
});

export default validation;
