import { makeStyles } from '@material-ui/core/styles';
import { TStatsTableRowProps } from '.';
// Styles for cells based on datum.hierarchy. Style at position 0 is default, the indices of the rest match to hierarchy vals between 1 and 3
// If adding more hierarchy levels, edit styling here
const hierarchyStyles = [
  {
    fontSize: 10,
    marginLeft: 0,
  },
  {
    fontSize: 16,
    marginLeft: 10,
  },
  {
    fontSize: 14,
    marginLeft: 26,
  },
  {
    fontSize: 12,
    marginLeft: 42,
  },
];

const useStyles = makeStyles((theme) => ({
  statsTableCell: (props: TStatsTableRowProps) => {
    const cellStyle = hierarchyStyles[props.datum.hierarchy] || hierarchyStyles[0];
    return {
      padding: 0,
      fontFamily: 'Roboto Condensed',
      fontSize: cellStyle.fontSize,
      border: 'none',
      height: cellStyle.fontSize * 1.75,
      '& div': { marginLeft: cellStyle.marginLeft },
    };
  },
  statsTableRow: {
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  statsTableNameCell: { textAlign: 'left', width: '70%' },
  statsTableValueCell: { textAlign: 'right', width: '25%' },
  statsTableExpandCell: {
    textAlign: 'right',
    width: '5%',
    '& .MuiIconButton-root': {
      padding: 0,
      '& svg': {
        transition: 'transform 200ms',
      },
    },
  },
}));

export default useStyles;
