import { Alert, AlertTitle } from '@material-ui/lab';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput/index.jsx';
import { useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { ActiveBranchContext } from 'providers';
import { Fragment, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toTitleCase } from 'utils/strings.js';
import useStyles from './styles.ts';

const DeleteEntityDialog = (props) => {
  const {
    entity,
    action,
    entityTypeText,
    setup,
    onClose,
    open,
    childEntities,
    onSuccess,
    doubleConfirmDelete,
    queryParams,
  } = props;

  const variant = props.variant || 'delete'; // 'delete' | 'clear'
  const warningMessage = props.warningMessage || 'This cannot be undone.';

  const [deleteItemInput, setDeleteItemInput] = useState('');

  const {
    MissionVersion: {
      actions: { invalidateSimulation },
    },
  } = SatelliteApi;

  const dispatch = useDispatch();

  const { branch } = useContext(ActiveBranchContext);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleDelete = () => {
    if (doubleConfirmDelete) {
      if (deleteItemInput !== entity.name) {
        return;
      }
    }
    setLoading(true);
    if (setup) {
      setup();
    }
    dispatch(
      action({
        branchId: branch.id,
        id: entity.id,
        entity,
        queryParams,
        successCallback: () => {
          branch && dispatch(invalidateSimulation(branch.id));
          setErrorMessage(null);
          setLoading(false);
          onClose();
          enqueueSnackbar(
            `${entityTypeText} ${variant}${
              variant[variant.length - 1] === 'e' ? 'd' : 'ed' // 'delete' + 'd' vs. 'clear' + 'ed'
            } successfully`,
            {
              variant: 'success',
            }
          );
          if (onSuccess) {
            onSuccess();
          }
        },
        failureCallback: (response) => {
          setErrorMessage(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  const handleInputChange = (event) => {
    setDeleteItemInput(event.target.value);
  };

  return (
    <Fragment>
      <Dialog
        prompt={`${toTitleCase(variant)} ${entityTypeText.toLowerCase()}`}
        submitActionText={toTitleCase(variant)}
        open={open}
        onSubmit={handleDelete}
        onClose={() => {
          setErrorMessage(null);
          onClose();
        }}
        loading={loading}
      >
        {entity && (
          <p>
            Are you sure you'd like to {variant}{' '}
            <strong>
              {entityTypeText}: '{entity.name}'
            </strong>
            ? {warningMessage}
          </p>
        )}
        {childEntities && (
          <p className={classes.note}>
            By confirming, you understand that deleting this entity will simultaneously delete all
            related child entities including: {childEntities}.
          </p>
        )}
        {entity && doubleConfirmDelete && (
          <>
            <p className={classes.note}>
              To confirm that you intend to {variant}, type "{entity.name}" below.
            </p>
            <LabeledInput
              type="text"
              placeholder={entityTypeText + ' Name'}
              onChange={handleInputChange}
            />
          </>
        )}
        {errorMessage && (
          <Alert severity="error" icon={false} variant="outlined" className={classes.error}>
            <AlertTitle className={classes.errorHeading}>
              {variant === 'delete' ? 'Deletion' : 'Update'} Error
            </AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </Dialog>
    </Fragment>
  );
};

export default DeleteEntityDialog;
