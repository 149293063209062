import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import theme from 'theme';

const diffViewerStyles = {
  variables: {
    dark: {
      diffViewerTitleBackground: theme.palette.background.default,
      diffViewerTitleBorderColor: theme.palette.background.default,
      diffViewerBackground: theme.palette.background.lightest,
      codeFoldBackground: theme.palette.background.paper,
      codeFoldGutterBackground: theme.palette.background.paper,
      codeFoldContentColor: theme.palette.text.primary,
      wordAddedBackground: theme.palette.primary.main,
      gutterBackground: theme.palette.background.paper,
      gutterBackgroundDark: theme.palette.background.paper,
    },
  },
  contentText: {
    overflowX: 'auto',
    fontFamily: 'open sans',
  },
  lineNumber: {
    color: theme.palette.text.primary,
  },
  gutter: { '&:hover': { cursor: 'auto', pre: { opacity: 0.5 } } }, // overwrite hover effect on line numbers so nothing happens on hover
  line: {
    whiteSpace: 'normal',
  },
  codeFold: {
    '& a pre': { fontFamily: 'open sans', fontWeight: 400, fontSize: 13 },
    '& a pre:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
};

interface IProps {
  leftTitle?: string;
  rightTitle?: string;
  oldValue: string;
  newValue: string;
}

const StyledReactDiffViewer = ({ leftTitle, rightTitle, oldValue, newValue }: IProps) => {
  return (
    <ReactDiffViewer
      leftTitle={leftTitle}
      rightTitle={rightTitle}
      useDarkTheme={true}
      oldValue={oldValue}
      newValue={newValue}
      compareMethod={DiffMethod.WORDS}
      extraLinesSurroundingDiff={2}
      // @ts-ignore // this prop doesn't seem to have all the custimization options available on it's typing, but they still work
      styles={diffViewerStyles}
    />
  );
};

export default StyledReactDiffViewer;
