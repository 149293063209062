import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRef } from 'react';
import { ellipsesOverflow } from '../../general/sharedStyles';
import { ICondition } from '../../general/types';
import { checkIsTruncated } from '../../general/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 8,
    display: 'inline',
    maxWidth: 68,
    ...ellipsesOverflow,
  },
  complianceCircle: ({ compliance }: { compliance: boolean }) => ({
    borderRadius: '50%',
    background: compliance ? theme.palette.success.pastel : theme.palette.error.pastel,
    width: 5,
    height: 5,
    marginLeft: 1.6,
  }),
}));

const Condition = ({ name, compliance }: ICondition) => {
  const styles = useStyles({ compliance });

  const nameRef = useRef<HTMLParagraphElement>(null);
  const nameTruncated = checkIsTruncated(nameRef.current);

  return (
    <div className={styles.root}>
      <Tooltip title={nameTruncated ? name : ''} placement="right" arrow>
        <p className={styles.text} ref={nameRef}>
          {name}
        </p>
      </Tooltip>
      <div className={styles.complianceCircle} />
    </div>
  );
};

export default Condition;
