import { schema } from 'normalizr';

// General
const BASE_ROUTE_USER = '/user/';
const BASE_ROUTE_REPOS = 'repositories/';
const BASE_ROUTE_WORKSPACES = '/workspaces/';

const BASE_ROUTE_SYSTEM = '/system/';
const BASE_ROUTE_SUBSYSTEM = 'subsystems/';
const BASE_ROUTE_COMPONENT = 'components/';

/*
- ? denotes optional field
endpoints = {
  EndpointModel: {
    baseRoute: 'API_URL' | fn => routeSelectorFn,
    routeSuffixes: {
      endpointAction1: {
        method: 'HTML method (lowercase)',
        suffix?: 'id/additional/url/params',
        customSaga?: boolean - set to true if a custom saga needs to be generated,
        ignoreResponse?: boolean - whether the data created with this endpoint should be added to Redux,
        ignoreDelete?: boolean – whether Redux should avoid deleting models after the request completes,
      },
      ...
    },
    relatedModels?: [
      {
        modelName: 'ModelName',
        field?: 'modelName' - field name on EndpointModel,
        relatedField?: 'relatedFieldName' - field name on related model,
        many?: boolean - whether or not many-to-many relationship }
      },
      ...
    ],
    processStrategy?: https://github.com/paularmstrong/normalizr/blob/e365e5196b48540b5c95d15b42b70cf4defd78ae/docs/api.md#entitykey-definition---options--
    mergeStrategy?: same as processStrategy
    sortBy?: 'param to sort by' (if no sortBy or reverseSortBy flag is added, defaults to dateModified ascending)
    reverseSortBy?: 'param to sort by'
    noModel?: boolean - whether or not to generate models, entityAdapters, and schemas,
    noEndpoint?: boolean - whether or not to generate actions and sagas
    superSlice?: 'ModelName' - model that this endpoint inherits from that should also be updated
  }
}
*/

export const defaultEndpoints = {
  create: { method: 'post' },
  get: { method: 'get' },
  gets: { method: 'get' },
  update: { method: 'patch' },
  delete: { method: 'delete' },
};

// TODO: What is this comment referring to? Have we deleted it or replaced it?
/*
-----------------------------------------------------------------
IMPORTANT:
When adding new models to endpoints object, make sure to add the
ModelName to the ISatelliteApi interface in api.d.ts as well
-----------------------------------------------------------------
*/

export const endpoints = {
  // ----------------------------------------------------------------------------------------------
  // General
  // ----------------------------------------------------------------------------------------------
  ApiKey: {
    baseRoute: `${BASE_ROUTE_USER}manage-key/`,
    routeSuffixes: {
      getKeys: { method: 'get' },
      createKey: { method: 'post' },
      revokeKey: { method: 'patch' },
    },
    noModel: true,
  },
  DefaultCADModels: {
    baseRoute: '/models/default-cad-models',
    routeSuffixes: {
      ...defaultEndpoints,
    },
    sim: true,
  },
  Job: {
    baseRoute: ({ branchId }) => `/simulations/branches/${branchId}/control/`,
    routeSuffixes: {
      abortJob: { method: 'delete' },
      create: { method: 'post' },
      get: { method: 'get' },
      gets: { method: 'get' },
    },
    relatedModels: [{ modelName: 'MissionVersion', field: 'branch' }],
    sim: true,
  },
  License: {
    // Just here so it can be a model in Redux
    baseRoute: undefined,
    routeSuffixes: {},
    relatedModels: [{ modelName: 'Workspace', field: 'workspace', relatedField: 'license' }],
  },
  Mission: {
    baseRoute: `/models/${BASE_ROUTE_REPOS}`,
    routeSuffixes: {
      ...defaultEndpoints,
      cloneMission: { method: 'post' },
    },
    sortBy: 'dateModified',
    relatedModels: [
      { modelName: 'MissionVersion', field: 'branches', many: true, relatedField: 'repository' },
      { modelName: 'Project', field: 'project', relatedField: 'repositories' },
      {
        modelName: 'Workspace',
        field: 'workspace',
        relatedField: 'repositories',
      },
    ],
  },
  MissionVersion: {
    baseRoute: `/models/branches/`,
    routeSuffixes: {
      ...defaultEndpoints,
      invalidateSimulation: { method: 'patch', customSaga: true },
      updateAnalyzeState: { method: 'patch', customSaga: true },
      getAndMakeActive: { method: 'get', customSaga: true },
      branchOffOfBranch: { method: 'post' },
      commit: { method: 'post', suffix: '/commits/', ignoreResponse: true },
      getGitHistory: { method: 'get', suffix: '/commits/', ignoreResponse: true },
      getGitChanges: { method: 'get', suffix: '/changes/', ignoreResponse: true },
      getCommittedBranchForCompare: { method: 'get', suffix: '/committed/', ignoreResponse: true },
      getSavedBranchForCompare: { method: 'get', suffix: '/saved/', ignoreResponse: true },
      gitMerge: { method: 'post', suffix: '/merge/' },
      checkSharePw: { method: 'post', suffix: '/share-auth/', ignoreResponse: true },
      toggleDefaultModel: { method: 'patch', suffix: '/cad/' },
      uploadCadFile: { method: 'post', suffix: '/cad/' },
    },
    sortBy: 'dateModified',
    relatedModels: [
      { modelName: 'Mission', field: 'repository', relatedField: 'branches' },
      { modelName: 'User', field: 'user' },
      { modelName: 'Workspace', field: 'workspace' },
    ],
  },
  Project: {
    baseRoute: `/projects/`,
    routeSuffixes: {
      ...defaultEndpoints,
      clearProject: { method: 'delete', ignoreDelete: true },
    },
    relatedModels: [
      { modelName: 'Workspace', field: 'workspace', relatedField: 'projects' },
      { modelName: 'Mission', field: 'repositories', many: true, relatedField: 'project' },
    ],
  },
  Role: {
    routeSuffixes: {},
    relatedModels: [
      { modelName: 'User', field: 'users', many: true, relatedField: 'roles' },
      { modelName: 'Workspace', field: 'workspace', relatedField: 'roles' },
    ],
  },
  User: {
    baseRoute: BASE_ROUTE_USER,
    routeSuffixes: {
      authorize: { method: 'patch', suffix: 'authorize/', ignoreResponse: true },
      login: { method: 'post', suffix: 'login/', ignoreResponse: true },
      logout: { method: 'delete', suffix: 'logout/', ignoreResponse: true },
      get: { method: 'get' },
      update: { method: 'patch' },
      register: { method: 'post', suffix: 'register/', ignoreResponse: true },
      requestPasswordReset: { method: 'post', suffix: 'reset-password/', ignoreResponse: true },
      resetPassword: { method: 'patch', suffix: 'reset-password/', ignoreResponse: true },
      resendVerificationEmail: { method: 'get', suffix: 'verify/', ignoreResponse: true },
      verify: { method: 'patch', suffix: 'verify/' },
    },
    relatedModels: [
      { modelName: 'Workspace', field: 'workspaces', many: true, relatedField: 'members' },
      { modelName: 'Role', field: 'roles', many: true, relatedField: 'users' },
    ],
    processStrategy: (value, parent, key) => {
      // Pre-process user: if user is self and came in from workspace.members,
      // then remove roles, since it already comes from getUser
      if (key === 'members' && 'dateCreated' in value) {
        delete value.roles;
      }
      return value;
    },
  },
  Workspace: {
    baseRoute: BASE_ROUTE_WORKSPACES,
    routeSuffixes: {
      ...defaultEndpoints,
      manageInvites: { method: 'post', suffix: '/members/' },
      editMemberRole: { method: 'patch', suffix: '/members/' },
      deleteMember: { method: 'delete', suffix: '/members/' },
      verifyNewMember: { method: 'patch', suffix: 'join/' },
      setProjectViewState: { method: 'patch', customSaga: true },
    },
    relatedModels: [
      { modelName: 'Mission', field: 'repositories', many: true, relatedField: 'workspace' },
      { modelName: 'User', field: 'members', many: true, relatedField: 'workspaces' },
      { modelName: 'Role', field: 'roles', many: true, relatedField: 'workspace' },
      { modelName: 'Project', field: 'projects', many: true, relatedField: 'workspace' },
      { modelName: 'License', field: 'license', relatedField: 'workspace' },
    ],
  },

  // ----------------------------------------------------------------------------------------------
  // COTS Library
  // ----------------------------------------------------------------------------------------------
  Cots: {
    baseRoute: `${BASE_ROUTE_SYSTEM}${BASE_ROUTE_SUBSYSTEM}${BASE_ROUTE_COMPONENT}cots/`,
    routeSuffixes: {
      getCots: { method: 'get', ignoreResponse: true },
      create: { method: 'post' },
    },
    noModel: true,
    block: true,
  },

  // ----------------------------------------------------------------------------------------------
  // Data Service
  // ----------------------------------------------------------------------------------------------
  Data: {
    baseRoute: `/data/`,
    routeSuffixes: {
      get: { method: 'get' },
    },
    noModel: true,
  },
};

const baseModels = {};

// normalizr schemas are used in conjunction with RTK's createEntityAdapter to ensure data is normalized and predictable
const generateSchema = (name, definition = {}, options = {}) => {
  return new schema.Entity(name, definition, options);
};

// populate baseModels object with a base schema for each endpoint
for (const endpoint in endpoints) {
  if (!endpoints[endpoint].noModel) {
    baseModels[endpoint] = generateSchema(endpoint);
  }
}

// recursive function that builds a nested schema with the specified depth
const nestedSchemaBuilder = (baseModel, depth) => {
  if (depth > 0 && endpoints[baseModel].relatedModels) {
    // generate model relationships
    const definition = {};
    const options = {
      // https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#entitykey-definition---options--
      processStrategy: endpoints[baseModel].processStrategy,
      mergeStrategy: endpoints[baseModel].mergeStrategy,
    };

    // loop through each endpoint's relatedModels to populate definition object
    for (const _schema of endpoints[baseModel].relatedModels) {
      if (endpoints[baseModel].schemaUnions && _schema.field in endpoints[baseModel].schemaUnions) {
        definition[_schema.field] = new schema.Union(
          Object.entries(endpoints[baseModel].schemaUnions[_schema.field][0]).reduce(
            (acc, [k, v]) => {
              acc[k] = nestedSchemaBuilder(v, depth - 1);
              return acc;
            },
            {}
          ),
          endpoints[baseModel].schemaUnions[_schema.field][1]
        );
      } else if (_schema.many) {
        definition[_schema.field] = [nestedSchemaBuilder(_schema.modelName, depth - 1)];
      } else if (_schema.field) {
        definition[_schema.field] = nestedSchemaBuilder(_schema.modelName, depth - 1);
      }
    }

    // use options object to generate new model with proper relationships
    return generateSchema(baseModel, definition, options);
  } else {
    // if relatedModels not provided, just assign the base model to the endpoint
    return baseModels[baseModel];
  }
};

// Determines the depth of the built schema object, 5 is used for now to encompass everything
const schemaDepth = 2;

for (const baseModel in baseModels) {
  endpoints[baseModel].model = nestedSchemaBuilder(baseModel, schemaDepth);
}
