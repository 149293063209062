import Grid from '@material-ui/core/Grid';
import Dialog from 'components/general/dialogs/Dialog';
import GuidanceCard from 'components/general/GuidanceCard';
import WidgetTable from 'components/general/widgets/WidgetTable';

interface IProps {
  open: boolean;
  closeDialog: () => void;
  issues: string[];
}

const columns = [
  {
    title: 'Issues:',
    field: 'issue',
  },
];
const emptyMessage = 'No issues to address -- model ready to simulate.';

const Tier2ValidationDialog = ({ open, closeDialog, issues }: IProps) => {
  const data = issues.map((issue) => ({ issue }));

  return (
    <Dialog
      title="Simulateable Model Issues"
      large
      open={open}
      onClose={closeDialog}
      secondaryActionText="Close"
    >
      <Grid container spacing={1}>
        <Grid item md={8}>
          <WidgetTable data={data} columns={columns} emptyMessage={emptyMessage} noXray />
        </Grid>
        <Grid item md={4}>
          <GuidanceCard
            guidance={{
              heading: 'Addressing Issues',
              body: `All issues in this dialog must be addressed before you can simulate this model.
              Use the edit boards to address them.`,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Tier2ValidationDialog;
