import { Alert, AlertTitle } from '@material-ui/lab';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput/index.jsx';
import { useState } from 'react';
import useStyles from './styles';

const DeleteLayoutDialog = (props) => {
  const { layout, action, layoutNameText, onClose, open, doubleConfirmDelete } = props;
  const [deleteItemInput, setDeleteItemInput] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const classes = useStyles();

  const handleInputChange = (event) => {
    setDeleteItemInput(event.target.value);
  };

  const handleDelete = () => {
    if (doubleConfirmDelete) {
      if (deleteItemInput !== layout.name) {
        return;
      }
    }
    action();
  };

  return (
    <>
      <Dialog
        prompt={`Delete ${layoutNameText.toLowerCase()}`}
        submitActionText="Delete"
        open={open}
        onSubmit={handleDelete}
        onClose={() => {
          setErrorMessage(null);
          onClose();
        }}
      >
        {layout && (
          <p>
            Are you sure you'd like to delete {layoutNameText}: '{layout.name}'? This cannot be
            undone.
          </p>
        )}
        {layout && doubleConfirmDelete && (
          <>
            <p className={classes.note}>
              To confirm that you intend to delete, type "{layout.name}" below.
            </p>
            <LabeledInput
              type="text"
              placeholder={layoutNameText + ' Name'}
              onChange={handleInputChange}
              value={deleteItemInput}
            />
          </>
        )}
        {errorMessage && (
          <Alert severity="error" icon={false} variant="outlined" className={classes.error}>
            <AlertTitle className={classes.errorHeading}>Deletion Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </Dialog>
    </>
  );
};

export default DeleteLayoutDialog;
