import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import validation from './validation';
import useGuidance from './guidance';
import { ISurfaceMaterial } from 'components/general/types/spacecraft';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { InputAdornment } from '@material-ui/core';

interface IForm {
  partNumber: string;
  manufacturer: string;
  irEmissivity: number | '';
  solarAbsorptivity: number | '';
  diffuseSolarReflectivity: number | '';
  specularSolarReflectivity: number | '';
  hotTempRating: {degC: number | ''};
  coldTempRating: {degC: number | ''};
}

const defaultValues: IForm = {
  partNumber: '',
  manufacturer: '',
  irEmissivity: '',
  solarAbsorptivity: '',
  diffuseSolarReflectivity: '',
  specularSolarReflectivity: '',
  hotTempRating: {degC: 100},
  coldTempRating: {degC: -40},
};

const SurfaceMaterialDialog = (props: { control: TEntityDialogControl<ISurfaceMaterial> }) => {
  const { control } = props;

  const classes = useStyles();

  const entityForm = useEntityForm<ISurfaceMaterial, IForm>({
    entityTypeText: 'Surface Material',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      percentages: [
        'irEmissivity',
        'solarAbsorptivity',
        'diffuseSolarReflectivity',
        'specularSolarReflectivity',
      ],
    },
    additionalCreateValues: { type: 'SurfaceMaterial' },
  });

  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            label="Part Number"
            placeholder="Part Number"
            {...getFieldProps('partNumber')}
            autoFocus
          />
          <LabeledInput
            label="Manufacturer"
            placeholder="Manufacturer"
            {...getFieldProps('manufacturer')}
            optional
          />
          <LabeledInput
            label="IR Emissivity"
            placeholder="IR Emissivity"
            {...getFieldProps('irEmissivity')}
            type="number"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
          <LabeledInput
            label="Solar Absorptivity"
            placeholder="Solar Absorptivity"
            {...getFieldProps('solarAbsorptivity')}
            type="number"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
          <LabeledInput
            label="Diffuse Solar Reflectivity"
            placeholder="Diffuse Solar Reflectivity"
            {...getFieldProps('diffuseSolarReflectivity')}
            type="number"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
          <LabeledInput
            label="Specular Solar Reflectivity"
            placeholder="Specular Solar Reflectivity"
            {...getFieldProps('specularSolarReflectivity')}
            type="number"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
          <LabeledInput
            label="Hot Temperature Rating"
            placeholder="Hot Temperature Rating"
            {...getFieldProps('hotTempRating.degC')}
            type="number"
            endAdornment={<InputAdornment position="end">°C</InputAdornment>}
          />
          <LabeledInput
            label="Cold Temperature Rating"
            placeholder="Cold Temperature Rating"
            {...getFieldProps('coldTempRating.degC')}
            type="number"
            endAdornment={<InputAdornment position="end">°C</InputAdornment>}
          />
        </div>
      </div>
    </EntityDialog>
  );
};

export default SurfaceMaterialDialog;
