import { IGenericObject, IMenu, IMissionVersion } from 'components/general/types';
import { ITEMS } from 'config';
import Routes from 'routes';

// Menus from modules
import { mmTypesInfo } from 'utils/repoAndMmType';

const AgentTemplateEditMenu = (activeEntities?: IGenericObject) => {
  const branch = activeEntities?.branch as IMissionVersion;
  const menu = // Conditionally render the menu items based on the metamodelType
    (branch?.metamodelType ? mmTypesInfo[branch.metamodelType].menu : {}) as IMenu['items'];
  const dynamicItems = (
    branch?.metamodelType ? mmTypesInfo[branch.metamodelType].dynamicItems || [] : []
  ) as IMenu['dynamicItems'];

  return {
    routeFn: Routes.AGENT_TEMPLATE_EDIT,
    dynamicItems: dynamicItems,
    [ITEMS]: menu,
  } as IMenu;
};

export default AgentTemplateEditMenu;
