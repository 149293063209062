import GncAccent from 'components/general/Accent/variants/GncAccent';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IFuelReservoir } from 'components/general/types/gnc';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectBlocks } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useCallback, useMemo, useRef } from 'react';
import { translateOut } from 'utils/forms';
import { useGuidance } from './guidance';
import validation from './validation';

interface IProps {
  control: TEntityDialogControl<IFuelReservoir>;
}

interface IForm {
  name: string;
}

const defaultValues: IForm = {
  name: '',
};

const tableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Priority',
    field: 'priority',
  },
];
const extras = ['priority'];

const FuelReservoirDialog = (props: IProps) => {
  // Handle props
  const { control } = props;
  const {
    dialogConfig: { entity: fuelReservoir },
  } = control;
  const { fuelTanks, fuelReservoirs } = useActiveEntities();

  // Set up styles
  const classes = useStyles();

  // Prep fuel tanks
  const tableRef = useRef(null);
  // Filter out tanks that are used in other reservoirs
  const unusedTanks = useMemo(() => {
    const otherReservoirs = fuelReservoirs.filter(
      (reservoir) => reservoir.id !== fuelReservoir?.id
    );
    return fuelTanks.filter((tank) => {
      return !otherReservoirs.some((reservoir) => reservoir.fuelTanks.includes(tank));
    });
  }, [fuelTanks, fuelReservoir, fuelReservoirs]);

  const { parsedBlocks: parsedTanks, setParsedBlocks: setParsedTanks } = useSelectBlocks(
    unusedTanks,
    fuelReservoir?.fuelTanks,
    extras
  );

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      values.fuelTanks = parsedTanks
        .filter((tank) => tank.tableData?.checked)
        .map((tank) => tank.id);

      return translateOut(values, allowedEmptyFields, options);
    },
    [parsedTanks]
  );

  const entityForm = useEntityForm<IFuelReservoir, IForm>({
    entityTypeText: 'Fuel Reservoir',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    additionalCreateValues: { type: 'FuelReservoir' },
    formikOptionalParams: {
      useGuidance,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, setFieldValue } = formik;

  return (
    <EntityDialog entityForm={entityForm} customDirty={true}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Reservoir Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <GncAccent header={'Fuel Tanks'}>
          <WidgetTable
            tableRef={tableRef}
            className={classes.table}
            columns={tableColumns}
            data={parsedTanks}
            setData={setParsedTanks}
            emptyMessage={'No unassigned fuel tanks found'}
            title="Select Fuel Tanks"
            search={true}
            selection={true}
            // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
            // It is used through a level of indirection here to set the value on the form data and therefore mark
            // the form as dirty. This will enable the save button for the form.
            onSelectionChange={(newTableData) => setFieldValue('fuelTanks', newTableData)}
          />
        </GncAccent>
      </div>
    </EntityDialog>
  );
};

export default FuelReservoirDialog;
