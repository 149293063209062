import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { ClearAll, ExitToApp } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import {
  setActiveMissionVersionId,
  setActiveWorkspaceId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';
import ProjectDialog from 'components/WorkspaceView/ReposBoard/ProjectDialog';
import RepoDialog from 'components/WorkspaceView/ReposBoard/RepoDialog';
import RepoRow from 'components/WorkspaceView/ReposBoard/RepoRow';
import LoadingInlay from 'components/general/LoadingInlay';
import Nav from 'components/general/Nav';
import RowedExplorer from 'components/general/RowedExplorer';
import StyledButton from 'components/general/StyledButton';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import ViewPortInlay from 'components/general/ViewPortInlay';
import { IErrorResponse, ILicense, IMission, IProject } from 'components/general/types';
import { ItemTypes, hotkeys } from 'config';
import {
  useEntityDialogControl,
  usePermissionCheck,
  useSelectById,
  useSelectByIds,
  useSnackbar,
} from 'hooks';
import useMountStatus from 'hooks/useMountStatus';
import useWorkspace from 'hooks/useWorkspace';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import moment from 'moment';
import stackImg from 'multimedia/icons/project.png';
import { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Routes from 'routes';
import { WorkspaceVables } from 'utils/vable';
import useStyles from './styles';

const ProjectView = () => {
  // Utils
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useMountStatus();

  // Workspace
  const workspace = useWorkspace();
  const license = useSelectById('License', workspace?.license) as ILicense;
  const canEdit = usePermissionCheck(WorkspaceVables.Permission.EDIT_REPOSITORY);
  const goToWorkspace = () => history.push(Routes.WORKSPACE(workspace.id, 'repositories'));
  useHotkeys(hotkeys.WORKSPACE.keys, goToWorkspace, [goToWorkspace]);

  // Project
  const { id: projectId } = useParams<{ id: string }>();
  const project = useSelectById('Project', projectId);
  const projectDialogControl = useEntityDialogControl<IProject>();
  const { openDialogForExisting: openDialogForExistingProject } = projectDialogControl;
  const { Project } = SatelliteApi;

  // Repos
  const repos = useSelectByIds('Mission', workspace?.repositories || []).filter(
    (repo) => repo.project === projectId
  );
  const repoDialogControl = useEntityDialogControl<IMission>();

  // Api caller
  const fetchProject = useCallback(() => {
    setLoading(true);
    dispatch(
      Project.actions.getProject({
        id: projectId,
        successCallback: () => setLoading(false),
        failureCallback: (response: IErrorResponse) => {
          if (isMounted()) {
            const message =
              response.error.code === 'RESOURCE_NOT_FOUND'
                ? 'The project does not exist or is not accessible from this account. Please select a different resource.'
                : response.error.message;
            enqueueSnackbar(message);
            setLoading(false);
            if (workspace) history.replace(Routes.WORKSPACE(workspace.id, 'repositories'));
            else history.replace(Routes.ROOT());
          }
        },
      })
    );
  }, [dispatch, Project, enqueueSnackbar, history, projectId, workspace, isMounted]);

  // Reset current branch to none
  useEffect(() => {
    dispatch(setActiveMissionVersionId(undefined));
    dispatch(setLatestJobId(undefined));
  }, [dispatch]);

  // Set active workspace
  useEffect(() => {
    if (project?.workspace) {
      dispatch(setActiveWorkspaceId(project.workspace));
    }
  }, [project?.workspace, dispatch]);

  // Fetch project
  useEffect(() => {
    if (loading || (project && project.repositories.length === repos.length)) return;
    // if project or any of its repos not in store (condition above not met), fetch project from backend
    fetchProject();
  }, [loading, project, fetchProject, repos.length]);

  // Drag and drop hook
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.REPO,
      canDrop: () => canEdit,
      drop: () => ({ id: 'workspace' }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [canEdit]
  );
  const [dragging, setDragging] = useState(false);
  const classes = useStyles({ highlight: isOver && canEdit });

  return (
    <ViewContainer>
      <Nav
        contextName="Project"
        contextImg={stackImg}
        contextHeader={workspace?.name}
        contextSubHeader={
          license &&
          `License expire${moment(license.dateExpires).unix() > moment.now() / 1000 ? 's' : 'd'
          }: ${moment(license.dateExpires).local().format('MMM Do, YYYY')}`
        }
        control={
          canEdit ? (
            <>
              <StyledButton
                type="button"
                onClick={() => openDialogForExistingProject(project, 'edit')}
                fullWidth
                style={{ marginTop: '10px' }}
              >
                <EditIcon />
                Edit Project
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => openDialogForExistingProject(project, 'delete')}
                fullWidth
                framed
              >
                <DeleteIcon />
                Delete Project
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => openDialogForExistingProject(project, 'clear')}
                fullWidth
                framed
              >
                <ClearAll />
                Clear Project
              </StyledButton>
            </>
          ) : undefined
        }
      >
        <p>Select a repository to view its branches.</p>
        <div
          ref={drop}
          className={clsx(classes.returnToWorkspaceContainer, classes.fadeIn, classes.fadeOut)}
          style={{
            opacity: dragging ? 1 : 0,
          }}
        >
          <h4>Move to workspace</h4>
          <ExitToApp fontSize="large" style={{ padding: '10px', transform: 'scaleX(-1)' }} />
        </div>
      </Nav>
      <ViewPort>
        <RowedExplorer
          name={project?.name}
          description={project?.description}
          rowsTitle="Repositories"
          primaryButtonProps={
            canEdit
              ? {
                  icon: faAdd,
                  onClick: repoDialogControl.openDialogForNew,
                  tooltip: 'Create repository',
                }
              : undefined
          }
          secondaryButtonProps={{
            icon: faRefresh,
            onClick: fetchProject,
            tooltip: 'Refresh repositories',
          }}
          backButtonProps={{
            text: 'Back to Workspace',
            onClick: goToWorkspace,
          }}
        >
          {loading ? (
            <LoadingInlay text="Your project is loading" />
          ) : repos.length ? (
            repos.map((repo) => (
              <RepoRow
                key={repo.id}
                repo={repo}
                repoDialogControl={repoDialogControl}
                draggable={canEdit}
                setDragging={setDragging}
              />
            ))
          ) : (
            <ViewPortInlay text="No Repositories." />
          )}
        </RowedExplorer>
        <RepoDialog control={repoDialogControl} project={project} />
        <ProjectDialog control={projectDialogControl} />
      </ViewPort>
    </ViewContainer>
  );
};

export default ProjectView;
