import { IJob } from 'components/general/types';
import { useSelector } from 'react-redux';
import { useSelectById } from './stateHooks';

const empty = {} as IJob; // empty object to return if no latest job.  Not inlinded in order to avoid rerenders

// Returns the latest job in a simulation, based on a redux value that is set in ActiveBranchProvider
// (or reset by SimulationControls in ScenarioView)
const useLatestJob = () => {
  // TS ignore because we added ui to state
  //@ts-ignore: next-line
  const latestJobId = useSelector((state) => state.ui.missionExplorer.latestJobId);
  const latestJob = useSelectById('Job', latestJobId) as IJob;

  // If latestJobId === undefined, Job fetch either hasn't happened or is still loading
  // If latestJobId === null, there is no latest job on this scenario branch
  return latestJobId === null ? empty : latestJob;
};

export default useLatestJob;
