import BaseNode from '../general/BaseNode';
import GNCNodeHandle, { Position } from '../general/GNCNodeHandle';
import { ISensorNodeData } from '../general/types';
import Conditions from './Conditions';

const SensorNode = ({ data }: ISensorNodeData) => {
  return (
    <BaseNode title={data.title} subtitle={data.subtitle}>
      <Conditions legend="Condition" conditions={data.conditions} />

      <GNCNodeHandle type="source" position={Position.Right} />
    </BaseNode>
  );
};

export default SensorNode;
