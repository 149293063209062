import { TChartSpec } from 'components/general/types';

const statsAttitudeControlChartSpec: TChartSpec = [
  {
    name: 'Reaction Wheel Utilization',
    plots: [
      {
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            legend: ['{name}'],
            keys: ['ReactionWheel.$each.powerConsumed'],
            ops: ['integral'],
          },
        ],
      },
    ],
  },
  {
    name: 'Magnetorquer Utilization',
    plots: [
      {
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            legend: ['{name}'],
            keys: ['Magnetorquer.$each.powerConsumed'],
            ops: ['integral'],
          },
        ],
      },
    ],
  },
];

export default statsAttitudeControlChartSpec;
