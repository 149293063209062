import { makeGuidance } from 'hooks';

// TODO
const agentGroupsHeading = 'Create and Edit Agent Groups';

const agentGroupsMainParagraph =
  'Agent Groups are created to populate the Target Groups of an Agent or Agents. This allows the agent to easily treat similar targets in a consistent manner. \nFor example, an imaging spacecraft may point at a Target Group for its downlink mode; the user would map the Target Group to an Agent Group that contains each ground station that can be downlinked to.\nIt is safe to pool Agents that will target each other into an Agent Group; if an Agent is mapped to an Agent Group that contains it, it will ignore itself and use all other Agents to populate the Target Group.';

export const agentGroupsGuidance = {
  _default: {
    heading: agentGroupsHeading,
    body: [
      {
        chunk:
          'Give the agent group a descriptive name. Select the type of agents and then add agents of that type, dragging to arrange them in order of priority. \nPresently, priority is static, but soon Agents will be able to sort targets by a user-defined parameter to determine priority.',
      },
    ],
  },
};

export const useGuidance = makeGuidance(agentGroupsGuidance);

const agentGroupsSegmentGuidance = {
  heading: 'Agent Groups',
  body: [
    {
      chunk: agentGroupsMainParagraph,
    },
  ],
};

export default agentGroupsSegmentGuidance;
