import { InputAdornment } from '@material-ui/core';
import GncAccent from 'components/general/Accent/variants/GncAccent';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IManySideData, ISelectOption } from 'components/general/types';
import { IActuator, IAlgorithm, ISensor } from 'components/general/types/gnc';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectBlocks } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useCallback, useMemo, useRef, useState } from 'react';
import { translateIn, translateOut } from 'utils/forms';
import { ActuatorVables, AlgorithmVables, isAc, isAd, isOd, isTc } from 'utils/vable';
import { useGuidance } from './guidance';
import validation from './validation';

interface IProps {
  control: TEntityDialogControl<IAlgorithm>;
}

interface IForm {
  name: string;
  rate: number | '';
  actuators?: IActuator[];
  type: ISelectOption | '';
  angularVelocitySensors?: ISensor[];
  gainK: number | '';
  gainG: number | '';
  gainC: number | '';
  epsilon: number | '';
  gainP: number | '';
  gainI: number | '';
  gainD: number | '';
  opticalAttitudeSensors?: ISensor[];
  positionSensor: ISelectOption | '';
  positionSensors?: ISensor[];
  vectorSensors?: ISensor[];
  thrusterField: IActuator[] | '';
  thrusts: number[] | '';
}

const defaultValues: IForm = {
  name: '',
  rate: '',
  type: '',
  gainK: '',
  gainG: '',
  gainC: '',
  gainP: '',
  gainI: '',
  gainD: '',
  epsilon: '',
  positionSensor: '',
  thrusterField: '',
  thrusts: '',
};

const tableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

const AlgorithmDialog = (props: IProps) => {
  // Handle props
  const { control } = props;
  const {
    dialogConfig: { entity: algorithm, action },
  } = control;
  const {
    actuators,
    angularVelocitySensors,
    directionSensors,
    vectorSensors,
    opticalAttitudeSensors,
    positionSensors,
  } = useActiveEntities();

  // Set up styles
  const classes = useStyles();

  /*********************************************************************************************/
  /***************************************** RELATIONS *****************************************/
  /*********************************************************************************************/
  const [thrusterNames, setNames] = useState<string[]>(
    () => algorithm?.thrusters?.map((t) => t.name) || []
  );

  // Prep actuators
  const actuatorTableRef = useRef(null);
  const {
    initBlocks: initActuators,
    parsedBlocks: parsedActuators,
    setParsedBlocks: setParsedActuators,
  } = useSelectBlocks(actuators, algorithm?.actuators);

  // Prep angular velocity sensors
  const angularVelocitySensorTableRef = useRef(null);
  const {
    initBlocks: initAngularVelocitySensors,
    parsedBlocks: parsedAngularVelocitySensors,
    setParsedBlocks: setParsedAngularVelocitySensors,
  } = useSelectBlocks(angularVelocitySensors, algorithm?.angularVelocitySensors);

  // Prep optical attitude sensors
  const opticalAttitudeSensorTableRef = useRef(null);
  const {
    initBlocks: initOpticalAttitudeSensors,
    parsedBlocks: parsedOpticalAttitudeSensors,
    setParsedBlocks: setParsedOpticalAttitudeSensors,
  } = useSelectBlocks(opticalAttitudeSensors, algorithm?.opticalAttitudeSensors);

  // Prep position sensors
  const positionSensorTableRef = useRef(null);
  const {
    initBlocks: initPositionSensors,
    parsedBlocks: parsedPositionSensors,
    setParsedBlocks: setParsedPositionSensors,
  } = useSelectBlocks(positionSensors, algorithm?.positionSensors);

  // Prep direction/vector sensors
  const vectorSensorTableRef = useRef(null);
  const vectorAndDirection = useMemo(() => {
    return vectorSensors.concat(directionSensors);
  }, [vectorSensors, directionSensors]);

  const {
    initBlocks: initVectorSensors,
    parsedBlocks: parsedVectorSensors,
    setParsedBlocks: setParsedVectorSensors,
  } = useSelectBlocks(vectorAndDirection, algorithm?.vectorSensors);

  // Prep thrusters
  const thrusterTableRef = useRef(null);
  const getThrusters = useMemo(
    () => actuators.filter((a) => a.type === ActuatorVables.Type.Thruster.value),
    [actuators]
  );
  const {
    initBlocks: initThrusters,
    parsedBlocks: parsedThrusters,
    setParsedBlocks: setParsedThrusters,
  } = useSelectBlocks(getThrusters, algorithm?.thrusters);

  // Set up select options
  const options = useMemo(
    () => ({
      type: AlgorithmVables.Type.options,
      positionSensor: positionSensors.map((c) => {
        return { value: c.id, label: c.name };
      }),
    }),
    [positionSensors]
  );

  /*********************************************************************************************/
  /*********************************************************************************************/

  // REF: positionSensor
  // GPS Orbit Determination algorithms have one positionSensor
  // Translate that into its own field here so it can be interacted with as a LabeledSelect
  const customTranslateIn = useCallback((algorithm, defaultValues, options) => {
    if (
      algorithm.type === AlgorithmVables.Type.GpsAlgorithm.value &&
      algorithm.positionSensors.length > 0
    )
      algorithm.positionSensor = algorithm.positionSensors[0].id;
    // Translate in manySideData for thruster algorithm
    if (algorithm.thrusters) {
      algorithm.thrusterField = [];
      algorithm.thrusts = [];
      algorithm.thrusters.forEach((thruster: IActuator & IManySideData) => {
        algorithm.thrusterField.push({ id: thruster.id });
        algorithm.thrusts.push(thruster.manySideData.thrust);
      });
    }
    return translateIn(algorithm, defaultValues, options);
  }, []);

  const customTranslateOut = useCallback(
    (algorithm, allowedEmptyFields, options) => {
      // REF: positionSensor
      // ... and translate (positionSensor = sensor) back out into (positionSensors = [sensor])
      if (
        algorithm.type.value === AlgorithmVables.Type.GpsAlgorithm.value &&
        algorithm.positionSensor
      )
        algorithm.positionSensors = [algorithm.positionSensor.value];
      else if (algorithm.positionSensors)
        algorithm.positionSensors = parsedPositionSensors
          .filter((positionSensor) => positionSensor.tableData?.checked)
          .map((positionSensor) => positionSensor.id);

      if (algorithm.actuators)
        algorithm.actuators = parsedActuators
          .filter((actuator) => actuator.tableData?.checked)
          .map((actuator) => actuator.id);
      if (algorithm.angularVelocitySensors)
        algorithm.angularVelocitySensors = parsedAngularVelocitySensors
          .filter((angularVelocitySensor) => angularVelocitySensor.tableData?.checked)
          .map((angularVelocitySensor) => angularVelocitySensor.id);
      if (algorithm.opticalAttitudeSensors)
        algorithm.opticalAttitudeSensors = parsedOpticalAttitudeSensors
          .filter((opticalAttitudeSensor) => opticalAttitudeSensor.tableData?.checked)
          .map((opticalAttitudeSensor) => opticalAttitudeSensor.id);
      if (algorithm.vectorSensors)
        algorithm.vectorSensors = parsedVectorSensors
          .filter((vectorSensor) => vectorSensor.tableData?.checked)
          .map((vectorSensor) => vectorSensor.id);
      if (algorithm.thrusterField) {
        const thrusterIds = parsedThrusters
          .filter((thruster) => thruster.tableData?.checked)
          .map((thruster) => thruster.id);
        const thrusts = algorithm.thrusts;
        const result = translateOut(algorithm, allowedEmptyFields, options);
        result.thrusters = {};
        thrusterIds.forEach((id, index) => {
          result.thrusters[id] = { thrust: thrusts[index] };
        });
        return result;
      }
      return translateOut(algorithm, allowedEmptyFields, options);
    },
    [
      parsedActuators,
      parsedPositionSensors,
      parsedAngularVelocitySensors,
      parsedOpticalAttitudeSensors,
      parsedVectorSensors,
      parsedThrusters,
    ]
  );

  const entityForm = useEntityForm<IAlgorithm, IForm>({
    entityTypeText: 'Algorithm',
    entityDialogControl: control,
    defaultValues,
    valuesToRemove: ['thrusterField', 'thrusts'],
    validationSchema: validation,
    extendReset: () => {
      initActuators();
      initAngularVelocitySensors();
      initOpticalAttitudeSensors();
      initPositionSensors();
      initVectorSensors();
      initThrusters();
    },
    formikOptionalParams: {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setFieldValue } = formik;

  return (
    <EntityDialog entityForm={entityForm} xlarge>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Algorithm Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          {/* <LabeledInput  // TODO: Re-enable this when update rate works
            {...getFieldProps('rate')}
            label="Update Rate"
            type="number"
            endAdornment={<InputAdornment position="end">Hz</InputAdornment>}
          /> */}
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('type')}
            label="Algorithm Type"
            options={options.type}
            isDisabled={action !== 'create'}
            formikOnChange={(selection: ISelectOption) => {
              setFieldValue('type', selection);
            }}
            updateGuidanceOnChange
          />
          {values.type && (
            <div className={classes.indent}>
              {values.type === AlgorithmVables.Type.SlidingModeAlgorithm && (
                <div className={classes.indent}>
                  <LabeledInput {...getFieldProps('gainK')} label="Gain K" type="number" />
                  <LabeledInput {...getFieldProps('gainG')} label="Gain G" type="number" />
                  <LabeledInput {...getFieldProps('gainC')} label="Gain C" type="number" />
                  <LabeledInput {...getFieldProps('epsilon')} label="Epsilon" type="number" />
                </div>
              )}
              {values.type === AlgorithmVables.Type.PidAlgorithm && (
                <div className={classes.indent}>
                  <LabeledInput {...getFieldProps('gainP')} label="Gain P" type="number" />
                  <LabeledInput {...getFieldProps('gainI')} label="Gain I" type="number" />
                  <LabeledInput {...getFieldProps('gainD')} label="Gain D" type="number" />
                  <LabeledInput {...getFieldProps('gainC')} label="Gain C" type="number" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isAc(values) && (
        <GncAccent header={'Actuators'}>
          <WidgetTable
            tableRef={actuatorTableRef}
            className={classes.table}
            columns={tableColumns}
            data={parsedActuators}
            setData={setParsedActuators}
            emptyMessage={'No actuators found'}
            title="Select Actuators"
            search={true}
            selection={true} // REF 2
            // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
            // It is used through a level of indirection here to set the value on the form data and therefore mark
            // the form as dirty. This will enable the save button for the form.
            onSelectionChange={(newTableData) => setFieldValue('actuators', newTableData)}
          />
        </GncAccent>
      )}
      {isAd(values) && (
        <>
          <GncAccent header={'Angular Velocity Sensors'}>
            <WidgetTable
              tableRef={angularVelocitySensorTableRef}
              className={classes.table}
              columns={tableColumns}
              data={parsedAngularVelocitySensors}
              setData={setParsedAngularVelocitySensors}
              emptyMessage={'No angular velocity sensors found'}
              title="Select Angular Velocity Sensors"
              search={true}
              selection={true}
              onSelectionChange={(
                newTableData // REF 2
              ) => setFieldValue('angularVelocitySensors', newTableData)}
            />
          </GncAccent>
          {(values.type === AlgorithmVables.Type.AveragingAlgorithm ||
            values.type === AlgorithmVables.Type.MekfAlgorithm) && (
            <GncAccent header={'Optical Attitude Sensors'}>
              <WidgetTable
                tableRef={opticalAttitudeSensorTableRef}
                className={classes.table}
                columns={tableColumns}
                data={parsedOpticalAttitudeSensors}
                setData={setParsedOpticalAttitudeSensors}
                emptyMessage={'No optical attitude sensors found'}
                title="Select Optical Attitude Sensors"
                search={true}
                selection={true}
                onSelectionChange={(
                  newTableData // REF 2
                ) => setFieldValue('opticalAttitudeSensors', newTableData)}
              />
            </GncAccent>
          )}
          {values.type === AlgorithmVables.Type.TriadAlgorithm && (
            <GncAccent header={'Vector and Direction Sensors'}>
              <WidgetTable
                tableRef={vectorSensorTableRef}
                className={classes.table}
                columns={tableColumns}
                data={parsedVectorSensors}
                setData={setParsedVectorSensors}
                emptyMessage={'No vector sensors found'}
                title="Select Vector Sensors"
                search={true}
                selection={true}
                onSelectionChange={(newTableData) => setFieldValue('vectorSensors', newTableData)} // REF 2
              />
            </GncAccent>
          )}
        </>
      )}
      {isOd(values) && (
        <>
          {values.type === AlgorithmVables.Type.EkfAlgorithm && (
            <GncAccent header={'Position Sensors'}>
              <WidgetTable
                tableRef={positionSensorTableRef}
                className={classes.table}
                columns={tableColumns}
                data={parsedPositionSensors}
                setData={setParsedPositionSensors}
                emptyMessage={'No position sensors found'}
                title="Select Position Sensors"
                search={true}
                selection={true}
                onSelectionChange={(newTableData) => setFieldValue('positionSensors', newTableData)} // REF 2
              />
            </GncAccent>
          )}
          {values.type === AlgorithmVables.Type.GpsAlgorithm && (
            <div className={classes.inputs}>
              <GncAccent header={'Position Sensor'}>
                <LabeledSelect
                  {...getFieldProps('positionSensor')}
                  options={options.positionSensor}
                />
              </GncAccent>
            </div>
          )}
        </>
      )}
      {isTc(values) && (
        <>
          {values.type === AlgorithmVables.Type.StaticThrustControlAlgorithm && (
            <div className={classes.inputs}>
              <GncAccent header={'Thrusters'}>
                <WidgetTable
                  tableRef={thrusterTableRef}
                  className={classes.table}
                  columns={tableColumns}
                  data={parsedThrusters}
                  setData={setParsedThrusters}
                  emptyMessage={'No thrusters found'}
                  title="Select Thrusters"
                  search={true}
                  selection={true}
                  onSelectionChange={(newTableData) => {
                    // REF 2 and get the thruster names
                    setNames(
                      parsedThrusters
                        .filter((thruster) => thruster.tableData?.checked)
                        .map((thruster) => thruster.name)
                    );
                    return setFieldValue('thrusterField', newTableData);
                  }}
                />
              </GncAccent>
            </div>
          )}
          {values.type === AlgorithmVables.Type.StaticThrustControlAlgorithm &&
            thrusterNames.map((thruster, index) => {
              return (
                <LabeledInput
                  label={`"${thruster}" Thrust`}
                  {...getFieldProps(`thrusts.${index}`)}
                  type="number"
                  key={index}
                  endAdornment={<InputAdornment position="end">N</InputAdornment>}
                />
              );
            })}
        </>
      )}
    </EntityDialog>
  );
};

export default AlgorithmDialog;
