import { InputAdornment } from '@material-ui/core';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { IFieldOfView } from 'components/general/types/spacecraft';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useMemo } from 'react';
import { FieldOfViewVables } from 'utils/vable';
import { useGuidance } from './guidance';
import validation from './validation';

interface IProps {
  control: TEntityDialogControl<IFieldOfView>;
}

interface IForm {
  boresightBodyFrameVector: ISelectOption | '';
  type: ISelectOption | '';
  halfAngle: {deg: number | ''};
  heightBodyFrameVector: ISelectOption | '';
  heightHalfAngle: {deg: number | ''};
  widthHalfAngle: {deg: number | ''};
  name: string;
}

const defaultValues: IForm = {
  boresightBodyFrameVector: '',
  type: '',
  halfAngle: {deg: ''},
  heightBodyFrameVector: '',
  heightHalfAngle: {deg: ''},
  widthHalfAngle: {deg: ''},
  name: '',
};

const FieldOfViewDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action },
  } = control;

  const classes = useStyles();
  const { bodyFrameVectors } = useActiveEntities();
  const bodyFrameVectorsList = useMemo(
    () => bodyFrameVectors.map((bfv) => ({ value: bfv.id, label: bfv.name })),
    [bodyFrameVectors]
  );

  const options = useMemo(() => {
    return {
      boresightBodyFrameVector: bodyFrameVectorsList,
      heightBodyFrameVector: bodyFrameVectorsList,
      type: FieldOfViewVables.Type.options,
    };
  }, [bodyFrameVectorsList]);

  const entityForm = useEntityForm<IFieldOfView, IForm>({
    entityTypeText: 'Field Of View',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    editAfterCreate: true,
    formikOptionalParams: { useGuidance, options },
  });

  const { formik } = entityForm;
  const { values, getFieldProps } = formik;

  return (
    <>
      <EntityDialog entityForm={entityForm}>
        <div className={classes.inputs}>
          <div className={classes.inputGroup}>
            <LabeledInput
              label="Field of View Name"
              placeholder="Field of View Name"
              {...getFieldProps('name')}
              autoFocus
            />
          </div>
          <div className={classes.inputGroup}>
            <LabeledSelect
              label="Boresight Body Frame Vector"
              options={options.boresightBodyFrameVector}
              {...getFieldProps('boresightBodyFrameVector')}
              noOptionsMessage={() => 'Create a Body Frame Vector'}
            />
          </div>
          <div className={classes.inputGroup}>
            <LabeledSelect
              {...getFieldProps('type')}
              label="Field of View Type"
              options={options.type}
              isDisabled={action !== 'create'}
            />
            <div className={classes.indent}>
              {values.type === FieldOfViewVables.Type.RectangularFieldOfView && (
                <>
                  <LabeledSelect
                    label="Vertical Axis Body Frame Vector"
                    options={options.heightBodyFrameVector}
                    {...getFieldProps('heightBodyFrameVector')}
                    noOptionsMessage={() => 'Create a Body Frame Vector'}
                  />
                  <LabeledInput
                    {...getFieldProps('heightHalfAngle.deg')}
                    label="Height Half Angle"
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  />
                  <LabeledInput
                    {...getFieldProps('widthHalfAngle.deg')}
                    label="Width Half Angle"
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  />
                </>
              )}
              {values.type === FieldOfViewVables.Type.CircularFieldOfView && (
                <LabeledInput
                  {...getFieldProps('halfAngle.deg')}
                  label="Conical Half Angle"
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                />
              )}
            </div>
          </div>
        </div>
      </EntityDialog>
    </>
  );
};

export default FieldOfViewDialog;
