import { IColumn } from 'components/general/types';
import { IDataInterface } from 'components/general/types/dataHandling';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import DataInterfaceDialog from './DataInterfaceDialog';
import guidance from './guidance';

interface IProps {
  index: string;
}

const dataInterfacesColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
];

const DataInterfaceSegment = ({ index }: IProps) => {
  const { dataInterfaces } = useActiveEntities();

  return (
    <EntityTableSegment<IDataInterface>
      title="Data Interfaces"
      index={index}
      guidance={guidance}
      entityColumns={dataInterfacesColumns}
      entityData={dataInterfaces}
      DialogComponent={DataInterfaceDialog}
      modelName="data interface"
    ></EntityTableSegment>
  );
};

export default DataInterfaceSegment;
