import { IColumn } from 'components/general/types';
import { IDataHandlingDevice } from 'components/general/types/dataHandling';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import DeviceDialog from './DeviceDialog';
import guidance from './guidance';

interface IProps {
  index: string;
}

const devicesColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
];

const DeviceSegment = ({ index }: IProps) => {
  const { dataHandlingDevices } = useActiveEntities();

  return (
    <EntityTableSegment<IDataHandlingDevice>
      title="Data Handling Devices"
      index={index}
      guidance={guidance}
      entityColumns={devicesColumns}
      entityData={dataHandlingDevices}
      DialogComponent={DeviceDialog}
      modelName="device"
    ></EntityTableSegment>
  );
};

export default DeviceSegment;
