import { useCallback, useContext, useMemo } from 'react';
//@ts-ignore
import { ILink, INode } from '@nivo/sankey';
//@ts-ignore
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BasicTooltip } from '@nivo/tooltip';
import CustomLegend from 'components/general/CustomLegend';
import SankeyDiagram from 'components/general/SankeyDiagram';
import { IComponent } from 'components/general/types/power';
import ViewPortInlay from 'components/general/ViewPortInlay';
import Widget from 'components/general/widgets/Widget';
import { MomentContext } from 'providers';
import theme from 'theme';
import { ComponentVables } from 'utils/vable';
import { colorMap, ISankeyData, useSankeySeriesData } from './data';
import useStyles from './styles';

// Helper function to not show labels being cut off on end nodes
const truncateString = (label: string, limit: number) => {
  return label.length > limit ? label.slice(0, limit) + '...' : label;
};

const legendKeys = [
  { label: 'Panel', color: 'rgb(244, 196, 48)' },
  { label: 'Array', color: 'rgb(228,208, 100)' },
  { label: 'Power Processor (PP)', color: theme.palette.primary.dark },
  { label: 'Subsystem', color: 'rgb(84, 143, 89)' },
  { label: 'Component', color: 'rgb(107, 142, 78)' },
  { label: 'Battery', color: 'rgb(125, 92, 217)' },
];

interface ILinkTooltip {
  link: ILink;
}

// Node Tooltip is needed to be able to use the truncate string functions above
// Nivo seems to edit the actual node object in the custom label function used below
// So a workaround is to have two different values, one for the tooltip label and one for the regulatr
interface INodeTooltip {
  node: INode;
}

// Custom tooltip to display the power and units
const LinkTooltip = (props: ILinkTooltip) => {
  return <BasicTooltip id="Power" value={`${props.link.value} W`} />;
};

const NodeTooltip = ({ node }: INodeTooltip) => {
  return <BasicTooltip id="Label" value={node.tooltipLabel ?? node.label} />;
};

const ElectricalPowerFlow = () => {
  const { model } = useContext(MomentContext);
  const components = model.Component.all() as IComponent[];
  const solarPanels = useMemo(
    () => components.filter((c) => c.type === ComponentVables.Type.SolarPanel.value),
    [components]
  );

  // Height will be scaled based on the maximum between the number of solar panels or components
  // NOTE: object.keys is only used currently with mock data. Once actual data is used then the component will have access to the arrays from the store to just check the length of
  // Also added a minimum height in case very minimal EB simulations are ran
  const height = useMemo(
    () => Math.max(Math.max(components.length, solarPanels.length) * 20, 250),
    [components, solarPanels]
  );

  const classes = useStyles({ height });

  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const xlScreen = useMediaQuery(theme.breakpoints.up('xl'));

  const truncateStringsAtBreakpoints = useCallback(
    (label) => {
      if (xlScreen) return truncateString(label, 22);
      if (mediumScreen) return truncateString(label, 15);
      return truncateString(label, 12);
    },
    [mediumScreen, xlScreen]
  );

  // data will be created based off of solar panels, arrays, subsystems, components, and their respective loads along with the battery
  // All these entities will be retrieved from the store or props
  // Series data only needs to be created once on load
  const sankeyData: ISankeyData = useSankeySeriesData(model);

  return (
    <Widget
      collapsibleConfig
      title="Electrical Power Flow"
      subtitle="Flow of electrical power throughout satellite"
      minWidth={650}
    >
      <div className={classes.sankeyWrapper}>
        <CustomLegend data={legendKeys} />
        {sankeyData.powerGenerated !== 0 ? (
          <SankeyDiagram
            data={sankeyData.sankeySeriesData}
            colors={(node: INode) => {
              return colorMap[node.type as keyof typeof colorMap];
            }}
            // Truncate labels if the nodes are on the end so they will not be cutoff
            labels={(l: ILink) => {
              if (l.type === 'panel' || l.type === 'component') {
                l.tooltipLabel = l.label;
                return truncateStringsAtBreakpoints(l.label);
              }
              return l.label;
            }}
            linkTooltip={LinkTooltip}
            nodeTooltip={NodeTooltip}
          />
        ) : (
          <ViewPortInlay text="No energy flow at the current timestep." />
        )}
      </div>
    </Widget>
  );
};

export default ElectricalPowerFlow;
