import { AxisTickProps } from '@nivo/axes';
import { ResponsiveBar } from '@nivo/bar';
import {
  IPowerBarGraphProps,
  TExtendedPowerBarDatum,
} from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import { useCallback, useMemo, useState } from 'react';
import theme from 'theme';
import CustomTick from './CustomTick';
import HoverHighlightLayer from './HoverHighlightLayer';
import InfoLabelLayer from './InfoLabelLayer';
import { barTheme } from './styles';

// Defines the max height for the graph, currently 3000 to essentially disable max height
// NOTE: Infinity is not a valid entry here because this is used as a CSS height input, which will throw an error if Infinity is used
const defaultMaxHeight = 3000;

// Height of bars in chart
const barHeight = 25;

// Margin between label and end of bars
const labelMargin = 4;

// Margins for top and bottom of chart area, it's helpful to define them here so they can also be included in CSS height declaration below
const topMargin = 16;
const bottomMargin = 24;

// Constants used to calculate side margins
// Distance between graph labels/bars and ticks/expand icons
const graphPadding = 12;
// Width of expansion icon and its padding/margin
const expandWidth = 24;

const barPalette = barTheme.bars.palette;

const PowerBarGraph = (props: IPowerBarGraphProps) => {
  const { colorBySign, baseUnit, height, maxHeight, data, title } = props;
  const [[leftMaxLabelWidth, rightMaxLabelWidth], setMaxLabelWidths] = useState<number[]>([0, 0]);
  const [maxTickWidth, setMaxTickWidth] = useState<number>(0);
  const [barData, setBarData] = useState<TExtendedPowerBarDatum[]>(
    data.map(
      (d, i) =>
        // adding 1 to data.indexInBars because nivo will delete data fields with values of 0
        ({ ...d, indexInBars: i + 1, visible: 1, barName: d.barName + '|' + d.hierarchy }) // Quick fix for items with the same name
    )
  );
  // Finds side margins based on widest possible values and labels, to prevent overflow
  const [leftMargin, rightMargin] = useMemo(
    () => [
      leftMaxLabelWidth + maxTickWidth + graphPadding,
      rightMaxLabelWidth + expandWidth + graphPadding,
    ],
    [leftMaxLabelWidth, rightMaxLabelWidth, maxTickWidth]
  );
  const colorSelector = useCallback(
    (bar) => {
      if (colorBySign)
        return bar.value >= 0
          ? barPalette.booleanColors.positive
          : barPalette.booleanColors.negative;
      return (
        barPalette.barColors?.[bar.data.hierarchy - 1] || theme.palette.background.contrastText
      );
    },
    [colorBySign]
  );

  // maps bar names to their indexInBars so that each CustomTick has access to their bar's data
  const nameIndexMap = useMemo(() => {
    const obj: { [key: string]: number } = {};
    barData.forEach((datum) => (obj[datum.barName] = datum.indexInBars));
    return obj;
    // we only want to recreate this when new data is passed, not when barData is updated on expansion and collapse, so only `data` in dependencies
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ textAlign: 'center' }}>
      {title && <h4 style={{ fontSize: 17, fontWeight: 100 }}>{title}</h4>}
      <div
        style={{
          // sets the height of the widget chart area (doesn't include labels/axes according to nivo docs)
          // HOWEVER it does seem to include top and bottom margin, so you must account for those
          height:
            height ||
            Math.min(
              barData.length * barHeight + topMargin + bottomMargin,
              maxHeight || defaultMaxHeight
            ),
        }}
      >
        <ResponsiveBar
          // reverse the data because nivo flips the data for some reason?
          data={barData.filter((d) => d.visible).reverse()}
          theme={barTheme}
          keys={['value']}
          indexBy="barName"
          margin={{ top: topMargin, right: rightMargin, bottom: bottomMargin, left: leftMargin }}
          // nivo doesn't have a max bar width property, so using padding can help adjust width (along with barHeight variable defined above)
          padding={0.5} // padding between bars (ratio, not pixel count)
          layout="horizontal"
          colors={colorSelector}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          // this removes the tooltips which may be useful but unnecessary for the MVP. set isInteractive to true to re-enable them
          isInteractive={false}
          axisBottom={null}
          axisLeft={{
            renderTick: (tick: AxisTickProps<string>) => (
              <CustomTick
                tick={tick}
                barData={barData}
                nameIndexMap={nameIndexMap}
                leftMargin={leftMargin}
                setMaxTickWidth={setMaxTickWidth}
              />
            ),
          }}
          enableGridX
          gridXValues={[0]}
          enableGridY={false}
          enableLabel={false}
          layers={[
            (layers) => (
              <HoverHighlightLayer
                layers={layers}
                barData={barData}
                leftMargin={leftMargin}
                setBarData={setBarData}
              />
            ),
            'grid',
            'axes',
            'bars',
            (layers) => (
              <InfoLabelLayer
                setMaxLabelWidths={setMaxLabelWidths}
                layers={layers}
                baseUnit={baseUnit}
                labelMargin={labelMargin}
              />
            ),
          ]}
        />
      </div>
    </div>
  );
};

export default PowerBarGraph;
