import { Grid, GridSize, Input } from '@material-ui/core';
import StyledLabel from 'components/general/StyledLabel.jsx';
import { IGenericObject } from 'components/general/types';
import { useInReadOnlyBranch } from 'hooks';
import _ from 'lodash';
import {
  CSSProperties,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  useCallback,
  useMemo,
} from 'react';
import theme from 'theme/index';
import useStyles from './styles';

interface IProps {
  label?: string;
  name: string;
  value: (number | '')[][];
  guide: ((event: FocusEvent, name?: string, label?: string) => void) | null;
  errors?: IGenericObject;
  onFocus?: FocusEventHandler;
  onChange?: ChangeEventHandler;
  optional?: boolean;
  dontDisableInReadOnly?: boolean;
  disabled?: boolean;
}

const errorStyle = { borderColor: theme.palette.error.main } as CSSProperties;

const LabeledMatrix = ({
  label,
  name,
  value,
  guide,
  errors,
  onFocus,
  onChange,
  optional,
  dontDisableInReadOnly,
  disabled,
}: IProps) => {
  const classes = useStyles();
  const inReadOnlyBranch = useInReadOnlyBranch();

  const createInput = useCallback(
    (row, col) => {
      return (
        <Grid
          item
          xs={Math.floor(12 / value.length) as GridSize}
          key={`matrix-${label}-${row}-${col}`}
        >
          <Input
            name={`${name}[${row}][${col}]`}
            value={value[row][col]}
            type="number"
            disableUnderline
            onFocus={onFocus ? onFocus : guide ? (e) => guide(e, name, label) : () => undefined}
            onChange={onChange}
            style={errors && _.get(errors, name) ? errorStyle : undefined}
            className={classes.root}
            disabled={disabled || (inReadOnlyBranch && !dontDisableInReadOnly)}
          />
        </Grid>
      );
    },
    [
      value,
      label,
      name,
      onFocus,
      guide,
      errors,
      classes,
      onChange,
      dontDisableInReadOnly,
      disabled,
      inReadOnlyBranch,
    ]
  );

  const inputs = useMemo(() => {
    return value.map((row, i) => row.map((col, j) => createInput(i, j)));
  }, [value, createInput]);

  return (
    <div className={classes.container}>
      <StyledLabel>{optional ? `${label} (optional)` : label}</StyledLabel>
      <Grid container spacing={1} className={classes.indent}>
        {inputs}
      </Grid>
    </div>
  );
};

export default LabeledMatrix;
