import { BarCustomLayerProps } from '@nivo/bar';
import {
  TBarData,
  TExtendedPowerBarDatum,
} from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import * as React from 'react';
import { useCallback } from 'react';
import { TUnit, precision, represent } from 'utils/units';
import InfoLabel from './InfoLabel';

interface IProps {
  layers: BarCustomLayerProps<TExtendedPowerBarDatum>;
  setMaxLabelWidths: React.Dispatch<React.SetStateAction<number[]>>;
  baseUnit?: TUnit;
  labelMargin: number;
}

const InfoLabelLayer = (props: IProps) => {
  const {
    layers: { bars, xScale },
    setMaxLabelWidths,
    baseUnit,
    labelMargin,
  } = props;
  // Callback for child labels - checks if the label's width is the greatest on the left/right side
  const handleMaxLabelWidth = useCallback(
    (width: number, sign) => {
      const marginIndexToUpdate = sign < 0 ? 0 : 1;
      setMaxLabelWidths((prev) => {
        if (width > prev[marginIndexToUpdate]) {
          if (marginIndexToUpdate === 0) return [width, prev[1]];
          else if (marginIndexToUpdate === 1) return [prev[0], width];
        }
        return prev;
      });
    },
    [setMaxLabelWidths]
  );
  return (
    <>
      {bars.map((barData: TBarData, i: number) => {
        const {
          y,
          height,
          data: {
            data: { value },
          },
        } = barData;
        const labelText = baseUnit ? represent(Number(value), baseUnit) : precision(Number(value));
        return (
          <InfoLabel
            key={i}
            value={value}
            xScale={xScale}
            labelMargin={labelMargin}
            translateY={Math.floor(y)}
            height={height}
            labelText={labelText}
            handleMaxLabelWidth={handleMaxLabelWidth}
          />
        );
      })}
    </>
  );
};

export default InfoLabelLayer;
