import { IRole } from 'components/general/types';
import { useSelectByIds, useUser } from '.';
import useWorkspace from './useWorkspace';

/**
 * @returns {IRole[]} a list of roles for the current user in the current workspace.
 */
const useWorkspaceRoles = (): IRole[] => {
  const user = useUser();
  const workspace = useWorkspace();
  const roles = useSelectByIds('Role', workspace?.roles || []);

  return roles.filter((r) => user.roles.includes(r.id));
};

export default useWorkspaceRoles;
