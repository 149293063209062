import ViewPortInlay from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import { hotkeys } from 'config';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import PlaybackControls from './PlaybackControls';
import PlaybackViewer from './PlaybackViewer';

const bottomSpacer = { height: 100 }; // Spacing to prevent any controls on the bottom of the view from being covered by the floating playback controls

export default function PlaybackWGroups(props) {
  const { index, masonryConfig, children, viewerProps } = props;

  const dataAliasing = useMemo(() => {
    // TODO: Logic for downsampling
    return false;
  }, []);

  const viewerRef = useRef(null);
  const cameraRef = useRef(null);
  const clockRef = useRef(null);
  const satelliteEntityRef = useRef(null);
  const playbackRef = useRef(null);
  const [animate, setAnimate] = useState(false);
  const [loop, setLoop] = useState(false);
  const [inFullscreen, setInFullscreen] = useState(false);
  const [positiveClockMultiplier, setPositiveClockMultiplier] = useState(true);

  useEffect(() => {
    function onFullscreenChange() {
      setInFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  // Fullscreen Hotkey
  useHotkeys(
    hotkeys.FULLSCREEN.keys,
    () => {
      if (playbackRef.current.innerHTML !== '')
        inFullscreen ? document.exitFullscreen() : playbackRef.current.requestFullscreen();
    },
    [inFullscreen, playbackRef]
  );

  return (
    <>
      {!dataAliasing ? (
        <div ref={playbackRef}>
          <WGroup index={index}>
            <PlaybackControls
              viewerRef={viewerRef}
              clockRef={clockRef}
              positiveClockMultiplier={positiveClockMultiplier}
              setPositiveClockMultiplier={setPositiveClockMultiplier}
              animate={animate}
              setAnimate={setAnimate}
              loop={loop}
              setLoop={setLoop}
              {...viewerProps}
            />
          </WGroup>
          <WGroup index={index} masonryConfig={masonryConfig}>
            <PlaybackViewer
              viewerRef={viewerRef}
              cameraRef={cameraRef}
              clockRef={clockRef}
              satelliteEntityRef={satelliteEntityRef}
              animate={animate}
              loop={loop}
            />
            {children}
          </WGroup>
          <WGroup index={index}>
            <div style={bottomSpacer}></div>
          </WGroup>
        </div>
      ) : (
        <WGroup index={index}>
          <ViewPortInlay
            text="Simulation playback cannot be displayed for downsampling rates greater than 50%. Please fetch
                a smaller window."
          />
        </WGroup>
      )}
    </>
  );
}

export { default as MissionDesignerPlaybackControls } from './PlaybackControls';
