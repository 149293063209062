import Routes, { routePathsCommon } from 'routes';
import { ITEMS, SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';

// REF: menusToBe
// Bring back additional menus when ready to be filled with data
const { EDIT, ANALYZE, /*TS, WS, FSS,*/ PLAYBACK } = routePathsCommon;

const AGENTS = 'agents';
const AGENTGROUPS = 'agent-groups';
const TIME = 'time';
// REF: menusToBe
// const TEMP = 'temp';

const ScenarioMenu: IMenu = {
  routeFn: Routes.SCENARIO,
  dynamicItems: [],
  [ITEMS]: {
    [EDIT]: {
      [TITLE]: 'Edit',
      [SUB_ITEMS]: {
        [TIME]: { [TITLE]: 'Time' },
        [AGENTS]: { [TITLE]: 'Agents' },
        [AGENTGROUPS]: { [TITLE]: 'Agent Groups' },
      },
    },
    [ANALYZE]: {
      [TITLE]: 'Analyze',
      [SUB_ITEMS]: {
        [PLAYBACK]: {
          [TITLE]: 'Playback',
        },
        // REF: menusToBe
        // [TS]: {
        //   [TITLE]: 'Time Series',
        //   [SUB_ITEMS]: {
        //     [TEMP]: {
        //       [TITLE]: 'Temp',
        //     },
        //   },
        // },
        // [WS]: {
        //   [TITLE]: 'Window Statistics',
        //   [SUB_ITEMS]: {
        //     [TEMP]: {
        //       [TITLE]: 'Temp',
        //     },
        //   },
        // },
        // [FSS]: {
        //   [TITLE]: 'Full Simulation Statistics',
        //   [SUB_ITEMS]: {
        //     [TEMP]: {
        //       [TITLE]: 'Temp',
        //     },
        //   },
        // },

        [AGENTS]: { [TITLE]: 'Agents' },
      },
    },
  },
};

export const wGroupIndicesScenario = {
  EDIT: `${EDIT}*`,
  EDIT_TIME: `${EDIT}.${TIME}`,
  EDIT_AGENTS: `${EDIT}.${AGENTS}`,
  EDIT_AGENTGROUPS: `${EDIT}.${AGENTGROUPS}`,
  ANALYZE: `${ANALYZE}*`,
  ANALYZE_PLAYBACK: `${ANALYZE}.${PLAYBACK}`,
  // REF: menusToBe
  // ANALYZE_TS: `${ANALYZE}.${TS}*`,
  // ANALYZE_TS_TEMP: `${ANALYZE}.${TS}.${TEMP}`,
  // ANALYZE_WS: `${ANALYZE}.${WS}*`,
  // ANALYZE_WS_TEMP: `${ANALYZE}.${WS}.${TEMP}`,
  // ANALYZE_FSS: `${ANALYZE}.${FSS}*`,
  // ANALYZE_FSS_TEMP: `${ANALYZE}.${FSS}.${TEMP}`,
  ANALYZE_AGENTS: `${ANALYZE}.${AGENTS}`,
};

export default ScenarioMenu;
