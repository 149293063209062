import { ModuleVables } from 'utils/vable';

import cdhLogo from './cdh.png';
import soonLogo from './comingsoon.png';
import targetsLogo from './crosshairs.png';
import powerLogo from './eb.png';
import gncLogo from './gnc.png';
import commsLogo from './link.png';
import thermLogo from './therm.png';

const moduleIconMap: { [key: string]: string } = {
  [ModuleVables.ModuleTypes.TARGETS.value]: targetsLogo,
  [ModuleVables.ModuleTypes.GNC.value]: gncLogo,
  [ModuleVables.ModuleTypes.CDH.value]: cdhLogo,
  [ModuleVables.ModuleTypes.POWER.value]: powerLogo,
  [ModuleVables.ModuleTypes.THERMAL.value]: thermLogo,
  [ModuleVables.ModuleTypes.DATA_HANDLING.value]: commsLogo,
  [ModuleVables.ModuleTypes.COMING_SOON.value]: soonLogo,
};

export default moduleIconMap;
