import { makeGuidance } from 'hooks';

// TODO
const targetGroupsHeading = 'Create and Edit Target Groups';

const targetGroupsMainParagraph =
  'Create abstract Target Groups so only one operational mode and one pointing mode are required to capture behavior that is consistent for multiple targets. A target group can be used in place of a single target to define ConOps condition parameters and/or pointing mode directions. Common applications of target groups include ground stations, relay satellites, constellations, and imaging targets.\nJust as Targets are associated to Agents in the Scenario, Target Groups are associated to Agent Groups in the Scenario.';

export const targetGroupsGuidance = {
  _default: {
    heading: targetGroupsHeading,
    body: [
      {
        chunk: targetGroupsMainParagraph,
      },
      {
        subHeading: 'Active Target and  Conditions',
        chunk:
          'The active target determines the value of the target group vector and thus the target of pointing modes. Filter condition compliance determines which targets are eligible to become active. At each timestep the eligible target with the highest priority will be chosen as the active target. Thus, if no filtering conditions are selected, the highest priority target will always be active.\n Optionally create and select conditions associated with this target group to use as filter conditions. You can also specify that a condition filters an associated target group upon creation/edit using the "Filters target group" checkbox.\n Tip: When using multiple conditions to filter targets, it is most convenient to create and associate a Same Target Multi-Condition.',
      },
    ],
  },
};

export const useGuidance = makeGuidance(targetGroupsGuidance);

const targetGroupsSegmentGuidance = {
  heading: 'Target Groups',
  body: [
    {
      chunk: targetGroupsMainParagraph,
    },
  ],
};

export default targetGroupsSegmentGuidance;
