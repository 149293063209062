import { InputAdornment } from '@material-ui/core';
import { CdhAccent, DataHandlingAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IModelBlock, ISelectOption } from 'components/general/types';
import { IDataInterface } from 'components/general/types/dataHandling';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectBlocks } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import _ from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { createNestedOption, translateIn, translateOut } from 'utils/forms';
import { DataInterfaceVables } from 'utils/vable';
import { IOptionsByCategory } from '../DataStorageSegment/DataStorageDialog';
import { useGuidance } from './guidance';
import validation from './validation';

export interface IForm {
  name: string;
  type: ISelectOption | '';
  dataType: ISelectOption | '';
  onBitRate: number | '';
  alwaysActive: boolean;
  source: ISelectOption | '';
  sink: ISelectOption | '';
  bus: ISelectOption | '';
  linkTarget: ISelectOption | '';
  modem: ISelectOption | '';
  directedEnergyDevice: ISelectOption | '';
}

interface IProps {
  control: TEntityDialogControl<IDataInterface>;
}

const defaultValues: IForm = {
  name: '',
  type: '',
  dataType: '',
  onBitRate: '',
  alwaysActive: false,
  source: '',
  sink: '',
  bus: '',
  linkTarget: '',
  modem: '',
  directedEnergyDevice: '',
};

interface INamedBlock extends IModelBlock {
  name: string;
}

const DataInterfaceDialog = ({ control }: IProps) => {
  const { dialogConfig } = control;
  const { entity: dataInterface, action } = dialogConfig;
  const {
    dataTypes,
    components,
    dataBuses,
    targets,
    modems,
    directedEnergyDevices,
    operationalModes,
    targetGroups,
  } = useActiveEntities();

  const { parsedBlocks: parsedOpModes, setParsedBlocks: setParsedOpModes } = useSelectBlocks(
    operationalModes,
    dataInterface?.opModes
  );
  const classes = useStyles();

  const toOptions = (entities: INamedBlock[]) =>
    entities.map((e) => {
      return { value: e.id, label: e.name };
    });

  const options = useMemo(() => {
    return {
      type: DataInterfaceVables.InterfaceType.options,
      dataType: toOptions(dataTypes),
      source: toOptions(components),
      sink: toOptions(components),
      bus: toOptions(dataBuses),
      linkTarget: [
        createNestedOption(targets, 'Targets', 'linkTarget', 'linkTarget', [], {
          linkTarget: 'self',
          linkTargetGroup: null,
        }),
        createNestedOption(targetGroups, 'Target Groups', 'linkTarget', 'linkTargetGroup', [], {
          linkTargetGroup: 'self',
          linkTarget: null,
        }),
      ],
      modem: toOptions(modems),
      directedEnergyDevice: toOptions(directedEnergyDevices),
    };
  }, [dataTypes, components, dataBuses, targets, modems, directedEnergyDevices, targetGroups]);

  const customTranslateIn = useCallback(
    (dataInterface, defaultValues, options, datetimes, percentages) => {
      // REF 1
      if (dataInterface.type.includes('TRANSMIT')) {
        delete dataInterface.source;
      } else if (dataInterface.type.includes('RECEIVE')) {
        delete dataInterface.sink;
      }
      return translateIn(dataInterface, defaultValues, options, datetimes, percentages);
    },
    []
  );

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      if (!values.alwaysActive) {
        values.opModes = parsedOpModes
          .filter((opMode) => opMode.tableData?.checked)
          .map((opMode) => opMode.id);
      }
      const translated = translateOut(values, allowedEmptyFields, options);
      // Modem is duplicated to source or sink for external interfaces REF: 1
      if (values?.type.includes('Transmit')) {
        translated.source = translated.modem;
      } else if (values?.type.includes('Receive')) {
        translated.sink = translated.modem;
      }
      return translated;
    },
    [parsedOpModes]
  );

  const entityForm = useEntityForm<IDataInterface, IForm>({
    entityTypeText: 'Data Interface',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    formikOptionalParams: {
      options,
      useGuidance,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      allowedEmptyFields: ['bus', 'linkTarget', 'linkTargetGroup'],
    },
  });
  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  const renderSourceSink = useMemo(() => {
    // Exclude any component that is currently selected
    const sourceId = values?.source ? values.source.value : '';
    const sinkId = values?.sink ? values.sink.value : '';
    const selectableSink = components.filter((c) => c.id !== sourceId);
    const selectableSource = components.filter((c) => c.id !== sinkId);

    // Group by subsystem
    let initial: IOptionsByCategory = {};
    const componentOptionsSink = selectableSink.reduce((cummulative, component) => {
      const subsystemName: string = component.subsystem.name;
      if (_.has(cummulative, subsystemName)) {
        cummulative[subsystemName].push({ value: component.id, label: component.name });
      } else {
        cummulative[subsystemName] = [{ value: component.id, label: component.name }];
      }
      return cummulative;
    }, initial);

    initial = {};
    const componentOptionsSource = selectableSource.reduce((cummulative, component) => {
      const subsystemName: string = component.subsystem.name;
      if (_.has(cummulative, subsystemName)) {
        cummulative[subsystemName].push({ value: component.id, label: component.name });
      } else {
        cummulative[subsystemName] = [{ value: component.id, label: component.name }];
      }
      return cummulative;
    }, initial);

    const subLabeledSink = Object.keys(componentOptionsSink).map((key) => ({
      label: key,
      options: componentOptionsSink[key],
    }));

    const subLabeledSource = Object.keys(componentOptionsSource).map((key) => ({
      label: key,
      options: componentOptionsSource[key],
    }));
    // Render select
    return (
      <>
        <LabeledSelect
          {...getFieldProps('source')}
          label="Data Source"
          autoFocus
          options={subLabeledSource}
        />
        <LabeledSelect {...getFieldProps('sink')} label="Data Sink" options={subLabeledSink} />
      </>
    );
  }, [values, components, getFieldProps]);

  const nullRef = useRef(null);

  const tableColumns = [
    {
      title: 'Name',
      field: 'name',
    },
  ];
  return (
    <EntityDialog entityForm={entityForm} customDirty={true}>
      <div className={classes.inputs}>
        <LabeledInput
          {...getFieldProps('name')}
          label="Data Interface Name"
          type="text"
          placeholder="Name"
          autoFocus
        />
        <LabeledInput
          {...getFieldProps('onBitRate')}
          label="Bitrate"
          type="number"
          endAdornment={<InputAdornment position="end">bit/s</InputAdornment>}
        />
        <DataHandlingAccent header="Data Type">
          <LabeledSelect
            {...getFieldProps('dataType')}
            // label="Data Type"
            options={options.dataType}
          />
        </DataHandlingAccent>
        <LabeledSelect
          {...getFieldProps('type')}
          label="Interface Type"
          options={options.type}
          isDisabled={action !== 'create'}
        />
        {values?.type &&
          values?.type.value === DataInterfaceVables.InterfaceType.InternalDataInterface.value &&
          renderSourceSink}
        {values?.type &&
          values?.type.value === DataInterfaceVables.InterfaceType.InternalDataInterface.value && (
            <DataHandlingAccent header="Data Bus">
              <LabeledSelect
                {...getFieldProps('bus')}
                optional
                label={'Data Bus'}
                options={options.bus}
              />
            </DataHandlingAccent>
          )}
        {values?.type &&
          values?.type.value !== DataInterfaceVables.InterfaceType.InternalDataInterface.value && (
            <>
              <DataHandlingAccent header="Modem">
                <LabeledSelect {...getFieldProps('modem')} autoFocus options={options.modem} />
              </DataHandlingAccent>
              <DataHandlingAccent header="Directed Energy Device">
                <LabeledSelect
                  {...getFieldProps('directedEnergyDevice')}
                  options={options.directedEnergyDevice}
                />
              </DataHandlingAccent>
              <LabeledSelect
                {...getFieldProps('linkTarget')}
                label="Link Target"
                options={options.linkTarget}
              />
            </>
          )}
        <LabeledCheckbox
          {...getFieldProps('alwaysActive')}
          label={'Always active'}
        ></LabeledCheckbox>
        {!values.alwaysActive && (
          <CdhAccent header="Operational Modes">
            <WidgetTable
              tableRef={nullRef}
              className={classes.table}
              columns={tableColumns}
              setData={setParsedOpModes}
              data={parsedOpModes}
              emptyMessage={'No opModes found'}
              title="Select Operational Modes"
              search={true}
              selection={true}
            />
          </CdhAccent>
        )}
      </div>
    </EntityDialog>
  );
};

export default DataInterfaceDialog;
