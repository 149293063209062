import * as Cesium from 'cesium';
import { BillboardGraphics, Entity, LabelGraphics, PolylineGraphics } from 'resium';
import { perigeeCadScale } from './utils';

const configMap = {
  SpaceTarget: {
    colors: {
      primary: Cesium.Color.RED,
      path: Cesium.Color.TOMATO,
    },
    namePrefix: 'Space',
    width: 1,
  },
  TerrestrialTarget: {
    colors: {
      primary: Cesium.Color.GOLD,
      secondary: Cesium.Color.GOLDENROD,
      PathRemaining: Cesium.Color.LIGHTYELLOW,
    },
    namePrefix: 'Terrestrial',
    width: 1,
  },
  GroundTarget: {
    colors: {
      primary: Cesium.Color.MAGENTA,
    },
    namePrefix: 'Ground',
    width: 1,
  },
  CelestialTarget: {
    colors: {
      primary: Cesium.Color.ORANGE,
    },
    namePrefix: 'Celestial',
    width: 1,
  },
};
const TargetEntity = (props) => {
  const {
    target,
    data,
    orbitTailFactor,
    showOrbitTails,
    showLabels,
    showComms,
    labelTextScale,
    viewerRef,
    cadSignedUrl,
    cadScaleFactor,
    scaleToRealSize,
    showWayPoints,
    showWayPaths,
    showModels,
  } = props;
  const config = configMap[target.type];
  const keyPrefix = `${target.type.charAt(0)}T_`;
  const perigeeAlt = target.perigeeAlt;
  const agentNametoIndex = {};
  data.targets.forEach((x, i) => (agentNametoIndex[x.name] = i));
  const pinBuilder = new Cesium.PinBuilder();
  return [
    !target.isModel && (
      <Entity
        name={`${config.namePrefix} Target: ${target.name}`}
        key={`${keyPrefix}${target.name}`}
        availability={
          new Cesium.TimeIntervalCollection([
            new Cesium.TimeInterval({
              start: data.startTime,
              stop: data.stopTime,
            }),
          ])
        }
        position={target.position3D}
        point={
          ((target.type === 'SpaceTarget' || target.type === 'TerrestrialTarget') && {
            pixelSize: 10,
            color: config.colors.primary,
            show: !showModels || !cadSignedUrl, // Space targets with no cad always show point
          }) ||
          (target.type === 'GroundTarget' && {
            pixelSize: 10,
            color: config.colors.primary,
            show: true,
          })
        }
        model={
          (target.type === 'SpaceTarget' || 'TerrestrialTarget') && {
            uri: cadSignedUrl,
            maximumScale: 2000000 * cadScaleFactor,
            scale: scaleToRealSize
              ? 1
              : perigeeCadScale(perigeeAlt ? perigeeAlt : 500, cadScaleFactor),
            show: showModels,
          }
        }
        path={
          target.type === 'SpaceTarget'
            ? {
                // resolution prop for path is the max seconds to step when sampling the position, so we set to infinity to not limit the sampling
                resolution: Infinity,
                show: showOrbitTails,
                trailTime: target.orbitalPeriod
                  ? target.orbitalPeriod * orbitTailFactor
                  : undefined,
                leadTime: 0,
                material: config.colors.path,
                width: config.width,
              }
            : undefined
        }
        orientation={
          target.type === 'SpaceTarget' || target.type === 'TerrestrialTarget'
            ? target.quaternion
            : undefined
        }
      >
        <LabelGraphics
          text={target.name}
          show={showLabels}
          scale={labelTextScale}
          fillColor={config.colors.primary}
          pixelOffset={Cesium.Cartesian2.fromElements(
            // Offset is further away the bigger the cad model gets
            20 * Math.sqrt((cadScaleFactor || 1) / (target.cadScaleFactor || 1)),
            20 * Math.sqrt((cadScaleFactor || 1) / (target.cadScaleFactor || 1)),
            new Cesium.Cartesian2()
          )}
        />
      </Entity>
    ),
    <Entity
      key={`${keyPrefix}LoS_${target.name}`}
      availability={
        new Cesium.TimeIntervalCollection([
          new Cesium.TimeInterval({
            start: data.startTime,
            stop: data.stopTime,
          }),
        ])
      }
      show={
        target.type === 'CelestialTarget'
          ? viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
            Cesium.SceneMode.SCENE2D
          : true
      }
    >
      {/* Draw data comms link lines */}
      {target.targetInfo &&
        Object.keys(target.targetInfo).map((x) => {
          if (!target.targetInfo[x]) return null; // REF: canLink backwards compatibility
          return (
            <Entity key={target.name + ' to ' + target.targetInfo[x].targetAgent}>
              <PolylineGraphics
                positions={
                  new Cesium.PositionPropertyArray([
                    target.position3D,
                    data.targets[agentNametoIndex[target.targetInfo[x].targetAgent]].position3D,
                  ])
                }
                show={showComms && target.targetInfo[x].canLink}
                width={5}
                material={
                  new Cesium.StripeMaterialProperty({
                    evenColor: target.targetInfo[x].linkColor,
                    oddColor: target.targetInfo[x].fadeColor,
                    repeat: new Cesium.CallbackProperty((time) => {
                      return target.targetInfo[x].activeLink.getValue(time) === 1 ||
                        target.targetInfo[x].activeLink.getValue(time) === 2
                        ? 2
                        : 1;
                    }, false),
                    offset: new Cesium.CallbackProperty((time) => {
                      const activity = target.targetInfo[x].activeLink.getValue(time);
                      const offset = activity
                        ? (viewerRef.current.cesiumElement._clockViewModel.systemTime.secondsOfDay *
                            2) %
                          1
                        : 0;
                      return activity === 2 ? 1 - offset : activity === 3 ? 0 : offset;
                    }, false),
                    orientation: Cesium.StripeOrientation.VERTICAL,
                  })
                }
                arcType={Cesium.ArcType.NONE}
              />
            </Entity>
          );
        })}
    </Entity>,

    // Draw Waypoints and Terrestrial Paths
    target.type === 'TerrestrialTarget' && (
      <Entity
        key={`${keyPrefix}WP_${target.name}`}
        availability={
          new Cesium.TimeIntervalCollection([
            new Cesium.TimeInterval({
              start: data.startTime,
              stop: data.stopTime,
            }),
          ])
        }
        show={true}
      >
        {target.waypoints.map(({ llaDeg: p }, i) => {
          return (
            <Entity
              key={`${keyPrefix}WP_${i}_${target.name}`}
              availability={
                new Cesium.TimeIntervalCollection([
                  new Cesium.TimeInterval({
                    start: data.startTime,
                    stop: data.stopTime,
                  }),
                ])
              }
              position={Cesium.Cartesian3.fromDegrees(p[1], p[0], p[2] * 1000)}
              point={{
                pixelSize: 5,
                color: target.isModel ? Cesium.Color.LIMEGREEN : config.colors.secondary,
                show: true,
              }}
              show={true}
              name={`${target.name}: Waypoint ${i}`}
            >
              <BillboardGraphics
                image={pinBuilder
                  .fromText(
                    i,
                    target.isModel ? Cesium.Color.LIMEGREEN : config.colors.secondary,
                    48
                  )
                  .toDataURL()}
                verticalOrigin={Cesium.VerticalOrigin.BOTTOM}
                scaleByDistance={new Cesium.NearFarScalar(100, 5, 1000000, 0.5)}
                show={
                  new Cesium.CallbackProperty((time) => {
                    if (i < target.currentWaypoint.getValue(time)) {
                      return showWayPoints[0];
                    } else if (
                      i === target.currentWaypoint.getValue(time) ||
                      i - 1 === target.currentWaypoint.getValue(time)
                    ) {
                      return showWayPoints[1];
                    } else {
                      return showWayPoints[2];
                    }
                  }, false)
                }
              />
              {i < target.waypoints.length && (
                <PolylineGraphics
                  positions={
                    new Cesium.CallbackProperty((time) => {
                      return target.currentWaypoint.getValue(time) === i ||
                        target.currentWaypoint.getValue(time) === i - 1
                        ? [
                            target.position3D.getValue(time),
                            Cesium.Cartesian3.fromDegrees(p[1], p[0], p[2] * 1000),
                          ]
                        : target.currentWaypoint.getValue(time) > i
                        ? [
                            Cesium.Cartesian3.fromDegrees(p[1], p[0], p[2] * 1000),
                            Cesium.Cartesian3.fromDegrees(
                              target.waypoints[i + 1].llaDeg[1],
                              target.waypoints[i + 1].llaDeg[0],
                              target.waypoints[i + 1].llaDeg[2] * 1000
                            ),
                          ]
                        : [
                            Cesium.Cartesian3.fromDegrees(p[1], p[0], p[2] * 1000),
                            Cesium.Cartesian3.fromDegrees(
                              target.waypoints[i - 1].llaDeg[1],
                              target.waypoints[i - 1].llaDeg[0],
                              target.waypoints[i - 1].llaDeg[2] * 1000
                            ),
                          ];
                    }, false)
                  }
                  show={
                    new Cesium.CallbackProperty((time) => {
                      if (i < target.currentWaypoint.getValue(time)) {
                        return showWayPaths[0];
                      } else if (
                        i === target.currentWaypoint.getValue(time) ||
                        i - 1 === target.currentWaypoint.getValue(time)
                      ) {
                        return showWayPaths[1];
                      } else {
                        return showWayPaths[2];
                      }
                    }, false)
                  }
                  width={config.width}
                  material={
                    new Cesium.PolylineDashMaterialProperty({
                      color: new Cesium.CallbackProperty((time) => {
                        if (i < target.currentWaypoint.getValue(time)) {
                          return target.isModel ? Cesium.Color.LIMEGREEN : config.colors.primary;
                        } else if (i === target.currentWaypoint.getValue(time)) {
                          return target.isModel ? Cesium.Color.LIMEGREEN : config.colors.primary;
                        } else {
                          return config.colors.PathRemaining;
                        }
                      }, false),
                      gapColor: new Cesium.CallbackProperty((time) => {
                        if (i < target.currentWaypoint.getValue(time)) {
                          return target.isModel ? Cesium.Color.LIMEGREEN : config.colors.primary;
                        } else {
                          return Cesium.Color.TRANSPARENT;
                        }
                      }, false),
                      dashLength: 16.0,
                      dashPattern: 255.0,
                    })
                  }
                  arcType={Cesium.ArcType.GEODESIC}
                />
              )}
            </Entity>
          );
        })}
      </Entity>
    ),
  ];
};

export default TargetEntity;
