import { InputAdornment } from '@material-ui/core';
import { BfVectorAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { IFuelTank } from 'components/general/types/gnc';
import { IBodyFrameVector } from 'components/general/types/spacecraft';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import getThermalProps from 'hooks/getThermalProps';
import { SpacecraftContext } from 'providers';
import { useContext, useMemo } from 'react';
import { FuelTankVables, SubsystemVables } from 'utils/vable';
import { useGuidance } from './guidance';
import validation from './validation';

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  type: ISelectOption | '';
  bodyFrameVector: ISelectOption | '';
  inertia: number | '';
  capacity: number | '';
  wetMass: number | '';
  location: [string, string, string];
  priority: number | '';
  diameter: number | '';
  capDiameter: number | '';
  cylinderLength: number | '';
  orientationVector: ISelectOption | '';
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  type: '',
  bodyFrameVector: '',
  inertia: '',
  capacity: '',
  wetMass: '',
  location: ['', '', ''],
  priority: '',
  diameter: '',
  capDiameter: '',
  cylinderLength: '',
  orientationVector: '',
};

interface IProps {
  control: TEntityDialogControl<IFuelTank>;
}

const FuelTankDialog = ({ control }: IProps) => {
  const { subsystems, bodyFrameVectors } = useActiveEntities();
  const { setSpacecraftDialogConfig, SpacecraftTabs } = useContext(SpacecraftContext);

  // Set up styles
  const classes = useStyles();

  const options = useMemo(() => {
    return {
      type: FuelTankVables.Type.options,
      orientationVector: bodyFrameVectors.map((v: IBodyFrameVector) => {
        return { value: v.id, label: v.name };
      }),
    };
  }, [bodyFrameVectors]);

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const entityForm = useEntityForm<IFuelTank, IForm & typeof thermalDefaultValues>({
    entityTypeText: 'Fuel Tank',
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: {
      subsystem: subsystems.find((s) => s.category === SubsystemVables.Categories.GNC.value)?.id,
    },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
      allowedEmptyFields: ['manufacturer', 'partNumber'],
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setValues } = formik;
  const { type } = values;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Fuel Tank Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('capacity')}
            label="Fuel Capacity"
            placeholder="Fuel Capacity"
            type="number"
            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('wetMass')}
            label="Initial Fuel Mass"
            placeholder="Initial Fuel Mass"
            type="number"
            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('priority')}
            label="Priority"
            placeholder="Priority"
            type="number"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('type')}
            label="Tank Shape"
            options={options.type}
            // If the tank shape is changed, reset all shape params
            formikOnChange={(val: ISelectOption) => {
              if (val !== type) {
                setValues({
                  ...values,
                  diameter: defaultValues.diameter,
                  capDiameter: defaultValues.capDiameter,
                  cylinderLength: defaultValues.cylinderLength,
                  orientationVector: defaultValues.orientationVector,
                });
              }
            }}
          />
          {type !== '' && type.value === FuelTankVables.Type.SphericalFuelTank.value && (
            <>
              <LabeledInput
                {...getFieldProps('diameter')}
                label="Tank Diameter"
                placeholder="Diameter"
                type="number"
                endAdornment={<InputAdornment position="end">m</InputAdornment>}
              />
            </>
          )}
          {type !== '' && type.value === FuelTankVables.Type.SpherocylinderFuelTank.value && (
            <>
              <LabeledInput
                {...getFieldProps('cylinderLength')}
                label="Cylinder Length"
                placeholder="Length"
                type="number"
                endAdornment={<InputAdornment position="end">m</InputAdornment>}
              />
              <LabeledInput
                {...getFieldProps('capDiameter')}
                label="Cap Diameter"
                placeholder="Diameter"
                type="number"
                endAdornment={<InputAdornment position="end">m</InputAdornment>}
              />
              <BfVectorAccent
                onAddAction={() =>
                  setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
                }
              >
                <LabeledSelect
                  {...getFieldProps('orientationVector')}
                  label="Length Orientation Vector"
                  options={options.orientationVector}
                />
              </BfVectorAccent>
            </>
          )}
        </div>
        <div className={classes.inputGroup}>
          <h6>Tank Centroid Location</h6>
          <LabeledInput
            label="x-Component"
            type="number"
            {...getFieldProps('location.0')}
            endAdornment={<InputAdornment position="end">m</InputAdornment>}
          />
          <LabeledInput
            label="y-Component"
            type="number"
            {...getFieldProps('location.1')}
            endAdornment={<InputAdornment position="end">m</InputAdornment>}
          />
          <LabeledInput
            label="z-Component"
            type="number"
            {...getFieldProps('location.2')}
            endAdornment={<InputAdornment position="end">m</InputAdornment>}
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
      </div>
    </EntityDialog>
  );
};

export default FuelTankDialog;
