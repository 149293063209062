import { TChartSpec } from 'components/general/types';

const statsDissipationChartSpec: TChartSpec = [
  {
    name: 'Subsystem Dissipation',
    plots: [
      {
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            keys: ['Subsystem.$each.dissipations.total'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Power Processing Dissipation',
    plots: [
      {
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            keys: [
              'powerProcessor.dissipations.powerController',
              'powerProcessor.dissipations.internalLoads',
              'powerProcessor.dissipations.busRegulators',
            ],
            legend: ['Power Controller', 'Internal Loads', 'Bus Regulators'],
          },
        ],
      },
    ],
  },
  {
    name: 'Power Generation Dissipation',
    plots: [
      {
        name: 'Solar Array: {name}',
        type: 'pie',
        each: 'SolarArray',
        unit: 'wday',
        variables: [
          {
            keys: ['panels.$each.dissipations.total'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Energy Storage Dissipation',
    plots: [
      {
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            keys: ['powerProcessor.battery.packs.$each.dissipations.total'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Subsystem Dissipation',
    each: 'Subsystem',
    plots: [
      {
        type: 'pie',
        unit: 'wday',
        variables: [
          {
            keys: ['components.$each.dissipations.total'], // TODO: Each of these only has 1 key `total` - brad or richard
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
];

export default statsDissipationChartSpec;
