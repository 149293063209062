import Grid from '@material-ui/core/Grid';
import GuidanceCard from 'components/general/GuidanceCard';
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useSnackbar } from 'hooks';
import useFormikForm from 'hooks/FormikHook';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TargetVables } from 'utils/vable';
import useGuidance from './guidance';
import targetSchema from './validation';

const defaultValues = {
  name: '',
  type: '',
};

const options = {
  type: TargetVables.Type.options,
};

const TargetDialog = ({ control }) => {
  const { dialogConfig, closeDialog: onClose } = control;
  const { action, entity: target, open } = dialogConfig;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { branch } = useActiveEntities();

  const {
    Block: {
      actions: { createBlock, updateBlock, deleteBlock },
    },
  } = SatelliteApi;

  const addTarget = useCallback(
    (values) => {
      setLoading(true);
      const valuesToDispatch = { ...values, branchId: branch.id };
      const { type } = values;
      // Delete values not needed for target request
      for (let val of ['type']) {
        delete valuesToDispatch[val];
      }
      dispatch(
        createBlock({
          ...valuesToDispatch,
          entity: { type },
          successCallback: (target) => {
            onClose();
            enqueueSnackbar('Target created successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response) => {
            enqueueSnackbar(response.error.message);
            setLoading(false);
          },
        })
      );
    },
    [branch, enqueueSnackbar, onClose, createBlock, dispatch]
  );

  const editTarget = useCallback(
    (values) => {
      setLoading(true);
      // Delete values not needed for target patch request
      for (let val of ['type']) {
        delete values[val];
      }
      dispatch(
        updateBlock({
          id: target.id,
          branchId: branch.id,
          ...values,
          entity: target,
          successCallback: (target) => {
            onClose();
            enqueueSnackbar('Target updated successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response) => {
            enqueueSnackbar(response.error.message);
            setLoading(false);
          },
        })
      );
    },
    [target, enqueueSnackbar, onClose, updateBlock, dispatch, branch]
  );

  const { formik, guidance } = useFormikForm(
    defaultValues,
    action === 'create' || action === 'clone' ? addTarget : editTarget,
    targetSchema,
    target,
    { useGuidance, options }
  );
  const { values, getFieldProps, resetForm, dirty, handleSubmit } = formik;

  // instead of calling resetForm in the onClose function, we reset it using a useEffect to prevent unwanted UX flash when closing the form
  // reset is not in the dependency array as the formik form is rerendered when this happens, causing an infinite rerender
  useEffect(() => {
    if (!dialogConfig.open) {
      resetForm();
    }
  }, [dialogConfig.open]); // eslint-disable-line

  if (action === 'delete' && target) {
    return (
      <DeleteEntityDialog
        action={deleteBlock}
        entity={target}
        entityTypeText={'Target'}
        onClose={onClose}
        open={open}
      />
    );
  }

  return (
    <Dialog
      prompt={
        action === 'create'
          ? 'Create a target'
          : action === 'clone'
          ? 'Clone target'
          : 'Edit target'
      }
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      submitActionText="Save"
      secondaryActionText="Reset"
      onSecondaryAction={resetForm}
      disableSubmit={action !== 'clone' && !dirty}
      large
      xray={values}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <div className={classes.inputGroup}>
              <LabeledInput
                {...getFieldProps('name')}
                label="Target Name"
                placeholder="Target Name"
                autoFocus
              />
            </div>
            <div className={classes.inputGroup}>
              <LabeledSelect
                label="Target Type"
                options={options.type}
                isDisabled={action === 'edit'}
                {...getFieldProps('type')}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard guidance={guidance} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TargetDialog;
