import { IconButton, TableCell, TableRow } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { TStatsTableDatum } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import useStyles from './styles';

type TDefaultStatsTableRowProps = {
  datum: TStatsTableDatum;
  unit?: string;
  className?: string;
};

type TExpandableStatsTableRowProps = TDefaultStatsTableRowProps & {
  expanded: boolean;
  onExpandClick: () => void;
};

type TNonExpandableStatsTableRowProps = TDefaultStatsTableRowProps & {
  expanded?: undefined;
  onExpandClick?: undefined;
};

export type TStatsTableRowProps = TNonExpandableStatsTableRowProps | TExpandableStatsTableRowProps;

const PowerTableRow = (props: TStatsTableRowProps) => {
  const { datum, unit, expanded } = props;
  const classes = useStyles(props);

  return (
    <TableRow
      key={datum.name}
      onClick={props.onExpandClick}
      className={clsx(classes.statsTableRow, props.className)}
    >
      <TableCell className={clsx(classes.statsTableCell, classes.statsTableNameCell)}>
        <div>{datum.name}</div>
      </TableCell>
      <TableCell className={clsx(classes.statsTableCell, classes.statsTableValueCell)}>
        {datum.value !== undefined ? `${datum.value} ${unit ?? ''}` : ''}
      </TableCell>
      <TableCell className={clsx(classes.statsTableCell, classes.statsTableExpandCell)}>
        {expanded !== undefined && (
          <IconButton disableFocusRipple disableRipple>
            <ExpandMoreIcon style={{ transform: `rotate(${expanded ? '180deg' : '0deg'})` }} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default PowerTableRow;
