import { TChartSpec } from 'components/general/types';

const tsDissipationChartSpec: TChartSpec = [
  {
    name: 'Dissipation Overview',
    subtitle: 'Dissipation of each subsystem',
    plots: [
      {
        type: 'line',
        label: 'Dissipation',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: ['Subsystem.$each.dissipations.total'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
  {
    name: 'Power Processing Dissipation',
    subtitle:
      'Dissipation of the power controller losses, internal loads, and bus regulator losses',
    plots: [
      {
        type: 'line',
        label: 'Dissipation',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: [
              'powerProcessor.dissipations.powerController',
              'powerProcessor.dissipations.internalLoads',
              'powerProcessor.dissipations.busRegulators',
              'powerProcessor.busRegulators.$each.dissipations.total',
            ],
            legend: [
              'Power Controller Losses',
              'Internal Loads',
              'Bus Regulator Total',
              '{name} Losses',
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Power Generation Dissipation: "{name}" Solar Array',
    subtitle: 'Dissipations by solar panel',
    each: 'SolarArray',
    plots: [
      {
        type: 'line',
        each: 'panels',
        name: '"{name}" Solar Panel',
        label: 'Dissipation',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: [
              'dissipations.total',
              'dissipations.blockingDiode',
              'dissipations.internalLoads',
            ],
            legend: ['Total', 'Blocking Diode Losses', 'Internal Loads'],
          },
        ],
      },
    ],
  },
  {
    name: 'Energy Storage Dissipation',
    subtitle: 'Equivalent Series resistance (ESR) and internal load dissipations by battery pack',
    each: 'Battery',
    plots: [
      {
        type: 'line',
        each: 'packs',
        name: '"{name}" Battery Pack',
        label: 'Dissipation',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: ['dissipations.total', 'dissipations.esr', 'dissipations.internalLoads'],
            legend: ['Total', 'ESR Losses', 'Internal Loads'],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Subsystem Dissipation',
    subtitle: 'Dissipation by component',
    each: 'Subsystem',
    plots: [
      {
        type: 'line',
        label: 'Dissipation',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: ['components.$each.dissipations.total'],
            legend: ['{name}'],
          },
        ],
      },
    ],
  },
];

export default tsDissipationChartSpec;
