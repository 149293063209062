import { IWorkspace } from 'components/general/types';
import { setPreviousWorkspaceId } from 'components/RootView/missionExplorerSlice';
import { SUPPORT_EMAIL } from 'config';
import { useSnackbar, useUser } from 'hooks';
import useWorkspace from 'hooks/useWorkspace';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes, { getSearchParams } from 'routes';

interface IWorkspaceContext {
  workspace?: IWorkspace;
  loading: boolean;
  fetchWorkspace: () => void;
}

interface IProps {
  children: ReactNode;
}

const defaultState: IWorkspaceContext = {
  workspace: undefined,
  loading: false,
  fetchWorkspace: () => undefined,
};

/**
 * Fetches current workspace data and all children, including repositories, members, and roles.
 * - Does NOT include branch model service data. That is handled by ActiveBranchProvider.
 */
export const WorkspaceContext = createContext<IWorkspaceContext>(defaultState);

const WorkspaceProvider = (props: IProps) => {
  const { children } = props;

  const [loading, setLoading] = useState(false);

  const {
    Workspace: {
      actions: { getWorkspace },
    },
    User: {
      actions: { getUser },
    },
  } = SatelliteApi;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  //@ts-ignore: next-line
  const activeWorkspaceId = useSelector((state) => state.ui.missionExplorer.activeWorkspaceId);
  //@ts-ignore: next-line
  const previousWorkspaceId = useSelector((state) => state.ui.missionExplorer.previousWorkspaceId);

  const user = useUser();
  const workspace = useWorkspace();
  const history = useHistory();
  const { share } = getSearchParams();

  const fetchWorkspace = useCallback(() => {
    setLoading(true);
    dispatch(
      getWorkspace({
        id: workspace?.id,
        queryParams: {
          expand: {
            members: {},
            roles: {},
            license: {},
            projects: {},
            repositories: {
              branches: {
                $remove: {
                  data: {},
                  tier2issues: {},
                },
              },
            },
          },
        },
        successCallback: () => {
          // Self user needs to always have a list of *all* roles in redux, not just those for this repo
          // Fetch user separately
          dispatch(
            getUser({
              successCallback: () => setLoading(false),
            })
          );
        },
        failureCallback: () => {
          if (!share)
            enqueueSnackbar(
              `Failed to retrieve workspace. Please refresh the page and reach out to ${SUPPORT_EMAIL} if error persists.`
            );
          setLoading(false);
        },
      })
    );
  }, [dispatch, enqueueSnackbar, workspace?.id, getWorkspace, getUser, share]);

  useEffect(() => {
    if (user?.id && activeWorkspaceId) {
      // Check if this workspace is part of user's list of workspaces
      if (!user.workspaces.includes(activeWorkspaceId) && !share) {
        enqueueSnackbar(
          'The workspace does not exist or is not accessible from this account. Please select a different workspace.'
        );
        setTimeout(() => history.replace(Routes.ROOT()), 0);
      }
      // Fetch workspace only when switching from another one. This overwrites the roles of any members the user
      // may share multiple workspaces with.
      else if (previousWorkspaceId !== activeWorkspaceId) {
        fetchWorkspace();
        dispatch(setPreviousWorkspaceId(activeWorkspaceId));
      }
    }
  }, [
    user,
    activeWorkspaceId,
    enqueueSnackbar,
    history,
    fetchWorkspace,
    dispatch,
    previousWorkspaceId,
    share,
  ]);

  const contextValue = useMemo(() => {
    return {
      workspace,
      loading,
      fetchWorkspace,
    };
  }, [workspace, loading, fetchWorkspace]);

  return <WorkspaceContext.Provider value={contextValue}>{children}</WorkspaceContext.Provider>;
};

export default WorkspaceProvider;
