import CDHEditBoard from './CDHEditBoard';
import ComingSoonEditBoard from './ComingSoonEditBoard';
import DataHandlingEditBoard from './DataHandlingEditBoard';
import GNCEditBoard from './GNCEditBoard';
import PowerEditBoard from './PowerEditBoard';
import TargetsEditBoard from './TargetsEditBoard';
import ThermalEditBoard from './ThermalEditBoard';

const EditBoards = () => {
  return (
    <>
      <TargetsEditBoard />
      <GNCEditBoard />
      <CDHEditBoard />
      <PowerEditBoard />
      <ThermalEditBoard />
      <DataHandlingEditBoard />
      <ComingSoonEditBoard />
    </>
  );
};

export default EditBoards;
