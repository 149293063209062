import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';
import { makeGuidance } from 'hooks';
import { ActuatorVables } from 'utils/vable';

const actuatorsHeading = 'Create and Edit Actuator';

const actuatorsMainParagraph =
  'Actuators are used to orient the spacecraft based on the attitude control algorithm. Each algorithm is assigned specific actuators by the user.';

const rwChunk =
  'Specify the reaction wheel’s inertia, rated angular momentum (maximum angular momentum storage), rated torque (maximum instantaneous torque), and efficiency (a measure of how much power is lost to heat).';
const mtChunk =
  'Specify the device’s maximum rated magnetic moment and the power draw at the maximum. The control algorithm uses Pulse-Width Modulation (PWM) to drive a variable torque.';

export const actuatorsGuidance = {
  _default: {
    heading: actuatorsHeading,
    body: [
      {
        chunk: actuatorsMainParagraph,
      },
    ],
  },
  bodyFrameVector: {
    heading: 'Torque Axis',
    body: [
      {
        subHeading: 'For Reaction Wheels:',
        chunk:
          'Input the torque body frame vector: the direction around which the reaction wheel spins and produces torque on the spacecraft.',
      },
      {
        subHeading: 'For Magnetorquers:',
        chunk:
          ' Input the vector along the magnetorquer: this is the direction of the magnetic moment. The produced torque will always be orthogonal to this vector.',
      },
    ],
  },
  ratedMagneticMoment: {
    heading: 'Magnetorquer',
    body: [
      {
        chunk: mtChunk,
      },
    ],
  },
  powerAtRatedMagneticMoment: {
    heading: 'Magnetorquer',
    body: [
      {
        chunk: mtChunk,
      },
    ],

    //TODO add Drawing
  },
  inertia: {
    heading: 'Reaction Wheel',
    body: [
      {
        chunk: rwChunk,
      },
    ],
    //TODO add Drawing
  },
  ratedTorque: {
    heading: 'Reaction Wheel',
    body: [
      {
        chunk: rwChunk,
      },
    ],
  },
  ratedMomentum: {
    heading: 'Reaction Wheel',
    body: [
      {
        chunk: rwChunk,
      },
    ],
  },
  efficiency: {
    heading: 'Reaction Wheel',
    body: [
      {
        chunk: rwChunk,
      },
    ],
  },
  isp: {
    heading: 'Specific Impulse (Isp)',
    body: [
      {
        chunk:
          'The specific impulse, in seconds, is the nozzle exhaust velocity divided by the standard gravitational acceleration.',
      },
    ],
  },
  minThrust: {
    heading: 'Minumum Thrust',
    body: [
      {
        chunk:
          'The minimum thrust (N) is the smallest nonzero thrust that can be produced, or zero if arbitrarily small thrusts are allowed.',
      },
    ],
  },
  location: {
    heading: 'Thruster Location',
    body: [
      {
        chunk: 'Enter the location at which the force of thrust is applied to the spacecraft.',
      },
    ],
  },
  orientation: {
    heading: 'Thruster Orientation',
    body: [
      {
        chunk:
          'Select a body frame vector parallel to the exhaust velocity produced by the thruster. The direction of thrust will be anti-parallel to this vector.',
      },
    ],
  },
  fuelReservoir: {
    heading: 'Fuel Reservoir',
    body: [
      {
        chunk: 'This thruster will consume fuel from the selected Fuel Reservoir.',
      },
    ],
  },
  // @ts-ignore
  powerSource: (values) => {
    if (values?.type?.value === ActuatorVables.Type.Thruster.value) {
      return {
        heading: 'Power Source',
        body: [
          {
            chunk:
              'Dynamic power consumption is currently not implemented for thrusters, so the choice of Power Source will not have an effect in the simulation. Loads can still be added to this component. ',
          },
        ],
      };
    } else return { alias: '_default' };
  },
  ...componentThermalGuidance,
};

export const useGuidance = makeGuidance(actuatorsGuidance);

const actuatorsSegmentGuidance = {
  heading: 'Actuators',
  body: [
    {
      chunk: actuatorsMainParagraph,
    },
  ],
};

export default actuatorsSegmentGuidance;
