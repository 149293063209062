import { makeGuidance } from 'hooks';
import { orbitGuidance } from '../OrbitForm/guidance';

const groundTargetDescription =
  'Ground Target - Ground targets are fixed to the surface of the Earth, specifically the WGS84 ellipsoid.';
const spaceTargetDescription =
  'Space Target - Space targets are other Earth-orbiting objects. These could be other satellites targeted by your payload or used for communications crosslink or relay.';
const celestialTargetDescription =
  "Celestial Target - Celestial targets are natural objects which are located outside of Earth's atmosphere, such as the Moon and Sun.";

const agentPermanentGuidance = [
  {
    subHeading: 'Peripheral',
    chunk:
      'Peripheral agents are simple targets. Only their position and velocity are modeled during a simulation. They are propagated based on their type: Ground Target and Space Target. Ground Targets are propagated on the surface of the Earth at their defined latitude, longitude, and altitude. Space Targets are propagated based on their given initial orbit. \n Note: Celestial Targets are not agents and are created at the Agent Template level, since they require no inital state specification.',
  },
  {
    subHeading: 'Templated',
    chunk:
      'Templated Agents are full Digital Twins. Digital Twins are instantiated from a specific branch of an Agent Template repository. The initial state used to differentiate Agent Templates currently includes Target assignments and an initial orbit. You may instantiate a Twin from any branch you have read access to.',
  },
];

const mappingText =
  "This interface allows the user to resolve the Agent's abstract Targets and Target Groups to other Agents and Agent Groups in the scenario. Note that Targets can only be mapped to agents of the appropriate type.  For example, Ground Targets may only be resolved to Peripheral Ground Agents, and Space Targets may only be resolved to Peripheral Space Agents or Spacecraft Digital Twins. \n The agent can be saved with an incomplete mapping. You create the required Agents and Agent Groups, then return to associate them to Targets and Target Groups of this agent.\n If this Agent is mapped to an Agent Group that contains this Agent, it will ignore itself and use all other Agents to populate the Target Group.";

export const useGuidance = makeGuidance({
  name: {
    heading: 'Agent Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the agent and select the appropriate agent type from the options below.',
      },
      ...agentPermanentGuidance,
    ],
  },
  agentType: {
    alias: 'name',
  },

  targetType: {
    heading: 'Peripheral Agent Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the target and select the appropriate target type from the options below. Note that celestial targets need not be instantiated as Peripheral Agents as they are directly created on the Agent Template.',
      },
      {
        subHeading: 'Peripheral Agent Types',
        chunk: groundTargetDescription,
      },
      {
        chunk: spaceTargetDescription,
      },
      {
        chunk: celestialTargetDescription,
      },
    ],
  },
  template: {
    heading: 'Templated Agent Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the agent and select the appropriate Agent Template repository and branch.',
      },
      {
        subHeading: 'Orbits',
        chunk: 'Select the initial orbit for the templated agent.',
      },
      {
        subHeading: 'Target and Target Group Mapping',
        chunk: mappingText,
      },
    ],
  },
  branch: { alias: 'template' },

  lat: {
    heading: 'Ground Target',
    body: [
      {
        chunk: groundTargetDescription,
      },
      {
        subHeading: 'Notes',
        chunk:
          'Latitude and altitude inputs are geodetic, which is typical for terrestrial latitude and altitude. \nNegative altitudes are not allowed. If your desired target location is below mean sea level (MSL), set altitude to zero. This is unlikely to impact simulation accuracy, and you can account for the impact of negative altitude when setting target-based conditions for your ConOps (e.g. setting a minimum elevation constraint based on the geometry of local terrain or other obstructions).',
      },
    ],
  },

  lon: {
    alias: 'lat',
  },

  alt: {
    alias: 'lat',
  },
  orbit: {
    heading: 'Orbiting Agent',
    body: [
      {
        chunk:
          'Select one of the orbits defined in the Orbits Segment to be assigned to this Agent.',
      },
    ],
  },

  ...orbitGuidance,

  _default: {
    heading: 'Resolve an Agent`s Targets to Other Agents',
    body: [
      {
        chunk: mappingText,
      },
    ],
  },

  //TODO: Dynamically retrieve the field names for the target mapping instead of having to use default like this
});

const agentSegmentGuidance = {
  heading: 'Agents',
  body: agentPermanentGuidance,
};

export default agentSegmentGuidance;
