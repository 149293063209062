import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';
import { useActiveEntities } from 'hooks';
import { useState } from 'react';
import theme from 'theme';
import useStyles from '../styles';
import Tier2ValidationDialog from './Tier2ValidationDialog';

const Tier2ValidationIcon = () => {
  const classes = useStyles();
  const issues = useActiveEntities().branch.tier2issues;
  const [open, setOpen] = useState(false);

  const tooltipTitle = issues.length
    ? 'Address issues before use in a simulation'
    : 'Model ready to simulate';

  return (
    <>
      <Tooltip arrow title={tooltipTitle}>
        <IconButton
          className={classes.branchNavIcon}
          onClick={() => setOpen(true)}
          style={issues.length ? { color: theme.palette.warning.main } : {}}
        >
          <FontAwesomeIcon icon={issues.length ? faCircleExclamation : faCircleCheck} />
        </IconButton>
      </Tooltip>
      <Tier2ValidationDialog open={open} closeDialog={() => setOpen(false)} issues={issues} />
    </>
  );
};

export default Tier2ValidationIcon;
