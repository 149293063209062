import BaseNode from './general/BaseNode';
import GNCNodeHandle, { Position } from './general/GNCNodeHandle';

import { IControlModeNodeData } from './general/types';

const customContainerStyle = {
  height: '3rem',
};

const SlidingModeNode = ({ data }: IControlModeNodeData) => {
  return (
    <BaseNode
      title={data.title}
      subtitle={data.subtitle}
      containerStyle={customContainerStyle}
      truncateTitleOverflow={false}
      truncateSubtitleOverflow={false}
    >
      <GNCNodeHandle type="target" position={Position.Left} />
      <GNCNodeHandle type="source" position={Position.Right} />
    </BaseNode>
  );
};

export default SlidingModeNode;
