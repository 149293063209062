import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  toolStatus: {
    display: 'inline-block',
    width: 40,
    marginBottom: 'auto',
    marginTop: 5,
  },
  toolBtn: {
    display: 'inline-block',
    marginLeft: 'auto !important',
    marginRight: '0px !important',
  },
  toolName: {
    display: 'inline-block',
    maxWidth: 120,
  },
  simDesriptors: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > p': {
      fontSize: 12,
      fontStyle: 'italic',
      textAlign: 'center',
      color: theme.palette.info.note,
      padding: 0,
      margin: 0,
    },
  },
  errorMessage: {
    color: theme.palette.info.note,
    overflowWrap: 'anywhere',
    textAlign: 'center',
    margin: '5px 0px 10px 0px',
  },
}));

export default useStyles;
