import { ISelectOption } from 'components/general/types';
import getThermalProps from 'hooks/getThermalProps';
import { FuelTankVables } from 'utils/vable';
import * as Yup from 'yup';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  name: Yup.string().required('An actuator name is required.'),
  manufacturer: Yup.string(),
  partNumber: Yup.string(),
  capacity: Yup.number()
    .required('Fuel capacity is required.')
    .min(0, 'Fuel capacity must be nonnegative.'),
  wetMass: Yup.number()
    .required('Fuel mass is required.')
    .min(0, 'Fuel mass must be nonnegative.'),
  priority: Yup.number()
    .required('Tank priority is required.')
    .integer('Priority must be an integer.'),
  type: Yup.object().required('Select a fuel tank shape.'),
  diameter: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === FuelTankVables.Type.SphericalFuelTank.value,
    then: Yup.number().required('Diameter is required').moreThan(0, 'Diameter must be positive.'),
  }),
  cylinderLength: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === FuelTankVables.Type.SpherocylinderFuelTank.value,
    then: Yup.number()
      .required('Cylinder Length is required.')
      .moreThan(0, 'Cylinder Length must be positive.'),
  }),
  capDiameter: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === FuelTankVables.Type.SpherocylinderFuelTank.value,
    then: Yup.number()
      .required('Cap Diameter is required.')
      .moreThan(0, 'Cap Diameter must be positive.'),
  }),
  orientationVector: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === FuelTankVables.Type.SpherocylinderFuelTank.value,
    then: Yup.object().required('Orientation Vector is required.'),
  }),
  location: Yup.array().of(
    Yup.number().required('Tank Centroid Location x, y, and z components are all required.')
  ),
  ...thermalValidation,
});

export default validation;
