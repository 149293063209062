import { Grid } from '@material-ui/core';
import GuidanceCard from 'components/general/GuidanceCard';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IErrorResponse, IGenericObject, IWorkspace } from 'components/general/types';
import { IUser } from 'components/general/types/general';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { EMAIL_REGEX } from 'config';
import { useFormikForm, useSelectById, useSnackbar } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import MemberDeleteDialog from './Delete';
import MemberEditDialog from './Edit';

interface IProps {
  workspace: IWorkspace;
  control: TEntityDialogControl<IUser>;
}

const membersSchema = Yup.object().shape({
  members: Yup.string()
    .required('One or more emails are required.')
    .test('Test email validity', 'All email addresses must be in a valid format.', (members) => {
      const emails = members?.replace(/\s/g, '').split(',');
      if (emails) {
        for (const email of emails) {
          if (!email.match(EMAIL_REGEX)) {
            return false;
          }
        }
      }
      return true;
    })
    .test('Uniqueness test', 'Emails must be unique.', (members) => {
      const emails = members?.replace(/\s/g, '').split(',') || [];
      const emailsSet = new Set(emails.map((e) => e.toLowerCase()));
      return emails?.length === emailsSet.size;
    }),
});

interface IMemberForm {
  members: string[];
}

const defaultValues: IMemberForm = {
  members: [''],
};

const MemberDialog = (props: IProps) => {
  const { control, workspace } = props;
  const [loading, setLoading] = useState(false);
  const { dialogConfig, closeDialog } = control;
  const defaultRole = useSelectById('Role', workspace.defaultRole);

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const {
    Workspace: {
      actions: { manageInvites },
    },
  } = SatelliteApi;

  const addMembers = (values: { members: string[] }) => {
    setLoading(true);
    dispatch(
      manageInvites({
        id: workspace.id,
        emails: values.members,
        successCallback: (response: IGenericObject) => {
          closeDialog();
          enqueueSnackbar('Invitation emails were successfully sent to members.', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  const customTranslateOut = useCallback((values) => {
    // convert emails from string into an array of emails, which is what the backend expects
    const emails = values.members?.replace(/\s/g, '').split(',');
    return { members: emails };
  }, []);

  const { formik } = useFormikForm<IMemberForm, IMemberForm>(
    defaultValues,
    addMembers,
    membersSchema,
    defaultValues,
    { translateOut: customTranslateOut }
  );

  const { handleSubmit, getFieldProps, resetForm } = formik;

  // use Effect to reset form whenever dialog is closed
  useEffect(() => {
    if (!dialogConfig.open) {
      resetForm();
    }
  }, [dialogConfig.open, resetForm]);

  if (dialogConfig.action === 'edit') {
    return <MemberEditDialog onClose={closeDialog} config={dialogConfig} workspace={workspace} />;
  }
  if (dialogConfig.action === 'delete') {
    return <MemberDeleteDialog onClose={closeDialog} config={dialogConfig} workspace={workspace} />;
  }

  return (
    <Dialog
      open={dialogConfig.open}
      prompt="Add members to the workspace"
      onClose={closeDialog}
      submitActionText="Invite"
      onSubmit={handleSubmit}
      large
      loading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <div className={classes.inputGroup}>
              <LabeledInput
                {...getFieldProps('members')}
                label="Members"
                multiline
                rows={4}
                type="text"
                placeholder="Members"
                autoFocus
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard
            guidance={{
              heading: 'Members',
              body: `Enter email addresses of new members on this workspace (separated by a comma).
              Members will be required to login or create an account to accept the invitation.
              After accepting, members will be displayed in the Members section and will be able to access this workspace.\
              They will join with the default role: ${defaultRole.name}.
              `,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default MemberDialog;
