import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { useInReadOnlyBranch } from 'hooks';
import { useCallback } from 'react';
import useStyles from './styles';

const options = {
  edit: 'edit',
  delete: 'delete',
  clone: 'clone',
};

const EntityMenu = (props) => {
  const {
    anchorEl,
    selectedData,
    onActionClick,
    handleClose,
    cloningAllowed,
    disableActionMenuOptions,
  } = props;

  const classes = useStyles();

  const inReadOnlyBranch = useInReadOnlyBranch();

  const handleEdit = () => {
    onActionClick(selectedData, options.edit);
    handleClose();
  };

  const handleClone = () => {
    const newData = structuredClone(selectedData);
    newData.name = 'Copy of ' + selectedData.name;
    delete newData.id;
    onActionClick(newData, options.clone);
    handleClose();
  };

  const handleDelete = () => {
    onActionClick(selectedData, options.delete);
    handleClose();
  };

  const shouldRestrictAction = useCallback(
    (action) => {
      if (selectedData && disableActionMenuOptions?.actions.includes(action)) {
        return disableActionMenuOptions.conditionalCB
          ? disableActionMenuOptions.conditionalCB(selectedData)
          : true;
      }
      return false;
    },
    [disableActionMenuOptions, selectedData]
  );

  return (
    <Menu
      id="more-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {!shouldRestrictAction(options.edit) && selectedData && (
        <MenuItem className={classes.menuItem} onClick={handleEdit}>
          {inReadOnlyBranch ? 'View' : 'Edit'}
        </MenuItem>
      )}
      {cloningAllowed && !inReadOnlyBranch && (
        <MenuItem className={classes.menuItem} onClick={handleClone}>
          Clone
        </MenuItem>
      )}
      {!shouldRestrictAction(options.delete) && selectedData && !inReadOnlyBranch && (
        <MenuItem className={clsx(classes.menuItem, classes.menuItemDelete)} onClick={handleDelete}>
          Delete
        </MenuItem>
      )}
    </Menu>
  );
};

export default EntityMenu;
