import TableIcons from 'components/general/TableIcons';
import { IApiKey, IErrorResponse } from 'components/general/types';
import Widget from 'components/general/widgets/Widget';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { useEntityDialogControl, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiKeyDialog, { IApiKeyForm } from './ApiKeyDialog';

const errorMessage =
  'Something went wrong. Please try again. If this problem persists, please contact our support team.';

const ApiKeysWidget = () => {
  const {
    ApiKey: {
      actions: { getKeys, createKey, revokeKey },
    },
  } = SatelliteApi;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // Whether the dialog buttons should be spinning
  const [loading, setLoading] = useState(false);
  // Brand new key delivered from the api when create action is initiated
  const [createdKey, setCreatedKey] = useState('');
  // User's current api keys
  const [apiKeys, setApiKeys] = useState<IApiKey[]>([]);
  // Boolean flag, says whether we have keys or we need a fresh fetch
  // Resets when new keys are created or deleted
  const [keysFetched, setKeysFetched] = useState(false);
  const dialogControl = useEntityDialogControl<IApiKey>();

  // Fetch API keys
  useEffect(() => {
    if (!keysFetched) {
      setLoading(true);
      dispatch(
        getKeys({
          successCallback: (response: IApiKey[]) => {
            setApiKeys(response);
            setKeysFetched(true);
            setLoading(false);
          },
          failureCallback: (errorResponse: IErrorResponse) => {
            enqueueSnackbar(errorResponse?.error?.message || errorMessage);
            setLoading(false);
          },
        })
      );
    }
  }, [dispatch, getKeys, enqueueSnackbar, keysFetched, setKeysFetched]);

  // Create new API key
  const handleCreate = useCallback(
    (values: IApiKeyForm) => {
      const result = { name: values.name, expires: values.expires };

      setLoading(true);
      dispatch(
        createKey({
          ...result,
          successCallback: (response: { apiKey: string }) => {
            // Display key
            setCreatedKey(response.apiKey);
            setLoading(false);
            setKeysFetched(false);
            enqueueSnackbar(`Key "${result.name}" successfully created.`, {
              variant: 'success',
            });
          },
          failureCallback: (errorResponse: IErrorResponse) => {
            enqueueSnackbar(errorResponse?.error?.message || errorMessage);
            setLoading(false);
          },
        })
      );
    },
    [dispatch, createKey, enqueueSnackbar]
  );

  // Immediately deactivate an API key
  const handleRevoke = useCallback(() => {
    const apiKey = dialogControl.dialogConfig.entity;
    setLoading(true);
    dispatch(
      revokeKey({
        id: apiKey?.id,
        successCallback: () => {
          setLoading(false);
          setKeysFetched(false);
          dialogControl.closeDialog();
          enqueueSnackbar(`Key "${apiKey?.name}" successfully revoked.`, {
            variant: 'success',
          });
        },
        failureCallback: (errorResponse: IErrorResponse) => {
          setLoading(false);
          enqueueSnackbar(
            errorResponse?.error?.message ||
              'Something went wrong. Please contact us if this issue persists.'
          );
        },
      })
    );
  }, [dispatch, revokeKey, enqueueSnackbar, setKeysFetched, dialogControl, setLoading]);

  return (
    <>
      <Widget title="API Keys" maxWidth={800}>
        <WidgetTable
          columns={[
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Expiration',
              render: (apiKey: IApiKey) => {
                if (apiKey.expires === 'never') {
                  return 'Never';
                } else {
                  const keyExpires = moment(apiKey.expires);
                  return apiKey.active
                    ? 'Expires on ' + keyExpires.local().format('MM/DD/YYYY')
                    : 'Expired on ' + keyExpires.local().format('MM/DD/YYYY');
                }
              },
            },
          ]}
          data={apiKeys}
          emptyMessage="Once created, info on your API keys will be listed here."
          actions={[
            // Show "revoke" button if key is active
            (rowData: IApiKey) => ({
              // TableIcons aren't exactly the right type for some reason, so typecast
              icon: TableIcons.Clear,
              tooltip: 'Revoke API Key',
              onClick: () => dialogControl.openDialogForExisting(rowData, 'edit'),
              hidden: !rowData.active,
            }),
          ]}
          onFabClick={dialogControl.openDialogForNew}
          isLoading={loading}
          sort={
            // Sort the keys
            // All active keys before all inactive keys
            // All finitely active keys before all infinitely active keys
            // Active keys sorted by expiration date, ascending (first key expires the soonest)
            // Inactivate keys sorted by expiration date, descending (first key expired most recently)
            (key1: IApiKey, key2: IApiKey) => {
              if (key1.active && !key2.active) {
                return -1;
              } else if (!key1.active && key2.active) {
                return 1;
              } else if (key1.expires === 'never') {
                if (key2.expires === 'never') return 0;
                else return key1.active ? 1 : -1;
              } else if (key2.expires === 'never') {
                return key1.active ? -1 : 1;
              } else {
                const key1Expires = moment(key1.expires);
                const key2Expires = moment(key2.expires);
                return key1.active && key2.active
                  ? key1Expires.valueOf() - key2Expires.valueOf()
                  : key2Expires.valueOf() - key1Expires.valueOf();
              }
            }
          }
          noXray
        />
      </Widget>
      <ApiKeyDialog
        dialogControl={dialogControl}
        loading={loading}
        onCreate={handleCreate}
        onRevoke={handleRevoke}
        createdKey={createdKey}
        setCreatedKey={setCreatedKey}
      />
    </>
  );
};

export default ApiKeysWidget;
