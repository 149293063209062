import { faAdd, faPencil, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { wGroupIndicesWorkspace } from 'components/RootView/menu';
import LoadingInlay from 'components/general/LoadingInlay';
import RowedExplorer from 'components/general/RowedExplorer';
import Row from 'components/general/RowedExplorer/Row';
import ViewPortInlay from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import { IWorkspace } from 'components/general/types';
import { IUser } from 'components/general/types/general';
import { useEntityDialogControl, usePermissionCheck, useSelectByIds, useUser } from 'hooks';
import useWorkspace from 'hooks/useWorkspace';
import _ from 'lodash';
import moment from 'moment';
import { WorkspaceContext } from 'providers';
import { useContext } from 'react';
import { WorkspaceVables } from 'utils/vable';
import DefaultRoleDialog from './DefaultRoleDialog';
import MemberDialog from './MemberDialog/Main';

const MembersBoard = () => {
  const user = useUser();
  const workspace = useWorkspace();
  const roles = useSelectByIds('Role', workspace.roles);
  let members = useSelectByIds('User', workspace.members || []);
  if (!members.length) members = [user];

  const { fetchWorkspace, loading } = useContext(WorkspaceContext);

  const [canViewMembers, canManageMembers, canManageRoles, canEditWorkspace] = usePermissionCheck([
    WorkspaceVables.Permission.VIEW_WORKSPACE_MEMBERS,
    WorkspaceVables.Permission.ADD_REMOVE_WORKSPACE_MEMBER,
    WorkspaceVables.Permission.ASSIGN_REVOKE_WORKSPACE_ROLE,
    WorkspaceVables.Permission.EDIT_WORKSPACE,
  ]);

  const memberDialogControl = useEntityDialogControl<IUser>();
  const defaultRoleDialogControl = useEntityDialogControl<IWorkspace>();

  return (
    <WGroup index={wGroupIndicesWorkspace.MEMBERS} masonryConfig={{}}>
      <RowedExplorer
        rowsTitle="Members"
        secondaryButtonProps={{
          icon: faRefresh,
          onClick: fetchWorkspace,
          tooltip: 'Refresh members',
        }}
      >
        {loading ? (
          <LoadingInlay text="Your workspace is loading" />
        ) : (
          members.map((member) => (
            <Row
              key={member.id}
              name={`${member.firstName} ${member.lastName}`}
              description={`ID: ${member.id} | Role: ${
                roles.find((r) => member.roles.includes(r.id))?.name
              } | Email: ${member.email}`}
              editIconProps={
                canManageRoles
                  ? {
                      onClick: () => memberDialogControl.openDialogForExisting(member, 'edit'),
                      tooltip: 'Edit member role',
                    }
                  : undefined
              }
              trashIconProps={
                canManageMembers || member.id === user.id
                  ? {
                      onClick: () => memberDialogControl.openDialogForExisting(member, 'delete'),
                      tooltip:
                        member.id === user.id ? 'Remove self from workspace' : 'Remove member',
                    }
                  : undefined
              }
            />
          ))
        )}
        {!loading && !canViewMembers && (
          <ViewPortInlay text={`Insufficient permissions to view members of ${workspace.name}.`} />
        )}
      </RowedExplorer>
      {!loading && canManageMembers && (
        <>
          <br />
          <br />
          <RowedExplorer
            rowsTitle="Invitations"
            primaryButtonProps={{
              icon: faAdd,
              onClick: memberDialogControl.openDialogForNew,
              tooltip: 'Invite member',
            }}
            secondaryButtonProps={
              canEditWorkspace
                ? {
                    icon: faPencil,
                    onClick: () =>
                      defaultRoleDialogControl.openDialogForExisting(workspace, 'edit'),
                    tooltip: 'Edit default role',
                  }
                : undefined
            }
          >
            {!_.isEmpty(workspace.pendingInvites) ? (
              Object.entries(workspace.pendingInvites).map(([email, date]) => (
                <Row
                  key={email}
                  name={email}
                  description={`Expire${
                    moment(date).unix() > moment.now() / 1000 ? 's' : 'd'
                  }: ${moment(date).local().format('MMM Do, YYYY [at] h:mma')}`}
                  trashIconProps={{
                    onClick: () => memberDialogControl.openDialogForExisting({ email }, 'delete'),
                    tooltip: 'Cancel invitation',
                  }}
                  noHover
                />
              ))
            ) : (
              <ViewPortInlay text="No pending invites." />
            )}
          </RowedExplorer>
        </>
      )}
      {memberDialogControl.dialogConfig.open && (
        <MemberDialog workspace={workspace} control={memberDialogControl} />
      )}
      {defaultRoleDialogControl.dialogConfig.open && (
        <DefaultRoleDialog workspace={workspace} control={defaultRoleDialogControl} />
      )}
    </WGroup>
  );
};

export default MembersBoard;
