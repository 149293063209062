import { makeGuidance } from 'hooks';

const dataModeDescription =
  'Data Modes are used to model the creation and consumption of data by Components.';

export const dataModeGuidance = {
  _default: {
    heading: 'Data Modes',
    body: [
      { chunk: dataModeDescription },
      {
        subHeading: 'Components',
        chunk:
          'Associate one or more Components to this Data Mode, and it will behave independently on each of them.',
      },
      {
        subHeading: 'Data Types and Input/Output',
        chunk:
          'Select the Data Types associated to this data mode and specify the input and output rate (bit/s) for each Data Type. Input data is consumed/destroyed by the Component while output data is generated.',
      },
      {
        subHeading: 'Activity and OpModes',
        chunk:
          'Select the Operational Modes during which this Data Mode is active, or indicate that it is always active using the checkbox.',
      },
    ],
  },
};

export const useGuidance = makeGuidance(dataModeGuidance);

const dataModeSegmentGuidance = {
  heading: 'Data Modes',
  body: [{ chunk: dataModeDescription }],
};

export default dataModeSegmentGuidance;
