import { IGenericObject } from 'components/general/types';
const sunSyncDescription =
  'A Sun-synchronous orbit provides nearly constant orbit geometry relative to the Sun and is often useful for imaging, weather, and other remote sensing missions.';
const geoDescription =
  "A geostationary orbit provides a constant position relative to the Earth's surface, which is valuable to communications, weather, and navigation missions.";
const geoTranDescription =
  'A geostationary transfer orbit is a Hohmann transfer orbit typically used to transfer between an equatorial low earth orbit (LEO) and geostationary orbit. Therefore, the apogee altitude is geostationary orbit altitude (about 35,786km).';
const issDescription =
  'The International Space Station (ISS) orbit is nearly circular with an altitude of about 410km and inclination of about 51.6 degrees. Many small satellites are deployed from the ISS or from launch vehicles resupplying the station.';
const polarCircDescription =
  'A polar circular orbit provides global coverage, which is often valuable for Earth observation and communications missions.';
const equatorialCircDescription =
  'An equatorial circular orbit provides reduced launch vehicle requirements and a consistent groundtrack.';
const eciDescription =
  "In the J2000 Earth-centered inertial (ECI) reference frame, the xy-plane is coincident with Earth's mean equator. The z-axis and the x-axis are aligned with celestial North and the vernal equinox as they were at 12:00 Terrestrial time on January 1, 2000.";
const ecefDescription =
  "Earth-centered, Earth-fixed (ECEF) coordinates (both spherical and cartesian) are defined in the Mission Module based on the WGS84 ellipsoid (for geodetic altitude) and polar rotation rate. The Mission Module does not consider the irregularities of Earth's rotation.\nIn this reference frame, the xy-plane is coincident with Earth's mean equator, the z-axis points to the North Pole, and the x-axis points to the prime (Greenwich) meridian.";
const tleDescription =
  'A TLE encodes a list of orbital elements for a given epoch. The US Air Force tracks objects in orbit and provides regularly updated TLEs to the public. Sedaro propagates your input TLE forward or backward to the start time of your mission and uses this state to set the initial conditions for orbit propagation.';

const orbitPermanentGuidance =
  'Define the Initial Orbit of the Agents in this Scenario. Orbits can be defined from Orbital Elements, a State Vector, a Reference Orbit, or a TLE.';
export const orbitGuidance = {
  _default: {
    heading: 'Orbits',
    body: [
      {
        chunk: orbitPermanentGuidance,
      },
    ],
  },

  initialStateDefType: {
    heading: 'How to Define Your Orbit',
    body: [
      {
        chunk:
          'Orbits can be specified by the initial classical orbital elements, position and velocity state, or TLE or by using a built-in Reference Orbit, such as Geostationary or Sun-sync. Select how you would like to define the initial state of your orbit. Once you make a selection, additional inputs and guidance will appear.',
      },
    ],
  },

  initialStateDefParams: {
    a: [
      {
        chunk:
          'Define semimajor axis, inclination, and eccentricity to determine what additional elements are required to fully define the initial state of your orbit. Inclination should be defined relative to the xy-plane of the J2000 ECI reference frame.',
      },
      {
        subHeading: 'Reference Frame',
        chunk: eciDescription,
      },
    ],

    inc: {
      alias: 'initialStateDefParams.a',
    },

    e: {
      alias: 'initialStateDefParams.a',
    },

    tle: [
      {
        chunk: 'Input a Two-Line Element. Both two- and three- line formats are supported.',
      },
      {
        chunk: tleDescription,
      },
    ],

    stateEci: {
      body: [
        {
          chunk:
            'Input the initial position and velocity components in the J2000 ECI reference frame.',
        },
        {
          subHeading: 'Reference Frame',
          chunk: eciDescription,
        },
      ],
    },

    initialRefOrbit: {
      body: [
        {
          chunk:
            'Select a reference orbit type. Once you make a selection, information on the selected reference orbit type and additional inputs will appear. ',
        },
        {
          subHeading: 'Polar Circular Orbit',
          chunk: polarCircDescription,
        },
        {
          subHeading: 'Equatorial Circular Orbit',
          chunk: equatorialCircDescription,
        },
        {
          subHeading: 'Sun-synchronous Circular Orbit',
          chunk: sunSyncDescription,
        },
        {
          subHeading: 'Geostationary Orbit',
          chunk: geoDescription,
        },
        {
          subHeading: 'Geostationary-Transfer Orbit',
          chunk: geoTranDescription,
        },
        {
          subHeading: 'International Space Station Orbit',
          chunk: issDescription,
        },
      ],
    },

    alt: (values: IGenericObject) => {
      const refOrbitType = values.initialStateDefParams.initialRefOrbit.value;
      if (refOrbitType === 'POLAR_CIRC') {
        return {
          heading: 'Polar Circular Orbit',
          body: [
            {
              chunk:
                polarCircDescription +
                ' Input altitude, right ascension of the ascending node (RAAN), and argument of latitude. Argument of latitude and RAAN should be defined relative to the xy-plane and x-axis of the J2000 ECI reference frame, respectively.',
            },
            {
              subHeading: 'Notes',
              chunk:
                'If you would like to impose nearly consistent local time conditions (e.g. for ground target lighting), consider a near-polar Sun-synchronous reference orbit type instead (commonly 700km - 800km altitude for near-polar). \nArgument of latitude and RAAN will oscillate - argument of latitude will complete a cycle each orbital period and RAAN will oscillate at a much slower rate driven by other orbital elements. So, unless your orbit uses RAAN-rate to synchronize with Earth or Sun motion (e.g. Sun-sync or repeat-groundtrack orbits), setting these to the exact value anticipated at the outset of your mission is usually not important. Also note that altitude here is relative to WGS84 global mean sea level (MSL).',
            },
            {
              subHeading: 'Reference Frame',
              chunk: eciDescription,
            },
          ],
        };
      } else if (refOrbitType === 'EQUATORIAL_CIRC') {
        return {
          heading: 'Equatorial Circular Orbit',
          body: [
            {
              chunk:
                equatorialCircDescription +
                ' Input altitude and true longitude. True longitude should be defined relative to the x-axis of the J2000 ECI reference frame.',
            },
            {
              subHeading: 'Notes',
              chunk:
                'The reduced revisit time over near-equatorial ground targets can be valuable for Earth observation and communications missions.  Note that altitude here is relative to WGS84 equatorial mean sea level (MSL). \nTrue longitude is measured from the x-axis of the inertial reference frame (i.e. the vernal equinox of J2000). True longitude will oscillate, completing a cycle each orbital period. So, setting true longitude to the exact value anticipated at the outset of your mission is usually not important.',
            },
            {
              subHeading: 'Reference Frame',
              chunk: eciDescription,
            },
          ],
        };
      } else {
        return {
          heading: 'Sun-Synchronous Circular Orbit',
          body: [
            {
              chunk:
                sunSyncDescription +
                ' This type of orbit provides consistent illumination of the satellite and ground targets beneath the satellite. Input altitude, ascending node mean local time (MLT), and true anomaly.',
            },
            {
              subHeading: 'Notes',
              chunk:
                'Note that altitude here is relative to WGS84 global mean sea level (MSL). \nTrue anomaly will oscillate, completing a cycle each orbital period. So, setting true anomaly to the exact value anticipated at the outset of your mission is usually not important. The ascending node MLT "clocks" the orbit - dictating what the MLT will be each time the satellite passes the equator from south-to-north.',
            },
          ],
        };
      }
    },

    raan: (values: IGenericObject) => {
      const stateDefinitionType = values.initialStateDefType.value;
      const inc = parseFloat(values.initialStateDefParams.inc);
      const e = parseFloat(values.initialStateDefParams.e);
      if (stateDefinitionType === 'ORBITAL_ELEMENTS') {
        if (inc !== 0 && e > 0) {
          return {
            heading: 'Eccentric-Inclined Orbit',
            body: [
              {
                chunk:
                  'Your orbit is eccentric-inclined. Input right ascension of the ascending node (RAAN), argument of perigee, and true anomaly. Argument of perigee and RAAN should be defined relative to the xy-plane and x-axis of the J2000 ECI reference frame, respectively.',
              },
              {
                subHeading: 'Notes',
                chunk:
                  'True anomaly and RAAN will oscillate - true anomaly will complete a cycle each orbital period and RAAN will oscillate at a much slower rate driven by other orbital elements. So, unless your orbit uses RAAN-rate to synchronize with Earth or Sun motion (e.g. Sun-sync or repeat-groundtrack orbits),  setting these to the exact value anticipated at the outset of your mission is usually not important.',
              },
              {
                subHeading: 'Reference Frame',
                chunk: eciDescription,
              },
            ],
          };
        } else if (inc === 0 && e === 0) {
          return {
            heading: 'Circular-Equatorial Orbit',
            body: [
              {
                chunk:
                  'Your orbit is circular-equatorial. As an equatorial orbit, the right ascension of the ascending node (RAAN) is undefined and set to zero. As a circular orbit, the true longitude of perigee is undefined and set to zero. Input true longitude defined relative to the x-axis of the J2000 ECI reference frame.',
              },
              {
                subHeading: 'Notes',
                chunk:
                  'True longitude will oscillate - completing a cycle each orbital period. So, setting this to the exact value anticipated at the outset of your mission is usually not important.',
              },
              {
                subHeading: 'Reference Frame',
                chunk: eciDescription,
              },
            ],
          };
        } else if (inc !== 0 && e === 0) {
          return {
            heading: 'Circular-Inclined Orbit',
            body: [
              {
                chunk:
                  'Your orbit is circular-inclined. In a circular orbit, argument of perigee is undefined and set to zero. Input right ascension of the ascending node (RAAN) and argument of latitude. Argument of latitude and RAAN should be defined relative to the xy-plane and x-axis of the J2000 ECI reference frame, respectively.',
              },
              {
                subHeading: 'Notes',
                chunk:
                  'Argument of latitude and RAAN will oscillate - argument of latitude will complete a cycle each orbital period and RAAN will oscillate at a much slower rate driven by other orbital elements. So, unless your orbit uses RAAN-rate to synchronize with Earth or Sun motion (e.g. Sun-sync or repeat-groundtrack orbits),  setting these to the exact value anticipated at the outset of your mission is usually not important.',
              },
              {
                subHeading: 'Reference Frame',
                chunk: eciDescription,
              },
            ],
          };
        } else {
          return {
            heading: 'Elliptical-Equatorial Orbit',
            body: [
              {
                chunk:
                  'Your orbit is elliptical-equatorial. As an equatorial orbit, the right ascension of the ascending node (RAAN) is undefined and set to zero. Input true longitude of perigee and true anomaly. True longitude of perigee should be defined relative to the x-axis of the J2000 ECI reference frame.',
              },
              {
                subHeading: 'Notes',
                chunk:
                  'True anomaly will oscillate, completing a cycle each orbital period. So, setting true anomaly to the exact value anticipated at the outset of your mission is usually not important.',
              },
              {
                subHeading: 'Reference Frame',
                chunk: eciDescription,
              },
            ],
          };
        }
      } else {
        const refOrbitType = values.initialStateDefParams.initialRefOrbit.value;
        if (refOrbitType === 'POLAR_CIRC') {
          return {
            alias: 'initialStateDefParams.alt',
          };
        } else {
          return {
            heading: 'International Space Station',
            body: [
              {
                chunk:
                  issDescription +
                  ' Input right ascension of the ascending node (RAAN) and true anomaly. RAAN should be defined relative to the x-axis of the J2000 ECI reference frame.',
              },
              {
                subHeading: 'Notes',
                chunk:
                  'True anomaly and RAAN will oscillate - true anomaly will complete a cycle each orbital period and RAAN will oscillate at a much slower rate driven by other orbital elements. So, setting these to the exact value anticipated at the outset of your mission is usually not important.',
              },
              {
                subHeading: 'Reference Frame',
                chunk: eciDescription,
              },
            ],
          };
        }
      }
    },

    om: (values: IGenericObject) => {
      const stateDefinitionType = values.initialStateDefType.value;
      if (stateDefinitionType === 'ORBITAL_ELEMENTS') {
        return {
          alias: 'initialStateDefParams.raan',
        };
      } else {
        return {
          alias: 'initialStateDefParams.altPerigee',
        };
      }
    },

    nu: (values: IGenericObject) => {
      const stateDefinitionType = values.initialStateDefType.value;
      if (stateDefinitionType === 'ORBITAL_ELEMENTS') {
        return {
          alias: 'initialStateDefParams.raan',
        };
      } else {
        const refOrbitType = values.initialStateDefParams.initialRefOrbit.value;
        if (
          refOrbitType === 'POLAR_CIRC' ||
          refOrbitType === 'EQUATORIAL_CIRC' ||
          refOrbitType === 'SUN_SYNC_CIRC'
        ) {
          return {
            alias: 'initialStateDefParams.alt',
          };
        } else if (refOrbitType === 'GEOSTAT_TRANSFER') {
          return {
            alias: 'initialStateDefParams.altPerigee',
          };
        } else {
          return {
            alias: 'initialStateDefParams.raan',
          };
        }
      }
    },

    mltAscNode: {
      alias: 'initialStateDefParams.alt',
    },

    lon: [
      {
        chunk:
          geoDescription +
          ' Input the geodetic longitude where the satellite will be positioned over the equator. Geodetic longitude is defined relative to the x-axis of the ECEF reference frame.',
      },
      {
        subHeading: 'Reference Frame',
        chunk: ecefDescription,
      },
    ],

    altPerigee: {
      heading: 'Geostationary Transfer Orbit',
      body: [
        {
          chunk:
            geoTranDescription +
            ' Input perigee altitude, true longitude of perigee, and true anomaly. True longitude of perigee should be defined relative to the x-axis of the J2000 ECI reference frame.',
        },
        {
          subHeading: 'Notes',
          chunk:
            'True anomaly will oscillate, completing a cycle each orbital period. So, setting true anomaly to the exact value anticipated at the outset of your mission is usually not important. Also note that altitude here is relative to WGS84 equatorial mean sea level (MSL).',
        },
        {
          subHeading: 'Reference Frame',
          chunk: eciDescription,
        },
      ],
    },
  },
};
