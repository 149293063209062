const thermalChunk = 'Specify the maximum and minimum temperatures.';
const thermalHeading = 'Thermal Properties';
const genericThermalGuidance = {

  hotTempRating: {
    degC: {
      heading: thermalHeading,
      body: [
        {
          chunk:
          thermalChunk,
        },
      ],
    }
  },
  coldTempRating: {
    degC: {
      heading: thermalHeading,
      body: [
        {
          chunk:
          thermalChunk,
        },
      ],
    }
  },
  };
export default genericThermalGuidance;