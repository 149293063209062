import { makeGuidance } from 'hooks';

const dataBusDescription =
  'A Data Bus is a collection of internal Data Interfaces, currently only used for analytics purposes.';

export const dataBusGuidance = {
  _default: {
    heading: 'Data Buses',
    body: [{ chunk: dataBusDescription }],
  },
};

export const useGuidance = makeGuidance(dataBusGuidance);

const dataBusSegmentGuidance = {
  heading: 'Data Buses',
  body: [{ chunk: dataBusDescription }],
};

export default dataBusSegmentGuidance;
