import { TChartSpec } from 'components/general/types';

const tsEnergyStorageChartSpec: TChartSpec = [
  {
    name: 'Battery State of Charge (SoC)',
    subtitle: '',
    plots: [
      {
        type: 'line',
        label: 'State of Charge',
        variables: [
          {
            keys: ['Battery.$0.soc'],
            legend: ['State of Charge (SoC)'],
          },
        ],
      },
    ],
  },
  {
    name: 'Battery Voltage and Current',
    subtitle: '',
    plots: [
      {
        type: 'line',
        label: 'Voltage',
        unit: 'V',
        step: 'end',
        labelRight: 'Current',
        unitRight: 'A',
        variables: [
          {
            keys: ['Battery.$0.voltage', 'Battery.$0.voc'],
            legend: ['Voltage', 'Open Circuit Voltage'],
          },
          {
            keys: ['Battery.$0.current'],
            legend: ['Current'],
            right: true,
          },
        ],
      },
    ],
  },
];

export default tsEnergyStorageChartSpec;
