import { makeGuidance } from 'hooks';

const reservoirMainParagraph =
  'Fuel Reservoirs are an abstract collection of Fuel Tanks from which thrusters draw.';

export const reservoirGuidance = {
  _default: {
    heading: 'Create and Edit Fuel Reservoir',
    body: [
      { chunk: reservoirMainParagraph },
      {
        chunk:
          'Select the Fuel Tanks which make up this Fuel Reservoir. Tanks with higher priority will be used first.',
      },
    ],
  },
};

export const useGuidance = makeGuidance(reservoirGuidance);

const reservoirSegmentGuidance = {
  heading: 'Fuel Reservoirs',
  body: [
    {
      chunk: reservoirMainParagraph,
    },
  ],
};

export default reservoirSegmentGuidance;
