import { statsAttitudeControlChartSpec } from 'components/AgentAnalyzeView/AnalyzeBoards/GncAnalyzeBoards/stats';
import { GNC } from 'components/AgentTemplateEditView/menu/gnc';
import { IMenu } from 'components/general/types';
import { CHART_SPEC, SUB_ITEMS, TITLE } from 'config';
import { routePathsCommon } from 'routes';
import {
  tsAdodChartSpec,
  tsAttitudeControlChartSpec,
  tsOrbitChartSpec,
  tsPerturbationsChartSpec,
  tsPropulsionChartSpec,
} from '../AnalyzeBoards/GncAnalyzeBoards/timeSeries';

const { PLAYBACK, TS, WS } = routePathsCommon;

const AD_OD = 'ad-od';
const PERTURBATIONS = 'perturbations';
const ATTITUDE_CONTROL = 'attitude-control';
const PROPULSION = 'propulsion';
const ORBIT = 'orbit';

const agentGncMenu: IMenu['items'] = {
  [GNC]: {
    [TITLE]: 'GNC',
    [SUB_ITEMS]: {
      [PLAYBACK]: {
        [TITLE]: 'Playback',
      },
      [TS]: {
        [TITLE]: 'Time Series',
        [SUB_ITEMS]: {
          [ORBIT]: {
            [TITLE]: 'Orbit',
            [CHART_SPEC]: tsOrbitChartSpec,
          },
          [AD_OD]: {
            [TITLE]: 'AD & OD',
            [CHART_SPEC]: tsAdodChartSpec,
          },
          [PERTURBATIONS]: {
            [TITLE]: 'Perturbations',
            [CHART_SPEC]: tsPerturbationsChartSpec,
          },
          [ATTITUDE_CONTROL]: {
            [TITLE]: 'Attitude Control',
            [CHART_SPEC]: tsAttitudeControlChartSpec,
          },
          [PROPULSION]: {
            [TITLE]: 'Propulsion',
            [CHART_SPEC]: tsPropulsionChartSpec,
          },
        },
      },
      [WS]: {
        [TITLE]: 'Window Statistics',
        [SUB_ITEMS]: {
          [ATTITUDE_CONTROL]: {
            [TITLE]: 'Attitude Control',
            [CHART_SPEC]: statsAttitudeControlChartSpec,
          },
        },
      },
    },
  },
};

export const wGroupIndicesAgentGnc = {
  GNC: `${GNC}*`,
  PLAYBACK: `${GNC}.${PLAYBACK}`,
  TS_ORBIT: `${GNC}.${TS}.${AD_OD}`,
  TS_ATTITUDE: `${GNC}.${TS}.${PERTURBATIONS}`,
  TS_BFV: `${GNC}.${TS}.${ATTITUDE_CONTROL}`,
  TS_PROP: `${GNC}.${TS}.${PROPULSION}`,
};

export default agentGncMenu;
