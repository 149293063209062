import { TimeProvider } from 'providers';
import Agents from './Agents';
import Playback from './Playback';

const AnalyzeBoards = () => {
  return (
    <>
      <TimeProvider>
        <Playback />
      </TimeProvider>
      <Agents />
    </>
  );
};
export default AnalyzeBoards;
