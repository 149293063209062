import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IDataType } from 'components/general/types/dataHandling';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { translateIn, translateOut } from 'utils/forms';
import { useGuidance } from './guidance';
import validation from './validation';

interface IForm {
  name: string;
}

interface IProps {
  control: TEntityDialogControl<IDataType>;
}

const defaultValues = {
  name: '',
};

const DataTypeDialog = ({ control }: IProps) => {
  // const { control } = props;
  const classes = useStyles();
  const entityForm = useEntityForm<IDataType, IForm>({
    entityTypeText: 'Data Type',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    additionalCreateValues: { type: 'DataType' },
    formikOptionalParams: {
      useGuidance,
      translateIn,
      translateOut,
    },
  });
  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <LabeledInput
          {...getFieldProps('name')}
          label="Data Type Name"
          type="text"
          placeholder="Name"
          autoFocus
        />
      </div>
    </EntityDialog>
  );
};

export default DataTypeDialog;
