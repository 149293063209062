import { AxisTickProps } from '@nivo/axes';
import { TExtendedPowerBarDatum } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import { IGenericObject } from 'components/general/types';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { animated } from 'react-spring';
import useStyles from './general/styles';
import { barTheme } from './styles';
interface IProps {
  tick: AxisTickProps<string>;
  barData: TExtendedPowerBarDatum[];
  nameIndexMap: { [index: string]: number };
  leftMargin: number;
  setMaxTickWidth: React.Dispatch<React.SetStateAction<number>>;
}
interface IFontSizeIndentMap {
  [key: number]: {
    fontSize: number;
    indent: number;
  };
}
const fontSizeIndentMap: IFontSizeIndentMap = {
  1: { fontSize: 16, indent: 30 },
  2: { fontSize: 16, indent: 40 },
  3: { fontSize: 14, indent: 50 },
};

const CustomTick = (props: IProps) => {
  const { tick, barData, nameIndexMap, leftMargin, setMaxTickWidth } = props;
  const classes = useStyles();

  const hierarchy = useMemo(
    () => barData[nameIndexMap[tick.value] - 1].hierarchy,
    [barData, nameIndexMap, tick.value]
  );

  const { fontSize, indent } = useMemo(() => fontSizeIndentMap[hierarchy], [hierarchy]);

  const leftEdge = useMemo(() => -leftMargin + indent, [leftMargin, indent]);

  // Upon rendering, checks if this tick is the widest, and if so, sets the max tick width accordingly
  const tickRefCallback = useCallback(
    (tickRef) => {
      const tickWidth = tickRef?.getBBox()?.width + indent || null;
      if (tickWidth) setMaxTickWidth((prev) => (tickWidth > prev ? tickWidth : prev));
    },
    [setMaxTickWidth, indent]
  );

  const labelArr = tick.value.split('|');
  const labelStr = labelArr.slice(0, labelArr.length - 1).join('|');

  return (
    <animated.g
      // passing in nivo's premade react-spring animation props
      style={tick.animatedProps as IGenericObject}
      transform={`translate(${tick.x},${tick.y + 2})`}
      className={classes.nonInteractable}
      ref={tickRefCallback}
    >
      <text
        textAnchor="beginning"
        dominantBaseline="middle"
        x={leftEdge}
        style={{
          ...barTheme.axis.ticks.text,
          fontSize: fontSize,
        }}
      >
        {labelStr}
      </text>
    </animated.g>
  );
};

export default CustomTick;
