import { useMemo, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import { TBarData } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';

interface IProps {
  bar: TBarData;
  leftMargin: number;
  expanded: boolean | undefined;
  onClick: () => void;
}

const dropdownHeight = 10;

const HoverHighlight = (props: IProps) => {
  const { bar, leftMargin, expanded, onClick } = props;
  const classes = useStyles(props);
  const [highlightWidth, setHighlightWidth] = useState<number | null>(null);

  const canToggle = useMemo(() => expanded !== undefined, [expanded]);
  const barMargin = useMemo(() => bar.height * 0.3, [bar.height]);
  const highlightHeight = useMemo(() => bar.height + barMargin * 2, [bar.height, barMargin]);

  return (
    <g
      transform={`translate(${-leftMargin},${bar.y - barMargin})`}
      className={classes.highlightBar}
      onClick={() => {
        canToggle && onClick();
      }}
    >
      <rect
        height={highlightHeight}
        width="100%"
        // instead of storing element and its width in a useRef, which doesn't re-render when the ref changes, the element's width is extracted thru a "callback ref"
        // more info on callback refs here: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
        ref={(highlightRef) => setHighlightWidth(highlightRef?.getBBox()?.width || null)}
      />
      {canToggle && highlightWidth && (
        <ExpandMoreIcon
          viewBox=""
          x={highlightWidth - 24}
          y={highlightHeight / 2 - dropdownHeight}
          className={classes.barExpandIcon}
        />
      )}
    </g>
  );
};

export default HoverHighlight;
