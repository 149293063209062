import { IGuidanceCard } from 'components/general/types';
import { TSubsystemCategoriesKeys } from 'utils/vable';

const powerGuidance = {
  heading: 'Power Subsystem',
  body: [
    {
      chunk:
        'The power subsystem houses all components related to generating, distributing, and storing electrical energy. It commonly includes the power processor, solar arrays, and battery - along with supporting components such as battery heaters and solar array drive assemblies.',
    },
  ],
};
const cCADHGuidance = {
  heading: 'Command & Control Subsystem',
  body: [
    {
      chunk:
        "The Command & Control subsystem houses all components related to controlling the satellite's operations throughout the mission. The primary command and control component is typically a central flight computer, but this subsystem can also include components for timekeeping, health monitoring, and security.",
    },
  ],
};
const commsGuidance = {
  heading: 'Data Handling Subsystem',
  body: [
    {
      chunk:
        'The data handling subsystem houses all components related to transmitting and receiving data between system components or between this vehicle and other nodes in your mission architecture (i.e. ground stations, other spacecraft, user terminals, etc.). It commonly includes amplifiers, antennas, radios, and mass memory units.',
    },
  ],
};
const gNCGuidance = {
  heading: 'GNC Subsystem',
  body: [
    {
      chunk:
        "The Guidance, Navigation, and Control subsystem houses all components related to determining and controlling the attitude of the satellite in addition to all components related to controlling the spacecraft's trajectory. It commonly includes star trackers, sun sensors, magnetometers, reaction wheels, thrusters, and propellant tanks.",
    },
  ],
};
const thermalGuidance = {
  heading: 'Thermal Subsystem',
  body: [
    {
      chunk:
        'The thermal subsystem houses all components related to determining and controlling the thermal state of the satellite. It commonly includes heaters, chillers, reflectors, radiators, and thermal sensors.',
    },
  ],
};
const structureGuidance = {
  heading: 'Structures Subsystem',
  body: [
    {
      chunk:
        'The structures subsystem houses all components that are responsible for the mechanical and/or thermal interconnect of most other vehicle subsystems and their components. This includes panels, struts, thrust tubes, bulkheads, launch vehicle interfaces, etc. - all of various material compositions (aluminum, composite, etc.).',
    },
  ],
};
const customSubsystemGuidance = {
  heading: 'Custom Subsystem',
  body: [
    {
      chunk:
        "Custom subsystems can be created for an other purpose not covered by the defaults.  Much like the defaults, custom subsystems can be assigned the components that carry out the subsystem's function.",
    },
  ],
};

const ssGuidanceObj: { [key in TSubsystemCategoriesKeys]: IGuidanceCard } = {
  POWER: powerGuidance,
  CDH: cCADHGuidance, // eslint-disable-line no-useless-computed-key
  DATA_HANDLING: commsGuidance,
  GNC: gNCGuidance,
  THERMAL: thermalGuidance,
  STRUCTURE: structureGuidance,
  CUSTOM: customSubsystemGuidance,
};

export default ssGuidanceObj;
