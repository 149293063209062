import { Grid } from '@material-ui/core';
import Dialog from 'components/general/dialogs/Dialog';
import GuidanceCard from 'components/general/GuidanceCard';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import { IRole } from 'components/general/types';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { TPermission, WorkspaceVables } from 'utils/vable';

interface IProps {
  control: TEntityDialogControl<IRole>;
}

const RoleDialog = (props: IProps) => {
  const { control } = props;
  const {
    dialogConfig: { entity: role, open },
    closeDialog,
  } = control;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      prompt={`${role?.name} permissions`}
      onClose={closeDialog}
      secondaryActionText="Close"
      large
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className={classes.swapRight}>
          {Object.values(WorkspaceVables.PermissionName.options).flatMap((permission) => {
            if (role) {
              const permValue = WorkspaceVables.Permission[permission.value as TPermission];
              return (
                <LabeledCheckbox
                  key={permission.label}
                  label={permission.label}
                  value={(role.permissions & permValue) === permValue}
                  disabled
                />
              );
            } else return [];
          })}
        </Grid>
        <Grid item xs={12} md={6} className={classes.swapLeft}>
          <GuidanceCard
            guidance={{
              heading: 'Roles',
              body: `In Sedaro, permissions within a workspace are defined by roles. A user with a certain role \
              can only perform the actions that the role allows. These permissions are dynamic in the sense that workspace \
              Administrators and Owners can update the roles of members as needed, at any time. This allows for precise \
              access control within a workspace - your models are only updated by those you trust.
              `,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RoleDialog;
