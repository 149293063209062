import { wGroupIndicesATDataHandling } from 'components/AgentTemplateEditView/menu/dataHandling';
import WGroup from 'components/general/WGroup';
import Wizard from 'components/general/wizards/Wizard';
import DataBusSegment from './DataBusSegment';
import DataInterfaceSegment from './DataInterfaceSegment';
import DataModeSegment from './DataModeSegment';
import DataStorageSegment from './DataStorageSegment';
import DataTypeSegment from './DataTypeSegment';
import DeviceSegment from './DeviceSegment';

const DataHandlingEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATDataHandling.DATA_HANDLING}>
      <Wizard>
        <DataTypeSegment index={wGroupIndicesATDataHandling.DATA_TYPES} />
        <DataStorageSegment index={wGroupIndicesATDataHandling.DATA_STORAGE} />
        <DeviceSegment index={wGroupIndicesATDataHandling.DEVICE} />
        <DataInterfaceSegment index={wGroupIndicesATDataHandling.DATA_INTERFACE} />
        <DataModeSegment index={wGroupIndicesATDataHandling.DATA_MODE} />
        <DataBusSegment index={wGroupIndicesATDataHandling.DATA_BUS} />
      </Wizard>
    </WGroup>
  );
};

export default DataHandlingEditBoard;
