import { IColumn } from 'components/general/types';
import { IDataType } from 'components/general/types/dataHandling';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import DataTypeDialog from './DataTypeDialog';
import guidance from './guidance';

interface IProps {
  index: string;
}

const dataTypesColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
];

const DataTypeSegment = ({ index }: IProps) => {
  const { dataTypes } = useActiveEntities();

  return (
    <EntityTableSegment<IDataType>
      title="Data Types"
      index={index}
      guidance={guidance}
      entityColumns={dataTypesColumns}
      entityData={dataTypes}
      DialogComponent={DataTypeDialog}
      modelName="data type"
    ></EntityTableSegment>
  );
};

export default DataTypeSegment;
