import MomentUtils from '@date-io/moment';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from 'app';
import { MasonryProvider, SnackbarProvider } from 'providers';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { StrictMode } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import store from 'store';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import GlobalStyle from 'theme/GlobalStyle';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <HashRouter>
              <SnackbarProvider>
                <MasonryProvider>
                  <DndProvider options={HTML5toTouch}>
                    <App />
                  </DndProvider>
                </MasonryProvider>
              </SnackbarProvider>
            </HashRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// ========================================
