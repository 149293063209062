import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface ITableData {
  checked: boolean;
}

interface INamedBlock {
  name: string;
  id: string;
}

interface ITableBlock extends INamedBlock {
  tableData: ITableData;
}
const useSelectBlocks = (
  blocks: INamedBlock[] | undefined | '',
  relatedBlocks: INamedBlock[] | undefined | '',
  extraFields: string[] | undefined = undefined
) => {
  const [parsedBlocks, setParsedBlocks] = useState<ITableBlock[]>([]);
  const initBlocks = useCallback(() => {
    let _parsedBlocks: ITableBlock[] = [];
    const relatedOrEmpty = relatedBlocks ? relatedBlocks : [];
    if (blocks) {
      _parsedBlocks = blocks
        .filter((c) => c != null)
        .map((c) => {
          const tableData: ITableData = { checked: false };
          const extra = _.pick(c, extraFields || []);
          if (relatedOrEmpty?.map(({ id }) => id).includes(c.id)) {
            tableData.checked = true;
          } else {
            tableData.checked = false;
          }
          return {
            ...extra,
            id: c.id,
            name: c.name,
            tableData,
          };
        });
    }
    setParsedBlocks(_parsedBlocks);
  }, [blocks, setParsedBlocks, relatedBlocks, extraFields]);
  useEffect(() => {
    initBlocks();
  }, [initBlocks]);
  return { parsedBlocks, initBlocks, setParsedBlocks };
};

export default useSelectBlocks;
