import { TChartSpec } from 'components/general/types';

const statsSurfaceRadiativeHeatTransferChartSpec: TChartSpec = [
  {
    name: 'Surface Average Total Radiative Heat Transfer',
    plots: [
      {
        type: 'margin',
        unit: 'wday',
        variables: [
          {
            each: 'Surface',
            legend: ['{name}'],
            keys: ['heatFlowRate'],
            ops: ['integral'],
          },
        ],
      },
    ],
  },
];

export default statsSurfaceRadiativeHeatTransferChartSpec;
