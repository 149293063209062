import { Grid } from '@material-ui/core';
import GuidanceCard from 'components/general/GuidanceCard';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import {
  IErrorResponse,
  IGenericObject,
  ISelectOption,
  IWorkspace,
} from 'components/general/types';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useFormikForm, useSelectByIds, useSnackbar } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

interface IProps {
  workspace: IWorkspace;
  control: TEntityDialogControl<IWorkspace>;
}

interface IDefaultRoleForm {
  defaultRole: ISelectOption | '';
}

const defaultValues: IDefaultRoleForm = {
  defaultRole: '',
};

const DefaultRoleDialog = (props: IProps) => {
  const { control, workspace } = props;
  const [loading, setLoading] = useState(false);
  const { dialogConfig, closeDialog } = control;
  const roles = useSelectByIds('Role', workspace.roles);
  const options = useMemo(
    () => ({
      defaultRole: roles
        .sort((r1, r2) => r2.permissions - r1.permissions)
        .map((r) => ({ value: r.id, label: r.name })),
    }),
    [roles]
  );

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const {
    Workspace: {
      actions: { updateWorkspace },
    },
  } = SatelliteApi;

  const updateDefaultRole = (values: IDefaultRoleForm) => {
    setLoading(true);
    dispatch(
      updateWorkspace({
        id: workspace.id,
        defaultRole: values.defaultRole,
        successCallback: (response: IGenericObject) => {
          closeDialog();
          enqueueSnackbar(
            `Default role successfully updated to "${
              roles.find((r) => r.id === values.defaultRole)?.name
            }".`,
            {
              variant: 'success',
            }
          );
          setLoading(false);
        },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  const { formik } = useFormikForm<IWorkspace, IDefaultRoleForm>(
    defaultValues,
    updateDefaultRole,
    Yup.object(),
    workspace,
    { options }
  );

  const { handleSubmit, getFieldProps, resetForm } = formik;

  // use Effect to reset form whenever dialog is closed
  useEffect(() => {
    if (!dialogConfig.open) {
      resetForm();
    }
  }, [dialogConfig.open, resetForm]);

  return (
    <Dialog
      prompt="Update default role"
      open={dialogConfig.open}
      onSubmit={handleSubmit}
      onClose={closeDialog}
      large
      loading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <div className={classes.inputGroup}>
              <LabeledSelect
                {...getFieldProps('defaultRole')}
                options={options.defaultRole}
                label="Role"
                autoFocus
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard
            guidance={{
              heading: 'Default Role',
              body: `Select a default role for your workspace. When a new member accepts an invitation (including \
              invitations that are already pending), they will be assigned this role by default. Once they have \
              joined, members with the appropriate permissions may re-assign roles as necessary.
              The default role selection has no bearing on the roles of current members.`,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DefaultRoleDialog;
