import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';

const thumbColor = theme.palette.info.main;

// Grabbing style slider thumb size to properly add horizontal padding on the responsive container, otherwise the thumbs clip
export const thumbSize = 18;

export const ThumbComponent = (props) => {
  return <span {...props}></span>;
};

const StyledSlider = withStyles(
  (theme) => ({
    root: {
      color: theme.palette.primary.main,
      height: 3,
      padding: '13px 0',
    },
    thumb: {
      color: theme.palette.primary.contrastText,
      height: thumbSize,
      width: thumbSize,
      backgroundColor: thumbColor,
      border: `2px solid ${thumbColor}`,
      marginTop: -8,
      marginLeft: -9,
      boxShadow: theme.shadows[6],
      '&:focus, &:hover, &$active': {
        boxShadow: theme.shadows[8],
      },
      '& .bar': {
        // display: inline-block !important;
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    active: {},
    track: {
      height: 3,
    },
    rail: {
      color: theme.palette.action.active,
      opacity: 1,
      height: theme.dimensions.rail.height,
    },
  }),
  { withTheme: true }
)(Slider);

export default StyledSlider;
