import { makeStyles } from '@material-ui/core/styles';

const thumbSize = 22;
const topThumbMargin = -thumbSize / 2 + 1.5;
const leftThumbMargin = -thumbSize / 2;
const fullWidth = 185;

const useStyles = makeStyles((theme) => ({
  playbackViewer: {
    width: '100%',
  },
  cesiumViewer: {
    height: '100%',
  },
  menuButton: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    left: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  viewerButton: {
    paddingRight: '5px',
    '& .MuiButton-label > div': {
      '& div': {
        fontSize: '.75rem',
      },
    },
    width: '32px',
  },
  followSpacecraftButton: {
    [theme.breakpoints.up('xl')]: {
      padding: '0 5px',
    },
  },
  playbackViewerContent: {
    height: '100%',
  },
  cadScaleSlider: {
    width: fullWidth,
    '& .MuiSlider-thumb': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      marginTop: topThumbMargin,
      marginLeft: leftThumbMargin,
      height: thumbSize,
      width: thumbSize,
      border: `0px solid`,
    },
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
    },
    '&.Mui-disabled .MuiSlider-thumb': {
      backgroundColor: 'rgb(111, 111, 111)',
    },
  },
  selectWrapper: {
    width: fullWidth,
    marginTop: 8,
  },
}));

export default useStyles;
