import { MomentContext } from 'providers';
import { useContext } from 'react';
import PowerStateArrow from '../PowerStateArrow';
import BatteryCharge from './BatteryCharge';
import useStyles from './styles';

const BatteryState = () => {
  // data will most likely be a prop once series data is available
  const { model } = useContext(MomentContext);
  const battery = model.powerProcessor?.battery;
  const classes = useStyles();
  return (
    <div className={classes.batteryStateContainer}>
      <div className={classes.batteryStateArrows}>
        <PowerStateArrow type="secondary" fill={battery?.current != null && battery.current > 0} />
        <PowerStateArrow flipped type="secondary" fill={battery?.current != null && battery.current < 0} />
      </div>
      <BatteryCharge soc={battery?.soc || 0} />
    </div>
  );
};

export default BatteryState;
