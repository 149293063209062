import { IGenericObject } from 'components/general/types';
import { useAppSelector } from 'hooks';
import { SatelliteApi, TSedaroEntities, TSedaroEntitiesMap } from 'middleware/SatelliteApi/api';
import { useMemo } from 'react';

// =====================================================================================================================
//                                               Hooks for accessing store
// =====================================================================================================================

/**
 * @param entityName - corresponds to `SatelliteApi` keys, string, PascalCase
 * @returns - integer number of corresponding entities
 */
export const useSelectTotalNum = (entityName: keyof TSedaroEntities): number => {
  const Entity = SatelliteApi[entityName];
  return useAppSelector(Entity.selectors.selectTotal) as number;
};

/**
 * @param entityName - corresponds to `SatelliteApi` keys, string, PascalCase
 * @param id - entity id, integer
 * @returns corresponding memoized entity
 */
export const useSelectById = <T extends keyof TSedaroEntities>(entityName: T, id: string) => {
  const Entity = SatelliteApi[entityName];
  const entityMap = useAppSelector(Entity.selectors.selectEntities);
  // @ts-ignore // b/c typescript doesn't know same keys are used for TSedaroEntities and TSederoEntitiesMap
  return useMemo(() => entityMap[id], [id, entityMap]) as TSedaroEntitiesMap[T];
};

/**
 * @param entityName - corresponds to `SatelliteApi` keys, string, PascalCase
 * @param ids - array of ids (integers)
 * @param sortBy - (optional, defaults to `'dateModified'`) attribute order return value. If sort order doesn't matter, set to `false` (will be more efficient)
 * @param inverse - (optional, defaults to `false`) flips the sort to the opposite direction
 * @returns corresponding memoized array of entities
 */
export const useSelectByIds = <T extends keyof TSedaroEntities>(
  entityName: T,
  ids: string[],
  // @ts-ignore // b/c typescript doesn't know same keys are used for TSedaroEntities and TSederoEntitiesMap
  sortBy: keyof TSedaroEntitiesMap[T] | false = 'dateModified',
  inverse = false
) => {
  const Entity = SatelliteApi[entityName];

  const entityMap = useAppSelector(Entity.selectors.selectEntities);
  return useMemo(() => {
    const entities: IGenericObject = ids.flatMap((id) => entityMap[id] || []);

    // @ts-ignore // b/c not all attribrutes are able to be sorted, devs will need to know to not pass in bad sortBy.
    if (sortBy) entities.sort((a, b) => (!inverse ? b[sortBy] - a[sortBy] : a[sortBy] - b[sortBy]));

    return entities;
    // @ts-ignore // b/c typescript doesn't know same keys are used for TSedaroEntities and TSederoEntitiesMap
  }, [ids, entityMap, sortBy, inverse]) as TSedaroEntitiesMap[T][];
};

/**
 * @param entityName - corresponds to `SatelliteApi` keys, string, PascalCase
 * @returns - array of all corresponding entities in the store (ordered in the same order they are in the store)
 */
export const useSelectAll = <T extends keyof TSedaroEntities>(entityName: T) => {
  const Entity = SatelliteApi[entityName];
  // @ts-ignore // b/c typescript doesn't know same keys are used for TSedaroEntities and TSederoEntitiesMap
  return useAppSelector(Entity.selectors.selectAll) as TSedaroEntitiesMap[T][];
};

/**
 *
 * @param entityName - corresponds to `SatelliteApi` keys, string, PascalCase
 * @returns a dictionary of entity id's mapped to corresponding entities
 */
export const useSelectEntityIdMap = <T extends keyof TSedaroEntities>(entityName: T) => {
  const Entity = SatelliteApi[entityName];
  return useAppSelector(Entity.selectors.selectEntities) as {
    // @ts-ignore // b/c typescript doesn't know same keys are used for TSedaroEntities and TSederoEntitiesMap
    [id: string]: TSedaroEntitiesMap[T];
  };
};

// =====================================================================================================================
