import AuthRoute from 'app/AuthRoute';
import {
  AgentAnalyzeView,
  AgentTemplateEditView,
  AuthorizeView,
  BranchesView,
  LoginView,
  ManagementConsoleView,
  NewMemberView,
  NotFoundView,
  PasswordResetView,
  RootView,
  ScenarioView,
  ShareAuthView,
  SignUpView,
  VerifyView,
  WelcomeView,
  WorkspaceView,
} from 'components';
import ProjectView from 'components/ProjectView';
import { setUserId } from 'components/RootView/missionExplorerSlice';
import { DISABLED_RECAPTCHA, RECAPTCHA_SITE_KEY, gaEvents, isProd } from 'config';
import useWorkspace from 'hooks/useWorkspace';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import {
  ActiveBranchProvider,
  COTSProvider,
  ContextNavProvider,
  DataProvider,
  SpacecraftProvider,
} from 'providers';
import WorkspaceProvider from 'providers/WorkspaceProvider';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import Routes from 'routes';
import { digest } from 'utils/strings';
import useStyles from './styles';

ReactGA.initialize(isProd() ? 'G-395745326' : 'G-395932023');

const renderRecaptcha = (children) => {
  if (DISABLED_RECAPTCHA) return children;
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} scriptProps={{ async: true }}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    User: {
      actions: { getUser },
    },
  } = SatelliteApi;

  const workspace = useWorkspace();

  // Set user ID for Google Analytics
  // GA just calls the identifier "user ID" but it's really workspace ID
  useEffect(() => {
    const setUserId = async () => {
      // Hash workspace ID to preserve anonymity (async)
      const userId = workspace?.id ? await digest(workspace.id) : undefined;
      ReactGA.set({ userId });
    };
    setUserId();
  }, [workspace?.id]);

  const page = location.pathname;
  useEffect(() => {
    // Send page view event to Google Analytics
    ReactGA.send({
      hitType: 'pageview',
      // Remove real IDs from URL to preserve anonymity
      page: page.replace(/([A-Za-z0-9\-_]){20}[kVF-]/g, 'uuid'),
    });

    // Send shareable link event to Google Analytics
    if (page.includes('share=')) {
      ReactGA.event(gaEvents.SHARE, {
        category: 'Collaborate',
        label: 'Shareable link',
      });
    }
  }, [page]);

  useEffect(() => {
    dispatch(
      getUser({
        successCallback: (user) => {
          dispatch(setUserId(user.id));
          setLoading(false);
        },
        failureCallback: () => {
          setLoading(false);
        },
      })
    );
  }, []); //eslint-disable-line

  return (
    <div className={classes.root}>
      {!loading && (
        <Switch>
          <AuthRoute exact path={Routes.ROOT()} render={() => <RootView />} />
          <AuthRoute
            exact
            path={Routes.WORKSPACE()}
            render={() => (
              <ContextNavProvider>
                <WorkspaceProvider>
                  <WorkspaceView />
                </WorkspaceProvider>
              </ContextNavProvider>
            )}
          />
          <AuthRoute exact path={Routes.BRANCH()} render={() => <BranchesView />} />
          <AuthRoute exact path={Routes.PROJECT()} render={() => <ProjectView />} />
          <AuthRoute
            path={Routes.AGENT_TEMPLATE_EDIT()}
            render={() => (
              <ActiveBranchProvider>
                <ContextNavProvider>
                  <COTSProvider>
                    <SpacecraftProvider>
                      <AgentTemplateEditView />
                    </SpacecraftProvider>
                  </COTSProvider>
                </ContextNavProvider>
              </ActiveBranchProvider>
            )}
          />
          <AuthRoute
            path={Routes.AGENT_ANALYZE()}
            render={() => (
              <ActiveBranchProvider>
                <ContextNavProvider>
                  <DataProvider>
                    <AgentAnalyzeView />
                  </DataProvider>
                </ContextNavProvider>
              </ActiveBranchProvider>
            )}
          />
          <AuthRoute
            path={Routes.SCENARIO()}
            render={() => (
              <WorkspaceProvider>
                <ActiveBranchProvider>
                  <ContextNavProvider>
                    <ScenarioView />
                  </ContextNavProvider>
                </ActiveBranchProvider>
              </WorkspaceProvider>
            )}
          />
          <Route path={Routes.SHARE_AUTH()} render={() => <ShareAuthView />} />
          <AuthRoute path={Routes.ACCOUNT()} render={() => <ManagementConsoleView />} />
          <AuthRoute path={Routes.VERIFY()} render={() => <VerifyView />} />
          <AuthRoute path={Routes.AUTHORIZE()} render={() => <AuthorizeView />} />
          <Route path={Routes.NEW_MEMBER()} render={() => <NewMemberView />} />
          <Route path={Routes.WELCOME()} render={() => <WelcomeView />} />
          <Route path={Routes.LOGIN()} component={LoginView} />
          <Route path={Routes.SIGNUP()} render={() => renderRecaptcha(<SignUpView />)} />
          <Route path={Routes.PASSWORD_RESET()} component={PasswordResetView} />
          <Route path={Routes.NOT_FOUND()} component={NotFoundView} />
          <Route component={NotFoundView} />
        </Switch>
      )}
    </div>
  );
};

export default App;
