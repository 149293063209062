import { TChartSpec } from 'components/general/types';

const statsComponentTempsChartSpec: TChartSpec = [
  {
    name: 'Component Average Temperature',
    plots: [
      {
        type: 'horizontal-bar',
        unit: 'c',
        variables: [
          {
            each: 'Subsystem',
            legend: ['{name}'],
            keys: ['components.$each.temperature.degC'],
            ops: ['avg'],
          },
        ],
      },
    ],
  },
  {
    name: 'Component Minimum Hot Margin',
    plots: [
      {
        type: 'margin',
        unit: 'c',
        variables: [
          {
            each: 'Subsystem',
            legend: ['{name}'],
            keys: ['components.$each.hotMargin'],
            ops: ['min'],
          },
        ],
      },
    ],
  },
  {
    name: 'Component Minimum Cold Margin',
    plots: [
      {
        type: 'margin',
        unit: 'c',
        variables: [
          {
            each: 'Subsystem',
            legend: ['{name}'],
            keys: ['components.$each.coldMargin'],
            ops: ['min'],
          },
        ],
      },
    ],
  },
];

export default statsComponentTempsChartSpec;
