import { makeStyles } from '@material-ui/core/styles';
import { width } from 'components/general/Nav/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.darkest,
    width: '70%',
    minWidth: 650,
    maxWidth: 1200,
    position: 'fixed',
    bottom: 20,
    left: width,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 1000,
    paddingLeft: 10,
    borderRadius: 67 / 2,
    boxShadow: theme.shadows[2],
  },
  playbackControlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  playbackControls: {
    width: '118px',
    flexDirection: 'column',
    marginRight: '15px',
    textAlign: 'center',
    '& button svg': {
      color: theme.palette.background.contrastText,
    },
    '& button.Mui-disabled svg': {
      color: theme.palette.action.disabled,
    },
  },
  playPauseSkipWrapper: {
    display: 'flex',
  },
  multiplierControl: {
    width: '95%',
    padding: '10px 0',
    paddingTop: 3,

    '& .MuiSlider-markLabel': {
      top: 15,
    },
  },
  progressWrapper: {
    flex: '1 100%',
  },
  progress: {
    height: theme.dimensions.rail.height,
  },
  inactiveLoopButton: {
    color: theme.palette.action.disabled,
    margin: 5,
  },
  activeLoopButton: {
    color: theme.palette.background.contrastText,
    margin: 5,
  },
  currentDate: {
    flex: '1 210px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
