import * as Yup from 'yup';

const targetSchema = Yup.object().shape({
  name: Yup.string()
    .required('A name is required')
    .max(32, 'Target name must be no more than 32 characters'),

  type: Yup.object().required('Select a target type'),
});

export default targetSchema;
