import { ISelectOption } from 'components/general/types';
import { AlgorithmVables } from 'utils/vable';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('An algorithm name is required.'),
  // rate: Yup.number().required('Rate is required.').moreThan(0, 'Update rate must be positive.'),
  type: Yup.object().required('Algorithm type is required.'),
  gainC: Yup.number().when(['type'], {
    is: (type: ISelectOption) => type?.value === AlgorithmVables.Type.SlidingModeAlgorithm.value,
    then: Yup.number().required('Gain C is required.'),
  }),
  gainK: Yup.number().when(['type'], {
    is: (type: ISelectOption) => type?.value === AlgorithmVables.Type.SlidingModeAlgorithm.value,
    then: Yup.number().required('Gain K is required.'),
  }),
  gainG: Yup.number().when(['type'], {
    is: (type: ISelectOption) => type?.value === AlgorithmVables.Type.SlidingModeAlgorithm.value,
    then: Yup.number().required('Gain G is required.'),
  }),
  epsilon: Yup.number().when(['type'], {
    is: (type: ISelectOption) => type?.value === AlgorithmVables.Type.SlidingModeAlgorithm.value,
    then: Yup.number().required('Epsilon is required.'),
  }),
  positionSensor: Yup.object().when(['type'], {
    is: (type: ISelectOption) => type?.value === AlgorithmVables.Type.GpsAlgorithm.value,
    then: Yup.object().required('Position sensor is required.'),
  }),
  thrusts: Yup.array().when('type', {
    is: (type: ISelectOption) =>
      type?.value === AlgorithmVables.Type.StaticThrustControlAlgorithm.value,
    then: Yup.array().of(
      Yup.number().required(
        'At least one thruster must be selected, and all selected thrusters must have assigned thrust.'
      )
    ),
  }),
});

export default validation;
