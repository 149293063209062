import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('A thermal interface material name is required.'),
  manufacturer: Yup.string(),
  partNumber: Yup.string(),
  resistivity: Yup.number()
    .required('Resistivity is required.')
    .moreThan(0, 'Resistivity must be greater than zero.'),
  thickness: Yup.number()
    .required('Thickness is required.')
    .moreThan(0, 'Thickness must be greater than zero.'),
  hotTempRating: Yup.object({
    degC: Yup.number()
      .required('Hot temperature rating is required.')
      .min(-273.15, 'Temperatures must be no less than absolute zero.')
  }),
  coldTempRating: Yup.object({
    degC: Yup.number()
      .required('Cold temperature rating is required.')
      .min(-273.15, 'Temperatures must be no less than absolute zero.')
  }),
});

export default validation;
