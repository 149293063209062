import LabeledInput from 'components/general/inputs/LabeledInput';
import { useStylesInterface } from './styles';
import Dialog from 'components/general/dialogs/Dialog';
import { useFormikForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import DeleteLayoutDialog from 'components/general/dialogs/DeleteLayoutDialog';
import * as Yup from 'yup';
import { ILayout } from 'components/general/types/layout';

interface IForm {
  name: string;
}

interface IProps {
  control: TEntityDialogControl<ILayout>;
  deleteLayout: () => void;
  addLayout: (newLayoutName: string) => void;
  loading: boolean;
}

const defaultValues: IForm = {
  name: '',
};

const LayoutDialog = ({ control, deleteLayout, addLayout, loading }: IProps) => {
  const { dialogConfig, closeDialog: onClose } = control;
  const { action, entity: layout, open } = dialogConfig;
  const classes = useStylesInterface();

  const layoutSchema = Yup.object().shape({
    name: Yup.string().required('A name is required'),
  });

  const { formik } = useFormikForm(
    defaultValues,
    (values) => {
      addLayout(values.name);
    },
    layoutSchema,
    null
  );
  const { getFieldProps, dirty, handleSubmit } = formik;

  if (action === 'delete' && layout) {
    return (
      <DeleteLayoutDialog
        action={deleteLayout}
        layout={layout}
        layoutNameText={'Layout'}
        onClose={onClose}
        open={open}
        doubleConfirmDelete={true}
      />
    );
  }

  return (
    <Dialog
      prompt={'Create a layout'}
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      submitActionText="Create"
      disableSubmit={!dirty}
      loading={loading}
      large
    >
      <div className={classes.layoutNameInput}>
        <LabeledInput
          {...getFieldProps('name')}
          label="Layout Name"
          type="text"
          placeholder="Layout Name"
          value={formik.values.name}
          autoFocus
        />
        {formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}
      </div>
    </Dialog>
  );
};

export default LayoutDialog;
