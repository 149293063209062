import { IGenericObject } from 'components/general/types';
import { makeGuidance } from 'hooks';
import fullyRegDetTopologyGraphic from 'multimedia/guidance/fully_reg_det_topology_full.png';
import fullyRegDetTopologyReducedGraphic from 'multimedia/guidance/fully_reg_det_topology_reduced.png';
import quasiRegDetTopologyGraphic from 'multimedia/guidance/quasi_reg_det_topology_full.png';
import quasiRegDetTopologyReducedGraphic from 'multimedia/guidance/quasi_reg_det_topology_reduced.png';
import singleConvHybridTopologyGraphic from 'multimedia/guidance/single_conv_hybrid_topology_full.png';
import singleConvHybridTopologyReducedGraphic from 'multimedia/guidance/single_conv_hybrid_topology_reduced.png';
import singleConvMPPTTopologyGraphic from 'multimedia/guidance/single_conv_mppt_topology_full.png';
import singleConvMPPTTopologyReducedGraphic from 'multimedia/guidance/single_conv_mppt_topology_reduced.png';
import twoConvMPPTTopologyGraphic from 'multimedia/guidance/two_conv_mppt_topology_full.png';
import twoConvMPPTTopologyReducedGraphic from 'multimedia/guidance/two_conv_mppt_topology_reduced.png';

const paramGuidance = {
  bcrEfficiency: {
    subHeading: 'Battery Charge Regulator Efficiency',
    chunk:
      'The battery charge regulator (BCR) operates at a given efficiency. Its losses are dissipated as heat.',
  },
  bdrEfficiency: {
    subHeading: 'Battery Discharge Regulator Efficiency',
    chunk:
      'The battery discharge regulator (BDR) operates at a given efficiency. Its losses are dissipated as heat.',
  },
  pptEfficiency: {
    subHeading: 'Peak Power Tracker Efficiency',
    chunk:
      'The peak power tracker (PPT) operates at a given efficiency. Its losses are dissipated as heat.',
  },
  outputPowerRating: {
    subHeading: 'Output Power Rating',
    chunk: 'The maximum power that the power processor can output.',
  },
  controllerBusVoltage: {
    subHeading: 'Controller Bus Voltage',
    chunk:
      'Voltage of the power controller bus when the battery current is regulated. If the battery discharge current is not regulated, then the battery drives the controller bus voltage.',
  },
  dischargeDiodeDrop: {
    subHeading: 'Battery Discharge Diode Drop',
    chunk:
      'This diode allows the battery to deliver power to the system when the solar arrays are not sufficiently illuminated and unable to operate above the battery voltage.',
  },
  chargeDiodeDrop: {
    subHeading: 'Solar Array Diode Drop',
    chunk:
      'This diode allows the solar array to deliver power to the system when the solar arrays are sufficiently illuminated and able to operate above the battery voltage.',
  },
  bypassBlockingDiodeDrop: {
    subHeading: 'Battery Discharge Diode Drop',
    chunk:
      'This diode allows the battery to deliver power to the bus regulators when the solar arrays are not sufficiently illuminated and unable to operate above the battery voltage.',
  },
};

const typeGuidance = {
  SingleConvMpptPowerProcessor: {
    heading: 'Single-Converter Maximum Peak Power Tracking',
    shortChunk:
      "Uses a single BCR/PPT converter per array to regulate battery charging and track the maximum power point of the array. A spacecraft's arrays and battery can provide power simultaneously. Battery discharge is not regulated.",
    longChunk:
      'This topology uses a single BCR/PPT converter per array to regulate battery charging and track the maximum power point of the array. The PPT controls the operating voltage of the array so as to supply as much of the required power as possible. When the arrays are not able to supply the loads, the battery discharges to supply the balance.',
    graphic: singleConvMPPTTopologyGraphic,
    graphicConfig: 'full',
  },
  TwoConvMpptPowerProcessor: {
    heading: 'Two-Converter Maximum Peak Power Point Tracking',
    shortChunk:
      "Uses a PPT to track the maximum power point of the solar arrays, and a BCR to regulate battery charging. A spacecraft's arrays and battery cannot provide power simultaneously. Battery discharge is not regulated.",
    longChunk:
      'This topology uses separate BCR and PPT converters. The PPT controls the operating voltage of the array so as to supply as much of the required power as possible. When the arrays are not able to supply the loads, the battery exclusively provides the required power.',
    graphic: twoConvMPPTTopologyGraphic,
    graphicConfig: 'full',
  },
  QuasiRegDetPowerProcessor: {
    heading: 'Quasi-Regulated Direct Energy Transfer',
    shortChunk:
      "Uses a shunt regulator to regulate controller bus voltage and shunt excess solar power. A spacecraft's array and battery cannot provide power simulatenously. Battery discharge is not regulated.",
    longChunk:
      'This topology uses a shunt regulator to regulate the power controller bus at a constant voltage. A single BCR regulates battery charging and any excess solar power is dissipated as heat by the shunt regulator. When the array is not able to supply the loads, the battery exclusively provides the required power.',
    graphic: quasiRegDetTopologyGraphic,
    graphicConfig: 'full',
  },
  FullyRegDetPowerProcessor: {
    heading: 'Fully-Regulated Direct Energy Transfer',
    shortChunk:
      "Uses a shunt regulator to regulate controller bus voltage and shunt excess solar power. A spacecraft's array and battery cannot provide power simulatenously. Battery discharge is regulated to maintain a constant controller bus voltage.",
    longChunk:
      'This topology uses a shunt regulator to operate the power controller bus at a constant voltage. A single BCR regulates battery charging, while a BDR regulates battery discharging. Any excess solar power is dissipated by the shunt regulator. When the array is not able to supply the loads, the battery exclusively provides the required power.',
    graphic: fullyRegDetTopologyGraphic,
    graphicConfig: 'full',
  },
  SingleConvHybridPowerProcessor: {
    heading: 'Single-Converter Hybrid',
    shortChunk:
      "Uses a single BCR/PPT converter to regulate battery charging and track the maximum power point of the array. A spacecraft's array and battery cannot provide power simultaneously to the bus regulators but can both provide power to the unregulated controller bus. Battery discharge is not regulated.",
    longChunk:
      'This topology uses a single BCR/PPT converter to regulate battery charging and track the maximum power point of the array. The PPT controls the operating voltage of the array so as to supply as much of the required power as possible. The bus regulators are supplied directly by the array (when solar power is sufficient), while battery charge is regulated by the BCR. The unregulated controller bus can be supplied by both the array and battery. When the arrays are not able to supply the bus regulators, the battery discharges to supply all required power.',
    graphic: singleConvHybridTopologyGraphic,
    graphicConfig: 'full',
  },
};

function topologyParamGuidance(
  topologyTypeKey: keyof typeof typeGuidance,
  paramKey: keyof typeof paramGuidance
) {
  return {
    heading: typeGuidance[topologyTypeKey].heading,
    body: [
      {
        chunk: typeGuidance[topologyTypeKey].longChunk,
        graphic: typeGuidance[topologyTypeKey].graphic,
        graphicConfig: typeGuidance[topologyTypeKey].graphicConfig,
      },
      paramGuidance[paramKey],
    ],
  };
}

const useGuidance = makeGuidance({
  _default: {
    heading: 'Define the Power Controller',
    body: [
      {
        chunk:
          "The power controller manages the generation and distribution of power throughout the satellite's Electric Power System (EPS), and is characterized by its topology.",
      },
    ],
  },

  type: {
    heading: 'Topology Types',
    body: [
      {
        chunk:
          'Choose from the following common power controller topologies. Further details are provided once you select a topology.',
      },
      {
        subHeading: typeGuidance.SingleConvMpptPowerProcessor.heading,
        chunk: typeGuidance.SingleConvMpptPowerProcessor.shortChunk,
        graphic: singleConvMPPTTopologyReducedGraphic,
        maxHeight: 100,
      },
      {
        subHeading: typeGuidance.TwoConvMpptPowerProcessor.heading,
        chunk: typeGuidance.TwoConvMpptPowerProcessor.shortChunk,
        graphic: twoConvMPPTTopologyReducedGraphic,
        maxHeight: 100,
      },
      {
        subHeading: typeGuidance.QuasiRegDetPowerProcessor.heading,
        chunk: typeGuidance.QuasiRegDetPowerProcessor.shortChunk,
        graphic: quasiRegDetTopologyReducedGraphic,
        maxHeight: 100,
      },
      {
        subHeading: typeGuidance.FullyRegDetPowerProcessor.heading,
        chunk: typeGuidance.FullyRegDetPowerProcessor.shortChunk,
        graphic: fullyRegDetTopologyReducedGraphic,
        maxHeight: 100,
      },
      {
        subHeading: typeGuidance.SingleConvHybridPowerProcessor.heading,
        chunk: typeGuidance.SingleConvHybridPowerProcessor.shortChunk,
        graphic: singleConvHybridTopologyReducedGraphic,
        maxHeight: 100,
      },
    ],
  },

  topologyParams: {
    bcrEfficiency: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'bcrEfficiency' as keyof typeof topologyParamGuidance
      );
    },

    bdrEfficiency: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'bdrEfficiency' as keyof typeof topologyParamGuidance
      );
    },

    pptEfficiency: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'pptEfficiency' as keyof typeof topologyParamGuidance
      );
    },

    outputPowerRating: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'outputPowerRating' as keyof typeof topologyParamGuidance
      );
    },

    chargeControllerBusVoltage: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'controllerBusVoltage' as keyof typeof topologyParamGuidance
      );
    },

    drivenControllerBusVoltage: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'controllerBusVoltage' as keyof typeof topologyParamGuidance
      );
    },

    dischargeDiodeDrop: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'dischargeDiodeDrop' as keyof typeof topologyParamGuidance
      );
    },

    chargeDiodeDrop: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'chargeDiodeDrop' as keyof typeof topologyParamGuidance
      );
    },

    bypassBlockingDiodeDrop: (values: IGenericObject) => {
      const type = values.type.value;
      return topologyParamGuidance(
        type as keyof typeof topologyParamGuidance,
        'bypassBlockingDiodeDrop' as keyof typeof topologyParamGuidance
      );
    },
  },
});

export default useGuidance;
