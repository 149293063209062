import { TChartSpec } from 'components/general/types';

const tsPowProcessingChartSpec: TChartSpec = [
  {
    name: 'Power Controller',
    subtitle: 'Output powers and total output power margin',
    plots: [
      {
        type: 'line',
        label: 'Power',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: [
              'powerProcessor.outputPowers.busRegulators',
              'powerProcessor.outputPowers.loads',
              'powerProcessor.outputPowers.total',
            ],
            legend: ['To Bus Regulators', 'To Loads', 'Total'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Output Power Margin',
        step: 'end',
        variables: [
          {
            keys: ['powerProcessor.outputPowerMargin'],
            legend: ['Output Power Margin'],
          },
        ],
      },
    ],
  },
  {
    name: '"{name}" Bus Regulator',
    subtitle: 'Output power and total output power margin',
    each: 'BusRegulator',
    plots: [
      {
        type: 'line',
        label: 'Power',
        unit: 'W',
        step: 'end',
        variables: [
          {
            keys: ['outputPowers.busRegulators', 'outputPowers.loads', 'outputPowers.total'],
            legend: ['To Bus Regulators', 'To Loads', 'Total'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Output Power Margin',
        step: 'end',
        variables: [
          {
            keys: ['outputPowerMargin'],
            legend: ['Output Power Margin'],
          },
        ],
      },
    ],
  },
];

export default tsPowProcessingChartSpec;
