import { Alert, AlertTitle } from '@material-ui/lab';
import Dialog from 'components/general/dialogs/Dialog';
import { IGenericObject, IWorkspace, TDialogConfig } from 'components/general/types';
import { IUser } from 'components/general/types/general';
import { GENERIC_ERROR_MESSAGE } from 'config';
import { useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { Fragment, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import useStyles from './styles';

interface IProps {
  config: TDialogConfig<IUser>;
  workspace: IWorkspace;
  onClose: () => void;
}

const MemberDeleteDialog = (props: IProps) => {
  const { config, workspace } = props;
  const { open, entity: member } = config;
  const user = useUser();

  const dispatch = useDispatch();
  const {
    Workspace: {
      actions: { deleteMember, manageInvites },
    },
  } = SatelliteApi;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const onClose = useCallback(() => {
    setErrorMessage(undefined);
    props.onClose();
  }, [props]);

  const classes = useStyles();

  const handleDelete = () => {
    setLoading(true);
    const func = member?.id ? deleteMember : manageInvites;
    dispatch(
      func({
        id: workspace.id,
        // DELETE with ids in query params if removing members
        queryParams: member?.id ? { id: member.id } : { cancel: undefined },
        // POST with emails in body if revoking invites
        emails: member?.id ? undefined : [member?.email],
        successCallback: (response: IWorkspace) => {
          setLoading(false);
          onClose();
          if (member?.id === user.id) {
            enqueueSnackbar(`Successfully left workspace.`, {
              variant: 'success',
            });
            history.push(Routes.ROOT());
          } else {
            enqueueSnackbar(`${member?.email} removed successfully.`, {
              variant: 'success',
            });
          }
        },
        failureCallback: (response: IGenericObject) => {
          setErrorMessage(response?.error?.message || GENERIC_ERROR_MESSAGE);
          setLoading(false);
        },
      })
    );
  };

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        open={open}
        loading={loading}
        prompt="Remove Member"
        submitActionText="Remove"
        onSubmit={handleDelete}
      >
        <p>
          {member?.id
            ? `Are you sure you want to remove ${
                member.id === user.id ? 'yourself' : `${member.firstName} ${member.lastName}`
              } as a member from "${workspace.name}"?`
            : `Are you sure you want to cancel your invitation to ${member?.email} to join "${workspace.name}?"`}
        </p>
        {errorMessage && (
          <Alert severity="error" icon={false} variant="outlined" className={classes.error}>
            <AlertTitle className={classes.errorHeading}>Deletion Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </Dialog>
    </Fragment>
  );
};

export default MemberDeleteDialog;
