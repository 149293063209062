import { useState, useRef } from 'react';
import * as React from 'react';
import { useActiveEntities, useAppDispatch } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IGenericObject } from 'components/general/types';
import useStyles from './styles';
import { useEscapeKeyDown, useSnackbar } from 'hooks';

const CreateSubsystemForm = ({ setTabToLastSS }: { setTabToLastSS: () => void }) => {
  const dispatch = useAppDispatch();
  const { Subsystem } = SatelliteApi;
  const { branch } = useActiveEntities();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef(null);

  const namePlaceholder = '+ Subsystem';
  const [name, setName] = useState(namePlaceholder);

  // MUI input struggled with css focus selector, so used focus & blur events with state to track it
  const [focused, setFocused] = useState(false);
  const classes = useStyles({ focused });

  // @ts-ignore
  // TLDR: unfocus the input to make it look like a button again. **See bottom of file for more details.
  const blurInput = () => inputRef.current?.children[0].blur();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name === '') {
      enqueueSnackbar('Please provide a name for your custom subsystem.');
      return;
    }
    if (name.length > 32) {
      enqueueSnackbar('Subsystem name must be no more than 32 characters.');
      return;
    }
    dispatch(
      Subsystem.actions.createSubsystem({
        branchId: branch.id,
        name,
        type: 'Subsystem',
        successCallback: () => {
          setTabToLastSS();
          blurInput();
          enqueueSnackbar('Subsystem created successfully', {
            variant: 'success',
          });
        },
        failureCallback: (res: IGenericObject) => {
          enqueueSnackbar(res.error.message);
        },
      })
    );
  };

  return (
    <form onSubmit={handleSubmit} className={classes.createSubsystemForm}>
      <LabeledInput
        fwdRef={inputRef}
        className={classes.createSubsystemFormInput}
        placeholder={namePlaceholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        value={name}
        onFocus={() => {
          setFocused(true);
          setName('');
        }}
        onBlur={() => {
          setFocused(false);
          setName(namePlaceholder);
        }}
        onKeyDown={useEscapeKeyDown(blurInput)}
      ></LabeledInput>
    </form>
  );
};

export default CreateSubsystemForm;

// **
// Explanation of formRef and @ts-ignore:
// TypeScript doesn't like this (keying into `children` on `current`), because the `ref`
// can sometimes be null (before everything renders), but by the time we get here it
// should always be defined.
//
// Also, `ref` is forwarded to and assigned on the mui Input. The actual input element is
// however nested inside of the mui Input one level, that's why `blur()` is on the child.
//
// Lastly, the `blur()` function unfocuses the input and also triggers the `onBlur` (which
// then also resets the `name` and `focused` states).
