import useStyles from './styles';

export default function LoadingInlay(props: { text: string; className?: string }) {
  const { text, className } = props;
  const classes = useStyles();

  return (
    <div className={className}>
      <h2 className={classes.loading}>{text}</h2>
    </div>
  );
}
