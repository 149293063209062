import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { wGroupIndicesATCDH } from 'components/AgentTemplateEditView/menu/cdh';
import { CdhAccent, MdAccent } from 'components/general/Accent/variants';
import GuidanceCard from 'components/general/GuidanceCard';
import { PriorityQueue } from 'components/general/PriorityQueue';
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import WidgetTable from 'components/general/widgets/WidgetTable';
import WizardSegment from 'components/general/wizards/WizardSegment';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useFormikForm, useSelectBlocks, useSnackbar } from 'hooks';
import { SatelliteApi, multiBlockCrud } from 'middleware/SatelliteApi/api';
import { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translateIn, translateOut } from 'utils/forms';
import useGuidance from './guidance';
import operationalModeSchema from './validation';

export const conditionsTableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

const defaultValues = {
  name: '',
  pointingMode: '',
  limitDurationAndFrequency: false,
  requireMinDuration: false,
  maxOccurrenceDuration: { min: '' },
  minTimeBetweenOccurrences: { min: '' },
  minOccurrenceDuration: { min: '' },
};

const OperationalModesDialog = (props) => {
  let { pointingModes, conditions, config, onClose } = props;
  const { open, action, operationalMode, nextHighestPriority } = config;
  const tableRef = useRef(null);

  const {
    OperationalMode: {
      actions: { createOperationalMode, updateOperationalMode, deleteOperationalMode },
    },
  } = SatelliteApi;

  const { branch } = useActiveEntities();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    parsedBlocks: parsedConditions,
    setParsedBlocks: setParsedConditions,
    initBlocks: initConditions,
  } = useSelectBlocks(conditions, operationalMode?.conditions);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const options = useMemo(() => {
    return {
      pointingMode: pointingModes
        ?.filter((pM) => pM != null)
        .map((pM) => {
          return { value: pM.id, label: pM.name };
        }),
    };
  }, [pointingModes]);

  const customTranslateIn = useCallback((values, defaultValues, options) => {
    // The operational mode form has booleans to determine whether the optional fields are rendered
    // But the backend only returns the values for those fields so we must set the boolean to true if those fields are not zero
    if (
      typeof values.maxOccurrenceDuration?.min === 'number' ||
      typeof values.minTimeBetweenOccurrences?.min === 'number'
    ) {
      values.limitDurationAndFrequency = true;
    } else {
      values.limitDurationAndFrequency = false;
    }
    if (typeof values.minOccurrenceDuration?.min === 'number') {
      values.requireMinDuration = true;
    } else {
      values.requireMinDuration = false;
    }
    return translateIn(values, defaultValues, options);
  }, []);

  const customTranslateOut = useCallback((values, allowedEmptyFields, options) => {
    // Delete checkboxes that the backend is not expecting as they are front end only fields
    delete values.limitDurationAndFrequency;
    delete values.requireMinDuration;
    return translateOut(values, allowedEmptyFields, options);
  }, []);

  const addOperationalMode = useCallback(
    (values) => {
      values.conditions = parsedConditions.filter((c) => c.tableData.checked).map((c) => c.id);
      setLoading(true);
      dispatch(
        createOperationalMode({
          branchId: branch.id,
          type: 'SpacecraftOperationalMode',
          ...values,
          priority: nextHighestPriority,
          successCallback: (response) => {
            onClose();
            enqueueSnackbar('Operational mode created successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response) => {
            enqueueSnackbar(response.error.message);
            setLoading(false);
          },
        })
      );
    },
    [
      nextHighestPriority,
      enqueueSnackbar,
      createOperationalMode,
      dispatch,
      branch,
      onClose,
      parsedConditions,
    ]
  );

  const editOperationalMode = useCallback(
    (values) => {
      values.conditions = parsedConditions.filter((c) => c.tableData.checked).map((c) => c.id);
      setLoading(true);
      dispatch(
        updateOperationalMode({
          branchId: branch.id,
          id: operationalMode.id,
          type: 'SpacecraftOperationalMode',
          ...values,
          priority: operationalMode.priority,
          successCallback: (response) => {
            onClose();
            enqueueSnackbar('Operational mode updated successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response) => {
            let errorMessage = response.error.message;
            if (response.error.message.includes('greater than or equal to')) {
              errorMessage =
                'Max occurrence duration must be greater than or equal to min occurrence duration.';
            }
            enqueueSnackbar(errorMessage);
            setLoading(false);
          },
        })
      );
    },
    [
      operationalMode,
      onClose,
      enqueueSnackbar,
      parsedConditions,
      updateOperationalMode,
      dispatch,
      branch,
    ]
  );

  const { formik, guidance } = useFormikForm(
    defaultValues,
    action === 'create' ? addOperationalMode : editOperationalMode,
    operationalModeSchema,
    operationalMode,
    {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      allowedEmptyFields: [
        'minOccurrenceDuration',
        'minTimeBetweenOccurrences',
        'maxOccurrenceDuration',
      ],
    }
  );
  const { handleSubmit, getFieldProps, values, setValues, resetForm } = formik;
  const { limitDurationAndFrequency } = values;

  if (action === 'delete') {
    return (
      <DeleteEntityDialog
        action={deleteOperationalMode}
        entity={operationalMode}
        entityTypeText={'Operational Mode'}
        onClose={onClose}
        open={open}
      />
    );
  }

  return (
    <Dialog
      prompt={
        action === 'create'
          ? 'Create an operational mode'
          : action === 'clone'
          ? 'Clone operational mode'
          : 'Edit operational mode'
      }
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      submitActionText="Save"
      secondaryActionText="Reset"
      onSecondaryAction={() => {
        initConditions();
        resetForm();
      }}
      large
      xray={{ ...operationalMode, ...formik.values }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <div className={classes.inputGroup}>
              <LabeledInput
                {...getFieldProps('name')}
                label="Operational Mode Name"
                type="text"
                placeholder="Name"
                autoFocus
              />
            </div>
            <div className={classes.inputGroup}>
              <MdAccent header="Pointing Mode">
                <LabeledSelect options={options.pointingMode} {...getFieldProps('pointingMode')} />
              </MdAccent>
            </div>
            <LabeledCheckbox
              {...getFieldProps('limitDurationAndFrequency')}
              label="Limit duration and/or frequency"
              formikOnChange={() => {
                setValues({
                  ...values,
                  maxOccurrenceDuration: defaultValues.maxOccurrenceDuration,
                  minTimeBetweenOccurrences: defaultValues.minTimeBetweenOccurrences,
                });
              }}
            />
            {limitDurationAndFrequency && (
              <div className={classes.inputGroup}>
                <LabeledInput
                  {...getFieldProps('maxOccurrenceDuration.min')}
                  label="Max Occurrence Duration"
                  type="number"
                  endAdornment={<InputAdornment position="end">{'min'}</InputAdornment>}
                  optional
                />
                <LabeledInput
                  {...getFieldProps('minTimeBetweenOccurrences.min')}
                  label="Min Time Between Occurrences"
                  type="number"
                  endAdornment={<InputAdornment position="end">{'min'}</InputAdornment>}
                />
              </div>
            )}
          </div>
          <div className={classes.inputGroup}>
            <CdhAccent header="Conditions">
              <WidgetTable
                tableRef={tableRef}
                className={classes.table}
                columns={conditionsTableColumns}
                data={parsedConditions}
                setData={setParsedConditions}
                emptyMessage={'No conditions found'}
                title="Select Conditions"
                search={true}
                selection={true}
              />
            </CdhAccent>
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard guidance={guidance} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

const OperationalModesSegment = () => {
  const {
    operationalModes: _operationalModes,
    pointingModes,
    conditions,
    branch,
  } = useActiveEntities();

  const dispatch = useDispatch();

  const [operationalModeDialogConfig, setOperationalModeDialogConfig] = useState({ open: false });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const operationalModes = useMemo(() => {
    return [..._operationalModes].sort(({ priority: a }, { priority: b }) => b - a);
  }, [_operationalModes]);

  const onAddClick = useCallback(() => {
    let hP = -1;
    let opMode;
    for (opMode of operationalModes) {
      if (opMode.priority > hP) {
        hP = opMode.priority;
      }
    }
    setOperationalModeDialogConfig({
      open: true,
      action: 'create',
      nextHighestPriority: hP + 1,
    });
  }, [setOperationalModeDialogConfig, operationalModes]);

  const onActionClick = useCallback(
    (slotData, action) =>
      setOperationalModeDialogConfig({
        open: true,
        action: action,
        operationalMode: slotData,
      }),
    [setOperationalModeDialogConfig]
  );

  const onChange = useCallback(
    (items, setLoading) => {
      const blocks = items
        .filter((i) => i.priority !== i.oldPriority)
        .map(({ id, priority, ...rest }) => ({ id, priority, type: 'SpacecraftOperationalMode' }));
      if (blocks.length > 0) {
        setLoading(true);
        dispatch(
          multiBlockCrud({
            branchId: branch.id,
            blocks,
            successCallback: () => {
              enqueueSnackbar('Priorities updated successfully', {
                variant: 'success',
              });
              setLoading(false);
            },
            failureCallback: () => {
              enqueueSnackbar(
                'An error occurred while updating operational mode priorities.  Please reload the page and try again.'
              );
              setLoading(false);
            },
          })
        );
      }
    },
    [enqueueSnackbar, dispatch, branch]
  );

  return (
    <Fragment>
      <WizardSegment
        title="Operational Modes"
        index={wGroupIndicesATCDH.OP_MODES}
        xray={operationalModes}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.swapRight}>
            <PriorityQueue
              className={classes.table}
              data={operationalModes}
              onAddClick={onAddClick}
              onActionClick={onActionClick}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.swapLeft}>
            <GuidanceCard
              guidance={{
                heading: 'Create and Edit Operational Modes',
                body: [
                  {
                    chunk:
                      'Operational modes are the building blocks of your ConOps. Each operational mode is assigned a pointing mode and - optionally - conditions and timing constraints. Other Sedaro modules will assign mode-dependent factors to these operational modes.\nThe order of modes in this interface indicates their respective priority levels.  Drag and drop operational modes to re-order them by priority.',
                  },
                  {
                    subHeading: 'Operational Mode Logic',
                    chunk:
                      'An operational mode can only be active if it is the default mode or if all of the conditions assigned to it are satisfied. At each time step in your mission simulation, the highest-priority operational mode with all conditions satisfied will become the active mode.',
                  },
                  {
                    subHeading: '"Default" Operational Mode',
                    chunk:
                      'The lowest priority mode is the "default" operational mode. Any conditions attached to a default mode are not evaluated during conops simulation.',
                  },
                ],
              }}
            />
          </Grid>
        </Grid>
      </WizardSegment>
      <OperationalModesDialog
        config={operationalModeDialogConfig}
        onClose={() =>
          setOperationalModeDialogConfig({
            ...operationalModeDialogConfig,
            open: false,
            // set operational mode to an empty object when it closes so our form will reset on open
            operationalMode: {},
          })
        }
        pointingModes={pointingModes}
        conditions={conditions}
      />
    </Fragment>
  );
};

export default OperationalModesSegment;
