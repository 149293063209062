import InputAdornment from '@material-ui/core/InputAdornment';
import { conditionsTableColumns } from 'components/AgentTemplateEditView/EditBoards/CDHEditBoard/OperationalModesSegment/index';
import MdAccent from 'components/general/Accent/variants/MdAccent';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledDateTimePicker from 'components/general/inputs/LabeledPickers/LabeledDateTimePicker';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectBlocks } from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  createNestedOption,
  createOptionsFromEntities,
  translateIn,
  translateOut,
} from 'utils/forms';
import { ConditionVables } from 'utils/vable';
import useGuidance from './guidance';
import fieldOptions from './options';
import conditionSchema from './validation';

const defaultValues = {
  name: '',
  paramACategory: '',
  compareTargetA: '',
  paramA: '',
  fovTarget: '',
  relationship: '',
  scalar: false,
  paramBCategory: '',
  compareTargetB: '',
  paramB: '',
  topLevelConditionType: '',
  fieldOfView: '',
  negate: false,
  terminator: false,
  groupRoller: '',
  countRelationship: '',
  countValue: '',
  targetGroupField: '',
  operator: '',
  isFilter: false, // TODO: Something about this field causes "react-fast-compare does not handle circular references" only on save
};

const {
  operatorTypes,
  topLevelConditionTypes,
  sharedParameterCategories,
  booleanValues,
  constantOnlyScalarOptions,
  targetOptionSpecs,
  boolOptionSpecs,
  scalarOption,
} = fieldOptions;

const ConditionDialog = (props) => {
  // Props
  const { control } = props;
  const { dialogConfig } = control;
  const { entity: condition } = dialogConfig;

  const selfId = condition?.id;
  const conditions = condition?.conditions;

  const {
    targets,
    targetGroups,
    fieldsOfView,
    referenceVectors,
    conditions: allConditions,
  } = useActiveEntities();

  // Hooks
  const classes = useStyles();

  // Create Options
  const targetsList = useMemo(() => createOptionsFromEntities(targets, ['type']), [targets]);
  const fovList = useMemo(() => createOptionsFromEntities(fieldsOfView), [fieldsOfView]);
  const tgList = useMemo(() => createOptionsFromEntities(targetGroups), [targetGroups]);

  const [targetAType, setTargetAType] = useState('SATELLITE');
  const [targetBType, setTargetBType] = useState('SATELLITE_B');

  const options = useMemo(() => {
    return {
      topLevelConditionType: topLevelConditionTypes,
      paramACategory: [...sharedParameterCategories, { value: 'TIME', label: 'Date and Time' }],
      paramBCategory: [...sharedParameterCategories, scalarOption],
      relationship: ConditionVables.ConditionRelationshipTypes.options,
      countRelationship: ConditionVables.ConditionRelationshipTypes.options,
      compareTargetA: [
        createNestedOption(targetGroups, 'Target Groups', {}, '', ['targetType'], {
          targetGroup: 'self',
          paramACategory: 'TARGET_GROUP',
        }),
        createNestedOption(targets, 'Targets', {}, 'targetA', ['type'], {
          targetA: 'self',
          paramACategory: 'TARGET',
        }),
      ],
      compareTargetB: targetsList,
      targetGroupField: tgList,
      fieldOfView: fovList,
      fovTarget: [
        createNestedOption(targetGroups, 'Target Groups', {}, 'targetGroup', ['targetType'], {
          field: 'targetGroup',
          type: 'TargetGroupInFovCondition',
        }),
        createNestedOption(targets, 'Targets', {}, 'targetA', ['type'], {
          field: 'targetA',
          type: 'TargetInFovCondition',
        }),
        createNestedOption(referenceVectors, 'Vectors', {}, 'referenceVector', [], {
          field: 'referenceVector',
          type: 'VectorInFovCondition',
        }),
      ],
      paramA: targetOptionSpecs[targetAType] || targetOptionSpecs['SATELLITE'],
      paramB: targetOptionSpecs[targetBType] || targetOptionSpecs['SATELLITE_B'],
      groupRoller: ConditionVables.GroupRollerTypes.options,
      operator: operatorTypes,
    };
  }, [
    targetsList,
    targetGroups,
    targets,
    fovList,
    referenceVectors,
    tgList,
    targetAType,
    targetBType,
  ]);

  // Helper functions and variables

  const isScalarATime = useCallback((values, direction) => {
    if (direction === 'out') {
      return values.paramBCategory?.value === 'SCALAR' && values.paramACategory?.value === 'TIME';
    } else {
      return values?.paramBCategory === 'SCALAR' && values?.paramACategory === 'TIME';
    }
  }, []);

  const ParamCategories = ConditionVables.ParamCategories;
  const ConditionTypes = ConditionVables.Type;

  const conditionSubclass = useCallback(
    (values) => {
      if (values.paramACategory.value === ParamCategories['TIME'].value) {
        values.type = 'TimeCondition';
      } else if (values.compareTargetA?.assumptions?.paramACategory === 'TARGET_GROUP') {
        values.tgParam = values.paramA.value;
        values.targetGroup = values.compareTargetA;
        switch (values.paramBCategory.value) {
          case ParamCategories['SCALAR'].value:
            values.type = 'TargetGroupToScalarCondition';
            break;
          case ParamCategories['SATELLITE'].value:
            values.type = 'TargetGroupToSatelliteCondition';
            values.selfParam = values.paramB.value;
            break;
          case ParamCategories['TARGET'].value:
            values.type = 'TargetGroupToTargetCondition';
            values.targetParam = values.paramB.value;
            values.targetA = values.compareTargetB.value.id;
            break;
          default:
          // This should not happen
        }
      } else if (values.paramBCategory.value === ParamCategories['SCALAR'].value) {
        if (values.paramACategory.value === ParamCategories['TARGET'].value) {
          values.type = 'TargetToScalarCondition';
          values.targetParam = values.paramA.value;
        } else {
          values.type = 'SatelliteToScalarCondition';
          values.param = values.paramA.value;
        }
      } else if (values.compareTargetA) {
        if (values.compareTargetB) {
          values.type = 'TargetToTargetCondition';
          values.targetAParam = values.paramA.value;
          values.targetBParam = values.paramB.value;
        } else {
          values.type = 'SatelliteToTargetCondition';
          values.selfParam = values.paramB.value;
          values.targetParam = values.paramA.value;
          switch (values.relationship.value) {
            case ConditionVables.ConditionRelationshipTypes['LESS'].value:
              values.relationship = ConditionVables.ConditionRelationshipTypes['GREATER'];
              break;
            case ConditionVables.ConditionRelationshipTypes['GREATER'].value:
              values.relationship = ConditionVables.ConditionRelationshipTypes['LESS'];
              break;
            case ConditionVables.ConditionRelationshipTypes['LESS_EQUAL'].value:
              values.relationship = ConditionVables.ConditionRelationshipTypes['GREATER_EQUAL'];
              break;
            case ConditionVables.ConditionRelationshipTypes['GREATER_EQUAL'].value:
              values.relationship = ConditionVables.ConditionRelationshipTypes['LESS_EQUAL'];
              break;
            default:
            // Equal and not equal need not be flipped
          }
        }
      } else if (values.compareTargetB) {
        values.type = 'SatelliteToTargetCondition';
        values.selfParam = values.paramA.value;
        values.targetParam = values.paramB.value;
        values.targetA = values.compareTargetB.value.id;
      } else {
        values.type = 'SatelliteToSatelliteCondition';
      }
    },
    [ParamCategories]
  );

  // The selections in the condition table are filtered for STMC only, but we can't determine the target group before
  // the form is filled out.  So we use a stateful variable and update it to track the later declared 'parseConditions'.
  const [selectedConditions, setSelectedConditions] = useState([]);

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      let datetimes;
      let percentages = [];
      const myOptions = { ...options };
      // Compare conditions
      if (
        values.topLevelConditionType.value ===
        ConditionVables.TopLevelConditionTypes['COMPARE'].value
      ) {
        // format data if form uses checkbox
        if (values?.paramA?.units === 'bool' || values?.paramA?.units === 'eclipse') {
          if (values.scalar.value === 1) {
            values.scalar = 1;
          } else {
            values.scalar = 0;
          }
        }
        // Turn percentage values into decimals
        if (values?.paramA?.units === '%') {
          percentages.push('scalar');
        }
        // Check if scalar is a time to pass in the proper datetimes array to translateOut
        if (isScalarATime(values, 'out')) {
          datetimes = ['scalar'];
        }
        // Determine condition subclass and set appropriate fields
        conditionSubclass(values);
      } else if (
        values.topLevelConditionType.value === ConditionVables.TopLevelConditionTypes['FOV'].value
      ) {
        values.type = values.fovTarget.assumptions.type;
        values[values.fovTarget.assumptions.field] = values.fovTarget.value.id;
      } else if (
        values.topLevelConditionType.value === ConditionVables.TopLevelConditionTypes['MULTI'].value
      ) {
        // Read out table
        values.type = 'CompoundCondition';
        values.conditions = selectedConditions.filter((c) => c.tableData.checked).map((c) => c.id);
      } else if (
        values.topLevelConditionType.value === ConditionVables.TopLevelConditionTypes['SAME'].value
      ) {
        values.type = 'SameTargetMultiCondition';
        values.conditions = selectedConditions.filter((c) => c.tableData.checked).map((c) => c.id);
        values.targetGroup = values.targetGroupField.value.id;
      }
      // Delete the fovTarget field as it is not required by the backend
      delete values.fovTarget;
      return translateOut(values, allowedEmptyFields, myOptions, datetimes, percentages);
    },
    [isScalarATime, selectedConditions, conditionSubclass]
  );

  const customTranslateIn = useCallback(
    (condition, defaultValues, options) => {
      const myCondition = { ...condition };
      const myOptions = { ...options };
      // Set the frontend fields using the appropriate backend values
      if (myCondition.type.includes('FovCondition')) {
        myCondition.topLevelConditionType = 'FOV';
        myCondition.fieldOfView = myCondition.fieldOfView?.id;
        if (myCondition?.targetA) {
          myCondition.fovTarget = myCondition.targetA?.id;
        } else if (myCondition?.targetGroup) {
          myCondition.fovTarget = myCondition.targetGroup?.id;
        } else {
          myCondition.fovTarget = myCondition.referenceVector;
        }
      } else if (myCondition.type === ConditionTypes['CompoundCondition'].value) {
        myCondition.topLevelConditionType = 'MULTI';
      } else if (myCondition.type === ConditionTypes['SameTargetMultiCondition'].value) {
        myCondition.topLevelConditionType = 'SAME';
        myCondition.targetGroupField = myCondition.targetGroup;
      } else {
        // Compare conditions require some extra care
        myCondition.topLevelConditionType = 'COMPARE';
        // A side
        if (myCondition?.targetGroup) {
          myCondition.paramACategory = 'TARGET';
          myCondition.compareTargetA = myCondition.targetGroup;
          myCondition.paramA = myCondition.tgParam;
        } else if (myCondition.type.includes('Time')) {
          myCondition.paramBCategory = 'SCALAR';
          myCondition.paramACategory = 'TIME';
        } else if (
          myCondition?.targetB ||
          (myCondition?.targetA && myCondition.type.includes('Scalar'))
        ) {
          // This is a target to target or target to scalar condition, so we must set A fields to targetA
          myCondition.paramACategory = 'TARGET';
          myCondition.compareTargetA = myCondition.targetA;
          myCondition.paramA = myCondition?.targetAParam
            ? myCondition.targetAParam
            : myCondition.targetParam;
        } else {
          myCondition.paramACategory = 'SATELLITE';
          if (myCondition?.selfParam) {
            myCondition.paramA = myCondition.selfParam;
          } else if (myCondition?.param) {
            myCondition.paramA = myCondition.param;
          }
        }
        // B side
        if (myCondition.type.includes('Scalar') || myCondition.type.includes('Time')) {
          myCondition.paramBCategory = 'SCALAR';
        } else if (myCondition?.targetB) {
          myCondition.paramBCategory = 'TARGET';
          myCondition.paramB = myCondition.targetBParam;
          myCondition.compareTargetB = myCondition.targetB;
        } else if (
          (myCondition?.targetGroup || myCondition.paramACategory === 'SATELLITE') &&
          myCondition?.targetA
        ) {
          // Satellite to target condition or target group to target condition
          myCondition.paramBCategory = 'TARGET';
          myCondition.compareTargetB = myCondition.targetA;
          myCondition.paramB = myCondition.targetParam;
        } else {
          myCondition.paramBCategory = 'SATELLITE';
          myCondition.paramB = myCondition?.paramB ? myCondition.paramB : myCondition.selfParam;
        }
        // The options must be reset for the correct parameter
        myOptions.paramA =
          targetOptionSpecs[
            myCondition.paramACategory === ParamCategories['TARGET'].value
              ? myCondition.compareTargetA.targetType || myCondition.compareTargetA?.type
              : 'SATELLITE'
          ];
        myOptions.paramB =
          targetOptionSpecs[
            myCondition.paramBCategory === ParamCategories['TARGET'].value
              ? myCondition.compareTargetB.type
              : 'SATELLITE'
          ];
      }
      // Recast some parameters to include value key
      if (myCondition.paramA === 'SHADOW' || myCondition.paramA === 'LO_SIGHT') {
        myCondition.scalar = { value: myCondition.scalar };
      }
      // Or a percent if using a percent
      const percentages = [];
      if (myCondition.paramA === 'BATTERY_SOC') {
        percentages.push('scalar');
      }
      let datetimes = undefined;
      if (isScalarATime(myCondition)) {
        datetimes = ['scalar'];
      }

      // isFilter is actually a relField, but in the form it's a boolean
      if (myCondition?.isFilter) {
        myCondition.isFilter = true;
      }
      return translateIn(myCondition, defaultValues, myOptions, datetimes, percentages);
    },
    [isScalarATime, ConditionTypes, ParamCategories]
  );

  // Form hook up
  const entityForm = useEntityForm({
    entityTypeText: 'Condition',
    entityDialogControl: control,
    defaultValues,
    validationSchema: conditionSchema,
    valuesToRemove: [
      'topLevelConditionType',
      'paramACategory',
      'paramBCategory',
      'compareTargetA',
      'compareTargetB',
      'fovTarget',
      'targetGroupField',
    ],
    formikOptionalParams: {
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      useGuidance,
      allowedEmptyFields: [],
    },
  });
  const { formik } = entityForm;
  const { getFieldProps, values, setFieldValue, setValues, dirty } = formik;
  const {
    paramA,
    paramACategory,
    paramBCategory,
    compareTargetA,
    compareTargetB,
    relationship,
    value,
    topLevelConditionType,
    targetGroupField,
  } = values;

  // Helper variables
  const booleanComparison = useMemo(
    () => paramA?.units === 'bool' || paramA?.units === 'eclipse',
    [paramA]
  );

  // Populate options with values
  useEffect(() => {
    setTargetAType(
      values?.compareTargetA
        ? values.compareTargetA.value?.type || values.compareTargetA.value.targetType
        : values?.targetGroupField
        ? values.targetGroupField.value.targetType
        : 'SATELLITE'
    );
  }, [values?.compareTargetA, values?.targetGroupField]);

  // Populate options with values
  useEffect(() => {
    setTargetBType(values?.compareTargetB ? values.compareTargetB.value.type : 'SATELLITE_B');
  }, [values?.compareTargetB]);

  // Use effect to set scalar to the correct data type
  // As well as set fields that are not displaying but need to be set for the backend
  useEffect(() => {
    // Only need to run the useEffect if the form is dirty, not on initial load
    if (dirty) {
      // Whenever condition is utilizing a date or time field then the scalar needs to be explicitly null
      // Otherwise the datetimepicker bug of showing the current time occurs
      if (paramACategory?.value === 'TIME') {
        setFieldValue('scalar', null);
      }
      // Booleans need to explicitly be true or false for the checked property to display correct
      // In this case only the checkbox is displayed so paramBCategory and relationship are manually set
      //
      else {
        // Otherwise set scalar back to the default value of empty string
        setFieldValue('scalar', '');
      }
    }
  }, [paramACategory, paramA, value]); //eslint-disable-line

  // Reset paramB field if paramA changes to or from a boolean value
  useEffect(() => {
    if (dirty) {
      setFieldValue('paramB', defaultValues['paramB']);
    }
  }, [booleanComparison, setFieldValue]); //eslint-disable-line

  // Render constant renders the scalar field, which shifts depending on the parameters being used
  const renderConstant = () => {
    if (relationship?.value && paramACategory?.value === 'TIME') {
      return <LabeledDateTimePicker {...getFieldProps('scalar')} label="Mission Time (UTC)" />;
    } else if (booleanComparison) {
      return (
        <LabeledSelect {...getFieldProps('scalar')} label={paramA?.label} options={booleanValues} />
      );
    } else if (paramA?.value) {
      return (
        <LabeledInput
          {...getFieldProps('scalar')}
          label={paramA?.label}
          type="number"
          endAdornment={<InputAdornment position="end">{paramA?.units}</InputAdornment>}
        />
      );
    }
  };

  // Render the actual select component
  const renderParameterSelect = (suffix, key) => {
    const paramOptions =
      booleanComparison && suffix === 'B' ? boolOptionSpecs[key] : options['param' + suffix];
    return (
      <LabeledSelect
        {...getFieldProps(`param${suffix}`)}
        label={``}
        options={paramOptions}
        formikOnChange={(val) => {
          if (suffix === 'A' && val.value !== paramA?.value) {
            // If a constant only param (i.e. not compared to dynamic value, e.g. battery soc),
            // set value to constant
            if (constantOnlyScalarOptions.find((option) => option.value === val.value)) {
              setValues({
                ...values,
                paramBCategory: scalarOption,
              });
            }
          }
        }}
      />
    );
  };

  const renderRollerSelect = (field) => {
    const isTgRoller = field === 'groupRoller'; // implied to be compound operator otherwise
    return (
      <>
        <LabeledSelect
          {...getFieldProps(field)}
          autoFocus={!isTgRoller}
          label={isTgRoller ? 'Group Roller' : 'Compound Operator'}
          options={options[field]}
        />
        {values[field] && values[field].value === 'COUNT' && (
          <>
            <LabeledSelect
              {...getFieldProps('countRelationship')}
              label="Count Relationship"
              autoFocus
              options={options.countRelationship}
            />
            <LabeledInput
              {...getFieldProps('countValue')}
              label=""
              placeholder="Count Value"
              type="number"
            />
          </>
        )}
      </>
    );
  };

  const renderParameters = (paramType, suffix, customOnChange) => {
    const key = 'compareTarget' + suffix;
    return (
      <>
        {paramType.value === 'SATELLITE' && (
          <div className={classes.indent}>{renderParameterSelect(suffix, 'SATELLITE')}</div>
        )}
        {paramType.value === 'TARGET' && (
          <MdAccent header="Target">
            <LabeledSelect
              {...getFieldProps(key)}
              options={options[key]}
              defaultValue={values[key]}
              formikOnChange={(val) => {
                if (customOnChange) customOnChange(val);
                setFieldValue(key, val);
              }}
              noOptionsMessage={() => 'Create a Target or Target Group'}
            />
            {suffix === 'A' &&
              compareTargetA &&
              renderParameterSelect(suffix, compareTargetA.value.type)}
            {suffix === 'B' &&
              compareTargetB &&
              renderParameterSelect(suffix, compareTargetB?.value.type)}
            {suffix === 'A' &&
              compareTargetA?.assumptions?.paramACategory === 'TARGET_GROUP' &&
              renderRollerSelect('groupRoller')}
          </MdAccent>
        )}
      </>
    );
  };

  const resetTargetParams = (suffix, newValue, prevValue) => {
    if (newValue.value !== prevValue?.value) {
      setFieldValue(`param${suffix}`, defaultValues['param' + suffix]);
      setFieldValue(`compareTarget${suffix}`, defaultValues['compareTarget' + suffix]);
    }
  };

  const otherConditions = useMemo(() => {
    // If this is a same target multi-condition, restrict to values in the target group
    if (values?.topLevelConditionType.value === 'SAME') {
      return allConditions.filter(
        (c) => c?.id !== selfId && c?.targetGroup?.id === targetGroupField?.value?.id
      );
    }
    // Otherwise, we just need to exclude the current condition
    return allConditions.filter((c) => c?.id !== selfId);
  }, [allConditions, selfId, values?.topLevelConditionType, targetGroupField]);

  const { parsedBlocks: parsedConditions, setParsedBlocks: setParsedConditions } = useSelectBlocks(
    otherConditions,
    conditions
  );
  const nullRef = useRef(null);

  const negateCheckbox = (
    <>
      <div className={classes.inputGroup}>
        <LabeledCheckbox {...getFieldProps('negate')} label={'Negate'}></LabeledCheckbox>
      </div>
    </>
  );

  useEffect(() => {
    setSelectedConditions(parsedConditions);
  }, [setSelectedConditions, parsedConditions]);
  return (
    <EntityDialog entityForm={entityForm} customDirty={true}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            placeholder="Condition Name"
            label="Name"
            autoFocus
          ></LabeledInput>
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('topLevelConditionType')}
            label="Condition Type"
            options={options.topLevelConditionType}
            formikOnChange={(val) => {
              if (val.value !== topLevelConditionType.value) {
                setValues({
                  ...defaultValues,
                  name: values.name,
                });
              }
            }}
          />
        </div>
        {topLevelConditionType?.value === 'COMPARE' && (
          <>
            <div className={classes.inputGroup}>
              <LabeledSelect
                {...getFieldProps('paramACategory')}
                label="Parameter A"
                autoFocus
                options={options.paramACategory}
                formikOnChange={(val) => {
                  if (val.value === 'TIME') {
                    setValues({ ...values, paramBCategory: scalarOption });
                  }
                  resetTargetParams('A', val, paramACategory);
                }}
              />
              {paramACategory &&
                renderParameters(paramACategory, 'A', (val) => {
                  if (paramACategory?.value === 'TARGET')
                    resetTargetParams('A', val, compareTargetA);
                })}
            </div>
            <div className={classes.inputGroup}>
              <LabeledSelect
                {...getFieldProps('relationship')}
                label="Relationship"
                options={
                  booleanComparison
                    ? ConditionVables.RestrictedRelationshipTypes.options
                    : options.relationship
                }
              />
            </div>
            <div className={classes.inputGroup}>
              <LabeledSelect
                {...getFieldProps('paramBCategory')}
                label="Parameter B"
                options={options.paramBCategory}
                isDisabled={
                  paramACategory?.value === 'TIME' ||
                  constantOnlyScalarOptions.find((option) => option.value === paramA?.value)
                }
                formikOnChange={(val) => {
                  resetTargetParams('B', val, paramBCategory);
                }}
              />
              {paramBCategory &&
                renderParameters(paramBCategory, 'B', (val) => {
                  if (paramBCategory.value === 'TARGET')
                    resetTargetParams('B', val, compareTargetB);
                })}
            </div>
            {paramBCategory?.value === 'SCALAR' && (
              <div className={classes.inputGroup}>{renderConstant()}</div>
            )}
          </>
        )}
        {topLevelConditionType?.value === 'FOV' && (
          <>
            <div className={classes.inputGroup}>
              <LabeledSelect
                {...getFieldProps('fieldOfView')}
                label="Field Of View"
                autoFocus
                options={options.fieldOfView}
              />
            </div>
            <div className={classes.inputGroup}>
              <LabeledSelect
                {...getFieldProps('fovTarget')}
                label="Target/Vector in FOV"
                options={options.fovTarget}
              />
            </div>
            {negateCheckbox}
          </>
        )}
        {topLevelConditionType?.value === 'SAME' && (
          <>
            <div className={classes.inputGroup}>
              <LabeledSelect
                {...getFieldProps('targetGroupField')}
                label="Target Group"
                autoFocus
                options={options.targetGroupField}
              />
            </div>
          </>
        )}
        {(topLevelConditionType?.value === 'MULTI' ||
          (topLevelConditionType?.value === 'SAME' && targetGroupField?.value)) && (
          <>
            <div className={classes.inputGroup}>
              <WidgetTable
                tableRef={nullRef}
                className={classes.table}
                columns={conditionsTableColumns}
                setData={setParsedConditions}
                data={parsedConditions}
                emptyMessage={'No conditions found'}
                title="Select Conditions"
                search={true}
                selection={true}
              />
            </div>
          </>
        )}
        {topLevelConditionType?.value === 'MULTI' && (
          <>
            {renderRollerSelect('operator')}
            {negateCheckbox}
          </>
        )}
        {(targetGroupField || compareTargetA?.assumptions?.paramACategory === 'TARGET_GROUP') && (
          <>
            <div className={classes.inputGroup}>
              <LabeledCheckbox
                {...getFieldProps('isFilter')}
                label={'Filters target group'}
              ></LabeledCheckbox>
            </div>
          </>
        )}
        <div className={classes.inputGroup}>
          <LabeledCheckbox
            {...getFieldProps('terminator')}
            label={'Terminate simulation when met'}
          ></LabeledCheckbox>
        </div>
      </div>
    </EntityDialog>
  );
};

export default ConditionDialog;
