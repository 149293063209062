import { IThermalInterfaceMaterial } from 'components/general/types/thermal';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import InputAdornment from '@material-ui/core/InputAdornment';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import validation from './validation';
import { useGuidance } from './guidance';

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  resistivity: number | '';
  thickness: number | '';
  hotTempRating: { degC: number | ''};
  coldTempRating: { degC: number | ''};
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  resistivity: '',
  thickness: '',
  hotTempRating: {degC: 100},
  coldTempRating: {degC: -40},
};

interface IProps {
  control: TEntityDialogControl<IThermalInterfaceMaterial>;
}
const ThermalInterfaceMaterialDialog = ({ control }: IProps) => {
  // Set up styles
  const classes = useStyles();

  const entityForm = useEntityForm<IThermalInterfaceMaterial, IForm>({
    entityTypeText: 'Thermal Interface Material',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      allowedEmptyFields: ['manufacturer', 'partNumber'],
    },
    additionalCreateValues: {type: 'ThermalInterfaceMaterial'}
  });

  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Thermal Interface Material Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('resistivity')}
            type="number"
            endAdornment={<InputAdornment position="end">K·m/W</InputAdornment>}
            label="Resistivity"
          />
          <LabeledInput
            {...getFieldProps('thickness')}
            type="number"
            endAdornment={<InputAdornment position="end">m</InputAdornment>}
            label="Thickness"
          />
          <LabeledInput
            {...getFieldProps('hotTempRating.degC')}
            type="number"
            endAdornment={<InputAdornment position="end">°C</InputAdornment>}
            label="Hot Temperature Rating"
          />
          <LabeledInput
            {...getFieldProps('coldTempRating.degC')}
            type="number"
            endAdornment={<InputAdornment position="end">°C</InputAdornment>}
            label="Cold Temperature Rating"
          />
        </div>
      </div>
    </EntityDialog>
  );
};

export default ThermalInterfaceMaterialDialog;
