import { IMenu } from 'components/general/types';
import { CHART_SPEC, SUB_ITEMS, TITLE } from 'config';
import { routePathsCommon } from 'routes';

const { TS } = routePathsCommon;

const TIME_STEPS = 'time-step';
const SIMULATION = 'simulation';

const agentSimulationMenu: IMenu['items'] = {
  [SIMULATION]: {
    [TITLE]: 'Simulation',
    [SUB_ITEMS]: {
      [TS]: {
        [TITLE]: 'Time Series',
        [SUB_ITEMS]: {
          [TIME_STEPS]: {
            [TITLE]: 'Time Steps',
            [CHART_SPEC]: [], // This is populated at runtime
          },
        },
      },
    },
  },
};

export const wGroupIndicesAgentSimulation = {
  POWER: `${SIMULATION}*`,
  TS_TS: `${SIMULATION}.${TS}.${TIME_STEPS}`,
};

export default agentSimulationMenu;
