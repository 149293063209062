import { makeGuidance } from 'hooks';

const loadHeading = 'Loads';

const loadPermanentGuidance = {
  chunk:
    "Loads are used to characterize the electrical power consumption of a satellite's components. The power subsystem will deliver power to these loads when active throughout the mission, as specified by the load states and their associated operational modes.",
};

const dutyCycleParamGuidance = {
  subHeading: 'Duty Cycle',
  chunk:
    'Loads can operated on regular cycles of set duration. The duty cycle period is the length of the cycle and the duty cycle percentage is the percentage of that cycle that the load will be active. At the beginning of each cycle, the load will become active and will stay active until it exceeds the specified percentage of the duty cycle period.',
};

const epsOutputTypeGuidance = {
  heading: loadHeading,
  body: [
    loadPermanentGuidance,
    {
      subHeading: 'Power Source',
      chunk:
        'Loads can either be powered directly from the power controller bus or via a bus regulator.',
    },
  ],
};

export const useGuidance = makeGuidance({
  _default: {
    heading: loadHeading,
    body: [loadPermanentGuidance, dutyCycleParamGuidance],
  },

  epsOutputType: epsOutputTypeGuidance,

  busRegulator: epsOutputTypeGuidance,

  dutyCyclePeriod: {
    min: {
      heading: loadHeading,
      body: [loadPermanentGuidance, dutyCycleParamGuidance],
    }
  },

  dutyCyclePercentage: {
    heading: loadHeading,
    body: [loadPermanentGuidance, dutyCycleParamGuidance],
  },

  type: {
    heading: loadHeading,
    body: [
      loadPermanentGuidance,
      {
        subHeading: 'Types of Loading',
        chunk:
          'Loads can be defined as constant power or constant resistance. Constant power loads will always consume the same power whenever active. Constant resistance loads will consume variable power depending on whether they are connected to a regulated or unregulated bus.',
      },
    ],
  },

  loadDefParams: {
    power: {
      alias: 'type',
    },
    resistance: {
      alias: 'type',
    },
  },
});
