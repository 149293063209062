import { ISelectOption } from 'components/general/types';
import getThermalProps from 'hooks/getThermalProps';
import { ActuatorVables } from 'utils/vable';
import * as Yup from 'yup';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  name: Yup.string().required('An actuator name is required.'),
  manufacturer: Yup.string(),
  partNumber: Yup.string(),
  type: Yup.object().required('Select an actuator type.'),
  ratedMagneticMoment: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.Magnetorquer.value,
    then: Yup.number().required('Rated magnetic moment is required.'),
  }),
  powerAtRatedMagneticMoment: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.Magnetorquer.value,
    then: Yup.number().required('Power at rated magnetic moment is required.'),
  }),
  inertia: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.ReactionWheel.value,
    then: Yup.number().required('Inertia is required.').moreThan(0, 'Inertia must be positive.'),
  }),
  ratedTorque: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.ReactionWheel.value,
    then: Yup.number()
      .required('Rated torque is required.')
      .moreThan(0, 'Rated torque must be positive.'),
  }),
  ratedMomentum: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.ReactionWheel.value,
    then: Yup.number()
      .required('Rated angular momentum is required.')
      .moreThan(0, 'Rated angular momentum must be positive.'),
  }),
  efficiency: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.ReactionWheel.value,
    then: Yup.number()
      .required('Efficiency is required.')
      .moreThan(0, 'Efficiency must be greater than 0%.')
      .max(100, 'Efficiency cannot be above 100%.'),
  }),
  isp: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.Thruster.value,
    then: Yup.number()
      .required('Specific impulse is required.')
      .moreThan(0, 'Specific impulse must be positive.'),
  }),
  maxThrust: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.Thruster.value,
    then: Yup.number()
      .required('Maximum thrust is required.')
      .moreThan(0, 'Maximum thrust must be positive.'),
  }),
  minThrust: Yup.number().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.Thruster.value,
    then: Yup.number()
      .required('Minimum thrust is required.')
      .moreThan(0, 'Minimum thrust must be positive.'),
  }),
  location: Yup.array().when('type', {
    is: (type: ISelectOption) =>
      type?.value === ActuatorVables.Type.Thruster.value,
    then: Yup.array().of(
      Yup.number().required('Thruster Location x, y, and z components are all required.')
    ),
  }),
  ...thermalValidation,
});

export default validation;
