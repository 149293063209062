import { ISelectOption } from 'components/general/types';
import { AgentVables, TargetVables } from 'utils/vable';
import * as Yup from 'yup';
import orbitValidation from '../OrbitForm/validation';

const validation = Yup.object().shape({
  name: Yup.string().required('An agent name is required.'),
  agentType: Yup.object().required('Select an agent type.'),

  // Templated agents
  template: Yup.object().when('agentType', {
    is: (agentType: ISelectOption) => agentType?.value === AgentVables.AgentType.TEMPLATED.value,
    then: Yup.object().required('Select an agent template to define this agent.'),
  }),
  branch: Yup.object().when('agentType', {
    is: (agentType: ISelectOption) => agentType?.value === AgentVables.AgentType.TEMPLATED.value,
    then: Yup.object().required('Select a branch to define this agent.'),
  }),

  // Peripheral agents
  targetType: Yup.object().when('agentType', {
    is: (agentType: ISelectOption) => agentType?.value === AgentVables.AgentType.PERIPHERAL.value,
    then: Yup.object().required('Select a peripheral agent subtype.'),
  }),

  polynomialEphemerisBody: Yup.object().when('targetType', {
    is: (agentType: ISelectOption) => agentType?.value === TargetVables.Type.CelestialTarget.value,
    then: Yup.object().required('Select a celestial body.'),
  }),

  // Ground targets
  lat: Yup.number().when(['agentType', 'targetType'], {
    is: (agentType: ISelectOption, targetType: ISelectOption) =>
      agentType?.value === AgentVables.AgentType.PERIPHERAL.value &&
      targetType?.value === TargetVables.Type.GroundTarget.value,
    then: Yup.number()
      .required('Latitude is required.')
      .min(-90, 'Latitude must be between -90° and 90°N, inclusive.')
      .max(90, 'Latitude must be between -90° and 90°N, inclusive.'),
  }),
  lon: Yup.number().when(['agentType', 'targetType'], {
    is: (agentType: ISelectOption, targetType: ISelectOption) =>
      agentType?.value === AgentVables.AgentType.PERIPHERAL.value &&
      targetType?.value === TargetVables.Type.GroundTarget.value,
    then: Yup.number()
      .required('Longitude is required.')
      .min(-180, 'Longitude must be between -180° and 180°E, inclusive.')
      .max(180, 'Longitude must be between -180° and 180°E, inclusive.'),
  }),
  alt: Yup.number().when(['agentType', 'targetType'], {
    is: (agentType: ISelectOption, targetType: ISelectOption) =>
      agentType?.value === AgentVables.AgentType.PERIPHERAL.value &&
      targetType?.value === TargetVables.Type.GroundTarget.value,
    then: Yup.number().required('Altitude is required.'),
  }),
  ...orbitValidation,
});

export default validation;
