import { IColumn } from 'components/general/types';
import { IDataStorage } from 'components/general/types/dataHandling';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import DataStorageDialog from './DataStorageDialog';
import guidance from './guidance';

interface IProps {
  index: string;
}

const DataStoragesColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
];

const DataStorageSegment = ({ index }: IProps) => {
  const { dataStorages } = useActiveEntities();

  return (
    <EntityTableSegment<IDataStorage>
      title="Data Storage"
      index={index}
      guidance={guidance}
      entityColumns={DataStoragesColumns}
      entityData={dataStorages}
      DialogComponent={DataStorageDialog}
      modelName="data storage block"
    ></EntityTableSegment>
  );
};

export default DataStorageSegment;
