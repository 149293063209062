import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from 'theme';

export const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.mainNested,
    borderRadius,
    padding: '1rem',
  },
  slider: {
    width: '90%',
    alignSelf: 'center',
  },
  sliderTitleFirst: {
    marginBlockStart: '0',
    marginBlockEnd: '0.5rem',
  },
  sliderTitle: {
    marginBlockStart: '0.75rem',
    marginBlockEnd: '0.5rem',
  },
}));

export const useStylesNode = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  newBorder: {
    border: 'solid 5px blue',
  },
  label: {
    color: 'black',
    fontSize: 14,
    lineHeight: '14px',
    letterSpacing: '-0.5px', // squish letters closer together
    margin: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  temp: {
    margin: 0,
    color: 'black',
  },
  child: {
    margin: 0,
    color: 'black',
  },
  svgWrapper: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    bottom: '1px',
    right: '1px',
  },
  svg: {
    fill: 'steelblue',
    animation: `$myEffect 3000ms`,
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '10%': {
      opacity: 1,
    },
    '20%': {
      opacity: 0,
    },
    '30%': {
      opacity: 1,
    },
    '40%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '60%': {
      opacity: 0,
    },
    '70%': {
      opacity: 1,
    },
    '80%': {
      opacity: 0,
    },
    '90%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
});

export const useStylesToolTipTitle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      margin: 0,
      padding: 0,
    },
  },
});

export const useStylesInterface = makeStyles({
  layoutControlsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  layoutSelectWrapper: {},
  layoutSelect: {
    width: '400px !important',
  },
  buttonsWrapper: {
    alignSelf: 'flex-end',
    paddingBottom: 3,
  },
  button: {
    position: 'relative',
    display: 'inline-block',
  },
  buttonDirty: {
    position: 'relative',
    display: 'inline-block',
    color: 'red',
  },
  layoutNameInput: {
    width: '400px !important',
  },
});
