import { IGenericObject } from 'components/general/types';
import { FormikValues } from 'formik';
import { useActiveEntities, useAppDispatch, useFormikForm, useSnackbar } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { SatelliteApi, TAction, TSedaroEntities } from 'middleware/SatelliteApi/api';
import { useCallback, useMemo, useState } from 'react';
import { toSentenceCase } from 'utils/strings';
import * as Yup from 'yup';
import { ISanitizedParameters, TTranslateOut } from './FormikHook';

const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please try again. If this problem persists, please contact our support team.';

interface IParams<EntityType, Values> {
  entityTypeText: string;
  entityDialogControl: TEntityDialogControl<EntityType>;
  validateForm?: (values: Values, action?: string) => boolean | string;
  predispatchCallback?: (values: Values) => Promise<boolean> | boolean;
  additionalCreateValues?: { [key: string]: unknown };
  valuesToRemove?: string[];
  extendOnSuccess?: (response: { [key: string]: unknown }, values: Values) => void;
  extendReset?: () => void;
  editAfterCreate?: boolean;
  defaultValues: Values;
  validationSchema: Yup.AnyObjectSchema;
  formikOptionalParams: Partial<ISanitizedParameters<EntityType, Values>>;
  overrideEntityText?: string;
  entityType?: { [key: string]: string };
  overrideAlreadyExistsMessage?: string;
}

type TSanitizedAction = 'create' | 'update' | 'delete' | undefined;

const useEntityForm = <T extends { id: string | number }, V extends FormikValues>(
  params: IParams<T, V>
) => {
  const {
    entityTypeText,
    entityDialogControl,
    // ----------------- OPTIONAL -----------------
    // custom validation not handled by formik
    validateForm = () => undefined,
    // anything you want to happen before the dispatch, can take in one positional argument (values)
    // returns true if all goes well, false if an error occurs and dispatch should stop
    predispatchCallback = () => true,
    // values that aren't in the form but are still needed for the dispatch
    additionalCreateValues = {},
    // names of values that should not be dispatched
    valuesToRemove = [],
    // anything you want to happen after the onSuccess dispatch, can take in two positional arguments (response, values)
    extendOnSuccess = () => undefined,
    // additional function needed to be performed on reset form calls
    extendReset = () => undefined,
    // boolean switch for dialogs that require being kept open after creation and setting new config to the response
    editAfterCreate = false,
    // override for specialized components (ex. 'Component' for 'Solar Panel')
    overrideEntityText,
    // manually specify entity's "type" for middleware baseRoute resolution. TODO Tech Debt: Rework things to not require this as it won't scale
    entityType,
    // override message that is displayed when an entity with the same name already exists
    overrideAlreadyExistsMessage,
    // ----------------- useFormik -----------------
    // below needed for useFormik hook, see that file for instructions
    defaultValues,
    validationSchema,
    // ------- useFormik OPTIONAL -------
    // NOTE: to use this hook with the entityDialog, you must pass in at least useGuidance in formikOptionalParams
    formikOptionalParams,
  } = params;

  const [loading, setLoading] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

  const { dialogConfig, closeDialog, openDialogForExisting } = entityDialogControl;
  const { entity, action } = dialogConfig;

  const sanitizedAction: TSanitizedAction =
    action === 'edit' ? 'update' : action === 'clone' ? 'create' : action;

  const isDelete = useMemo(() => sanitizedAction === 'delete', [sanitizedAction]);

  const {
    MissionVersion: {
      actions: { invalidateSimulation },
    },
  } = SatelliteApi;
  const { branch } = useActiveEntities();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const entityText = useMemo(
    () => overrideEntityText || entityTypeText.split(' ').join(''),
    [entityTypeText, overrideEntityText]
  );
  const snackbarEntityText = useMemo(() => toSentenceCase(entityTypeText), [entityTypeText]);

  const onClose = useCallback(
    (response, action) => {
      if (editAfterCreate && action === 'create') {
        openDialogForExisting(response, 'edit');
      } else {
        closeDialog();
        setDeleteErrorMessage('');
      }
    },
    [closeDialog, setDeleteErrorMessage, editAfterCreate, openDialogForExisting]
  );

  const crudActionDispatch: (values: V) => void = useCallback(
    async (values) => {
      setLoading(true);

      // predispatchCallback does not always have to use values even though they are always passed in
      // returns false if error occurs and crudActionDispatch should stop
      const continueDispatch = await predispatchCallback(values);
      if (!continueDispatch) {
        setLoading(false);
        return;
      }

      if (values && !isDelete) {
        const result = validateForm(values, action);
        if (result) {
          enqueueSnackbar(result);
          setLoading(false);
          return;
        }
      }

      // for deletion action, we only need the entity id and branch id, otherwise we use the form values
      let valuesToDispatch: { [key: string]: unknown } = {
        ...values,
        entity: { ...entity, ...values, ...entityType },
        branchId: branch.id,
      };

      if (isDelete && entity && 'id' in entity) {
        valuesToDispatch = { id: entity.id, entity, branchId: branch.id };
      } else if (sanitizedAction === 'create') {
        valuesToDispatch = { ...valuesToDispatch, ...additionalCreateValues };
      } else if (sanitizedAction === 'update' && entity && 'id' in entity) {
        valuesToDispatch = { ...valuesToDispatch, id: entity.id };
      }

      // extract out all unneccessary values
      valuesToRemove.forEach((val) => {
        delete valuesToDispatch[val];
      });

      const slice = SatelliteApi[entityText as keyof TSedaroEntities] || SatelliteApi.Block;
      const actions: { [key: string]: TAction } = slice.actions;
      const _action =
        actions[`${sanitizedAction}${entityText}`] || actions[`${sanitizedAction}Block`];

      dispatch(
        _action({
          ...valuesToDispatch,
          successCallback: (response: IGenericObject) => {
            if (isDelete && branch.id) {
              dispatch(invalidateSimulation(branch.id));
            }
            // allows for extending the default onSuccess cb
            // ex: for creating a new op mode or target group, we also want to open the edit dialog on success
            // extendOnSuccess does not always have to use response and values even though they are always passed in
            extendOnSuccess(response, values);
            onClose(response, action);
            enqueueSnackbar(`${snackbarEntityText} ${sanitizedAction}d successfully`, {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response?: { error: { message: string } }) => {
            let errorMessage = response?.error?.message || DEFAULT_ERROR_MESSAGE;

            if (isDelete) setDeleteErrorMessage(errorMessage);

            if (errorMessage.includes('already exists') && overrideAlreadyExistsMessage) {
              errorMessage = overrideAlreadyExistsMessage;
            }

            enqueueSnackbar(errorMessage);
            setLoading(false);
          },
        })
      );
    },
    [
      action,
      additionalCreateValues,
      branch,
      dispatch,
      enqueueSnackbar,
      entity,
      entityText,
      entityType,
      extendOnSuccess,
      invalidateSimulation,
      isDelete,
      onClose,
      overrideAlreadyExistsMessage,
      predispatchCallback,
      sanitizedAction,
      snackbarEntityText,
      validateForm,
      valuesToRemove,
    ]
  );

  const _formikOptionalParams = formikOptionalParams;
  if (isDelete) {
    const deleteDisabledTranslateOut: TTranslateOut<V> = (values, allowedEmptyFields, options) => {
      if (isDelete || !formikOptionalParams.translateOut) return {} as V;
      return formikOptionalParams.translateOut(values, allowedEmptyFields, options);
    };
    _formikOptionalParams.translateOut = deleteDisabledTranslateOut;
  }

  const { formik, guidance } = useFormikForm<T, V>(
    defaultValues,
    crudActionDispatch,
    isDelete ? undefined : validationSchema,
    entity,
    _formikOptionalParams
  );

  return {
    formik,
    guidance,
    loading,
    deleteErrorMessage,
    entityTypeText,
    dialogConfig,
    onClose,
    extendReset,
  };
};

export default useEntityForm;
