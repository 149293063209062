/* eslint no-template-curly-in-string: 0 */
import { PowerProcessorVables } from 'utils/vable';
import { maxLimitMessage, requiredMessage } from 'utils/validation';
import * as Yup from 'yup';
import getThermalProps from 'hooks/getThermalProps';
import { ISelectOption } from 'components/general/types';

const { thermalValidation } = getThermalProps();

// Repeated validation
const efficiencyValidation = (name: string) => {
  return Yup.number()
    .required(requiredMessage(`${name} efficiency value`))
    .max(100, name + ' efficiency cannot be greater than ${max}%.')
    .moreThan(0, name + ' efficiency must be greater than ${more}%.');
};

const outputPowerRatingValidation = Yup.number()
  .required(requiredMessage('output power rating value'))
  .moreThan(0, 'Output power rating must be greater than ${more} watts.');

const dischargeDiodeDropValidation = Yup.number()
  .required(requiredMessage('battery discharge diode drop value'))
  .min(0, 'Battery discharge diode drop cannot be less than ${min} volts.');

const chargeDiodeDropValidation = Yup.number()
  .required(requiredMessage('solar array diode drop value'))
  .min(0, 'Solar array diode drop cannot be less than ${min} volts.');

const controllerBusVoltageValidation = Yup.number()
  .required(requiredMessage('controller bus voltage value'))
  .moreThan(0, 'Controller bus voltage must be greater than ${more} volts.');

// Validation Schema
const powerProcessorSchema = Yup.object().shape({
  name: Yup.string()
    .required(requiredMessage('power processor name'))
    .max(32, maxLimitMessage('power processor name', 32)),

  manufacturer: Yup.string().max(32, 'Manufacturer must be no more than ${max} characters.'),

  partNumber: Yup.string().max(32, 'Part Number must be no more than ${max} characters.'),

  type: Yup.object().required('Please select a topology type.'),

  topologyParams: Yup.object()

    // Single Conv MPPT
    .when('type', {
      is: (type: ISelectOption) =>
        type?.value === PowerProcessorVables.Type.SingleConvMpptPowerProcessor.value,
      then: Yup.object({
        bcrEfficiency: efficiencyValidation('BCR'),

        outputPowerRating: outputPowerRatingValidation,
      }),
    })

    // Quasi-Regulated Direct Energy Transfer
    .when('type', {
      is: (type: ISelectOption) =>
        type?.value === PowerProcessorVables.Type.QuasiRegDetPowerProcessor.value,
      then: Yup.object({
        bcrEfficiency: efficiencyValidation('BCR'),

        outputPowerRating: outputPowerRatingValidation,

        dischargeDiodeDrop: dischargeDiodeDropValidation,

        chargeDiodeDrop: chargeDiodeDropValidation,

        chargeControllerBusVoltage: controllerBusVoltageValidation,
      }),
    })

    // Fully Regulated Direct Energy Transfer
    .when('type', {
      is: (type: ISelectOption) =>
        type?.value === PowerProcessorVables.Type.FullyRegDetPowerProcessor.value,
      then: Yup.object({
        bcrEfficiency: efficiencyValidation('BCR'),

        bdrEfficiency: efficiencyValidation('BDR'),

        outputPowerRating: outputPowerRatingValidation,

        chargeDiodeDrop: chargeDiodeDropValidation,

        drivenControllerBusVoltage: controllerBusVoltageValidation,
      }),
    })

    // Two Conv MPPT
    .when('type', {
      is: (type: ISelectOption) =>
        type?.value === PowerProcessorVables.Type.TwoConvMpptPowerProcessor.value,
      then: Yup.object({
        bcrEfficiency: efficiencyValidation('BCR'),

        outputPowerRating: outputPowerRatingValidation,

        dischargeDiodeDrop: dischargeDiodeDropValidation,

        pptEfficiency: efficiencyValidation('PPT'),

        chargeControllerBusVoltage: controllerBusVoltageValidation,
      }),
    })

    // Single Conv Hybrid
    .when('type', {
      is: (type: ISelectOption) =>
        type?.value === PowerProcessorVables.Type.SingleConvHybridPowerProcessor.value,
      then: Yup.object({
        bcrEfficiency: efficiencyValidation('BCR'),

        outputPowerRating: outputPowerRatingValidation,

        bypassBlockingDiodeDrop: dischargeDiodeDropValidation,
      }),
    }),

  ...thermalValidation,
});

export default powerProcessorSchema;
