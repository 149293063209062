import { TChartSpec } from 'components/general/types';

const tsDataStorageDPSchema: TChartSpec = [
  {
    name: 'Data Storage "{name}"',
    each: 'DataStorage',
    plots: [
      {
        type: 'line',
        label: 'Utilization Fraction',
        // unit: 'bits',
        variables: [
          {
            keys: ['fillPercent'],
            legend: ['Total Storage'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Transfer Rate',
        unit: 'bit/s',
        variables: [
          {
            keys: ['readRate', 'writeRate'],
            legend: ['Read', 'Write'],
          },
        ],
      },
    ],
  },
];

export default tsDataStorageDPSchema;
