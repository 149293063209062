import { makeGuidance } from 'hooks';

const controlStateHeading = 'Control States';
const controlStatePermanentGuidance = {
  chunk:
    "Control States are used to direct Temperature Controllers to heat/cool their controlled component to the target control temperature. Input that temperature here and select for which Operational Modes this Control State will be active.",
};

export const useGuidance = makeGuidance({
  _default: {
    heading: controlStateHeading,
    body: [controlStatePermanentGuidance],
  },

  constantTemperature: {
    degC: {
      heading: controlStateHeading,
      body: [controlStatePermanentGuidance],
    }
  },
});
