import {
  faChevronDown,
  faChevronUp,
  faPencilAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import ClipboardCopy from 'components/general/ClipboardCopy';
import StyledButton from 'components/general/StyledButton';
import { IMissionVersion, TClickEvent } from 'components/general/types';
import { useEditBoardRoute, usePermissionCheck, useSelectById } from 'hooks';
import moment from 'moment-timezone';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import { toTitleCase } from 'utils/strings';
import { WorkspaceVables } from 'utils/vable';
import useStyles from './styles';

interface IProps {
  branch: IMissionVersion;
  openBranchDialog: (branch: IMissionVersion, action: string) => void;
  openMergeDialog: (branch: IMissionVersion, action: string) => void;
  openCommitDialog: (branchId: string, action: string) => void;
  fetchGitHistAndOpenDialog: (branch: IMissionVersion) => void;
  loadingGitHistBrId: string;
  fetchGitChangesAndOpenDialog: (branch: IMissionVersion) => void;
  branchLoadingGitChanges: string;
  openBranchId: string;
  setOpenBranchId: Dispatch<SetStateAction<string>>;
}

const textButtonStyle = {
  backgroundColor: theme.palette.background.dark,
  minWidth: 90,
  whiteSpace: 'nowrap',
};

const BranchRow = (props: IProps) => {
  const {
    branch,
    openBranchDialog,
    openMergeDialog,
    openCommitDialog,
    fetchGitHistAndOpenDialog,
    loadingGitHistBrId,
    fetchGitChangesAndOpenDialog,
    branchLoadingGitChanges,
    openBranchId,
    setOpenBranchId,
  } = props;

  const editBoardRoute = useEditBoardRoute();
  const history = useHistory();

  const mission = useSelectById('Mission', branch.mission);
  const isLastBranchOnRepo = mission.versions.length === 1;

  const [canView, canEdit] = usePermissionCheck([
    WorkspaceVables.Permission.VIEW_BRANCH,
    WorkspaceVables.Permission.EDIT_BRANCH,
  ]);

  const tooltip = useCallback(
    (action) => {
      return action === 'delete' && isLastBranchOnRepo
        ? 'Every repository must have at least one branch. You can only delete this final branch by deleting the whole repository.'
        : toTitleCase(action);
    },
    [isLastBranchOnRepo]
  );

  const isOpenBranch = openBranchId === branch.id;

  const classes = useStyles({
    enabledDelete: canEdit,
    selected: isOpenBranch,
  });

  return (
    <div className={classes.row} onClick={() => history.push(editBoardRoute(branch.id))}>
      <div className={classes.infoContainer}>
        <h3>{branch.name}</h3>
        {isOpenBranch && (
          <>
            <div className={classes.branchDescription}>{branch.description}</div>
            <div className={classes.description}>
              Created {moment(branch.dateCreated).fromNow()}
            </div>
          </>
        )}
        <div className={classes.description}>
          ID: <ClipboardCopy text={branch.id} /> | Updated {moment(branch.dateModified).fromNow()}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <StyledButton
          framed
          style={{ backgroundColor: theme.palette.background.dark }}
          tooltip={tooltip(isOpenBranch ? 'hide description' : 'show description')}
          className={classes.iconButton}
          onClick={(e: TClickEvent) => {
            e.stopPropagation();
            setOpenBranchId((_prev) => (isOpenBranch ? '' : branch.id));
          }}
        >
          <FontAwesomeIcon icon={isOpenBranch ? faChevronUp : faChevronDown} />
        </StyledButton>
        {canEdit && (
          <>
            <StyledButton
              onClick={(e: TClickEvent) => {
                e.stopPropagation();
                openMergeDialog(branch, 'create');
              }}
              framed
              style={textButtonStyle}
              tooltip="Pull in updated changes from a target branch to this branch"
            >
              Merge In
            </StyledButton>
            <StyledButton
              onClick={(e: TClickEvent) => {
                e.stopPropagation();
                openCommitDialog(branch.id, 'create');
              }}
              framed
              style={textButtonStyle}
              tooltip="Commit changes to save history and allow for merging changes into other branches"
            >
              Commit
            </StyledButton>
          </>
        )}
        {canEdit && (
          <StyledButton
            framed
            onClick={(e: TClickEvent) => {
              e.stopPropagation();
              openBranchDialog(branch, 'branch');
            }}
            style={textButtonStyle}
            tooltip="Create a new branch using this branch as a starting point"
          >
            Branch
          </StyledButton>
        )}
        {canView && (
          <>
            <StyledButton
              framed
              onClick={(e: TClickEvent) => {
                e.stopPropagation();
                fetchGitChangesAndOpenDialog(branch);
              }}
              style={textButtonStyle}
              loading={branchLoadingGitChanges === branch.id}
              replaceSpinner
              tooltip="View uncommitted changes"
            >
              Changes
            </StyledButton>
            <StyledButton
              framed
              onClick={(e: TClickEvent) => {
                e.stopPropagation();
                fetchGitHistAndOpenDialog(branch);
              }}
              style={textButtonStyle}
              loading={loadingGitHistBrId === branch.id}
              replaceSpinner
              tooltip="View all previous commits"
            >
              History
            </StyledButton>
          </>
        )}
        <StyledButton
          framed
          style={{ backgroundColor: theme.palette.background.dark }}
          disabled={!canEdit}
          tooltip={tooltip('update')}
          className={classes.iconButton}
          onClick={(e: TClickEvent) => {
            e.stopPropagation();
            openBranchDialog(branch, 'edit');
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </StyledButton>
        <StyledButton
          disabled={!canEdit || isLastBranchOnRepo}
          tooltip={tooltip('delete')}
          className={clsx(classes.iconButton, classes.deleteButton)}
          onClick={(e: TClickEvent) => {
            e.stopPropagation();
            openBranchDialog(branch, 'delete');
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </StyledButton>
      </div>
    </div>
  );
};

export default BranchRow;
