import { makeGuidance } from 'hooks';

const surfacePermanentGuidance =
  'Define the relevant external surfaces of your satellite. These can be used to mount solar panels and can either be fixed or sun-tracking.  In the future, these panels will also be used to compute atmospheric drag, radiative heat transfer, and solar radiation pressure perturbations.';

const motionTypeGuidance = {
  heading: 'External Surfaces',
  body: [
    {
      chunk: surfacePermanentGuidance,
    },
    {
      subHeading: 'Fixed Surfaces',
      chunk: "Fixed surfaces are fixed with respect to the satellite's body reference frame",
    },
    {
      subHeading: 'Sun Tracking Surfaces',
      chunk:
        "Sun Tracking surfaces rotate about a fixed axis in the satellite's body reference frame. These surfaces maintain a rotation angle that maximimizes alignment with the sun vector.",
    },
    {
      subHeading: 'Anti-Sun Tracking Surfaces',
      chunk:
        "Anti-Sun Tracking surfaces rotate about a fixed axis in the satellite's body reference frame. These surfaces maintain a rotation angle that minimizes alignment with the sun vector. This type is useful for creating the 'back' side of a Sun Tracking Surface.",
    },
    {
      subHeading: 'Vector Tracking Surfaces',
      chunk:
        "Vector Tracking surfaces rotate about a fixed axis in the satellite's body reference frame. These surfaces maintain a rotation angle to maximize or minimize (anti-track) alignment with a Reference Vector.",
    },
  ],
};

const useGuidance = makeGuidance({
  _default: {
    heading: 'External Surfaces',
    body: surfacePermanentGuidance,
  },

  type: motionTypeGuidance,

  bodyFrameVector: motionTypeGuidance,

  trackedVector: {
    heading: 'Vector Tracking Surface',
    body: [
      {
        chunk:
          "Vector Tracking surfaces rotate about a fixed axis in the satellite's body reference frame. These surfaces maintain a rotation angle to maximize or minimize (anti-track) alignment with a Reference Vector.",
      },
      {
        subHeading: 'Tracked Reference Vector',
        chunk:
          'Select a Reference Vector that this surface will min/max align to. Click the "Anti-track" checkbox if you wish to minimize alignment (in other words, to align with the negative Reference Vector).',
      },
    ],
  },
  antiTrack: {
    heading: 'Vector Tracking Surface',
    alias: 'trackedVector',
  },

  surfaceCentroid: {
    heading: 'Surface Centroid',
    body: [
      {
        chunk: 'Specify the centroid (center of pressure) of the surface in the body frame.',
      },
    ],
  },
});
export default useGuidance;
