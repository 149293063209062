import { Switch, Tooltip } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { TOGGLE } from 'config';
import { useActiveEntities, useSnackbar } from 'hooks';
import { ContextNavContext } from 'providers';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from 'theme';
import useStyles from './styles';

const sortFn = (a, b) => a[1].order - b[1].order;

const ContextNavList = () => {
  const classes = useStyles();
  const {
    state: { items, activeKey },
    dispatch,
  } = useContext(ContextNavContext);
  const { model, branch } = useActiveEntities();

  const apiDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleItemClick = useCallback(
    (keyStr) => {
      dispatch({ type: 'CONTEXT_NAV_ITEM_HANDLE_CLICK', key: keyStr });
    },
    [dispatch]
  );

  const getActiveIndicator = useCallback(
    (keyStr) => {
      return keyStr === activeKey
        ? { borderLeft: `1px solid ${theme.palette.background.contrastText}` }
        : { borderLeft: '1px solid transparent' };
    },
    [activeKey]
  );

  // Titles of items for which toggle Switch should be set to true
  // TODO: This isn't very robust
  const [toggledItems, setToggledItems] = useState([]);
  useEffect(() => {
    const result = [];
    if (model?.enabledModules) model.enabledModules.forEach((m) => result.push(m));
    // NOTE: For other toggled items, add them here
    setToggledItems(result);
  }, [model]);

  return (
    <List>
      {Object.entries(items).map(([key, item]) => {
        return (
          <Fragment key={item.title}>
            <ListItem
              button
              onClick={(event) => {
                // Don't click on row if Switch was clicked
                if (
                  !event?.target?.className?.includes ||
                  !event.target.className.includes('MuiSwitch')
                )
                  handleItemClick(key);
              }}
              className={classes.listItem}
              style={!item.subItems ? getActiveIndicator(key) : undefined}
            >
              <ListItemText primary={item.title} className={classes.listItemText} />
              {/* Include toggle on row if necessary, and set up dispatch for its usage */}
              {item[TOGGLE] && (
                <Tooltip arrow title={item[TOGGLE].label}>
                  <Switch
                    size="small"
                    color="primary"
                    checked={toggledItems.includes(item.title)}
                    onChange={(event) => {
                      // For smoothness, set toggle before API-call is actually set
                      // Undo later if it fails
                      let toggled; // true if item was toggled, false if un-toggled
                      if (toggledItems.includes(item.title)) {
                        setToggledItems(toggledItems.filter((i) => i !== item.title));
                        toggled = false;
                      } else {
                        setToggledItems([...toggledItems, item.title]);
                        toggled = true;
                      }
                      // Send api call to set toggle
                      apiDispatch(
                        item[TOGGLE].action({
                          // TODO: Values given to the toggle action need to be generalizable.
                          // For now, these just update the spacecraft to enable/disable certain modules.
                          branchId: branch.id,
                          rootValues: {
                            enabledModules: event.target.checked
                              ? model.enabledModules.concat(item.title)
                              : model.enabledModules.filter((m) => m !== item.title),
                          },
                          failureCallback: (response) => {
                            const errorMessage =
                              response.error?.message ||
                              'Something went wrong. Please try again. If this problem persists, please contact our support team.';
                            enqueueSnackbar(errorMessage);
                            // Undo action if it fail
                            if (toggled)
                              setToggledItems(toggledItems.filter((i) => i !== item.title));
                            else setToggledItems([...toggledItems, item.title]);
                          },
                        })
                      );
                    }}
                  />
                </Tooltip>
              )}
              {item.subItems &&
                (item.open ? (
                  <KeyboardArrowDownIcon className={classes.expandIcon} />
                ) : (
                  <ChevronRightIcon className={classes.expandIcon} />
                ))}
            </ListItem>
            {item.subItems && (
              <Collapse in={item.open} timeout="auto" unmountOnExit>
                <List className={classes.subList}>
                  {Object.entries(item.subItems).map(([subKey, subItem]) => {
                    const subKeyStr = `${key}.${subKey}`;
                    return (
                      <Fragment key={subItem.title}>
                        <ListItem
                          className={classes.subListItem}
                          key={subItem.title}
                          dense
                          button
                          onClick={() => handleItemClick(subKeyStr)}
                          style={getActiveIndicator(subKeyStr)}
                        >
                          <ListItemText
                            primary={subItem.title}
                            className={classes.subListItemText}
                          />
                          {subItem.subItems &&
                            (subItem.open ? (
                              <KeyboardArrowDownIcon className={classes.expandIcon} />
                            ) : (
                              <ChevronRightIcon className={classes.expandIcon} />
                            ))}
                        </ListItem>
                        {subItem.subItems && (
                          <Collapse in={subItem.open} timeout="auto" unmountOnExit>
                            <List className={classes.subList}>
                              {Object.entries(subItem.subItems)
                                .sort(sortFn)
                                .map(([subSubKey, subItem]) => {
                                  const subSubKeyStr = `${key}.${subKey}.${subSubKey}`;
                                  return (
                                    <ListItem
                                      className={classes.subSubListItem}
                                      key={subItem.title}
                                      dense
                                      button
                                      onClick={() => handleItemClick(subSubKeyStr)}
                                      style={getActiveIndicator(subSubKeyStr)}
                                    >
                                      <ListItemText
                                        primary={subItem.title}
                                        className={classes.subListItemText}
                                      />
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </Collapse>
                        )}
                      </Fragment>
                    );
                  })}
                </List>
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </List>
  );
};

export default ContextNavList;
