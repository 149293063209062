import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import theme from 'theme';
import { mmTypesInfo, TMetamodelTypes } from 'utils/repoAndMmType';

interface IProps {
  metamodelType: TMetamodelTypes;
}

const MetamodelTypeIcon = ({ metamodelType }: IProps) => {
  const { iconSrc, label } = mmTypesInfo[metamodelType];

  return (
    <Tooltip title={label} arrow>
      <span style={{ color: theme.palette.text.primary, marginLeft: '0.5rem', height: '100%' }}>
        {typeof iconSrc === 'string' ? (
          <img src={iconSrc} style={{ height: '100%' }} alt="scenario-logo" />
        ) : (
          <FontAwesomeIcon icon={iconSrc} />
        )}
      </span>
    </Tooltip>
  );
};

export default MetamodelTypeIcon;
