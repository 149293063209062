import { useUser } from 'hooks';
import { Route } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import Routes, { getSearchParams, routePathsCommon } from 'routes';

const { AGENT_TEMPLATE_EDIT, SCENARIO, AGENT_ANALYZE } = routePathsCommon;

/* Authenticated Route:
 * Redirects to login route if user is not authenticated (i.e. USER_GET_FAILURE)
 * Redirects to verify route if user is not verified
 * Redirects to authorize route if user needs to be authorized
 * Otherwise render normal route
 *
 * Note: if in a module while on a shareable link, bypass requirements for user (and auth/verify/sub). If the shareable
 * link is not valid, failure callbacks will force appropriate redirect.
 */
const AuthRoute = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { share } = getSearchParams();

  const user = useUser();

  // --------------------- in order to allow shareable links, don't require a user when in a module --------------------
  // note, can't use useParams to check if in a module here (only inside a something lower than a route)
  // thus function below to figure it out
  const inAModule = [AGENT_TEMPLATE_EDIT, SCENARIO, AGENT_ANALYZE].some((path) =>
    location.pathname.includes(path)
  );
  if (share && inAModule) return <Route {...props} />;
  // -------------------------------------------------------------------------------------------------------------------

  if (!user) {
    // If user is not logged in, restrict them to the login view unless they are trying to get to welcome (via ROOT)
    history.push({
      pathname: location.pathname === Routes.ROOT() ? Routes.WELCOME() : Routes.LOGIN(),
      state: { from: location },
    });
    return null;
  } else if (!user.isVerified && !location.pathname.includes(Routes.VERIFY().split('/')[1])) {
    // If user is not yet verified, restrict them to the verify view
    history.push({
      pathname: Routes.VERIFY(),
      state: { from: location },
    });
    return null;
  } else if (
    user.isFlagged &&
    !location.pathname.includes(Routes.VERIFY().split('/')[1]) &&
    !location.pathname.includes(Routes.AUTHORIZE().split('/')[1])
  ) {
    // If user is not yet authorized, restrict them to the authorization view
    history.push({
      pathname: Routes.AUTHORIZE(),
      state: { from: location },
    });
    return null;
  }

  return <Route {...props} />;
};

export default AuthRoute;
