import { makeStyles } from '@material-ui/core/styles';
import { nodeFieldSetLegendStyle, nodeFieldSetStyle } from '../../general/sharedStyles';
import { ICondition } from '../../general/types';
import Condition from './Condition';

const useStyles = makeStyles({
  root: nodeFieldSetStyle,
  legend: nodeFieldSetLegendStyle,
});

interface IProps {
  legend: string;
  conditions: ICondition[];
}

const Conditions = ({ legend, conditions }: IProps) => {
  const styles = useStyles();
  if (!conditions?.length) return null;

  return (
    <fieldset className={styles.root}>
      <legend className={styles.legend}>{legend}</legend>
      {conditions.map((c, i) => (
        <Condition key={i} name={c.name} compliance={c.compliance} />
      ))}
    </fieldset>
  );
};

export default Conditions;
