import DataPlotting from 'components/general/DataPlotting';
import Nav from 'components/general/Nav';
import SimulationControls from 'components/general/SimulationControls';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import ViewPortInlay from 'components/general/ViewPortInlay';
import { useActiveEntities, useSnackbar } from 'hooks';
import { RangeProvider, TimeProvider } from 'providers';
import AnalyticsProvider from 'providers/AnalyticsProvider';
import { DataContext } from 'providers/DataProvider';
import { useCallback, useContext } from 'react';
import Routes, { getSearchParams } from 'routes';
import AnalyzeBoards from './AnalyzeBoards';
import AgentAnalyzeMenu from './menu';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const AgentAnalyzeView = () => {
  const { repo, branch } = useActiveEntities();
  const { staticModels } = useContext(DataContext);
  const { agentId } = getSearchParams();
  const history = useHistory();

  const reroute = useCallback(() => {
    history.push(Routes.AGENT_ANALYZE_AGENT_SELECTION(branch.id));
  }, [history, branch.id]);

  if (staticModels.scenario && !staticModels.scenario?._blocksById?.[agentId]) {
    reroute();
    return <FallbackComponent />;
  }

  return (
    <ViewContainer>
      <Nav
        contextName={
          staticModels.scenario && 'Analyze - ' + staticModels.scenario._blocksById[agentId].name
        }
        contextHeader={repo.name}
        control={<SimulationControls />}
      ></Nav>
      <ViewPort minWidth={650}>
        {
          // If data is here, show analyze boards
          branch.analyzeState?.dataState && staticModels?.agents[agentId] ? (
            <AnalyticsProvider>
              <TimeProvider>
                <AnalyzeBoards />
                <RangeProvider>
                  <DataPlotting menu={AgentAnalyzeMenu} />
                </RangeProvider>
              </TimeProvider>
            </AnalyticsProvider>
          ) : (
            // Otherwise, show placeholders
            <ViewPortInlay text="Fetch a window of data to view analytics" />
          )
        }
      </ViewPort>
    </ViewContainer>
  );
};

export default AgentAnalyzeView;

const FallbackComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    enqueueSnackbar('The agent no longer exists.  Please select a different agent.');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};
