import { round } from 'mathjs';

export const array2String = (a) => {
  return JSON.stringify(a, (k, v) => {
    return isNaN(v) ? v : round(v, 3);
  }).replaceAll(',', ', ');
};

export const direction2String = (type, vector) => {
  if (type === 'PassivePointingMode') {
    return 'None (Passive)';
  } else {
    const vectorType = vector.type;
    if (vectorType === 'TargetGroupVector') {
      return `${vector.name} (${vector.targetGroup.activeTarget.name})`;
    } else {
      return vector.name;
    }
  }
};

export const toSnakeCase = (str) => {
  return (
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join('_')
  );
};

// Capitalize first letter of first word and lowercase everything else
// i Love SedAro TechNOlogies -> I love sedaro technologies
// Pointing Mode -> Pointing mode
export const toSentenceCase = (str) => {
  if (str.length > 0) {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }
  return str;
};

export const toTitleCase = (str) => {
  const strArr = str.split(' ');
  const titleArr = strArr.map((str) => str[0].toUpperCase() + str.slice(1).toLowerCase());
  return titleArr.join(' ');
};

/**
 * Prepends an indefinite article to the given string
 * @param {string} str the string to come after the article
 * @param {boolean} capital whether the article should be capitalized, default true
 * @returns appropriate article + str
 */
export const withArticle = (str, capital = true) => {
  const vowels = new Set('aeiouAEIOU');
  const article =
    str.length > 0 ? (vowels.has(str[0]) ? (capital ? 'An ' : 'an ') : capital ? 'A ' : 'a ') : '';
  return article + str;
};


/**
 * Hash-digests a message into a hex string using the given algorithm
 * @param {string} message the message to digest
 * @param {string} algorithm the algorithm to use, default SHA-256
 * @returns promise that resolves to the hex string
 */
export const digest = async (message, algorithm="SHA-256") =>
  Array.prototype.map
    .call(
      new Uint8Array(
        await crypto.subtle.digest(algorithm, new TextEncoder().encode(message))
      ),
      (x) => ("0" + x.toString(16)).slice(-2)
    )
    .join("");
