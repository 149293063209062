import { TChartSpec } from 'components/general/types';

const tsThermalInterfaceChartSpec: TChartSpec = [
  {
    name: '"{name}" Thermal Interface',
    each: 'ThermalInterface',
    subtitle: '',
    plots: [
      {
        type: 'line',
        label: 'Thermal Heat Flow Rate',
        unit: 'W',
        variables: [
          {
            keys: ['heatFlowRate'],
            legend: ['Component Heat Flow Rate'],
          },
        ],
      },
      {
        type: 'line',
        label: 'Temperatures',
        unit: 'C',
        labelRight: 'Delta',
        unitRight: 'C',
        variables: [
          {
            keys: ['hotTemp.degC', 'coldTemp.degC'],
            legend: ['Hot Temp', 'Cold Temp'],
          },
          {
            keys: ['tempDelta'],
            legend: ['Temperature Delta'],
            right: true,
          },
        ],
      },
      {
        type: 'line',
        label: 'Hot/Cold Margin',
        unit: 'C',
        variables: [
          {
            keys: ['hotMargin', 'coldMargin'],
            legend: ['Hot Margin', 'Cold Margin'],
          },
        ],
      },
    ],
  },
];

export default tsThermalInterfaceChartSpec;
