import { createTheme } from '@material-ui/core/styles';

export const offWhite = (t) => {
  return 'rgba(221, 221, 221, ' + t + ')';
};

/**
 * Use to access the main background color with a transparency
 *
 * @param {int} t - level of transparency between 0 and 1
 * @returns `'rgba(41, 45, 61, ' + t + ')'`
 */
export const offMainBg = (t) => 'rgba(41, 45, 61, ' + t + ')';

const palettePrimaryMain = '#4492aa';
const inputHeight = 32;
export const borderRadius = 3;

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#78c2dc',
      main: palettePrimaryMain,
      dark: '#00637d',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#9d6cff',
      main: '#6734cc',
    },
    tertiary: {
      main: '#C03302',
      contrastText: '#ffffff',
    },
    background: {
      lightest: '#4c5061',
      light: '#3e4253',
      paper: '#3e4253',
      paperNested: '#464a5d',
      main: '#292d3d',
      mainNested: '#323543',
      default: '#292d3d',
      dark: '#202330',
      darker: '#151825',
      darkest: '#090A17',
      contrastText: offWhite(1),
      inlayText: offWhite(0.3),
    },
    text: {
      primary: offWhite(1),
      secondary: offWhite(0.7),
      tertiary: offWhite(0.5),
      disabled: '#456745',
    },
    action: {
      active: offWhite(0.5),
      hover: offWhite(0.04),
      selected: offWhite(0.08),
      disabled: offWhite(0.3),
      disabledBackground: offWhite(0.12),
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    success: {
      light: '#91dc72',
      main: '#5faa44',
      dark: '#2c7a14',
      pastel: '#5AA17F',
    },
    error: {
      light: '#e46773',
      main: '#DC3545',
      pastel: '#a05a5a',
    },
    warning: {
      light: '#ffcf33',
      main: '#ffc400',
    },
    info: {
      main: '#80CBC4',
      note: '#999',
    },
    charts: {
      primary: {
        green: '#34d68b',
        yellow: '#ffd56c',
        blue: '#4f9efb',
        purple: '#c44dff',
        orange: '#ff884d',
        red: '#fd607b',
        teal: '#0da5a5',
        grey: '#a5b1d7',
      },
      secondary: {
        blue: '#88A8ff',
      },
      booleanBars: {
        primary: {
          blue: {
            light: 'hsl(224, 100%, 77%)',
            dark: 'hsl(224, 40%, 30%)',
          },
          green: {
            light: 'hsl(152, 100%, 65%)',
            dark: 'hsl(152, 40%, 30%)',
          },
          yellow: {
            light: 'hsl(43, 100%, 70%)',
            dark: 'hsl(43, 40%, 30%)',
          },
          red: {
            light: 'hsl(350, 100%, 65%)',
            dark: 'hsl(350, 40%, 30%)',
          },
        },
        secondary: {
          blue: {
            light: 'hsl(200, 100%, 77%)',
            dark: 'hsl(200, 40%, 30%)',
          },
          green: {
            light: 'hsl(175, 100%, 65%)',
            dark: 'hsl(175, 40%, 30%)',
          },
          yellow: {
            light: 'hsl(68, 100%, 70%)',
            dark: 'hsl(68, 40%, 30%)',
          },
          red: {
            light: 'hsl(325, 100%, 65%)',
            dark: 'hsl(325, 40%, 30%)',
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 24,
    },
    subh1: {
      fontFamily: 'Montserrat',
      fontWeight: 300,
      fontSize: 22,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 20,
    },
    subh2: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 300,
      fontSize: 15,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 17,
    },
    subh3: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 14,
    },
    h4: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 400,
      fontSize: 19,
      letterSpacing: 1,
    },
    subh4: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 300,
      fontSize: 14,
    },
    h5: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 300,
      fontSize: 17,
    },
    h6: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 300,
      fontSize: 15,
    },
    body: {
      fontFamily: 'open sans',
      fontWeight: 400,
      fontSize: 13,
    },
    highlight1: {
      fontFamily: 'open sans',
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '0.05em',
    },
    highlight2: {
      fontFamily: 'open sans',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '0.1em',
    },
    button: {
      textTransform: 'none',
      fontWeight: 400,
      height: inputHeight,
      borderRadius: borderRadius,
    },
  },
  dimensions: {
    inputs: {
      borderRadius: borderRadius,
      height: inputHeight,
      padding: '6px 12px',
      marginBottom: 8,
    },
    rail: {
      height: 3,
    },
  },
  mixins: {
    noScroll: {
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    boardElementStyles: {
      padding: 3,
      // '@media(min-width: 1920px)': {
      //   padding: 5,
      // },
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: palettePrimaryMain,
        '& h3': {
          fontSize: '3rem',
        },
        '& h4': {
          fontSize: '2.125rem',
        },
      },
    },
    MuiTablePagination: {
      spacer: {
        display: 'none',
      },
      toolbar: {
        borderBottom: 'none',
      },
    },
    MuiTableCell: {
      footer: {
        borderBottom: 'none',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: palettePrimaryMain,
      },
    },
  },
  // Custom material ui breakpoints can be added here and referred to with theme.breakpoints
  // In order to add any custom breakpoints the default breakpoints must also be passed in
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      smLg: 1400,
      mdLg: 1660,
      xl: 1920,
    },
  },
});

export default theme;

export const shade = (hex, amount) => {
  var usePound = false;
  if (hex[0] === '#') {
    hex = hex.slice(1);
    usePound = true;
  }
  var num = parseInt(hex, 16);
  var r = (num >> 16) + amount;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  var b = ((num >> 8) & 0x00ff) + amount;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  var g = (num & 0x0000ff) + amount;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};
