import { TChartSpec } from 'components/general/types';

const tsDataModeDPSchema: TChartSpec = [
  {
    name: 'Data Modes',
    plots: [
      {
        name: 'Active Modes',
        type: 'bool',
        label: '',
        variables: [
          {
            keys: ['DataMode.$each.isActive'],
            legend: ['{name}'],
          },
        ],
      },
    ]
  },
];

export default tsDataModeDPSchema;
