import LoadingView from 'components/LoadingView';
import A from 'components/general/A';
import StyledButton from 'components/general/StyledButton';
import WaveContainer from 'components/general/WaveContainer';
import AuthWidget from 'components/general/widgets/AuthWidget';
import { useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, withRouter } from 'react-router-dom';
import Routes from 'routes';
import useStyles from './styles';

const VerifyView = () => {
  const {
    User: {
      actions: { verify, resendVerificationEmail, logout, getUser },
    },
  } = SatelliteApi;
  const user = useUser();
  const dispatch = useDispatch();

  const { token } = useParams();
  let history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();

  useEffect(() => {
    if (token !== ':token') {
      dispatch(
        verify({
          verificationToken: token,
          successCallback: () => {
            history.replace(
              // If the user reaches this point and is already verified, that means they are changing their email, so push to account view
              user.isVerified
                ? Routes.ACCOUNT()
                : user.isFlagged
                ? Routes.AUTHORIZE()
                : Routes.ROOT()
            );
            // Display a notification for successful email verifications
            enqueueSnackbar('Email successfully verified.', { variant: 'success' });
          },
          failureCallback: () => {
            history.replace(Routes.VERIFY());
            enqueueSnackbar(
              'Something went wrong. Your account may already be verified or your link may be expired. Please try again or request a new link.'
            );
          },
        })
      );
    } else if (user.isVerified) {
      // If user is already verified, keep this view away from them
      history.replace(Routes.ROOT());
    }
  }, [token]); //eslint-disable-line

  const resendEmail = () => {
    dispatch(
      resendVerificationEmail({
        successCallback: () => {
          enqueueSnackbar('Successfully resent email', { variant: 'success' });
        },
        failureCallback: (response) => {
          enqueueSnackbar(response.error.message);
        },
      })
    );
  };

  if (token !== ':token') {
    return <LoadingView />;
  }
  return (
    <WaveContainer>
      <AuthWidget>
        <form>
          <h2 className={classes.heading}>Please Confirm Your Email Address</h2>
          <h5>
            An email with a personalized confirmation link has been sent to the address you gave us.
            Once confirmed, please click below to enter Sedaro.
          </h5>
          {!user?.isVerified && (
            <p className={classes.footer}>
              If you haven't received an email with your confirmation link or your link is expired,
              please click <A onClick={resendEmail}>here</A> to send a new email.
            </p>
          )}
          <div className={classes.buttonWrapper}>
            <StyledButton
              onClick={() => {
                dispatch(
                  getUser({
                    successCallback: (user) => {
                      if (user.isVerified)
                        history.replace(user.isFlagged ? Routes.AUTHORIZE() : Routes.ROOT());
                      else
                        enqueueSnackbar(
                          'Your account is not yet verified. Please check your email for a confirmation link or re-send the email using the link above.'
                        );
                    },
                  })
                );
              }}
              fullWidth
            >
              My email is confirmed
            </StyledButton>
            <StyledButton
              type="button"
              onClick={() => {
                closeSnackbar();
                dispatch(logout());
              }}
              framed
              fullWidth
            >
              Logout
            </StyledButton>
          </div>
        </form>
      </AuthWidget>
    </WaveContainer>
  );
};

export default withRouter(VerifyView);
