import { ISelectOption } from 'components/general/types';
import { DataInterfaceVables } from 'utils/vable';
import * as Yup from 'yup';

const dataInterfaceSchema = Yup.object().shape({
  name: Yup.string()
    .required('A name is required')
    .max(32, 'Interface name must be no more than 32 characters'),
  type: Yup.object().required('Interface type is required'),
  dataType: Yup.object().required('Data type is required'),
  onBitRate: Yup.number().required('Bitrate is required').moreThan(0, 'Bitrate must be positive'),
  source: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === DataInterfaceVables.InterfaceType.InternalDataInterface.value,
    then: Yup.object().required('Data source is required'),
  }),
  sink: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === DataInterfaceVables.InterfaceType.InternalDataInterface.value,
    then: Yup.object().required('Data sink is required'),
  }),
  linkTarget: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value !== DataInterfaceVables.InterfaceType.InternalDataInterface.value,
    then: Yup.object().required('Link target is required for external interfaces'),
  }),
  modem: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value !== DataInterfaceVables.InterfaceType.InternalDataInterface.value,
    then: Yup.object().required('Modem is required for external interfaces'),
  }),
  directedEnergyDevice: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value !== DataInterfaceVables.InterfaceType.InternalDataInterface.value,
    then: Yup.object().required('Directed energy device is required for external interfaces'),
  }),
});

export default dataInterfaceSchema;
