import { TChartSpec } from 'components/general/types';

const statsSurfaceTempsChartSpec: TChartSpec = [
  {
    name: 'Surface Average Temperature',
    plots: [
      {
        type: 'horizontal-bar',
        unit: 'c',
        variables: [
          {
            each: 'Surface',
            legend: ['{name}'],
            keys: ['temperature.degC'],
            ops: ['avg'],
          },
        ],
      },
    ],
  },
  {
    name: 'Surface Minimum Hot Margin',
    plots: [
      {
        type: 'margin',
        unit: 'c',
        variables: [
          {
            each: 'Surface',
            legend: ['{name}'],
            keys: ['hotMargin'],
            ops: ['min'],
          },
        ],
      },
    ],
  },
  {
    name: 'Surface Minimum Cold Margin',
    plots: [
      {
        type: 'margin',
        unit: 'c',
        variables: [
          {
            each: 'Surface',
            legend: ['{name}'],
            keys: ['coldMargin'],
            ops: ['min'],
          },
        ],
      },
    ],
  },
];

export default statsSurfaceTempsChartSpec;
