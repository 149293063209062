import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accent from 'components/general/Accent';
import { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  header: string;
  icon?: ReactNode;
  className?: string;
  onAddAction?: () => void;
  onEditAction?: () => void;
}

const DataHandlingAccent = (props: IProps) => {
  const { children, ...remainingProps } = props;

  return (
    <Accent icon={<FontAwesomeIcon icon={faCubes} />} {...remainingProps}>
      {children}
    </Accent>
  );
};

export default DataHandlingAccent;
