import { faArrowsLeftRightToLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, CircularProgress, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import ClipboardCopy from 'components/general/ClipboardCopy';
import Dialog from 'components/general/dialogs/Dialog';
import InfoBadge from 'components/general/InfoBadge';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IJob } from 'components/general/types';
import { useFormikForm, useLatestJob, useSelectById, useSnackbar } from 'hooks';
import { useDataContext } from 'providers';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'routes';
import theme from 'theme';
import { dateFormatLong, mjd2Moment } from 'utils/time';
import { represent } from 'utils/units';
import * as Yup from 'yup';
import { IInlineBadgeProps } from '../../InlineBadge';
import StyledSlider from '../../StyledSlider';
import useStyles from './styles';

interface IConfig {
  positions: number[];
  open: boolean;
}

interface IProps {
  config: IConfig;
  setConfig: (config: IConfig) => void;
  progressPct: number;
  metadata: {
    id?: number;
    binWidth?: number;
  };
  dataIsCurrent?: boolean;
  bounds: number[];
  notifyOutdated?: boolean;
}

interface ISearch {
  limit?: number;
  start?: number;
  stop?: number;
}

interface IForm {
  limit: number | '';
}

const defaultValues: IForm = {
  limit: '',
};

const fetchSchema = Yup.object().shape({
  limit: Yup.number()
    .required('An upper limit on the number of data points to fetch is required.')
    .min(2, 'Limit must be greater than or equal to 2.'),
});

const clampSliderPositions = (positions: number[], bounds: number[]) => [
  Math.max(bounds[0], positions[0] || 0),
  Math.min(bounds[1], positions[1] || Number.MAX_SAFE_INTEGER),
];

const limitInputLabel = 'Max Data Points';

const DataWindowDialog = ({
  config,
  setConfig,
  progressPct,
  metadata,
  dataIsCurrent,
  bounds,
  notifyOutdated,
}: IProps) => {
  const classes = useStyles();
  const latestJob = useLatestJob();
  const { fetchData, fetching, seriesData } = useDataContext();
  const { enqueueSnackbar } = useSnackbar();
  const [search] = useSearchParams();

  // Check for out of bounds positions and adjust
  if (config.open) {
    if (config.positions[0] < bounds[0] || config.positions[0] > bounds[1]) {
      config.positions[0] = bounds[0];
      setConfig(config);
    }
    if (config.positions[1] < bounds[0] || config.positions[1] > bounds[1]) {
      config.positions[1] = bounds[1];
      setConfig(config);
    }
  }

  const submit = ({ limit }: IForm) => {
    fetchData(config.positions[0], config.positions[1], limit);
    config.positions[0] === config.positions[1]
      ? enqueueSnackbar('Data window must have a range of at least 1 second')
      : setConfig({ ...config, open: false });
  };

  const { formik } = useFormikForm<ISearch, IForm>(defaultValues, submit, fetchSchema, search);
  const { handleSubmit, getFieldProps, resetForm } = formik;

  const _positions = [Number(search.start), Number(search.stop)];

  return (
    <Dialog
      title={'Data Window'}
      xray={seriesData.hierarchical}
      downloadXray={true}
      open={config.open}
      onClose={() => {
        resetForm();
        setConfig({
          ...config,
          positions: clampSliderPositions(_positions, bounds),
          open: false,
        });
      }}
      submitActionText="Fetch Window"
      disableSubmit={
        progressPct === 0 ||
        (dataIsCurrent &&
          config.positions[0] === _positions[0] &&
          config.positions[1] === _positions[1] &&
          Number(formik.values.limit) === Number(search.limit))
      }
      large
      onSubmit={handleSubmit}
      loading={fetching}
      dontDisableInReadOnly
    >
      {notifyOutdated && (
        <>
          <h5 style={{ marginTop: 20 }}>Updated Results Available:</h5>
          <p className={classes.dialogParagraph}>
            Use the interface below to fetch the most recent results.
          </p>
        </>
      )}
      {search.start && search.stop && dataIsCurrent && (
        <>
          <h5>Most Recent Window:</h5>
          <div className={classes.dialogTitle}>
            <p className={classes.dialogParagraph}>
              {mjd2Moment(search.start).format(dateFormatLong)} to{' '}
              {mjd2Moment(search.stop).format(dateFormatLong)} | Limit: {search.limit}{' '}
            </p>
            <SamplingBadge style={{ marginLeft: 10 }} binWidth={metadata.binWidth} />
          </div>
        </>
      )}
      <h5 style={{ marginTop: 20 }}>New Window:</h5>
      <p className={classes.dialogParagraph}>
        Select a window of data to view. When viewing large regions of the simulation, downsampling
        can be used to reduce data volumes. Enter a value for "{limitInputLabel}" to adjust the
        downsampling rate. Only data from the completed portion of the simulation is available and
        will be returned in the query result.
      </p>

      {progressPct > 0 ? (
        <>
          <div className={classes.dialogTitle}>
            <p className={classes.dialogParagraph}>
              {mjd2Moment(config.positions[0]).format(dateFormatLong)} to{' '}
              {mjd2Moment(config.positions[1]).format(dateFormatLong)}
            </p>
          </div>
          <LabeledInput
            {...getFieldProps('limit')}
            label={limitInputLabel}
            type="number"
            placeholder="Limit"
            dontDisableInReadOnly
          />
          <div className={classes.sliderWrapper}>
            <div className={classes.sliderTotal}></div>
            <StyledSlider
              value={config.positions}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => mjd2Moment(value).format(dateFormatLong)}
              onChange={(e, v) => {
                if (Array.isArray(v))
                  setConfig({
                    ...config,
                    positions: v,
                  });
              }}
              min={bounds[0]}
              max={bounds[1]}
              step={1 / 86400} // 1 second
              style={{ width: `${progressPct}%` }}
              className={classes.slider}
            />
          </div>
          <div className={classes.limitsWrapper}>
            <p>{mjd2Moment(latestJob.startTime).format(dateFormatLong)}</p>
            <p>{mjd2Moment(latestJob.stopTime).format(dateFormatLong)}</p>
          </div>
        </>
      ) : (
        <p style={{ display: 'flex', alignItems: 'center' }}>
          <b>Please wait until the simulation has progressed further </b>
          <InfoBadge
            style={{ marginLeft: 5 }}
            content="If the simulation is running, please wait until sufficient progress is reported by all Agents before fetching. If the simulation terminated or was aborted, please resimulate. An interactive windowing interface will display here once data is available."
          />
        </p>
      )}
    </Dialog>
  );
};

const DataWindow = () => {
  const classes = useStyles();
  const latestJob = useLatestJob();
  const [search] = useSearchParams();
  const { meta: metadata, jobId, fetching, fetchError } = useDataContext();
  const latestJobWithDataFetched = {
    startTime: 0,
    stopTime: 0,
    dataArray: null,
    ...(useSelectById('Job', jobId) as IJob | null),
  };

  const start = Number(search.start);
  const stop = Number(search.stop);
  let progressPct = Math.max(0, latestJob?.progress?.percentComplete || 0);
  const duration = latestJobWithDataFetched.stopTime - latestJobWithDataFetched.startTime;
  if (progressPct !== 100) {
    progressPct =
      (((latestJob?.progress?.currentTime || latestJob?.stopTime) - latestJob?.startTime) * 100) /
      (latestJob?.stopTime - latestJob?.startTime);
  }
  const progressLeft = ((start - latestJobWithDataFetched.startTime) * 100) / duration;
  const progressWidth = ((stop - start) * 100) / duration;

  const bounds = latestJob
    ? [
        latestJob.startTime,
        latestJob.startTime + ((latestJob.stopTime - latestJob.startTime) * progressPct) / 100,
      ]
    : [0, 0];

  const dataIsCurrent = latestJob?.id === jobId;
  const notifyOutdated = Boolean(!dataIsCurrent && progressPct && !fetching);
  const [prevStatus, setPrevStatus] = useState<string | undefined>(undefined);

  const [config, setConfig] = useState<IConfig>({
    positions: [start, stop],
    open: false,
  });

  useEffect(() => {
    if (latestJob) {
      setConfig((curr) => ({
        ...curr,
        positions: clampSliderPositions(config.positions, bounds),
      }));
    }
  }, [latestJob]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Only trigger fetch reminder when simulation transitions from running to not running
    if (latestJob?.status && prevStatus === 'RUNNING' && prevStatus !== latestJob.status) {
      if (jobId)
        // Don't open on first fetch of a fresh scenario
        setConfig({
          positions: [latestJob.startTime, latestJob.stopTime],
          open: true,
        });
    }
    setPrevStatus(latestJob?.status);
  }, [latestJob?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <h5 className={classes.header}>
            Analysis Window
            {latestJobWithDataFetched.dataArray && (
              <>
                <InfoBadge
                  content={`Data Array ID: ${latestJobWithDataFetched.dataArray}`}
                  style={{ marginLeft: 5 }}
                />
                <ClipboardCopy
                  text={latestJobWithDataFetched.dataArray}
                  style={{ marginLeft: 5, fontSize: 15 }}
                  displayLabel={false}
                />
              </>
            )}
          </h5>
          <Tooltip
            title={
              notifyOutdated
                ? 'Currently viewing outdated results. Fetch the latest.'
                : 'Fetch new window'
            }
          >
            <IconButton
              className={classes.expandBtn}
              disabled={jobId === undefined && latestJob?.id === undefined}
              onClick={() =>
                setConfig({
                  ...config,
                  positions: clampSliderPositions([start, stop], bounds),
                  open: true,
                })
              }
            >
              <FontAwesomeIcon icon={faArrowsLeftRightToLine} style={{ height: 18 }} />
              {notifyOutdated && <div className={classes.notificationBubble}></div>}
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <div className={classes.miniBarWrapper}>
            <div className={classes.miniBarTotal}></div>
            {dataIsCurrent && (
              <div className={classes.miniBarProgress} style={{ width: `${progressPct}%` }}></div>
            )}
            <div
              className={classes.miniBarWindow}
              style={{
                left: `${progressLeft}%`,
                width: `${progressWidth}%`,
              }}
            ></div>
          </div>
          {search.start &&
            search.stop &&
            (latestJobWithDataFetched.dataArray || fetching || fetchError) && (
              <p className={classes.dates}>
                {`${mjd2Moment(start).format(dateFormatLong)} to ${mjd2Moment(stop).format(
                  dateFormatLong
                )}`}
              </p>
            )}
          <div className={classes.sampleWrapper}>
            <SamplingBadge binWidth={metadata.binWidth} />
          </div>
        </div>
      </div>
      <DataWindowDialog
        config={config}
        setConfig={setConfig}
        progressPct={progressPct}
        metadata={metadata}
        dataIsCurrent={dataIsCurrent}
        bounds={bounds}
        notifyOutdated={notifyOutdated}
      />
    </>
  );
};

interface ISamplingBadgeProps extends IInlineBadgeProps {
  binWidth?: number;
  fetching?: boolean;
  fetchError?: string;
  downsampled?: boolean;
}

const useBadgeStyles = makeStyles((theme) => ({
  samplingBadge: (props: ISamplingBadgeProps) => ({
    textAlign: 'center',
    marginTop: 3,
    '& > span': {
      backgroundColor: props.fetching
        ? theme.palette.primary.light
        : props.fetchError
        ? theme.palette.error.light
        : props.downsampled && props.binWidth != null
        ? theme.palette.warning.main
        : theme.palette.success.light,
      color: theme.palette.background.main,
      top: 'unset',
      right: 'unset',
      transform: 'none',
      transformOrigin: 'center',
      position: 'relative',
      cursor: 'default',
    },
  }),
  samplingTooltip: {
    ...theme.typography.body,
  },
}));

const SamplingBadge = (props: ISamplingBadgeProps) => {
  const { binWidth, style, ...restOfProps } = props;
  const { meta: metadata, fetching, fetchError } = useDataContext();
  const downsampled =
    metadata.resolutions &&
    Object.values(metadata.resolutions as { [key: string]: number }).some((r) => r < 1);
  const classes = useBadgeStyles({ ...restOfProps, downsampled, fetching, fetchError });
  let title;
  try {
    title = fetchError
      ? 'Error ' +
        fetchError.error.status +
        ' - ' +
        fetchError.error.code +
        ': ' +
        fetchError.error.message
      : !fetching && downsampled
      ? `Your current window requires downsampling because the selected data set is larger than the specified "${limitInputLabel}". This creates risk of aliasing and other issues. For full-resolution analytics, choose either a smaller window of time or enter a larger limit in the "${limitInputLabel}" input. For analyzing slower parameters on a larger timescale (e.g., beta angle), downsampling is a great tool for avoiding excessive data queries.`
      : '';
  } catch (error) {
    title = 'Error occured while fetching data.';
  }

  return (
    <Tooltip arrow title={title} classes={{ tooltip: classes.samplingTooltip }}>
      <Badge
        badgeContent={
          fetching ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Fetching Data{' '}
              <CircularProgress
                style={{
                  height: 10,
                  width: 10,
                  marginLeft: 5,
                  color: theme.palette.background.main,
                }}
              />
            </div>
          ) : fetchError ? (
            'Error fetching data'
          ) : downsampled && binWidth != null ? (
            `Downsampled to ${represent(binWidth * 86400 * 1000, 'ms', {
              plural: false,
              digits: 3,
            })} bins`
          ) : metadata && Object.keys(metadata).length > 0 ? (
            'Full resolution'
          ) : (
            'No Data'
          )
        }
        className={classes.samplingBadge}
        color="primary"
        style={style}
      />
    </Tooltip>
  );
};

export default DataWindow;
