import {
  statsComponentTempsChartSpec,
  statsSurfaceRadiativeHeatTransferChartSpec,
  statsSurfaceTempsChartSpec,
  statsThermalInterfaceChartSpec,
} from 'components/AgentAnalyzeView/AnalyzeBoards/ThermalAnalyzeBoards/stats';
import { THERMAL } from 'components/AgentTemplateEditView/menu/thermal';
import { IMenu } from 'components/general/types';
import { CHART_SPEC, SUB_ITEMS, TITLE } from 'config';
import {
  tsComponentDissipationChartSpec,
  tsComponentTempsChartSpec,
  tsSurfaceRadiativeHeatTransferChartSpec,
  tsSurfaceTempsChartSpec,
  tsTempControllerChartSpec,
  tsThermalInterfaceChartSpec,
} from '../AnalyzeBoards/ThermalAnalyzeBoards/timeSeries';

import { routePathsCommon } from 'routes';
const { PLAYBACK, TS, WS } = routePathsCommon;

const COMPONENT_DISSIPATION = 'component-dissipation';
const SURFACE_RADIATION = 'surface-radiation';
const THERMAL_INTERFACE = 'thermal-interface';
const TEMP_CONTROLLER = 'temp-controller';
const COMPONENT_TEMPS = 'component-temps';
const SURFACE_TEMPS = 'surface-temps';
const agentThermalMenu: IMenu['items'] = {
  [THERMAL]: {
    [TITLE]: 'Thermal',
    [SUB_ITEMS]: {
      [PLAYBACK]: {
        [TITLE]: 'Playback',
      },
      [TS]: {
        [TITLE]: 'Time Series',
        [SUB_ITEMS]: {
          [COMPONENT_DISSIPATION]: {
            [TITLE]: 'Component Dissipation',
            [CHART_SPEC]: tsComponentDissipationChartSpec,
          },
          [SURFACE_RADIATION]: {
            [TITLE]: 'Surface Radiative Heat Transfer',
            [CHART_SPEC]: tsSurfaceRadiativeHeatTransferChartSpec,
          },
          [TEMP_CONTROLLER]: {
            [TITLE]: 'Temperature Controllers',
            [CHART_SPEC]: tsTempControllerChartSpec,
          },
          [THERMAL_INTERFACE]: {
            [TITLE]: 'Thermal Interfaces',
            [CHART_SPEC]: tsThermalInterfaceChartSpec,
          },
          [COMPONENT_TEMPS]: {
            [TITLE]: 'Component Temperatures',
            [CHART_SPEC]: tsComponentTempsChartSpec,
          },
          [SURFACE_TEMPS]: {
            [TITLE]: 'Surface Temperatures',
            [CHART_SPEC]: tsSurfaceTempsChartSpec,
          },
        },
      },
      [WS]: {
        [TITLE]: 'Window Statistics',
        [SUB_ITEMS]: {
          [SURFACE_RADIATION]: {
            [TITLE]: 'Surface Radiative Heat Transfer',
            [CHART_SPEC]: statsSurfaceRadiativeHeatTransferChartSpec,
          },
          [THERMAL_INTERFACE]: {
            [TITLE]: 'Thermal Interfaces',
            [CHART_SPEC]: statsThermalInterfaceChartSpec,
          },
          [COMPONENT_TEMPS]: {
            [TITLE]: 'Component Temperatures',
            [CHART_SPEC]: statsComponentTempsChartSpec,
          },
          [SURFACE_TEMPS]: {
            [TITLE]: 'Surface Temperatures',
            [CHART_SPEC]: statsSurfaceTempsChartSpec,
          },
        },
      },
    },
  },
};
export const wGroupIndicesAgentThermal = {
  THERMAL: `${THERMAL}*`,
  PLAYBACK: `${THERMAL}.${PLAYBACK}`,
};

export default agentThermalMenu;
