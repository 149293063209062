import useStyles from './styles';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledButton from 'components/general/StyledButton';
import { ReactNode } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import theme from 'theme';

export interface IIconProps {
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}

export interface IButtonProps extends IIconProps {
  text?: string;
  icon?: IconDefinition;
}

interface IProps {
  name?: string | ReactElement;
  description?: string | (string | ReactElement)[];
  rowsTitle?: string;
  primaryButtonProps?: IButtonProps;
  secondaryButtonProps?: IButtonProps;
  backButtonProps?: IButtonProps;
  smallTitle?: boolean;
  children: ReactNode;
}

const RowedExplorer = (props: IProps) => {
  const {
    name,
    description,
    rowsTitle,
    primaryButtonProps,
    secondaryButtonProps,
    backButtonProps,
    smallTitle,
    children,
  } = props;

  const classes = useStyles();
  const {
    text: prmBtnTxt,
    onClick: prmBtnOnClick,
    tooltip: prmBtnTooltip,
    icon: prmBtnIcon,
  } = primaryButtonProps || {};
  const {
    text: scndBtnTxt,
    onClick: scndBtnOnClick,
    tooltip: scndBtnTooltip,
    icon: scndBtnIcon,
  } = secondaryButtonProps || {};
  const {
    text: backBtnTxt,
    onClick: backBtnOnClick,
    tooltip: backBtnTooltip,
    icon: backBtnIcon,
  } = backButtonProps || {};

  return (
    <div className={classes.branchExplorerRoot}>
      <div className={classes.headerContainer}>
        {name && (
          <div className={classes.info}>
            <h1>{name}</h1>
            {description && <p>{description}</p>}
          </div>
        )}
        {backBtnOnClick && (
          <StyledButton onClick={backBtnOnClick} tooltip={backBtnTooltip}>
            {backBtnTxt}
            {backBtnIcon && <FontAwesomeIcon icon={backBtnIcon} />}
          </StyledButton>
        )}
      </div>
      <div className={classes.title}>
        <h1
          style={{
            fontSize: name || smallTitle ? undefined : Number(theme.typography.h1.fontSize) * 1.15,
          }}
        >
          {rowsTitle}
        </h1>
        <div className={classes.primaryAndSecondaryButtonContainer}>
          {prmBtnOnClick && (
            <StyledButton onClick={prmBtnOnClick} tooltip={prmBtnTooltip}>
              {prmBtnTxt}
              {prmBtnIcon && <FontAwesomeIcon icon={prmBtnIcon} />}
            </StyledButton>
          )}
          {scndBtnOnClick && (
            <StyledButton onClick={scndBtnOnClick} tooltip={scndBtnTooltip} framed>
              {scndBtnTxt}
              {scndBtnIcon && <FontAwesomeIcon icon={scndBtnIcon} />}
            </StyledButton>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default RowedExplorer;
