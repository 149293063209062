import { makeGuidance } from 'hooks';

const spacecraftHeading = 'Mass Properties';

const spacecraftMainParagraph =
  'Input spacecraft mass in kg. Input the inertia matrix calculated based on the size and mass of your spacecraft. In each case, exclude any propellant that is initialized in the Fuel Tanks section.';

export const spacecraftGuidance = {
  _default: {
    heading: spacecraftHeading,
    body: [
      {
        chunk: spacecraftMainParagraph,
      },
    ],
  },
};

const useGuidance = makeGuidance(spacecraftGuidance);
export default useGuidance;
