import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import * as Cesium from 'cesium';
import { hotkeys } from 'config';
import { useActiveEntities } from 'hooks';
import { ContextNavContext, DataContext, PlaybackStatusContext, TimeContext } from 'providers';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { isLiveDemo, isLiveDemoFf } from 'utils/debt';
import { mjd2Moment } from 'utils/time';
import { cesiumClockMultiplierScaleDefault } from '../general/constants';
import useStyles from './styles';

export default function PlaybackControls(props) {
  const {
    viewerRef,
    clockRef,
    positiveClockMultiplier,
    setPositiveClockMultiplier,
    animate,
    setAnimate,
    loop,
    setLoop,
  } = props;

  const timeSkipScale = [1, 5, 10, 60];

  const classes = useStyles();
  const { setPlaybackStatus } = useContext(PlaybackStatusContext);
  const { startTime, stopTime, injestConfig } = useContext(DataContext);
  const {
    activeAgentData: { cesiumData, calculateMultiplier },
  } = useAnalyticsContext();
  const { time, setTime, timeFormatted } = useContext(TimeContext);
  const navContext = useContext(ContextNavContext);
  const { branch } = useActiveEntities();

  const [visible, setVisible] = useState(true);
  const timeoutRef = useRef(null);
  const containerRef = useRef(null);
  const playRef = useRef(null);

  // Limit time range to start and stop times
  const clampTime = useCallback(
    (newJulianDate) => {
      return Cesium.JulianDate.compare(newJulianDate, cesiumData.stopTime) > 0
        ? cesiumData.stopTime
        : Cesium.JulianDate.compare(newJulianDate, cesiumData.startTime) < 0
        ? cesiumData.startTime
        : newJulianDate;
    },
    [cesiumData]
  );

  // Forward Hotkey
  useHotkeys(
    hotkeys.PLAYBACK_FORWARD.keys,
    (_, handler) => {
      clearTimeout(timeoutRef.current);
      setVisible(true);
      clockRef.current.cesiumElement.currentTime = clampTime(
        Cesium.JulianDate.addSeconds(
          clockRef.current.cesiumElement.currentTime,
          1 * timeSkipScale[hotkeys.PLAYBACK_FORWARD.keys.split(/,[ ]*/).indexOf(handler.key)],
          new Cesium.JulianDate()
        )
      );
      timeoutRef.current = setTimeout(() => {
        setVisible(false);
      }, 3000);
    },
    [clampTime]
  );

  // Rewind Hotkey
  useHotkeys(
    hotkeys.PLAYBACK_REWIND.keys,
    (_, handler) => {
      clearTimeout(timeoutRef.current);
      setVisible(true);
      clockRef.current.cesiumElement.currentTime = clampTime(
        Cesium.JulianDate.addSeconds(
          clockRef.current.cesiumElement.currentTime,
          -1 * timeSkipScale[hotkeys.PLAYBACK_REWIND.keys.split(/,[ ]*/).indexOf(handler.key)],
          new Cesium.JulianDate()
        )
      );
      timeoutRef.current = setTimeout(() => {
        setVisible(false);
      }, 3000);
    },
    [clampTime]
  );

  // Play/Pause Handler
  const playPauseOnClick = () => {
    viewerRef.current.cesiumElement.clockViewModel.shouldAnimate =
      !viewerRef.current.cesiumElement.clockViewModel.shouldAnimate;
    setPlaybackStatus(viewerRef.current.cesiumElement.clockViewModel.shouldAnimate);
  };

  // Play/Pause Hotkey
  useHotkeys(
    hotkeys.PLAYBACK_PLAY.keys,
    (event) => {
      playPauseOnClick();
      event.preventDefault();
    },
    { enabled: document.activeElement !== playRef.current },
    [viewerRef]
  );

  useEffect(() => {
    // This useEffect is triggered whenever we navigate to this page and when new series data is fetched. It makes sure
    // the cesium playback is paused when navigate to/from playback
    setAnimate(false);
    // eslint-disable-next-line
  }, [navContext.state.activeKey]); // (adding setAnimate causes infinite loop in useEffect)

  const [shouldAnimateListenerInitialized, setShouldAnimateListenerInitialized] = useState(false);
  useEffect(() => {
    // create listener to shouldAnimate and use to make `animate` state always align with it
    if (!shouldAnimateListenerInitialized && viewerRef.current) {
      Cesium.knockout
        .getObservable(viewerRef.current.cesiumElement.clockViewModel, 'shouldAnimate')
        .subscribe(setAnimate);
      setShouldAnimateListenerInitialized(true);
    }
  }, [
    viewerRef,
    setAnimate,
    shouldAnimateListenerInitialized,
    setShouldAnimateListenerInitialized,
  ]);

  const progressSliderOnChange = useCallback(
    (e, t) => {
      const newJulianDate = Cesium.JulianDate.fromIso8601(mjd2Moment(t).format());
      // There were edge cases where the playback progress bar forced the currentTime
      // out of the boundaries of startTime or stopTime, which caused all entities to
      // disappear. The below logic prevents the currentTime from being set to
      // something exceeding those boundaries.
      clockRef.current.cesiumElement.currentTime = clampTime(newJulianDate);
      if (viewerRef.current?.cesiumElement.clockViewModel.shouldAnimate) {
        viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
      }
    },
    [clockRef, viewerRef, clampTime]
  );

  useEffect(() => {
    if (clockRef.current && injestConfig.rate && isLiveDemo(branch)) {
      const W = 0.01;
      const K = 1;
      const NAME_ME = 10;

      const now = Date.now() / 1000 / 86400 + 40587;

      let x = time - now;
      if (x > 0.0099999999) x = 0.0099999999;
      else if (x < -0.0099999999) x = -0.0099999999;
      let inner = (2 * W) / (x + W) - 1;
      let adjustment = K * Math.log(inner) * 40;
      if (adjustment > NAME_ME) adjustment = NAME_ME;
      else if (adjustment < -NAME_ME) adjustment = -NAME_ME;
      const fn = positiveClockMultiplier ? Math.max : Math.min;
      const result = fn(0, 1 + adjustment);
      clockRef.current.cesiumElement.multiplier = result;
    }
  }, [injestConfig, time]); //eslint-disable-line

  useEffect(() => {
    const el = document.getElementById('viewport');
    el.addEventListener('mousemove', (event) => {
      setVisible(true);
      clearTimeout(timeoutRef.current);
      // Don't set timeout if mouse is inside container
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        if (
          event.x <= rect.right &&
          event.x >= rect.left &&
          event.y <= rect.bottom &&
          event.y >= rect.top
        ) {
          return;
        }
      }
      timeoutRef.current = setTimeout(() => {
        setVisible(false);
      }, 3000);
    });
  }, []);

  return (
    <div ref={containerRef} className={classes.root} style={{ display: visible || 'none' }}>
      <div className={classes.playbackControlsWrapper}>
        <div className={classes.playbackControls}>
          <div className={classes.playPauseSkipWrapper}>
            <IconButton
              edge="start"
              onClick={() => {
                viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
                viewerRef.current.cesiumElement.clockViewModel.currentTime =
                  viewerRef.current.cesiumElement.clockViewModel.startTime;
                // manually set time to make sure the cesium currentTime observer never gets out of sync
                setTime(startTime);
              }}
            >
              <SkipPreviousIcon />
            </IconButton>
            <IconButton
              disabled={
                (time === startTime && !positiveClockMultiplier) ||
                (time === stopTime && positiveClockMultiplier)
              }
              ref={playRef}
              onClick={playPauseOnClick}
            >
              {animate ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <IconButton
              onClick={() => {
                viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
                viewerRef.current.cesiumElement.clockViewModel.currentTime =
                  viewerRef.current.cesiumElement.clockViewModel.stopTime;
                // manually set time to make sure the cesium currentTime observer never gets out of sync
                setTime(stopTime);
              }}
            >
              <SkipNextIcon />
            </IconButton>
          </div>
          <Slider
            className={classes.multiplierControl}
            defaultValue={cesiumClockMultiplierScaleDefault}
            step={1}
            min={-100}
            max={100}
            track={false}
            marks={[{ value: -100 }, { value: 0 }, { value: 100 }]}
            onChange={(c, v) => {
              const multiplier = calculateMultiplier(v);
              clockRef.current.cesiumElement.multiplier = multiplier;
              setPositiveClockMultiplier(multiplier > 0);
            }}
          />
        </div>
        <div className={classes.progressWrapper}>
          <Slider
            className={classes.progress}
            min={startTime}
            max={stopTime}
            step={10 ** -11}
            value={time}
            onChange={progressSliderOnChange}
          />
        </div>
        {!isLiveDemoFf(branch) && (
          <div className={classes.currentDate}>
            <IconButton
              disabled={!positiveClockMultiplier} // Cesium can't loop when playing backwards
              onClick={() => {
                setLoop((prev) => !prev);
              }}
              tooltip="Loop Playback"
              size="small"
              className={loop ? classes.activeLoopButton : classes.inactiveLoopButton}
            >
              <FontAwesomeIcon icon={faRepeat} />
            </IconButton>
            <p>{timeFormatted} UTC</p>
          </div>
        )}
      </div>
    </div>
  );
}
