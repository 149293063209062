import InputAdornment from '@material-ui/core/InputAdornment';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { ISolarCell } from 'components/general/types/power';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import useGuidance from './guidance';
import validation from './validation';

// Entities will have fields not utilized for the form so a separate form interface is required
interface IForm {
  manufacturer: string;
  partNumber: string;
  maxPowerCurrent: number | '';
  maxPowerVoltage: number | '';
  openCircuitVoltage: number | '';
  shortCircuitCurrent: number | '';
  numJunctions: number | '';
}

const defaultValues: IForm = {
  manufacturer: '',
  partNumber: '',
  maxPowerCurrent: '',
  maxPowerVoltage: '',
  openCircuitVoltage: '',
  shortCircuitCurrent: '',
  numJunctions: '',
};

interface IProps {
  control: TEntityDialogControl<ISolarCell>;
}

const Dialog = (props: IProps) => {
  // Handle props
  const { control } = props;

  // Set up styles
  const classes = useStyles();

  const entityForm = useEntityForm<ISolarCell, IForm>({
    entityTypeText: 'Solar Cell',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    additionalCreateValues: { type: 'SolarCell' },
    formikOptionalParams: {
      useGuidance,
      allowedEmptyFields: ['manufacturer'],
    },
  });

  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('openCircuitVoltage')}
            type="number"
            endAdornment={<InputAdornment position="end">V</InputAdornment>}
            label="Open Circuit Voltage"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('shortCircuitCurrent')}
            type="number"
            endAdornment={<InputAdornment position="end">A</InputAdornment>}
            label="Short Circuit Current"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('maxPowerVoltage')}
            type="number"
            endAdornment={<InputAdornment position="end">V</InputAdornment>}
            label="Max Power Voltage"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('maxPowerCurrent')}
            type="number"
            endAdornment={<InputAdornment position="end">A</InputAdornment>}
            label="Max Power Current"
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('numJunctions')}
            type="number"
            label="Number of Junctions"
          />
        </div>
      </div>
    </EntityDialog>
  );
};

export default Dialog;
