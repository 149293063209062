import { EXPIRED_WORKSPACE_PERMISSIONS } from 'config';
import useWorkspace from './useWorkspace';
import useWorkspaceRoles from './useWorkspaceRoles';

// This hook uses function overloads to indicate that return type depends on input type
// https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads

/**
 * Checks if user has the given permissions in the current workspace.
 *
 * @param {number | number[]} permissions A permission or list of permissions, each given as an integer.
 * @returns A boolean or list of booleans indicating if user has each respective permission, aligned with input.
 */
function usePermissionCheck(permissions: number): boolean;
function usePermissionCheck(permissions: number[]): boolean[];
function usePermissionCheck(permissions: number | number[]): boolean | boolean[] {
  const workspace = useWorkspace();
  const roles = useWorkspaceRoles();

  let userPermissions = roles.reduce((total, r) => total | r.permissions, 0);
  if (!workspace?.isActive) userPermissions &= EXPIRED_WORKSPACE_PERMISSIONS;

  if (Array.isArray(permissions)) return permissions.map((p) => (userPermissions & p) === p);
  else return (userPermissions & permissions) === permissions;
}

export default usePermissionCheck;
