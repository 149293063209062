import { DATA_HANDLING } from 'components/AgentTemplateEditView/menu/dataHandling';
import { IMenu } from 'components/general/types';
import { CHART_SPEC, SUB_ITEMS, TITLE } from 'config';
import { routePathsCommon } from 'routes';
import {
  tsDataStorageChartSpec,
  tsDataTransferChartSpec,
  tsDataModeChartSpec,
} from '../AnalyzeBoards/DataHandlingAnalyzeBoard/timeSeries';

const { TS } = routePathsCommon;
const DATA_STORAGE = 'data-storage';
const DATA_TRANSFER = 'data-transfer';
const DATA_MODE = 'data-mode';

const agentDataHandlingMenu: IMenu['items'] = {
  [DATA_HANDLING]: {
    [TITLE]: 'Data Handling',
    [SUB_ITEMS]: {
      [TS]: {
        [TITLE]: 'Time Series',
        [SUB_ITEMS]: {
          [DATA_STORAGE]: {
            [TITLE]: 'Data Storage',
            [CHART_SPEC]: tsDataStorageChartSpec,
          },
          [DATA_TRANSFER]: {
            [TITLE]: 'Data Transfer',
            [CHART_SPEC]: tsDataTransferChartSpec,
          },
          [DATA_MODE]: {
            [TITLE]: 'Data Modes',
            [CHART_SPEC]: tsDataModeChartSpec,
          },
        },
      },
    },
  },
};
export const wGroupIndicesAgentDataHandling = {
  DATA_HANDLING: `${DATA_HANDLING}*`,
  TS_DATA_STORAGE: `${DATA_HANDLING}.${TS}.${DATA_STORAGE}`,
  TS_DATA_TRANSFER: `${DATA_HANDLING}.${TS}.${DATA_TRANSFER}`,
};

export default agentDataHandlingMenu;
