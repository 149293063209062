//@ts-nocheck
import { Grid } from '@material-ui/core';
import GuidanceCard from 'components/general/GuidanceCard';
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IProject } from 'components/general/types';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { GENERIC_ERROR_MESSAGE } from 'config';
import { useFormikForm, useSnackbar } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import useWorkspace from 'hooks/useWorkspace';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import * as Yup from 'yup';

const defaultValues = {
  name: '',
  description: '',
};

const projectSchema = Yup.object().shape({
  name: Yup.string()
    .required('A project name is required')
    .max(256, 'Project name must be no more than 256 characters'),
  description: Yup.string().max(1000, 'Project description must be no more than 1000 characters'),
});

const ProjectDialog = (props: { control: TEntityDialogControl<IProject> }) => {
  const { control } = props;

  // Utils
  const { closeDialog: onClose, dialogConfig: config } = control;
  const { open, action, entity: project } = config;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Endpoints
  const {
    Project: {
      actions: { createProject, updateProject, deleteProject, clearProject },
    },
  } = SatelliteApi;

  // Workspace
  const workspace = useWorkspace();

  // Api callers
  const addProject = (values) => {
    setLoading(true);
    dispatch(
      createProject({
        ...values,
        workspace: workspace.id,
        successCallback: () => {
          exitOnClose();
          enqueueSnackbar('Project created successfully', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          enqueueSnackbar(response?.error?.message || GENERIC_ERROR_MESSAGE);
          setLoading(false);
        },
      })
    );
  };

  const editRepo = (values) => {
    setLoading(true);
    dispatch(
      updateProject({
        id: project.id,
        ...values,
        successCallback: () => {
          exitOnClose();
          enqueueSnackbar('Project updated successfully', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          enqueueSnackbar(response?.error?.message || GENERIC_ERROR_MESSAGE);
          setLoading(false);
        },
      })
    );
  };

  // Form stuff
  let prompt, submit, subheader;
  switch (action) {
    case 'create':
      prompt = 'Create a project';
      submit = addProject;
      break;
    default:
      prompt = 'Edit project';
      submit = editRepo;
      break;
  }

  const { formik } = useFormikForm(defaultValues, submit, projectSchema, project, {
    allowedEmptyFields: ['description'],
  });

  const { handleSubmit, getFieldProps, resetForm } = formik;
  const exitOnClose = () => {
    onClose();
    setTimeout(() => resetForm(), 200);
  };

  // Different dialog for delete and clear
  if (action === 'delete' || action === 'clear') {
    // Vary inputs based on action
    const isDelete = action === 'delete';

    return (
      <DeleteEntityDialog
        action={isDelete ? deleteProject : clearProject}
        queryParams={isDelete ? { delete: true } : { clear: true }}
        entity={project}
        entityTypeText={'Project'}
        childEntities={isDelete ? 'repositories, branches, simulations, data' : undefined}
        onSuccess={
          isDelete
            ? () => {
                history.replace(Routes.WORKSPACE(workspace.id, 'repositories'));
              }
            : undefined
        }
        onClose={exitOnClose}
        open={open}
        variant={action}
        warningMessage={
          isDelete
            ? undefined
            : 'This will move all repositories in this project to the parent workspace.'
        }
        doubleConfirmDelete={isDelete}
      />
    );
  }

  return (
    <Dialog
      prompt={prompt}
      open={open}
      onSubmit={handleSubmit}
      onClose={exitOnClose}
      loading={loading}
      large
    >
      {subheader && <p>{subheader}</p>}
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <LabeledInput
              {...getFieldProps('name')}
              label="Project Name"
              type="text"
              placeholder="Project Name"
              autoFocus
            />
            <LabeledInput
              {...getFieldProps('description')}
              label="Project Description"
              type="text"
              placeholder="Project Description"
              multiline
              rows={10}
              optional
            />
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard
            guidance={{
              heading: 'Projects',
              body: `Projects are an organizational tool for reducing clutter in your workspace and associating related repositories.
              
              Design your projects in a way that is most useful for your team; for example, \
              you may choose to group repositories by mission, team, data type, or a mix of several factors. \
              
              Once you've created a project, you can add or remove repositories via the drag-and-drop interface.`,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ProjectDialog;
