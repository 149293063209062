//@ts-nocheck
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IWorkspace } from 'components/general/types';
import { GENERIC_ERROR_MESSAGE } from 'config';
import { useFormikForm, useSnackbar } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import * as Yup from 'yup';

const defaultValues = {
  name: '',
  description: '',
};

const workspaceSchema = Yup.object().shape({
  name: Yup.string()
    .required('A workspace name is required')
    .max(256, 'Workspace name must be no more than 256 characters'),
  description: Yup.string().max(1000, 'Workspace description must be no more than 1000 characters'),
});

const WorkspaceDialog = (props: { control: TEntityDialogControl<IWorkspace> }) => {
  const { control } = props;
  const { closeDialog: onClose, dialogConfig: config } = control;
  const { open, action, entity: workspace } = config;

  const {
    Workspace: {
      actions: { createWorkspace, updateWorkspace, deleteWorkspace },
    },
  } = SatelliteApi;

  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const addWorkspace = (values) => {
    setLoading(true);
    dispatch(
      createWorkspace({
        ...values,
        successCallback: () => {
          exitOnClose();
          enqueueSnackbar('Workspace created successfully', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          enqueueSnackbar(response?.error?.message || GENERIC_ERROR_MESSAGE);
          setLoading(false);
        },
      })
    );
  };

  const editRepo = (values) => {
    setLoading(true);
    dispatch(
      updateWorkspace({
        id: workspace.id,
        ...values,
        successCallback: () => {
          exitOnClose();
          enqueueSnackbar('Workspace updated successfully', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          enqueueSnackbar(response?.error?.message || GENERIC_ERROR_MESSAGE);
          setLoading(false);
        },
      })
    );
  };

  let prompt, submit, subheader;
  switch (action) {
    case 'create':
      prompt = 'Create a workspace';
      submit = addWorkspace;
      subheader = 'Create a workspace by filling out the fields below.';
      break;
    default:
      prompt = 'Edit workspace';
      submit = editRepo;
      break;
  }

  const { formik } = useFormikForm(defaultValues, submit, workspaceSchema, workspace, {
    allowedEmptyFields: ['description'],
  });

  const { handleSubmit, getFieldProps, resetForm } = formik;
  const exitOnClose = () => {
    onClose();
    setTimeout(() => resetForm(), 200);
  };

  if (action === 'delete') {
    return (
      <DeleteEntityDialog
        action={deleteWorkspace}
        entity={workspace}
        entityTypeText={'Workspace'}
        childEntities="roles, repositories, licenses"
        onSuccess={() => {
          history.push(Routes.ROOT());
        }}
        onClose={exitOnClose}
        open={open}
        doubleConfirmDelete={true}
      />
    );
  }

  return (
    <Dialog
      prompt={prompt}
      open={open}
      onSubmit={handleSubmit}
      onClose={exitOnClose}
      loading={loading}
    >
      {subheader && <p>{subheader}</p>}
      <LabeledInput
        {...getFieldProps('name')}
        label="Workspace Name"
        type="text"
        placeholder="Workspace Name"
        autoFocus
      />
      <LabeledInput
        {...getFieldProps('description')}
        label="Workspace Description"
        type="text"
        placeholder="Workspace Description"
        multiline
        rows={10}
        optional
      />
    </Dialog>
  );
};

export default WorkspaceDialog;
